import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AutoSizer } from 'react-virtualized';
import { Button, IconButton } from '../../UI';
import plus_icon from '../../assets/images/plus_icon.svg';
import { TickerCard } from '../../blocks';
import urlStore from '../../stores/urlStore';
import Lang from '../Lang';
import modalStore from '../ModalManager/modalStore';
import styles from './Collection.module.scss';

import PortfolioStore from './PortfolioStore';

const Collection = () => {
  const navigate = useNavigate();
  const { collectionId } = useParams();
  const collectionData = PortfolioStore.collectionData[collectionId];

  const handleFirstButtonClick = () => {
    const event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      button: 0,
    });
    const secondButton = document.getElementById('secondButton');
    secondButton.dispatchEvent(event);
  };
  useEffect(() => {
    window.scroll(0, 0);
    urlStore.setSection('favorites');
    if (collectionId) PortfolioStore.getCollection(collectionId);
    // PortfolioStore.getCollection(collectionId)
  }, [collectionId]);

  // if (!collectionData || collectionData.load) return null;

  return (
    <>
      <div className={styles.header}>
        <Row>
          <IconButton icon="arrow-left" onClick={() => navigate('/favorites')} />
          <Col flex={'auto'} />
          {!collectionData?.userId && (
            <Col flex={'auto'}>
              <Button
                style={{ float: 'right' }}
                onClick={() => PortfolioStore.collectionClone(collectionId)}
                variant="text">
                <img src={plus_icon} />
                <span>{Lang({ str: 'copy', section: 'portfolio' })}</span>
              </Button>
            </Col>
          )}

          {!!collectionData?.userId && (
            <>
              <Col flex={'50px'}>
                <IconButton
                  id="secondButton"
                  variant="contained"
                  icon="plus"
                  onClick={() =>
                    modalStore.openModal('collectionAddTicker', {
                      collectionId,
                    })
                  }
                />
              </Col>
              <Col flex={'40px'}>
                {/* <CollectionModalEdit collectionId={collectionId} /> */}
                <IconButton icon='pencil' onClick={() => modalStore.openModal('collectionEdit', { collectionId })} />
              </Col>
            </>
          )}
        </Row>
      </div>

      <div className="pt-16">
        <Row>
          {/* <Col flex={'110px'}>
            <ImagePreview
              src={collectionData?.logo}
              name={collectionData?.name}
              iconNoPhoto={false}
              style={{ borderRadius: 20, width: 100, height: 100 }}
            />
          </Col> */}
          <Col flex={'auto'} style={{ overflow: 'hidden' }}>
            <AutoSizer disableHeight>
              {({ width }) => (
                <p className={styles.title} style={{ width }}>
                  {collectionData?.name || 'Без имени'}
                </p>
              )}
            </AutoSizer>

            <div className={styles.text_one}>
              {Lang({ str: 'stocks', section: 'portfolio' })}: {''}
              {!!collectionData?.tickers ? collectionData?.tickers?.length : 0}
            </div>
            {collectionData?.tickers?.length > 0 && (
              <div
                className={
                  collectionData?.percent < 0 ? 'minus' : collectionData?.percent > 0 ? 'plus' : ''
                }
                style={{ fontFamily: 'GolosText-Medium' }}>
                {collectionData?.percent < 0 && '-'}
                {Math.abs(collectionData?.percent)}%{' '}
              </div>
            )}
          </Col>
        </Row>
      </div>

      <div className="pt-16" style={{ marginBottom: 100 }}>
        {collectionData?.tickers &&
          collectionData.tickers.length > 0 &&
          collectionData.tickers.map((tickerId, index) => (
            <div key={index} style={{ marginTop: index && 10 }}>
              <TickerCard tickerId={tickerId} withTrading={false} />
            </div>
          ))}

        {collectionData?.tickers && collectionData.tickers.length === 0 && (
          <div className={styles.not_tickers}>
            <p>{Lang({ str: 'no_stocks', section: 'portfolio' })}</p>
            <span>{Lang({ str: 'follow_companies', section: 'portfolio' })}</span>
            <Button variant="secondary" size="sm" autoWidth onClick={handleFirstButtonClick}>
              {Lang({ str: 'add', section: 'portfolio' })}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default observer(Collection);
