import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { Button } from '../../UI';
import { $class } from '../../Utils';
import Lang from '../Lang';
import styles from './index.module.scss';

const ModalConfirmButton = ({
  confirmTitle,
  okLabel = Lang({ str: 'yes', section: 'default' }),
  cancelLabel = Lang({ str: 'no', section: 'default' }),
  btnLabel,
  closeModal,
  onClick,
  loading,
  className
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={$class(styles.modalConfirm, className)}>
      <Button
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}>
        {btnLabel}
      </Button>

      <div className={$class(styles.confirmBlock, [styles.confirmBlockActive, open])}>
        <p className={styles.confirmTitle}>{confirmTitle}</p>
        <Row gutter={20} style={{ margin: '8px 16px 24px 16px' }}>
          <Col flex={'auto'}>
            <Button
              variant="outlined"
              className={styles.btnOk}
              loading={loading}
              onClick={() => {
                setOpen(false);
                if (onClick) onClick();
                if (closeModal) closeModal();
              }}>
              {okLabel}
            </Button>
          </Col>
          <Col flex={'auto'}>
            <Button
              loading={loading}
              className={styles.btnCancel}
              onClick={() => {
                setOpen(false);
              }}>
              {cancelLabel}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ModalConfirmButton;
