import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { $class } from '../../Utils';
import { SearchInput } from '../../blocks';
import Lang from '../Lang';
import tickersSearchList from '../Portfolio/tickers';
import styles from './index.module.scss';

const SearchList = ({
  search,
  children,
  height,
  withInput,
  onChange,
  defaultInput,
  className,
  itemHeight = 72,
  content
}) => {
  const timeOutId = useRef(null);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    clearTimeout(timeOutId.current);

    search = search.trim();

    if (search === '' && defaultInput) {
      search = defaultInput;
    }

    if (search === '') {
      setFiltered([]);
      setPage(1);
      setLoading(false);
      return;
    }

    setLoading(true);
    timeOutId.current = setTimeout(() => {
      let list1_ = [];
      let list2_ = [];

      for (let item of tickersSearchList) {
        if (!item) continue;
        if (item.symbol.toUpperCase().indexOf(search.toUpperCase()) !== -1) list1_.push(item);
        else if (item.name.toUpperCase().indexOf(search.toUpperCase()) !== -1) list2_.push(item);
      }
      let list_ = [...list1_, ...list2_];

      setFiltered(list_);
      setPage(1);
      setLoading(false);
    }, 300);
  }, [search, defaultInput]);

  const scrollHandler = ({ scrollHeight, clientHeight, scrollTop }) => {
    if (scrollHeight - clientHeight === scrollTop) {
      setPage((x) => x + 1);
    }
  };

  const rows = filtered.slice(0, page * 20);

  return (
    <div className={$class(styles.searchList, className)}>
      {withInput && onChange && (
        <SearchInput
          placeholder={Lang({ str: 'search', section: 'default' })}
          value={search}
          onChange={(e) => onChange(e.target.value)}
        />
      )}

      <div className={styles.searchListWrap} onScroll={scrollHandler} style={{ height }}>
        {content}

        {!loading && !filtered.length && search.trim() !== '' && (
          <div className={styles.searchListEmpty}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 2V6M12 18V22M6 12H2M22 12H18M19.0784 19.0784L16.25 16.25M19.0784 4.99994L16.25 7.82837M4.92157 19.0784L7.75 16.25M4.92157 4.99994L7.75 7.82837"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <h4>{Lang({ str: 'no_results', section: 'home' })}</h4>
          </div>
        )}

        {loading && (
          <Spin
            className={styles.loader}
            indicator={
              <LoadingOutlined style={{ fontSize: 30, color: 'var(--basic-dark)' }} spin />
            }
          />
        )}

        {!!rows.length && (
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowCount={rows.length}
                rowHeight={itemHeight}
                onScroll={scrollHandler}
                rowRenderer={({
                  key, // Unique key within array of rows
                  index, // Index of row within collection
                  isScrolling, // The List is currently being scrolled
                  isVisible, // This row is visible within the List (eg it is not an overscanned row)
                  style, // Style object to be applied to row (to position it)
                }) => {
                  const item = rows[index];

                  return children(item, key, style);
                }}
              />
            )}
          </AutoSizer>
        )}
      </div>
    </div>
  );
};

export default SearchList;
