import {Button, Col, Form, message, Row} from "antd";
import styles from "./BrokerAccountNew.module.scss";
import Lang from "../Lang";
import clear_ic from "../../assets/images/clear_ic.svg";
import React, {useEffect, useRef, useState} from "react";
import AppStore from "../App/AppStore";
import BrokerAccountStore from "./BrokerAccountStore";
import AlertStore from "../Alert/AlertStore";
import SignaturePad from 'react-signature-pad';
import './Sign.css'
import {observer} from "mobx-react-lite";

const Sign2 = ({setBack}) => {
  const canvasRef = useRef(null);
  const [sing, setSing] = useState('');
  const [load, setLoad] = useState(false);
  const w = AppStore.width;
  let width = w > 500 ? 500 : w - 32;
  if (width < 200) width = 200

  const onChange = (v) => {
    setSing(v)
  }

  const onFinish = async () => {
    if (sing === '') return AlertStore.error(Lang({str: "make_signature", section: "onboarding"}))
    let body = {
      view: "brokerSuccess",
      step: 1,
      sign: sing,
    };
    setLoad(true)
    await BrokerAccountStore.save(body);
    setLoad(false)
  }

  useEffect(() => {
    console.log('CanvasDraw открыт')

    setBack({
      view: "questionnaire",
      step: 1,
    });

    return () => {
      console.log('CanvasDraw закрыт')
    }
  }, []);

  setTimeout(() => {
    canvasRef.current.fromDataURL(localStorage.getItem("savedDrawing"));
  }, 100)

  return <>
  <Row>
    <Col flex={"auto"}>
      <p className={styles.text_500}>
        {Lang({str: "make_signature", section: "onboarding"})}
      </p>
    </Col>
    <Col flex={"20px"}>
      <div style={{textAlign: "right"}}>
        <Button
          className={styles.clear_ic}
          type="dashed"
          onClick={() => {
            onChange("");
            localStorage.removeItem("savedDrawing");
            canvasRef.current.clear();
          }}
        >
          <img src={clear_ic} alt={''}/>
          {Lang({str: "clear", section: "default"})}
        </Button>
      </div>
    </Col>
  </Row>

  <div style={{height: width, width: width}} className={styles.signDiv}>
    <SignaturePad ref={canvasRef}
                  penColor={"#177ddc"}
                  minWidth={2}
                  maxWidth={5}
                  onEnd={() => {
                    const d = canvasRef.current.toDataURL()
                    localStorage.setItem("savedDrawing", d);
                    onChange(d)
                  }}
    />
  </div>

  <Form.Item className={styles.buttonBlockFixed + " m-0 b-0"}>
    <Button
        type="primary"
        htmlType="submit"
        className={styles.gr_btn + " " + styles.activ}
        onClick={onFinish}
        loading={load}
      >
        {Lang({str: "continue", section: "onboarding"})}
      </Button>
    </Form.Item>
  </>
}

export default observer(Sign2);