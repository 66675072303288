import { LoadingOutlined } from "@ant-design/icons";
import {Col, Layout, Row, Spin} from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";
import userStore from "../components/Auth/userStore";
import blocked_warning from '../assets/images/blocked_warning.svg'
import Lang from "../components/Lang";
import exit1 from "../assets/images/exit1.svg";
import styles from './Layout.module.scss'
import {Button} from "../UI";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "var(--basic-dark)" }} spin />
);

const CabinetLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = userStore.auth;

  useEffect(() => {
    userStore.authCheck().catch();
  }, []);

  if (auth === null)
    return (
      <div
        className="example"
        style={{
          margin: "20px 0",
          marginBottom: "20px",
          padding: "30px 50px",
          textAlign: "center",
        }}
      >
        <Spin indicator={antIcon} />
      </div>
    );

  if (auth === false) {
    navigate("/login");
    return null;
  }

  if (
    !userStore?.userData?.emailVerified &&
    !location.pathname.includes("verify_email")
  ) {
    navigate("/verify_email");
    return null;
  }

  if (userStore?.userData?.blocking) return (
    <div className={styles.blocked_page}>
      <img src={blocked_warning} alt=''/>
      <div>
        <p>{Lang({str: "acc_blocked", section: "home"})}</p>
        <p>{Lang({str: "with_support", section: "home"})}</p>
        <p>{Lang({str: "why_blocked", section: "home"})}</p>
      </div>
      <Button
        variant="outlined"
        style={ {
          width: 'fit-content',
          borderColor: '#EAEAEA',
          fontFamily: 'GolosText-Medium, sans-serif',
          color: '#959595',
          fontSize: '14px',
          padding: '8px 16px'
        } }
        onClick={() => {
          userStore.logout();
          navigate('/login');
        }}
      >
        <img src={exit1} alt="" style={ {marginRight: '6px'} }/>
        {Lang({ str: 'exit', section: 'default' })}
      </Button>
    </div>
  )

  return (
    <Layout>
      {!location.pathname.includes("broker_account") ? (
        <div className={"container"}>
          <Row id="cabinet-wrapper" style={{transition: "0.6s"}}>
            <Col span={24}>
              <Outlet/>
            </Col>
          </Row>

          {/* <SideBarBottom/> */}
        </div>
      ) : (
        <Outlet/>
      )}
    </Layout>
  );
};

export default observer(CabinetLayout);
