import { LoadingOutlined } from '@ant-design/icons';
import { Form, InputNumber, Spin, Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Clickable } from '../../UI';
import { $class, apiRequest } from '../../Utils';
import between2 from '../../assets/images/between2.svg';
import plus_1 from '../../assets/images/plus_1.svg';
import vivod from '../../assets/images/vivod21.svg';
import { BackButton } from '../../blocks';
import DollarSign from '../../blocks/DollarSign';
import AlertStore from '../Alert/AlertStore';
import AppStore from '../App/AppStore';
import userStore from '../Auth/userStore';
import Lang from '../Lang';
import ModalSwipe from '../ModalSwipe/ModalSwipe';
import NumberFormat from '../NumberFormat';
import BankAccountsStore from '../PersonalInformation/BankAccountsStore';
import TitleCategory from '../TitleCategory';
import AccountStore from './AccountStore';
import styles from './Accounts.module.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#7A24EA' }} spin />;

const onChange = (value) => {
  console.log(`selected ${value}`);
};
const numberFStyles = {
  styleWholePart: { fontSize: 32 },
  styleFractionalPart: { fontSize: 24, color: 'var(--basic-dark40)' },
};

const WithdrawalBrokerageAccount = () => {
  const bankAccountsItems = BankAccountsStore.items;
  const bankAccountsItemFavorite = BankAccountsStore.itemFavorite;
  const [bankAccountSelected, setBankAccountSelected] = useState(null);
  const [load, setLoad] = useState(false);
  const { TabPane } = Tabs;
  const [tab, setTab] = useState('1');
  function callback(key) {
    setTab(key);
  }
  // let params = useParams();
  const location = useLocation();
  // const navigate = useNavigate();
  const navigate = useNavigate();
  // let {to} = params
  const [amount, setAmount] = useState(0);
  const [commission, setCommission] = useState(0);
  const [buttonActive, setButtonActive] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen1, setModalOpen1] = useState(false);
  const [code, setCode] = useState('');
  const [to, setTo] = useState('trading');

  // const [modalConfirm, setModalConfirm] = useState(false);

  // console.log(to,'to')

  // let to = "trading";
  const numberFormat2 = (v) => {
    v = String(v).replace(',', '.');
    return String(v.replace(/(\d)(?=(\d{3})+((\.+\d*$)|$))/g, '$1 '));
  };
  const checkRoutes = () => {
    if (to === AccountStore.transferFrom) {
      // to = "brokerage";
      setTo('brokerage');
      // AccountStore.transferFrom ='trading'
    }
    // else{
    //   AccountStore.transferFrom ="brokerage"
    // }

    if (!AccountStore.transferFrom) {
      if (to === 'trading') {
        AccountStore.transferFrom = 'brokerage';
      } else {
        AccountStore.transferFrom = 'trading';
      }
    }
  };

  checkRoutes();

  const Commissions = {
    transferTobrokerage: {
      client: {
        min: 70,
        max: 70,
        koef: 0.002,
      },
      worker: {
        min: 1,
        max: 1,
        koef: 0.002,
      },
    },
    transferTotrading: {
      client: {
        min: 32,
        max: 215,
        koef: 0.002,
      },
      worker: {
        min: 1,
        max: 1,
        koef: 0.002,
      },
    },
  };

  const getTransferCommissionLimits = (user, route) => {
    let role = 'client';

    if (user?.role?.worker) {
      role = 'worker';
    }

    const limits = Commissions['transferTo' + route][role];

    return { minCom: limits.min, maxCom: limits.max, koef: limits.koef };
  };

  const { minCom, maxCom, koef } = getTransferCommissionLimits(userStore.userData, to);
  // console.log(userStore.userData, 'aaaaaaaaaaaaaaaaaaaa')

  const onFinish = (values) => {
    console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onChange = (value) => {
    console.log('changed', value);

    if (value > 0) {
      setButtonActive(true);
    } else {
      setButtonActive(false);
    }

    setAmount(value);
    // commissionProcess(value)

    setCommission(getCommission(value, minCom, maxCom, koef));
  };

  const commissionProcess = (amount) => {
    const commission = getCommission(amount, minCom, maxCom, koef);

    setCommission(commission);
  };

  const getCommission = (amount, min, max, koef = 0.002) => {
    let commission = Number(amount) * koef;

    if (commission > max) {
      commission = max;
    }
    if (commission < min) {
      commission = min;
    }

    return commission;
  };

  const pay = (cb) => {
    console.log({ amount });

    apiRequest({
      url: `/private/v1/createTransfer/`,
      method: 'POST',
      body: {
        code,
        amount,
        route: AccountStore.transferFrom === 'trading' ? 'brokerage' : 'trading',
      },
      success: (data) => {
        if (data?.error) {
          cb(false);
          return AlertStore.error(data?.error.msg);
        }
        console.log({ data });
        if (data?.status == 'ok') {
          AlertStore.success(Lang({ str: 'transfer_created', section: 'accounts' }));
          AccountStore.refreshHistory = true;
          setAmount(0);
          setCommission(0);
          setButtonActive(false);
          cb(true);
          // demoPayHistory();
        }
      },
    }).catch((error) => {
      console.error({ error });
    });
  };
  useEffect(() => {
    AccountStore.getAccounts();

    setLoad(true);
    BankAccountsStore.list()
      .then(() => setLoad(false))
      .catch(() => setLoad(false));
  }, []);
  useEffect(() => {
    checkRoutes();
  }, [to]);

  console.log(AccountStore.transferFrom, 'AccountStore.transferFrom');

  const items = {
    brokerage: {
      title: Lang({ str: 'broker_acc', section: 'accounts' }),
      price: AccountStore.accounts.brokerage.price,
    },
    trading: {
      title: Lang({ str: 'trading_acc', section: 'accounts' }),
      price: AccountStore.accounts.trading.price,
    },
  };
  const categoryConfig = [
    {
      title: Lang({ str: 'transfer_between1', section: 'accounts' }),
    },
  ];

  useEffect(() => {
    if (bankAccountsItemFavorite && !bankAccountSelected)
      setBankAccountSelected(bankAccountsItemFavorite);
  }, [bankAccountsItemFavorite]);
  console.log(userStore.userData);
  console.log('aaaaaaaaaaaaaaaa');
  return (
    <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <div className={styles.fixed_bl}>
        <BackButton label={Lang({ str: 'back', section: 'default' })} />
      </div>
      <div className={styles.account_page + ' pb-56'}>
        <div className={styles.center_text}>
          <TitleCategory title={categoryConfig[0].title} />
        </div>

        <div className={styles.tabs_block + ' mt-8'}>
          <div className={styles.tabs1}>
            <div className={styles.one_accounts + ' mt-16 '}>
              <p>{Lang({ str: 'broker_acc', section: 'accounts' })} Geeko Invest Limited</p>
              <span>
                <DollarSign size={16} top={3} right={2} />
                {NumberFormat({
                  value: items[AccountStore.transferFrom]?.price,
                  ...numberFStyles,
                })}
              </span>
            </div>
            <button className={styles.between}>
              <img src={between2} />
            </button>
            <div className={styles.one_accounts}>
              <div
                className={styles.one_links}
                onClick={() => {
                  setModalOpen(true);
                }}>
                {!bankAccountSelected && (
                  <div>
                    <p>{Lang({ str: 'choose_where', section: 'accounts' })}</p>
                    <span>... ...</span>
                  </div>
                )}

                {bankAccountSelected && (
                  <div>
                    <p
                      style={{
                        margin: '5px 0',
                      }}>
                      {Lang({ str: 'bank_acc', section: 'accounts' })}{' '}
                      {BankAccountsStore.banks[bankAccountSelected.bankRecipient]}
                    </p>
                    <p
                      style={{
                        color: '#221f1c',
                      }}>
                      ****************{bankAccountSelected.iban.slice(-4)}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.block_valute}>
              <Form
                name="basic"
                onFinish={onFinish}
                labelCol={{
                  span: 24,
                }}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
                <Form.Item
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: Lang({
                        str: 'enter_amount',
                        section: 'accounts',
                      }),
                    },
                  ]}>
                  <label htmlFor="transfer-input" className={styles.inputLabel}>
                    {Lang({ str: 'transfer_amount', section: 'accounts' })}
                  </label>

                  <InputNumber
                    inputmode="decimal"
                    pattern="(^0-9.)"
                    id="transfer-input"
                    defaultValue={0}
                    min={0}
                    formatter={(value) => `${value}`}
                    parser={(value) => value.replace('$', '')}
                    onChange={onChange}
                    value={amount}
                  />
                  <p className={styles.commis}>
                    {Lang({ str: 'fee', section: 'accounts' })}{' '}
                    <DollarSign size={8} top={2} right={1} color={'#d5b610'} />
                    {commission}
                  </p>
                </Form.Item>

                <Form.Item className={styles.buttonBlockFixed + ' mt-32'}>
                  {/* <ModalSMSVerify
                    button={
                      <Button variant="primary" disabled={!buttonActive}>
                        Подтвердить
                      </Button>
                    }
                    onChange={(v) => setCode(v)}
                    onClick={pay}
                    verify={() => {
                      console.log(amount);
                      let result = amount && amount > 0;
                      if (!result) AlertStore.error('Введите сумму перевода');
                      return result;
                    }}
                  /> */}
                </Form.Item>
              </Form>
            </div>
            <div className={styles.vivod_bl}>
              <div className={styles.vivod_bl_top}>
                <img src={vivod} /> <p>{Lang({ str: 'order_withdrawal', section: 'accounts' })}</p>
              </div>
              <div className={styles.vivod_item}>
                <span>{Lang({ str: 'iin', section: 'accounts' })}:</span>
                <p>{userStore.userData?.iin}</p>
              </div>
              <div className={styles.vivod_item}>
                <span>{Lang({ str: 'name', section: 'accounts' })}:</span>
                <p>{userStore.userData?.fio}</p>
              </div>
              <div className={styles.vivod_item}>
                <span>{Lang({ str: 'address', section: 'accounts' })}:</span>
                <p>{userStore?.userData?.address?.[AppStore.lang] || ''}</p>
              </div>
              {/* <div className={styles.vivod_item}>
                <span>Откуда:</span>
                <p>Брокерский счет №12345678</p>
              </div>
              <div className={styles.vivod_item}>
                <span>Куда:</span>
                <p>Счет Halyk Bank **** 7743</p>
              </div>
              <div className={styles.vivod_item}>
                <span>Сумма:</span>
                <p>
                  <DollarSign size={16} top={3} right={2} />
                  {NumberFormat({
                    value: items[AccountStore.transferFrom]?.price,
                    ...numberFStyles,
                    styleWholePart: { fontSize: 16, color: '#221f1c' },
                    styleFractionalPart: { fontSize: 16, color: '#221f1c' },
                  })}
                </p>
              </div>
              <div className={styles.vivod_item}>
                <span>Комиссия вывода:</span>
                <p>
                  <DollarSign size={8} top={2} right={1} />
                  {commission}
                </p>
              </div>*/}
              <span className={styles.lit_text}>
                {Lang({ str: 'fee_warning', section: 'accounts' })}{' '}
              </span>
            </div>
          </div>
        </div>
      </div>

      <ModalSwipe
        visible={modalOpen}
        cancelText=""
        cancelVisible={false}
        okVisible={false}
        onCancel={() => {
          setModalOpen(false);
        }}>
        <div className={styles.list_bb}>
          <div className="p-16">
            <Clickable
              onClick={() => navigate('/user/my_accounts/add_account', { state: location })}
              className={$class(styles.link_bl)}>
              <img src={plus_1} />
              {Lang({ str: 'add_new_acc', section: 'menu' })}
            </Clickable>

            <div className={styles.accounts_bb}>
              <p>{Lang({ str: 'bank_accounts', section: 'menu' })}:</p>
              {load && <Spin indicator={antIcon} />}
              {!load &&
                bankAccountsItems.map((item, index) => (
                  <div
                    key={index}
                    className={styles.one_account}
                    onClick={() => {
                      setBankAccountSelected(item);
                      setModalOpen(false);
                    }}
                    style={{ cursor: 'pointer' }}>
                    <p>
                      {Lang({ str: 'acc', section: 'menu' })}{' '}
                      {BankAccountsStore.banks[item.bankRecipient]}
                    </p>
                    <p>****************{item.iban.slice(-4)}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </ModalSwipe>
    </div>
  );
};

export default observer(WithdrawalBrokerageAccount);
