import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';
import React from 'react';

function max_min(data) {
  let max = null;
  let min = null;
  let proc = 100;

  for (let d of data) {
    if (!max || d.y > max) max = d.y;
    if (!min || d.y < min) min = d.y;
  }

  if (max && min) {
    proc = (((max - min) * 100) / max).toFixed(2);
  }

  return {
    max,
    min,
    proc,
  };
}

const WalletChart = ({ data }) => {
  data = data || [];

  return (
    <ResponsiveLine
      data={[
        {
          id: 1,
          data,
        },
      ]}
      colors={['var(--basic-dark)']}
      lineWidth={2}
      margin={{ top: 15, right: 1, bottom: 40, left: 1 }}
      xScale={{ type: 'linear' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      curve={'monotoneX'}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      enableGridX={false}
      enableGridY={false}
      enablePoints={false}
      enablePointLabel={false}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={0}
      enableArea={true}
      areaBlendMode="luminosity"
      isInteractive={false}
      enableCrosshair={false}
      legends={[]}
      defs={[
        linearGradientDef('gradientA', [
          { offset: 0, color: 'var(--basic-dark)' },
          { offset: 100, color: 'transparent' },
        ]),
      ]}
      fill={[{ match: '*', id: 'gradientA' }]}
    />
  );
};

export default WalletChart;
