import SumSubUpdate from "./SumSubUpdate";
import { apiQueryError, apiRequest } from "../../Utils";
import { Button, message, Space, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../blocks";
import styles from "../BrokerAccountNew/BrokerAccountNew.module.scss";
import React, {useCallback, useEffect, useRef, useState} from "react";
import Lang from "../Lang";
import AlertStore from "../Alert/AlertStore";

const AddressPKB = () => {
  const navigate = useNavigate();
  const [load, setLoad] = useState();
  const count = useRef(0);
  const time = useRef();

  const getPKBInfo = useCallback((t = false) => {
    // Кол-во автообновлений
    if (!t && count.current > 4) return;

    setLoad(true);
    apiRequest({
      url: "/private/v1/trader/PKBInfo?docStatus=confirmationRequest",
      success: (data) => {
        count.current++;
        if (data.error) {
          setLoad(false);
          time.current = setTimeout( () => {
            getPKBInfo()
          }, 15000)
          return AlertStore.error(data.error.msg);
        }

        if (data.info) {
          setLoad(false);
          time.current = setTimeout( () => {
            getPKBInfo()
          }, 15000)
          return AlertStore.info(data.info.msg);
        }

        AlertStore.success(Lang({ str: "data_updated", section: "menu" }));
        navigate("/user/verification");
      },
      error: (error) => {
        console.error(error);
        setLoad(false);
      },
    }).catch();
  }, [navigate]);

  useEffect(() => {
    getPKBInfo();
    return () => {
      clearInterval(time.current);
    };
  }, [getPKBInfo]);

  return (
    <>
      <h4 className="bacc-title">{Lang({ str: "address_conf", section: "menu" })}</h4>
      <p style={{ marginTop: 20 }}>
        {Lang({ str: "msg_sent_1414", section: "menu" })}
      </p>
      <p style={{ marginTop: 40 }}>
        <strong>Статус:</strong>{" "}
        <span style={{ color: "#888" }}>
          {load ? <Spin /> : Lang({ str: "consent_requested", section: "menu" })}
        </span>
      </p>

      <p style={{ marginTop: 20 }}>
        <Button className="bacc-main-btn" onClick={() => getPKBInfo(true)} loading={load}>
          {Lang({ str: "update_status", section: "menu" })}
        </Button>
      </p>
    </>
  );
};

const ChangeAddress = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const onFinish = (data, setLoad = () => {}) => {
    console.log(data);
    apiRequest({
      url: "/private/v1/trader/SumSubAddressUpdate",
      method: "POST",
      body: data,
      success: (data) => {
        if (data?.error) return AlertStore.error(data.error.msg);
        AlertStore.success(Lang({ str: "data_updated", section: "menu" }));
        navigate(-1);
        setLoad(false)
      },
      error: (error) => {
        apiQueryError({ error });
        setLoad(false)
      },
    }).catch();
  };

  return (
    <div className={styles.page_fl}>
      <div className={styles.text_bl}>
        <BackButton />

        {step === 1 && (
          <div>
            <p className={styles.titile}>{Lang({ str: "reg_address", section: "menu" })}</p>
            <p className={styles.black_text}>
              {Lang({ str: "address_question", section: "menu" })}
            </p>

            <div style={{ marginTop: 20 }}>
              <Space className={styles.bacc_block}>
                <Button
                  className={styles.bacc_block_no}
                  onClick={() => setStep(2)}
                >
                  {Lang({ str: "yes", section: "default" })}
                </Button>
                <Button
                  className={styles.bacc_block_yes}
                  onClick={() => setStep(3)}
                >
                  {Lang({ str: "no", section: "default" })}
                </Button>
              </Space>
            </div>

            <div className={styles.error_block}>
              <p>
                {Lang({ str: "if_answer_no", section: "menu" })}
              </p>
            </div>
          </div>
        )}
        {step === 2 && <AddressPKB />}
        {step === 3 && (
          <SumSubUpdate
            levelName={"geekoinvestAddress"}
            resetStep={"PROOF_OF_RESIDENCE"}
            onFinish={onFinish}
          />
        )}
      </div>
    </div>
  );
};
export default ChangeAddress;
