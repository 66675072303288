import React, {useCallback, useEffect} from "react";
import styles from "../BrokerAccountNew/BrokerAccountNew.module.scss";
import { observer } from "mobx-react-lite";
import icon from "../../assets/images/IconButton.svg";
import BrokerAccountStore from "./BrokerAccountStore";
import Lang from "../Lang";

const Page4 = ({setBack}) => {

  const onForm = useCallback(() => {
    let body = {
      view: "questionnaire",
      step: 1,
    };
    BrokerAccountStore.save(body).catch();
  }, [])

  useEffect(() => {

    setTimeout(() => {
      onForm()
    }, 2000)

    setBack({
      view: "address",
      step: 1
    })
  }, [onForm, setBack])

  return (
    <div className={styles.page_fl}>
      <div className={styles.text_bl}>
        <div className={styles.center + " mt-24"}>
          <img src={icon} alt={''}/>
          <h4 className={styles.text_center}>{Lang({ str: "address_verified", section: "onboarding" })}</h4>
        </div>
      </div>
      {/*<button className={styles.start_but} onClick={onForm}>Продолжить</button>*/}
    </div>
  );
};

export default observer(Page4);
