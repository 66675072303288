import React from 'react';
import './index.scss';
import DollarSign from '../DollarSign';

const PriceNumber = ({ price = 0, fixed = 2, align = 'left', styles }) => {
  const [decimal, fraction = '00'] = price ? new Intl.NumberFormat('en-EN').format(price.toFixed(fixed)).split('.') : ['-', '--'];

  return (
    <div className="price-number" style={{ textAlign: align, ...styles }}>
      <DollarSign size={8} top={1} right={1} />
      <span className="price-number-decimal">{decimal}</span>
      <span>.</span>
      <span className="price-number-fraction">{fraction}</span>
    </div>
  );
};

export default PriceNumber;
