import React from 'react';
import { AutoSizer, List } from 'react-virtualized';
import './index.scss';

const VList = ({ items, useDynamicHeight = true, useAnimDelay = false, paddingBottom = 30, children, width, height, style, rowHeight = 80, gap = 16 }) => {

  const getHeight = (_h) => {
    if (items?.length === 0 || !items) return 0
    if (!height) return _h
    if (useDynamicHeight && (rowHeight + gap) * items.length < height) return (rowHeight + gap) * items.length + paddingBottom;
    return height;
  }

  return (
    <AutoSizer className='vlist'>
      {({ width: _w, height: _h }) => (
        <List
          width={width || _w}
          height={getHeight(_h)}
          style={{ paddingBottom: paddingBottom, ...style }}
          rowCount={items?.length || 0}
          rowHeight={rowHeight + gap}
          rowRenderer={({
            key, // Unique key within array of rows
            index, // Index of row within collection
            isScrolling, // The List is currently being scrolled
            isVisible, // This row is visible within the List (eg it is not an overscanned row)
            style, // Style object to be applied to row (to position it)
          }) => {
            const item = items[index] || null;

            if (!item) return null;

            return (
              <div style={{ ...style, ...(useAnimDelay ? ({ animationDelay: `${index * 100}ms` }) : ({}))}} key={key}>
                {children({ item })}
              </div>
            );
          }}
        />
      )}
    </AutoSizer>
  );
};

export default VList;
