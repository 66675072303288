import randomColor from 'randomcolor';
import React, { useEffect, useState } from 'react';
import { $class, shimmer } from '../../Utils';
import './index.scss';

const TickerTitle = ({ ticker, withSymbol, withDesc, size = 40, children }) => {
  // if (!ticker) return null;
  const loading = !ticker?.symbol;

  const [loadingLogo, setLoadingLogo] = useState(false);

  useEffect(() => {
    if (!ticker?.symbol) return;

    if (!ticker?.logo) {
      setLoadingLogo(false);
      return;
    }

    // if (ticker?.logo.match(/^data:/g) !== null) {
    //   setLoad(true);
    // } else {
    //   setLoad(null);
    // }

    function imageError() {
      setLoadingLogo(false);
    }
    function imageLoad() {
      setLoadingLogo(false);
    }

    const image = new Image();
    image.addEventListener("load", imageLoad);
    image.addEventListener("error", imageError);
    image.src = ticker?.logo;

    return () => {
      image.removeEventListener("load", imageLoad);
      image.removeEventListener("error", imageError);
    };
  }, [ticker?.logo]);

  return (
    <div className="ticker-title">
      <div
        className={$class('ticker-title-logo', shimmer('logo', loading || loadingLogo))}
        style={{
          width: size,
          height: size,
          lineHeight: size + 'px',
          backgroundImage: `url(${ticker?.logo})`,
        }}>
        {!ticker?.logo && ticker?.symbol && (
          <span
            style={{
              fontSize: size * 0.35,
              background: randomColor({
                seed: ticker.symbol,
                luminosity: 'dark',
                format: 'rgb', // e.g. 'rgba(9, 1, 107, 0.6482447960879654)'
              }),
            }}>
            {ticker.symbol[0]}
          </span>
        )}
      </div>
      <div className="ticker-title-info">
        {withSymbol && (
          <div className={$class('ticker-title-symbol', shimmer('text', loading))}>
            {ticker?.symbol || '_______'}
          </div>
        )}
        {withDesc && (
          <div className={$class('ticker-title-desc', shimmer('text', loading))}>
            {ticker?.dataAsset?.name || '_____________'}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default TickerTitle;
