import { LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { shimmer } from '../../Utils';
import DollarSign from '../../blocks/DollarSign';
import ImagePreview from '../ImagePreview';
import NumberFormat from '../NumberFormat';
import PortfolioStore from '../Portfolio/PortfolioStore';
import TickerStore from '../Ticker/TickerStore';
import styles from './TickerItemPopular.module.scss';

const antIcon = <LoadingOutlined style={{ fontSize: '14px', color: '#7A24EA' }} spin />;

function TickerItemPopular({ tickerId, type }) {
  let navigate = useNavigate();
  const location = useLocation();
  const item = TickerStore.tickerData[tickerId];

  useEffect(() => {
    TickerStore.getTicker(tickerId);
  }, [tickerId]);

  // if (!item)
  //   return (
  //     <div>
  //       <Row>
  //         <Col flex="40px">
  //           <ImagePreview
  //             src={null}
  //             style={{ borderRadius: 50, width: 40, height: 40 }}
  //           />
  //         </Col>
  //         <Col flex="auto" className={styles.col_width + " pl-8"}>
  //           <p className={styles.ticker_desc1}></p>
  //           <div className={styles.ticker_desc}></div>
  //         </Col>
  //       </Row>
  //       <Row style={{ marginTop: 6 }}>
  //         <Col flex="auto">$</Col>
  //         <Col flex="auto" style={{ textAlign: "right" }}></Col>
  //       </Row>
  //     </div>
  //   );

  const changePercent = item?.snapshot
    ? (
        ((item.snapshot?.dailyBar.c - item.snapshot?.prevDailyBar.c) /
          item.snapshot?.prevDailyBar.c) *
        100
      ).toFixed(2)
    : 0;

  return (
    <div
      className={styles.ticker_popular}
      style={{
        cursor: 'pointer',
        zIndex: 2,
        position: 'relative',
      }}
      onClick={() => {
        PortfolioStore.closeModalSearch();
        navigate(`/trading/${item?._id}`, { state: location });
      }}>
      <div className={styles.ticker_popular__date}>
        <div>
          <ImagePreview
            src={item?.logoData ? item?.logoData : item?.logo}
            name={item?.symbol}
            iconNoPhoto={false}
            style={{ borderRadius: 50, width: 40, height: 40 }}
          />
        </div>
        <p className={shimmer('text', !item?.symbol)}>{item?.symbol ? item.symbol : '______'}</p>
      </div>

      <div className={styles.ticker_popular__price}>
        <p>
          <DollarSign white size={9} top={2.5} right={2} />
          {NumberFormat({
            value: item?.snapshot ? item?.snapshot?.dailyBar?.c?.toFixed(2) : 0,
            styleWholePart: { fontSize: 18 },
            styleFractionalPart: { fontSize: 18, color: '#A8A8A8' },
          })}
        </p>
        <span className={changePercent < 0 ? 'minus' : changePercent > 0 ? 'plus' : ''}>
          {changePercent < 0 && '-'}
          {new Intl.NumberFormat('en-EN').format(parseFloat(Math.abs(changePercent)).toFixed(2))}%
          {/* {changePercent < 0 ? <img src={redarrow} /> : <img src={grarrow} />} */}
        </span>
      </div>
    </div>
  );
}

export default observer(TickerItemPopular);
