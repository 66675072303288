import { Carousel } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '../../UI';
import Utils, { $class, $null, apiRequest, shimmer } from '../../Utils';
import icon3 from '../../assets/images/kolgeek.svg';
import { PriceChanging, TickerCard } from '../../blocks';
import DollarSign from '../../blocks/DollarSign';
import urlStore from '../../stores/urlStore';
import websocket from '../../websocket';
import AccountItem from '../Accounts/AccountItem';
import AccountStore from '../Accounts/AccountStore';
import AppStore from '../App/AppStore';
import userStore from '../Auth/userStore';
import Header from '../Header';
import Lang from '../Lang';
import NewsItem from '../News/NewsItem';
import NumberFormat from '../NumberFormat';
import StartVerificationBlock from '../StartVerificationBlock';
import TickerItemPopular from '../TickerItem/TickerItemPopular';
import TickersCategorySlider from '../TickersCategorySlider';
import TitleCategory from '../TitleCategory';
import TradingStore from '../Trading/TradingStore';
import WalletChart from '../Wallet/WalletChart';
import styles from './Portfolio.module.scss';
import PortfolioStore from './PortfolioStore';

const categoryConfig = [
  {
    title: Lang({ str: 'popular', section: 'home' }),
    image: `${icon3}`,
  },
  {
    title: Lang({ str: 'news', section: 'home' }),
    image: `${icon3}`,
  },
];

const Main = () => {
  const [page, setPage] = useState(1);
  const [news, setNews] = useState([]);
  const freshNewsCount = useRef(0);
  const $newsList = useRef(null);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    getNews(page);
  }, [page]);

  const getNews = (pageNumber) => {
    // let tickerData = TickerStore.tickerData[tickerId];
    console.log({ pageNumber });
    apiRequest({
      url: `/private/v1/get_news/${pageNumber}?lang=${AppStore.lang}&offset=${freshNewsCount.current}`,
      method: 'GET',
      success: async (data) => {
        if (data?.error) return; // message.error(data?.error.msg);
        console.log('news: ', data);
        if (data?.status !== 'ok' || !Array.isArray(data?.news)) {
          return;
        }
        if (data?.news?.length === 0) {
          setIsEnd(true);
        } else {
          setNews([...news, ...data?.news]);
        }
      },
    }).catch((error) => {
      console.error({ error });
    });
  };

  useEffect(() => {
    urlStore.setSection('portfolio');

    // ====== SUBSCRIBE TO UPDATE FRESH NEWS
    const eventNewsId = websocket.subscribe({ event: 'updateNews', key: '*' }, ({ news, ref }) => {
      console.log('FRESH NEWS', { ref, news });

      news.className = 'highlightEffect';

      if (AppStore.lang === 'en') {
        setNews((prev) => [news, ...prev.map((x) => ({ ...x, className: '' }))]);
        freshNewsCount.current += 1;
      } else if (!!news?.translate?.content) {
        setNews((prev) => [news, ...prev.map((x) => ({ ...x, className: '' }))]);
        freshNewsCount.current += 1;
      }
    });
    // ====== SUBSCRIBE TO UPDATE FRESH NEWS

    return () => {
      websocket.unSubscribe(eventNewsId);
    };
  }, []);

  const openSearch = () => {
    TradingStore.openSearch();
  };

  return (
    <div style={{ paddingBottom: '116px' }}>
      <Header />
      {userStore?.userData?.statusData?.alpaca === 'active' && <AccountsList />}
      {userStore?.userData?.statusData?.alpaca !== 'active' && <StartVerificationBlock />}

      <PopularList />
      <TickersCategorySlider
        ComponentCard={TickerCard}
        items={[
          {
            label: Lang({ str: 'growth', section: 'home' }),
            icon: 'growing',
            category: 'best',
            moreBtn: {
              label: Lang({ str: 'all_shares', section: 'home' }),
              onClick: openSearch,
            },
          },
          {
            label: Lang({ str: 'decline', section: 'home' }),
            icon: 'falling',
            category: 'nobest',
            moreBtn: {
              label: Lang({ str: 'all_shares', section: 'home' }),
              onClick: openSearch,
            },
          },
          {
            label: 'ETF',
            icon: 'etf',
            category: 'indices',
            moreBtn: {
              label: Lang({ str: 'all_shares', section: 'home' }),
              onClick: openSearch,
            },
          },
        ]}
      />
      <div className={styles.news}>
        <TitleCategory title={categoryConfig[1].title} />

        <div ref={$newsList}>
          {news.map((item, index) => {
            let img_small = '';
            let img_large = '';
            if (Array.isArray(item?.images) && item?.images.length > 0) {
              for (let i = 0; i < item?.images.length; i++) {
                const img = item?.images[i];
                if (img?.size === 'small') {
                  img_large = img?.url;
                }
                if (img?.size === 'thumb') {
                  img_small = img?.url;
                }
              }
            }
            return (
              <NewsItem
                key={`${item?._id}-${index}`}
                className={item?.className}
                id={item?.id}
                headline={item?.headline}
                symbols={item?.symbols ? item?.symbols : []}
                datetime={item?.created_at}
                summary={item?.summary}
                translate={item?.translate}
                content={item?.content}
                author={item?.author}
                img_small={img_small}
                img_large={img_large}
                openTickers
              />
            );
          })}
        </div>

        <Button
          className="but_more_news"
          variant="text"
          onClick={() => setPage((prev) => prev + 1)}>
          {Lang({ str: 'show_more', section: 'home' })}
        </Button>
      </div>
    </div>
  );
};

const AccountsList = observer(() => {
  const portfolio_history = PortfolioStore.portfolio_history;
  const portfolio_value = PortfolioStore.portfolio_value;
  const brokerage_price = AccountStore.accounts.brokerage.price;

  const accounts = useMemo(
    () => [
      {
        title:
          Utils.apiDomain() === 'app2.geekoinvest.kz'
            ? Lang({ str: 'broker_acc2', section: 'accounts' })
            : Lang({ str: 'broker_acc', section: 'accounts' }),
        price: brokerage_price,
        button1: true,
        button2: true,
        button3: true,
        src1: '/accounts/add_broker_account',
        src3: '/accounts/between_account',
        src2: '/accounts/output_broker_account',
        buttons: true,
        number: AccountStore.accounts.brokerage.number,
        type: 'brokerage',
      },
      {
        title:
          Utils.apiDomain() === 'app2.geekoinvest.kz'
            ? Lang({ str: 'trading_acc2', section: 'accounts' })
            : Lang({ str: 'trading_acc', section: 'accounts' }),
        price: portfolio_value,
        button2: true,
        src3: '/accounts/between_account',
        buttons: true,
        number: AccountStore.accounts.trading.number,
        type: 'trading',
      },
    ],
    [portfolio_value, brokerage_price]
  );

  const onClickHandler = (route) => {
    AccountStore.transferFrom = route;
  };

  useEffect(() => {
    AccountStore.getAccounts();
    PortfolioStore.getPortfolioHistory();
    PortfolioStore.getTrading();
  }, []);

  return (
    <div className={styles.slider_wrp + ' mt-16'}>
      <Carousel draggable>
        <div className={styles.slider_bl}>
          <p>{Lang({ str: 'investments_overview', section: 'home' })}</p>
          <div className={styles.flex_price}>
            <div
              className={$class(
                styles.slider_mainprice,
                shimmer('text', portfolio_value === null)
              )}>
              <DollarSign size={14} top={2} right={1} />
              {NumberFormat({
                value: $null(portfolio_value, '99999.99'),
                styleWholePart: { fontSize: 32 },
                styleFractionalPart: { fontSize: 24, color: '#A8A8A8' },
              })}
            </div>

            <div
              className={$class(
                styles.slider_mainpercent,
                shimmer('text', PortfolioStore.today_percent === null)
              )}>
              <PriceChanging
                style={{ marginTop: 6 }}
                change={PortfolioStore.today_percent}
                align="center"
              />
            </div>
          </div>
          <div
            className={$class(styles.graph, [styles.graphLoading, portfolio_history === null])}
            style={{ height: '50%', width: '100%' }}>
            <WalletChart data={portfolio_history} />
            {/* <WalletLightweightChart data={portfolioHistory} /> */}
            {/* <p className={styles.grf_icon}>
        <img src={grafic} />
      </p> */}
          </div>
        </div>
        <AccountItem
          title={accounts[0].title}
          price={accounts[0].price}
          button1={accounts[0].button1}
          button2={accounts[0].button2}
          button3={accounts[0].button3}
          number={accounts[0].number}
          src1={accounts[0].src1}
          src2={accounts[0].src2}
          src3={accounts[0].src3}
          buttons={accounts[0].buttons}
          clickHandler={() => {
            onClickHandler(accounts[0].type);
          }}
        />
        <AccountItem
          title={accounts[1].title}
          price={accounts[1].price}
          button3={accounts[1].button2}
          number={accounts[1].number}
          src3={accounts[1].src3}
          buttons={accounts[1].buttons}
          clickHandler={() => {
            onClickHandler(accounts[1].type);
          }}
        />
      </Carousel>
    </div>
  );
});

const PopularList = observer(() => {
  const popularRows = TradingStore.popularRows;

  useEffect(() => {
    TradingStore.loadPopular({ clear: true });
  }, []);

  return (
    <>
      <TitleCategory title={categoryConfig[0].title} />
      <div className={styles.popular_wrp}>
        <div className={styles.popular_wrp__scroll}>
          {popularRows &&
            popularRows.map((tickerId, index) => (
              <TickerItemPopular key={index} tickerId={tickerId} />
            ))}
        </div>
      </div>
    </>
  );
});

export default observer(Main);
