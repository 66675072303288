import { useDrag } from '@use-gesture/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { AutoSizer, List } from 'react-virtualized';
import { Button } from '../../UI';
import { $class, shimmer, useDelayRender } from '../../Utils';
import Icon from '../Icon';
import TradingStore from '../Trading/TradingStore';
import styles from './index.module.scss';

const TickersCategorySlider = ({
  items = [],
  style,
  ComponentCard,
  count = 5,
  virtualized,
  delay,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const scrollableRef = useRef([]);
  const scrollableTab = useRef();

  const [{ x }, springApi] = useSpring(() => ({
    x: 0,
  }));

  const calcX = () => -currentTab * window.innerWidth + currentTab * 16;

  useEffect(() => {
    springApi.start({ x: calcX() });
  }, [currentTab]);

  const onSlide = (i) => {
    if (i < 0) i = 0;
    if (i > items.length - 1) i = items.length - 1;
    setCurrentTab(i);
  };

  const bind = useDrag(
    ({ movement: [mx], down }) => {
      const current = calcX();

      if (currentTab === 0 && mx > 50) {
        return springApi.start({ x: current });
      }
      if (currentTab === items.length - 1 && mx < -50) {
        return springApi.start({ x: current });
      }

      if (!down && Math.abs(mx) > 60) {
        onSlide(currentTab - Math.sign(mx));
        return;
      }

      springApi.start({ x: down ? current + mx : current, immediate: down });
    },
    {
      preventScroll: true,
    }
  );

  const show = useDelayRender(300);

  if (!show && delay) return <Mockup ComponentCard={ComponentCard} style={style} count={count} />

  return (
    <div {...bind()} className={styles.TabsSymbol} style={style}>
      <div className={styles.tabs + ' m-0'} ref={scrollableTab}>
        {items.map((item, index) => (
          <div
            ref={(element) => {
              scrollableRef.current[index] = element;
            }}
            key={index}
            onClick={() => onSlide(index)}
            className={[styles.tab, currentTab === index ? styles.active : null].join(' ')}>
            <Icon slug={item.icon} />
            <span>{item.label}</span>
          </div>
        ))}
      </div>

      <div
        className={styles.TabsSymbolWrap + ' tabs-symbol-wrap'}
        style={{ paddingTop: virtualized && 16 }}>
        <animated.div style={{ x }}>
          {items.map((x) => (
            <div key={x.category} className={styles.TabsSymbolList + ' TabsSymbolList'}>
              {x.category === 'best' && (
                <Best count={count} Component={ComponentCard} virtualized={virtualized} />
              )}
              {x.category === 'nobest' && (
                <NoBest count={count} Component={ComponentCard} virtualized={virtualized} />
              )}
              {x.category === 'indices' && (
                <Indices count={count} Component={ComponentCard} virtualized={virtualized} />
              )}

              {x.moreBtn && (
                <Button
                  className={styles.TabsSymbolMoreBtn}
                  variant="text"
                  onClick={x.moreBtn.onClick}>
                  {x.moreBtn.label}
                </Button>
              )}
            </div>
          ))}
        </animated.div>
      </div>
    </div>
  );
};

const Mockup = ({ style, count, ComponentCard }) => (
  <div className={styles.TabsSymbol} style={style}>
    <div className={styles.tabs + ' m-0'}>
      {Array(3)
        .fill()
        .map((item, i) => (
          <div key={i} className={$class(styles.tab, [styles.active, i === 0])}>
            <span className={shimmer('text', true)}>_________</span>
          </div>
        ))}
    </div>

    <div className={styles.TabsSymbolWrap + ' tabs-symbol-wrap'}>
      <div>
        <div className={styles.TabsSymbolList + ' TabsSymbolList'}>
          {Array(count)
            .fill()
            .map((_, i) => (
              <ComponentCard key={i} style={{ marginTop: 16 }} />
            ))}
        </div>
      </div>
    </div>
  </div>
);

// Category Groups
const CardSymbolGroups = observer(({ Component, items, count, virtualized }) => {
  if (!Component) return null;

  const rows = items.slice(0, count);

  if (rows.length === 0) return Array(count)
    .fill()
    .map((_, i) => 
      <Component key={i} style={{ marginTop: 16 }} />
    )

  if (virtualized)
    return (
      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            rowCount={rows.length}
            rowHeight={72 + 16}
            rowRenderer={({
              key, // Unique key within array of rows
              index, // Index of row within collection
              isScrolling, // The List is currently being scrolled
              isVisible, // This row is visible within the List (eg it is not an overscanned row)
              style, // Style object to be applied to row (to position it)
            }) => {
              const item = rows[index];

              return <Component key={key} style={style} tickerId={item} />;
            }}
          />
        )}
      </AutoSizer>
    );

  return rows.map(
    (tickerId, i) => <Component key={i} tickerId={tickerId} style={{ marginTop: 16 }} />
  );
});

const Best = observer(({ count, Component, virtualized }) => {
  const bestRows = TradingStore.bestRows;

  useEffect(() => {
    TradingStore.loadBest({ clear: true });
  }, []);

  return (
    <CardSymbolGroups
      count={count}
      Component={Component}
      items={bestRows}
      virtualized={virtualized}
    />
  );
});

const NoBest = observer(({ count, Component, virtualized }) => {
  const rows = TradingStore.noBestRows;

  useEffect(() => {
    TradingStore.loadNoBest({ clear: true });
  }, []);

  return (
    <CardSymbolGroups count={count} Component={Component} items={rows} virtualized={virtualized} />
  );
});

const Indices = observer(({ count, Component, virtualized }) => {
  const rows = TradingStore.indicesRows;

  useEffect(() => {
    TradingStore.loadIndices({ clear: true });
  }, []);

  return (
    <CardSymbolGroups count={count} Component={Component} items={rows} virtualized={virtualized} />
  );
});

export default TickersCategorySlider;
