import React, {useCallback, useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import styles from "./PersonalInformation.module.scss";
import {Upload, Form, Input, message, Spin} from "antd";
import Modal from "../Modal";
import userStore from "../Auth/userStore";
import { apiQuery, apiQueryError, getBase64 } from "../../Utils";
import urlStore from "../../stores/urlStore";
import HeaderTitle from "../Header/HeaderTitle";
import left_icon from "../../assets/images/arrowmenu.svg";
import ReactCodeInput from "react-code-input";
import {PhoneInput} from "react-international-phone";
import "react-international-phone/style.css";
import "../BrokerAccount/react-international-phone-style.css";
import ModalSMSVerify from "../SMSVerifyModal/SMSVerify.modal";
import Lang from "../Lang";
import AlertStore from "../Alert/AlertStore";
import { Button } from "../../UI";
import modalStore from "../ModalManager/modalStore";

const ChangePhone = () => {
  const navigate = useNavigate();
  const [traderData, setTraderData] = useState(null);
  const [phone, setPhone] = useState("");
  const [load, setLoad] = useState();

  const onCodeVerified = (code) => {
    apiQuery("/private/v1/updatePhone", {
      method: "POST",
      body: {
        phone,
        code
      },
    })
      .then(() => {
        modalStore.closeModal();
        AlertStore.success(Lang({ str: "phone_updated", section: "menu" }));
        userStore.authCheck().catch();
        navigate('/user/verification')
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  }

  const getTraderData = useCallback(() => {
    setLoad(true)
    apiQuery("/private/v1/trader/data")
      .then((data) => {
        setTraderData(data)
        setPhone(data?.data?.questionnaire?.personalProfile?.phoneNumber)
        setLoad(false)
      })
      .catch((error) => {
        apiQueryError({ error });
        navigate('/user/security')
      });
  },[])

  const onClickHandler = () => {
    if (!phone || phone.length < 16) {
      AlertStore.error("Введите номер телефона");
      return;
    }

    modalStore.openModal('SMSVerify', { phone, onCodeVerified })
  }

  useEffect(() => {
    urlStore.setSection("");
    window.scroll(0, 0);
    getTraderData()
  }, [getTraderData]);

  return (
    <div className={styles.personal_information_wrp} style={{ paddingTop: 32 }}>
      <HeaderTitle
        title={Lang({ str: "update_phone", section: "menu" })}
        button1={<img src={left_icon} alt={""} />}
      />
      {!load && <>
        <div className={styles.security_wrp + " mt-32 mb-16"}>
          <div className={styles.width_vv}>
            <p className="pb-16">
                {Lang({ str: "current_phone", section: "menu" })} - {traderData?.data?.questionnaire?.personalProfile?.phoneNumber}
            </p>
            <p>
                {Lang({ str: "correct_contact_info", section: "menu" })}
            </p>
          </div>
        </div>
        <div className={styles.personal_information}>
          <Form className={styles.personal_pas} layout="vertical">

            <div style={{marginBottom: 20}}>
              <PhoneInput
                className={styles.phone_input}
                defaultCountry="kz"
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
            </div>

            <Form.Item>
              <Button onClick={onClickHandler}>
                {Lang({ str: "confirm_phone", section: "menu" })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </>}
    </div>
  );
};

export default ChangePhone;
