import { configure, makeAutoObservable } from "mobx";
import {apiRequest} from "../../Utils";
import {message} from "antd";
import AlertStore from "../Alert/AlertStore";

configure({ enforceActions: "never" });

class BrokerAccountStore {

  traderDataStatus = null;
  traderData = null;

  constructor() {
    makeAutoObservable(this);
  }

  setStep(v) {
    if(!this.traderData) return;
    this.traderData.step = v;
  }

  get() {
    apiRequest({
      url: "/private/v1/trader/data",
      success: (data) => {
        console.log(data)
        if (data.error) return AlertStore.error(data.error?.msg);
        this.traderDataStatus = data.status;
        this.traderData = data.data;
      },
      error: (error) => {
        console.error(error);
        if (error.res?.status === 401) this.auth = false;
      },
    }).catch();
  }

  async save(body) {
    return await apiRequest({
      url: "/private/v1/trader/data",
      method: 'POST',
      body,
      success: (data) => {
        if (data.error) return AlertStore.error(data.error?.msg);
        this.traderDataStatus = data.status;
        this.traderData = data.data;
      },
      error: (error) => {
        console.error(error);
        if (error.res?.status === 401) this.auth = false;
      },
    });
  }

}

const clsBrokerAccountStore = new BrokerAccountStore();
export default clsBrokerAccountStore
