import { Form, message } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, NumberInput } from '../../UI';
import { apiRequest } from '../../Utils';
import between from '../../assets/images/between.svg';
import { BackButton } from '../../blocks';
import DollarSign from '../../blocks/DollarSign';
import AlertStore from '../Alert/AlertStore';
import userStore from '../Auth/userStore';
import Lang from '../Lang';
import modalStore from '../ModalManager/modalStore';
import NumberFormat from '../NumberFormat';
import TitleCategory from '../TitleCategory';
import AccountStore from './AccountStore';
import styles from './Accounts.module.scss';

const numberFStyles = {
  styleWholePart: { fontSize: 32 },
  styleFractionalPart: { fontSize: 24, color: 'var(--basic-dark40)' },
};

const BetweenAccount = () => {
  const { state } = useLocation();
  const [messageApi, contextHolder] = message.useMessage();

  // let params = useParams();
  // const location = useLocation();
  // const navigate = useNavigate();
  let history = useNavigate();
  // let {to} = params
  const [amount, setAmount] = useState(0);
  const [commission, setCommission] = useState(0);
  const [buttonActive, setButtonActive] = useState(false);
  // const [code, setCode] = useState('');
  const [to, setTo] = useState('trading');
  // const [modalConfirm, setModalConfirm] = useState(false);

  // console.log(to,'to')

  // let to = "trading";

  const checkRoutes = () => {
    if (to === AccountStore.transferFrom) {
      // to = "brokerage";
      setTo('brokerage');
      // AccountStore.transferFrom ='trading'
    }
    // else{
    //   AccountStore.transferFrom ="brokerage"
    // }

    if (!AccountStore.transferFrom) {
      if (to === 'trading') {
        AccountStore.transferFrom = 'brokerage';
      } else {
        AccountStore.transferFrom = 'trading';
      }
    }
  };

  checkRoutes();

  const Commissions = {
    transferTobrokerage: {
      client: {
        min: 70,
        max: 70,
        koef: 0.002,
      },
      worker: {
        min: 1,
        max: 1,
        koef: 0.002,
      },
    },
    transferTotrading: {
      client: {
        min: 32,
        max: 215,
        koef: 0.002,
      },
      worker: {
        min: 1,
        max: 1,
        koef: 0.002,
      },
    },
  };

  const getTransferCommissionLimits = (user, route) => {
    let role = 'client';

    if (user?.role?.worker) {
      role = 'worker';
    }

    const limits = Commissions['transferTo' + route][role];

    return { minCom: limits.min, maxCom: limits.max, koef: limits.koef };
  };

  const { minCom, maxCom, koef } = getTransferCommissionLimits(userStore.userData, to);
  // console.log(userStore.userData, 'aaaaaaaaaaaaaaaaaaaa')

  const onFinish = (values) => {
    console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onChange = (value) => {
    console.log('changed', value);

    if (value > 0) {
      setButtonActive(true);
    } else {
      setButtonActive(false);
    }

    setAmount(value);
    // commissionProcess(value)

    setCommission(getCommission(value, minCom, maxCom, koef));
  };

  const commissionProcess = (amount) => {
    const commission = getCommission(amount, minCom, maxCom, koef);

    setCommission(commission);
  };

  const getCommission = (amount, min, max, koef = 0.002) => {
    let commission = Number(amount) * koef;

    if (commission > max) {
      commission = max;
    }
    if (commission < min) {
      commission = min;
    }

    return commission;
  };

  const getTimeZone = () => {
    var offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    return (
      (offset < 0 ? '+' : '-') +
      ('' + Math.floor(o / 60)).slice(-2) +
      ':' +
      ('00' + (o % 60)).slice(-2)
    );
  };

  const onCreateTransfer = (code) => {
    console.log({ amount });

    const date = new Date();

    apiRequest({
      url: `/private/v1/createTransfer/`,
      method: 'POST',
      body: {
        code,
        amount,
        userTime:
          ('00' + date.getHours()).slice(-2) +
          ':' +
          ('00' + date.getMinutes()).slice(-2) +
          ':' +
          ('00' + date.getSeconds()).slice(-2),
        timezone: getTimeZone(),
        route: AccountStore.transferFrom === 'trading' ? 'brokerage' : 'trading',
      },
      success: (data) => {
        if (data?.error) {
          // cb(false);
          return AlertStore.error(data?.error.msg);
        }
        console.log({ data });
        if (data?.status === 'ok') {
          AlertStore.orderSuccess(Lang({ str: 'transfer_created', section: 'accounts' }));
          AccountStore.refreshHistory = true;
          setAmount(0);
          setCommission(0);
          setButtonActive(false);
          modalStore.closeModal();
          // cb(true);
          // demoPayHistory();
        }
      },
    }).catch((error) => {
      console.error({ error });
    });
  };

  useEffect(() => {
    AccountStore.getAccounts();
  }, []);
  useEffect(() => {
    checkRoutes();
  }, [to]);

  useEffect(() => {
    state.pathname == '/accounts/trading'
      ? (AccountStore.transferFrom = 'trading')
      : (AccountStore.transferFrom = 'brokerage');
  }, []);

  console.log(AccountStore.transferFrom, 'AccountStore.transferFrom');

  const items = {
    brokerage: {
      title: Lang({ str: 'broker_acc', section: 'accounts' }),
      price: AccountStore.accounts.brokerage.price,
      number: AccountStore.accounts.brokerage.number,
    },
    trading: {
      title: Lang({ str: 'trading_acc', section: 'accounts' }),
      price: AccountStore.accounts.trading.price,
      number: AccountStore.accounts.trading.number,
      button1: (
        <p
          onClick={() =>
            modalStore.openModal('helper', {
              title: Lang({ str: 'cash', section: 'wallet' }),
              text: Lang({ str: 'cash_is', section: 'wallet' }),
            })
          }
          className={styles.one_link}>
          {Lang({ str: 'cash', section: 'wallet' })}
        </p>
      ),
    },
  };
  const categoryConfig = [
    {
      title: Lang({ str: 'transfer_between', section: 'accounts' }),
    },
  ];
  const onClickHandler = (values, cb = () => {}) => {
    if (items[AccountStore.transferFrom].price <= 0) {
      AlertStore.error('Недостаточно средств на исходящем счете');
      return false;
    }

    if (!amount || amount <= 0) return AlertStore.error('Введите сумму перевода');

    modalStore.openModal('SMSVerify', { onCodeVerified: onCreateTransfer });
  };
  return (
    <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <div className={styles.account_page}>
        <div className={styles.fixed_bl}>
          <BackButton label={Lang({ str: 'back', section: 'default' })} />
        </div>
        {contextHolder}
        <TitleCategory title={categoryConfig[0].title} />

        <div className={styles.one_accounts + ' mt-16 '}>
          <p className={styles.one_tit}>{items[AccountStore.transferFrom]?.title}</p>
          <p className={styles.one_num}>{items[AccountStore.transferFrom]?.number}</p>
          {AccountStore.transferFrom == 'trading' && items.trading.button1}
          <p>
            <span>
              <DollarSign size={16} top={3} right={2} />
              {NumberFormat({
                value: items[AccountStore.transferFrom]?.price,
                ...numberFStyles,
              })}
            </span>
          </p>
        </div>
        <button
          className={styles.between}
          onClick={() => {
            // navigate('/accounts/transfer_to/'+AccountStore.transferFrom, { state: location });
            setTo(AccountStore.transferFrom);
            AccountStore.transferFrom = to;
            setCommission(0);
            setAmount(0);
            // history('/accounts/transfer_to/'+AccountStore.transferFrom);
          }}>
          <img src={between} />
        </button>
        <div className={styles.one_accounts}>
          <p className={styles.one_tit}>{items[to]?.title}</p>
          <p className={styles.one_num}>{items[to]?.number}</p>
          {AccountStore.transferFrom == 'brokerage' && items.trading.button1}
          <p>
            <span>
              <DollarSign size={16} top={3} right={2} />
              {NumberFormat({ value: items[to]?.price, ...numberFStyles })}
            </span>
          </p>
        </div>

        <div className={styles.block_valute}>
          <Form
            name="basic"
            onFinish={onFinish}
            labelCol={{
              span: 24,
            }}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Form.Item
              name="amount"
              rules={
                [
                  // {
                  //   required: true,
                  //   message: Lang({ str: 'enter_amount', section: 'accounts' }),
                  // },
                ]
              }>
              <label htmlFor="transfer-input" className={styles.inputLabel}>
                {Lang({ str: 'transfer_amount', section: 'accounts' })}
              </label>
              <NumberInput id="transfer-input" onChange={onChange} value={amount} />
              <p className={styles.commis}>
                {Lang({ str: 'fee', section: 'accounts' })}{' '}
                <DollarSign size={8} top={2} right={1} color={'#d5b610'} />
                {commission}
              </p>
            </Form.Item>

            <Form.Item className="mt-32">
              {/*<Button type="primary" htmlType="submit" onClick={pay}>*/}
              {/*  Перевести*/}
              {/*</Button>*/}

              <Button variant="primary" onClick={onClickHandler} disabled={!buttonActive}>
                {Lang({ str: 'transfer_2', section: 'accounts' })}
              </Button>
              {/* <ModalSMSVerify
                button={
                  <Button variant="primary" disabled={!buttonActive}>
                    {Lang({ str: 'transfer_2', section: 'accounts' })}
                  </Button>
                }
                onShow={(cb) => onCheck({}, cb)}
                onChange={(v) => setCode(v)}
                onClick={pay}
                verify={() => {
                  console.log(amount);

                  if (items[AccountStore.transferFrom].price <= 0) {
                    AlertStore.error('Недостаточно средств на исходящем счете');
                    return false;
                  }

                  let result = amount && amount > 0;
                  if (!result) AlertStore.error('Введите сумму перевода');
                  return result;
                }}
              /> */}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default observer(BetweenAccount);
