import React from "react";
import { observer } from "mobx-react-lite";
import PushNotificationStore from "./PushNotificationStore";
import { Spin, Switch } from "antd";
import styles from "../../components/PersonalInformation/PersonalInformation.module.scss";
import { LoadingOutlined } from "@ant-design/icons";
import Lang from "../Lang";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "var(--basic-dark)" }} spin />
);

const PushNotificationButton = (props) => {
  //if (PushNotificationStore.hidden) return null;

  // PushNotificationStore.updateMangeWebPushSubscriptionButton();

  return (
    <Spin
      style={{ display: "block", width: "100%" }}
      spinning={PushNotificationStore.loading}
      indicator={antIcon}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
        onClick={() => {
          PushNotificationStore.onManageWebPushSubscriptionButtonClicked().catch(
            (e) => {
              console.error(e);
              alert(Lang({ str: "error", section: "default" }));
            }
          );
        }}
      >
        <p>{PushNotificationStore.buttonText}</p>
        <Switch
          checked={
            PushNotificationStore.buttonText !== Lang({ str: "notifications_off", section: "menu" })
              // PushNotificationStore.buttonText === Lang({ str: "notifications_off", section: "menu" })
          }
        />
      </div>
    </Spin>
  );
};

export default observer(PushNotificationButton);
