import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {Tabs} from '../../UI';
import Button from '../../UI/Button';
import {$null, apiRequest, shimmer} from '../../Utils';
import img33 from '../../assets/images/broker_status_icon.svg';
import {PriceChanging} from '../../blocks';
import DollarSign from '../../blocks/DollarSign';
import urlStore from '../../stores/urlStore';
import websocket from '../../websocket';
import AlertStore from '../Alert/AlertStore';
import userStore from '../Auth/userStore';
import BrokerAccountStore from '../BrokerAccount/BrokerAccountStore';
import Header from '../Header';
import Lang from '../Lang';
import modalStore from '../ModalManager/modalStore';
import NumberFormat from '../NumberFormat';
import Orders from './Orders';
import Papers from './Papers';
import styles from './Wallet.module.scss';
import WalletStore from './WalletStore';
import BrokerStatusBlock from "../BrokerStatusBlock";

const Wallet2 = (params) => {
  const [search] = useSearchParams();
  const [tab, setTab] = useState(search.get('tab') || '1');

  const traderData = BrokerAccountStore.traderData;

  useEffect(() => {
    urlStore.setSection('wallet');
    WalletStore.getTrading();
    // getOrders(WalletStore.ordersPage);
    // getActivities(WalletStore.activitiesSkip);

    let eventId = null;

    eventId = websocket.subscribe(
      {event: 'walletBalanceChange', key: userStore.userData._id},
      (data) => {
        if (data.type === 'changeBalance') {
          WalletStore.getTrading();
        }
        // getActivities();
      }
    );

    let activesEventId = websocket.subscribe(
      {event: 'activesChange', key: userStore.userData._id},
      (data) => {
        // getPapers();
        WalletStore.getPapers();
        WalletStore.getTrading();
        // getOrders();
        // getActivities();
        // getPortfolioHistory();
      }
    );

    return () => {
      websocket.unSubscribe(eventId);
      websocket.unSubscribe(activesEventId);
    };
  }, []);

  const closeAllPositions = () => {
    console.log('closeAllPositions');
    apiRequest({
      url: '/private/v1/close_all_positions',
      method: 'POST',
      body: {},
      success: (data) => {
        console.log({data});
        AlertStore.success('Позиции успешно закрыты');
      },
      error: () => {
        AlertStore.error('Произошла ошибка');
      },
    }).catch();
  };

  return (
    <div style={{paddingBottom: '116px'}}>
      <Header/>
      {userStore?.userData?.statusData?.alpaca !== 'active' ? (
        <BrokerStatusBlock/>
      ) : (
        <div className={styles.wallet_wrp}>
          <div className={styles.fl_bl}>
            <div style={{width: '100%'}}>
              <div className={styles.investments}>
                <div className={styles.flrx_bb}>
                  <p
                    onClick={() =>
                      modalStore.openModal('helper', {
                        title: Lang({str: 'my_investments', section: 'wallet'}),
                        text: Lang({str: 'my_investments_are', section: 'wallet'}),
                      })
                    }>
                    {Lang({str: 'investments', section: 'onboarding'})}
                  </p>

                  <Button
                    autoWidth
                    onClick={() => {
                      if (!userStore.userData.role.trader) {
                        modalStore.openModal('openBrokerAccount');
                      } else {
                        modalStore.openModal('confirm', {
                          text: Lang({str: 'close_positions_2', section: 'wallet'}),
                          onYes: closeAllPositions,
                        });
                      }
                    }}
                    type="button"
                    disabled={WalletStore?.my_papers?.length === 0}>
                    {Lang({str: 'close_positions', section: 'wallet'})}
                  </Button>
                </div>
                <p className={shimmer('text', WalletStore.market_value === null)}>
                  <DollarSign size={12} top={2} right={1}/>
                  {NumberFormat({
                    value: $null(WalletStore.market_value, '99999.99'),
                    styleWholePart: {
                      fontSize: '24px',
                      color: '#252525',
                      fontFamily: 'GolosText',
                    },
                    styleFractionalPart: {fontSize: 20, color: '#A8A8A8'},
                  })}
                </p>
                <div className={shimmer('text', WalletStore.today_percent === null)}>
                  <PriceChanging
                    delimiter
                    pnl={WalletStore.today_dif}
                    change={WalletStore.today_percent}
                  />
                </div>
              </div>
              <div className={styles.price_free}>
                <p
                  onClick={() =>
                    modalStore.openModal('helper', {
                      title: Lang({str: 'cash', section: 'wallet'}),
                      text: Lang({str: 'cash_is', section: 'wallet'}),
                    })
                  }>
                  {Lang({str: 'cash', section: 'wallet'})}
                </p>
                <p className={shimmer('text', WalletStore.cash === null)}>
                  <DollarSign size={12} top={2} right={1}/>
                  {NumberFormat({
                    value: $null(WalletStore.cash, '99999.99'),
                    styleWholePart: {fontSize: 24},
                    styleFractionalPart: {fontSize: 20, color: '#A8A8A8'},
                  })}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.tabs_block + ' tabs_bl'}>
            {/* <IconButton className={styles.tabs_info} size="sm" icon='i' /> */}
            <Tabs
              value={tab}
              onChange={setTab}
              items={[
                {
                  label: Lang({str: 'positions', section: 'wallet'}),
                  value: '1',
                },
                {
                  label: Lang({str: 'orders', section: 'wallet'}),
                  value: '2',
                },
                {
                  label: Lang({str: 'history', section: 'wallet'}),
                  value: '3',
                },
              ]}
            />
          </div>
          {tab === '1' && <Papers/>}
          {tab === '2' && <Orders/>}
          {tab === '3' && <Orders history/>}
        </div>
      )}
      {/*)}*/}
    </div>
  );
};

export default observer(Wallet2);
