import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Clickable } from '../../../UI';
import { apiRequest } from '../../../Utils';
import { TickerTitle } from '../../../blocks';
import DollarSign from '../../../blocks/DollarSign';
import AlertStore from '../../Alert/AlertStore';
import Lang from '../../Lang';
import ModalConfirmButton from '../../ModalManager/ModalConfirmButton';
import NumberFormat from '../../NumberFormat';
import TickerStore from '../../Ticker/TickerStore';
import stylesW from '../Wallet.module.scss';
import WalletStore from '../WalletStore';

const OrderDetailsModal = ({ details, isHistory, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const onDeleteOrder = () => {
    const orderId = details?.id;

    setLoading(true);
    apiRequest({
      url: `/private/v1/del-order/${orderId}`,
      method: 'GET',
      success: async (data) => {
        if (data?.error) return;

        WalletStore.orders = WalletStore.orders.filter((x) => x.id !== orderId);

        AlertStore.success(Lang({ str: 'order_cancelled', section: 'wallet' }));
        closeModal();
      },
    })
      .catch((error) => {
        console.error({ error });
        AlertStore.error(Lang({ str: 'order_cancelled_err', section: 'wallet' }));
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className={stylesW.assignment_modal}>
        <Clickable
          className={stylesW.assignment_modal_ticker}
          onClick={() => {
            navigate(`/trading/${details?.tickerId || details?.ticker._id}`, { state: location });
            closeModal();
          }}>
          <TickerTitle
            ticker={TickerStore.tickerData[details?.tickerId || details?.ticker._id]}
            withSymbol
            withDesc
          />
        </Clickable>

        <div className={stylesW.assignment_modalWrap}>
          <div>
            <p>{Lang({ str: 'order_type', section: 'wallet' })}</p>
            <p>
              <span
                style={{
                  color: details?.side === 'buy' ? 'var(--misk-success)' : 'var(--misk-error)',
                }}>
                {details?.side === 'buy'
                  ? Lang({ str: 'purchase', section: 'wallet' })
                  : Lang({ str: 'sale', section: 'wallet' })}
              </span>
              <span style={{ margin: '0 5px' }}>/</span>
              <span>{Lang({ str: 'limit', section: 'wallet' })}</span>
            </p>
          </div>
          <div>
            <p>{Lang({ str: 'assets_qty', section: 'wallet' })}</p>
            <p>{NumberFormat({ value: details?.qty })}</p>
          </div>
          <div>
            <p>{Lang({ str: 'market_volume', section: 'wallet' })}</p>
            <div>
              <p>≈</p>
              <p>
                <DollarSign size={9} top={2} right={1} />
                {NumberFormat({
                  // value: parseFloat(
                  //   (details?.type === 'limit' ? +details?.limit_price : details?.amount) *
                  //     details?.qty
                  // ).toFixed(2),
                  value: parseFloat(
                    details?.order_type === 'market' || details?.order?.order_type === 'market'
                      ? details?.notional
                        ? details?.notional
                        : details?.qty * details?.current_price
                        ? details?.qty * details?.current_price
                        : details?.qty * details?.price
                      : details?.order?.filled_qty && details?.order?.filled_avg_price
                      ? details?.order?.filled_qty * details?.order?.filled_avg_price
                      : details?.limit_price
                      ? details?.limit_price * details?.qty
                      : details?.qty * details?.current_price
                      ? details?.qty * details?.current_price
                      : details?.qty * details?.price
                  ).toFixed(2),
                  // value: parseFloat(
                  //   details?.notional
                  //     ? details?.notional
                  //     : (details?.order?.filled_qty && details?.order?.filled_avg_price)
                  //     ? details?.order?.filled_qty * details?.order?.filled_avg_price
                  //       : details?.limit_price
                  //       ? details?.limit_price * details?.qty
                  //           : (details?.qty * details?.current_price)
                  //       ? details?.qty * details?.current_price
                  //       : details?.qty * details?.price
                  // ).toFixed(2),
                  styleWholePart: { fontSize: 16 },
                  styleFractionalPart: { fontSize: 14 },
                })}
              </p>
            </div>
          </div>
          <div>
            <p>{Lang({ str: 'avg_entry_price', section: 'wallet' })}</p>
            <div>
              <p>≈</p>
              <p>
                <DollarSign size={9} top={2} right={1} />
                {NumberFormat({
                  value:
                    details?.order_type === 'market' || details?.order?.order_type === 'market'
                      ? details?.price
                        ? details?.price?.toString()
                        : details?.current_price?.toString()
                      : details?.order?.filled_avg_price
                      ? details?.order?.filled_avg_price
                      : details?.limit_price
                      ? details?.limit_price
                      : details?.price
                      ? details?.price?.toString()
                      : details?.current_price?.toString(),

                  // value:
                  //     details?.order?.filled_avg_price
                  //     ? details?.order?.filled_avg_price
                  //     : (details?.limit_price)
                  //     ? details?.limit_price
                  //     : (details?.price)
                  //     ? details?.price?.toString()
                  //     : details?.current_price?.toString(),
                })}
              </p>
              <p style={{ fontSize: 14 }}>
                (
                {Lang({
                  str: details?.type === 'limit' ? 'limit_2' : 'market_2',
                  section: 'wallet',
                })}
                )
              </p>
            </div>
          </div>
          <div>
            <p>{Lang({ str: 'order_status', section: 'wallet' })}</p>
            <p>
              {isHistory
                ? WalletStore.formatOrderStatus(details?.order_status)
                : Lang({ str: 'pending', section: 'wallet' })}
            </p>
          </div>
        </div>
      </div>
      {!isHistory && <ModalConfirmButton
        onClick={onDeleteOrder}
        loading={loading}
        btnLabel={Lang({ str: 'cancel_order', section: 'wallet' })}
        confirmTitle={Lang({ str: 'cancel_order_2', section: 'wallet' })}
      />}
    </>
  );
};

export default OrderDetailsModal;
