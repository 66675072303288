import React from "react";
import styles from "../BrokerAccountNew/BrokerAccountNew.module.scss";
import { observer } from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import Lang from "../Lang";

const Step10 = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.page_fl}>
      <div className={styles.text_bl}>
        <div className={styles.center + " mt-24"}>
          <div className={styles.error_block}>
            <p>
              {Lang({str: "double", section: "onboarding"})}
            </p>
          </div>
        </div>
      </div>
      <button className={styles.start_but} onClick={() => navigate('/')}>{Lang({
        str: "to_home",
        section: "onboarding"
      })}</button>
    </div>
  );
};

export default observer(Step10);
