import { Button, Form } from "antd";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import ReactCodeInput from "react-code-input";
import { apiQuery, apiQueryError } from "../../Utils";
import AlertStore from "../Alert/AlertStore";
import styles from "../BrokerAccountNew/BrokerAccountNew.module.scss";
import Lang from "../Lang";
import BrokerAccountStore from "./BrokerAccountStore";

const propsReactCodeInput = {
  inputStyle: {
    margin: "6px 3% 6px 0",
    width: "40px",
    fontSize: "22px",
    height: 40,
    backgroundColor: "#EAEAEA",
    border: 0,
    borderRadius: "4px",
    textAlign: "center",
    color: "#252525",
  },
};
const Step7 = ({ phone }) => {
  const [btnVisible, setBtnVisible] = useState();
  const [load, setLoad] = useState(false);
  const [sec, setSec] = useState(0);
  const [code, setCode] = useState("");
  const [form] = Form.useForm();

  const onLoad = useCallback(() => {
    setSec(60);
    apiQuery("/private/v1/sms_captcha", {
      method: "POST",
      body: { phone },
    })
      .then((data) => {
        if (data.error) {
          return AlertStore.error(data.error.msg);
        }
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  }, [phone]);

  const onFinishForm = () => {
    if (code === "")
      return AlertStore.error(
        Lang({ str: "enter_code_2", section: "onboarding" })
      );
    setLoad(true)
    apiQuery("/private/v1/signAgreement", {
      method: "POST",
      body: { code, phone },
    })
      .then((data) => {
        if (data.error) {
          AlertStore.error(data.error.msg);
          setLoad(false)
          return;
        }
        AlertStore.success(Lang({ str: "docs_signed", section: "onboarding" }));
        BrokerAccountStore.save({
          view: "questionnaire",
          step: 1,
        }).catch();
        setLoad(false)
      })
      .catch((error) => {
        apiQueryError({ error });
        setLoad(false)
      });
  };

  const codeOnChange = (v) => {
    setCode(v);
  };

  useEffect(() => {
    onLoad();
    const time = setInterval(() => {
      setSec((v) => {
        if (v === 0) return 0;
        if (v === 1) {
          setBtnVisible(true);
          return v - 1;
        }
        setBtnVisible(false);
        return v - 1;
      });
    }, 1000);

    return () => {
      clearInterval(time);
    };
  }, []);

  return (
    <>
      <div className={styles.page_fl}>
        <div className={styles.text_bl}>
          <p className={styles.lit_text}>
            {Lang({
              str: "brokerage_service_agreement_3",
              section: "onboarding",
            })}
          </p>
          <h4 className="mt-16">
            {Lang({ str: "enter_code", section: "onboarding" })}
          </h4>

          <Form
            className={styles.form}
            layout="vertical"
            form={form}
            onFinish={onFinishForm}
          >
            <p className={styles.lit_text}>
              {Lang({ str: "code_sent", section: "onboarding" })} {phone}
            </p>
            <div
              style={{
                display: "block",
                margin: "0px auto 24px",
                width: "100%",
              }}
            >
              <ReactCodeInput
                type="number"
                fields={6}
                inputMode="numeric"
                name="code"
                value={code}
                onChange={codeOnChange}
                autoComplete={"one-time-code"}
                {...propsReactCodeInput}
              />
            </div>

            {!btnVisible && (
              <p style={{ color: "#7c7c7c" }}>
                {Lang({ str: "resend", section: "onboarding" })} {sec}{" "}
                {Lang({ str: "seconds", section: "onboarding" })}
              </p>
            )}
            {btnVisible && (
              <p>
                <span className={styles.link_all} onClick={() => onLoad()}>
                  {Lang({ str: "resend_code", section: "onboarding" })}
                </span>
              </p>
            )}

            <Form.Item className={styles.buttonBlockFixed + " m-0 b-0"}>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.gr_btn + " " + styles.activ}
                loading={load}
              >
                {Lang({ str: "sign", section: "onboarding" })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default observer(Step7);
