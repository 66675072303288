import React, { useCallback, useEffect, useState } from 'react';
import {Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './PersonalInformation.module.scss';
import userStore from '../Auth/userStore';
import Lang from '../Lang/index';
import { apiQuery, apiQueryError, shimmer } from '../../Utils';
import { BackButton } from '../../blocks';
import AlertStore from '../Alert/AlertStore';
import {Button} from "../../UI";
import modalStore from "../ModalManager/modalStore";

const Verification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [traderData, setTraderData] = useState(null);
  const [address, setAddress] = useState(null);

  console.log({traderData});

  const getTraderData = useCallback(() => {
    apiQuery('/private/v1/trader/data')
      .then((data) => {
        if (!data) return;

        setTraderData(data);
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  }, []);

  const getAddressFull = useCallback(() => {
    apiQuery('/private/v1/trader/addressFull')
      .then((data) => {
        if (data?.error) return AlertStore.error(data.error.msg);
        if (!data) return;

        setAddress(data);
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  }, []);

  useEffect(() => {
    getTraderData();
    getAddressFull();
  }, [getTraderData, getAddressFull]);

  // if (!traderData) return null;

  return (
    <div className={styles.personal_information_wrp}>
      <BackButton />
      <div className="wrp_height">
        <div className={styles.personal_information + ' ' + styles.horizont_in}>
          <p className={styles.titile}>{Lang({ str: 'verification', section: 'menu' })}</p>
          {/*<p className={styles.verify_id}>ID: 16352673</p>*/}
          <div className={styles.security_link + ' ' + styles.bg_securt + ' mt-24'}>
            <div className={styles.flex_tabl}>
              <div>
                <p>{Lang({ str: 'full_name', section: 'menu' })}</p>
                <MockUpRow loading={traderData === null} prevalue={25}>
                  {userStore.userData?.fio}
                </MockUpRow>
              </div>
            </div>
            <div className={styles.flex_tabl}>
              <div>
                <p>{Lang({ str: 'birth', section: 'menu' })} </p>
                <MockUpRow loading={traderData === null} prevalue={10}>
                  {traderData?.data.questionnaire.personalProfile.dob}
                </MockUpRow>
              </div>
            </div>

            <div className={styles.flex_tabl1}>
              <div>
                <div>
                  <p>{Lang({ str: 'phone', section: 'menu' })}</p>
                  <MockUpRow loading={traderData === null} prevalue={11}>
                    {traderData?.data.questionnaire.personalProfile.phoneNumber}
                  </MockUpRow>
                </div>
              </div>
              {traderData !== null && (
                <Button
                  autoWidth
                  className={styles.change_btn}
                  onClick={() => modalStore.openModal('confirm', {
                    text: Lang({ str: 'doc_change_warning', section: 'menu' }),
                    onYes: () => navigate('/user/change_phone', { state: location })
                  })}
                >
                  {Lang({ str: 'change', section: 'menu' })}
                </Button>
              )}
            </div>

            <div className={styles.flex_tabl1}>
              <div>
                <div>
                  <p>
                    {Lang({
                      str: 'identification_docs',
                      section: 'menu',
                    })}{' '}
                  </p>
                  <MockUpRow loading={traderData === null} prevalue={30}>
                    {traderData?.data.questionnaire?.personalProfile?.docType === 'ID_CARD' && (
                      <>{Lang({ str: 'id_card', section: 'menu' })}, </>
                    )}
                    {traderData?.data.questionnaire?.personalProfile?.docType === 'PASSPORT' && (
                      <>{Lang({ str: 'passport', section: 'menu' })}, </>
                    )}
                    {traderData?.data.questionnaire?.personalProfile?.docNumber}
                  </MockUpRow>
                </div>
              </div>

              {traderData !== null && (
                <Button
                  autoWidth
                  className={styles.change_btn}
                  onClick={() => modalStore.openModal('confirm', {
                    text: Lang({ str: 'doc_change_warning', section: 'menu' }),
                    onYes: () => navigate('/user/change_document')
                  })}
                >
                  {Lang({ str: 'change', section: 'menu' })}
                </Button>
              )}
            </div>
            <div className={styles.flex_tabl1}>
              <div>
                <div>
                  <p>
                    {Lang({
                      str: 'address_doc',
                      section: 'menu',
                    })}
                  </p>
                  <MockUpRow loading={address === null} prevalue={30}>
                    {address}
                  </MockUpRow>
                </div>
              </div>
              {address !== null && (
                <Button
                  autoWidth
                  className={styles.change_btn}
                  onClick={() => modalStore.openModal('confirm', {
                    text: Lang({ str: 'doc_change_warning', section: 'menu' }),
                    onYes: () => navigate('/user/change_address', { state: location })
                  })}
                >
                  {Lang({ str: 'change', section: 'menu' })}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MockUpRow = ({ prevalue, loading, children }) => (
  <span className={shimmer('text', loading)}>
    {loading ? Array(prevalue).fill('_').join('') : children}
  </span>
);

export default Verification;
