import {message} from "antd";
import {makeAutoObservable} from "mobx";
import {apiRequest} from "../../Utils";
import AlertStore from "../Alert/AlertStore";

class BankAccountsStore {
  items = [];
  itemFavorite = null;

  banks = {
    '949': `АО "Altyn Bank" (ДБ China Citic Bank Corporation Limited)`,
    '913': `АО ДБ "БАНК КИТАЯ В КАЗАХСТАНЕ"`,
    '914': `АО "Bereke Bank"`,
    '722': `АО "KASPI BANK"`,
    '766': `АО "Центральный Депозитарий Ценных Бумаг"`,
    '832': `АО "Ситибанк Казахстан"`,
    '907': `АО "Банк Развития Казахстана"`,
    '700': `ЕВРАЗИЙСКИЙ БАНК РАЗВИТИЯ`,
    '948': `АО "Евразийский Банк"`,
    '009': `НАО Государственная корпорация "Правительство для граждан"`,
    '972': `АО "Жилищный строительный сберегательный банк "Отбасы банк"`,
    '246': `АО "Исламский Банк "Al Hilal"`,
    '601': `АО "Народный Банк Казахстана"`,
    '930': `АО "Торгово-промышленный Банк Китая в г. Алматы"`,
    '550': `г.Москва Межгосударственный Банк`,
    '886': `АО "Home Credit Bank"`,
    '965': `АО "ForteBank"`,
    '715': `АО "Клиринговый центр KASE"`,
    '856': `АО "Банк ЦентрКредит"`,
    '927': `АО "Казахстанская фондовая биржа"`,
    '821': `АО "Банк "Bank RBK"`,
    '070': `РГУ "Комитет казначейства Министерства финансов РК"`,
    '563': `АО "КАЗПОЧТА"`,
    '551': `АО "Банк Фридом Финанс Казахстан"`,
    '885': `АО "ДБ "КАЗАХСТАН-ЗИРААТ ИНТЕРНЕШНЛ БАНК"`,
    '125': `РГУ Национальный Банк Республики Казахстан`,
    '849': `АО "Нурбанк"`,
    '435': `АО "Шинхан Банк Казахстан"`,
    '998': `АО "First Heartland Jusan Bank"`,
    '432': `ДО АО Банк ВТБ (Казахстан)`,
    '896': `АО "Исламский банк "Заман-Банк"`,
  }

  constructor() {
    makeAutoObservable(this);
  }

  list() {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/private/v1/bankAccounts/list',
        success: (data) => {
          if (data?.error) return AlertStore.error(data.error.msg)
          this.items = data.items;
          if (data.items[0]?.favorite) this.itemFavorite = data.items[0];
          resolve(data.items)
        },
        error: () => {
          reject()
        },
      }).catch();
    });
  }

  get(id) {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/private/v1/bankAccounts/'+id,
        success: (data) => {
          if (data?.error) {
            reject()
            return AlertStore.error(data.error.msg)
          }
          resolve(data)
        },
        error: () => {
          reject()
        },
      }).catch();
    });
  }

  del(id) {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/private/v1/bankAccounts/'+id,
        method: 'DELETE',
        success: (data) => {
          if (data?.error) {
            reject()
            return AlertStore.error(data.error.msg)
          }
          resolve(data)
          this.list().catch()
        },
        error: () => {
          reject()
        },
      }).catch();
    });
  }

  create(values) {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/private/v1/bankAccounts/create',
        method: 'POST',
        body: values,
        success: (data) => {
          if (data?.error) return AlertStore.error(data.error.msg)
          this.list().catch()
          resolve()
        },
        error: () => {
          reject()
        },
      }).catch();
    });
  }

  edit(values) {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/private/v1/bankAccounts/edit',
        method: 'POST',
        body: values,
        success: (data) => {
          if (data?.error) return AlertStore.error(data.error.msg)
          this.list().catch()
          resolve()
        },
        error: () => {
          reject()
        },
      }).catch();
    });
  }

  favorite(values) {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/private/v1/bankAccounts/favorite',
        method: 'POST',
        body: values,
        success: (data) => {
          if (data?.error) return AlertStore.error(data.error.msg)
          this.list().catch()
          resolve()
        },
        error: () => {
          reject()
        },
      }).catch();
    });
  }
}

const clsBankAccountsStore = new BankAccountsStore();
export default clsBankAccountsStore;
