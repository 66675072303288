import { Button, Form, Input } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { useNavigate } from 'react-router-dom';
import { apiQuery, apiQueryError } from '../../Utils';
import left_icon from '../../assets/images/arrowmenu.svg';
import { BackButton } from '../../blocks';
import urlStore from '../../stores/urlStore';
import AlertStore from '../Alert/AlertStore';
import userStore from '../Auth/userStore';
import HeaderTitle from '../Header/HeaderTitle';
import Lang from '../Lang';
import styles from './PersonalInformation.module.scss';

const propsReactCodeInput = {
  inputStyle: {
    margin: '6px 3% 6px 0',
    width: '40px',
    fontSize: '22px',
    height: 40,
    backgroundColor: '#EAEAEA',
    border: 0,
    borderRadius: '4px',
    textAlign: 'center',
    color: '#252525',
  },
};

const Resend = ({ email }) => {
  const [sec, setSec] = useState(0);
  const [btnVisible, setBtnVisible] = useState(false);

  const onLoad = useCallback(
    (s) => {
      setSec(s);
      apiQuery('/private/v1/email_captcha', {
        method: 'POST',
        body: {
          email,
        },
      })
        .then(() => {
          AlertStore.success(Lang({ str: 'email_sent', section: 'menu' }));
        })
        .catch((error) => {
          apiQueryError({ error });
        });
    },
    [email]
  );

  useEffect(() => {
    const time = setInterval(() => {
      setSec((v) => {
        if (v === 0) return 0;
        if (v === 1) {
          setBtnVisible(true);
          return v - 1;
        }
        setBtnVisible(false);
        return v - 1;
      });
    }, 1000);

    return () => {
      clearInterval(time);
    };
  }, []);

  useEffect(() => {
    onLoad(30);
  }, [onLoad]);

  return (
    <>
      {!btnVisible && (
        <p style={{ color: '#A8A9AD' }}>
          {Lang({ str: 'resend_in', section: 'menu' })} {sec}{' '}
          {Lang({ str: 'seconds', section: 'menu' })}
        </p>
      )}
      {btnVisible && (
        <p>
          <span style={{ color: '#A8A9AD', cursor: 'pointer' }} onClick={() => onLoad(30)}>
            {Lang({ str: 'resend', section: 'menu' })}
          </span>
        </p>
      )}
    </>
  );
};

const VerifyEmail = ({ email }) => {
  const navigate = useNavigate();
  const [code, setCode] = useState('');

  const onFinish = () => {
    if (code === '') return;

    apiQuery('/private/v1/updateEmail', {
      method: 'POST',
      body: {
        email,
        code,
      },
    })
      .then((data) => {
        if (data.error) return AlertStore.error(data.error.msg);
        AlertStore.success(Lang({ str: 'email_confirmed', section: 'menu' }));
        userStore.authCheck().catch();
        navigate('/user/security');
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };
  const onChange = (v) => {
    setCode(v);
  };

  return (
    <div>
      <HeaderTitle
        title={Lang({ str: 'enter_conf_code', section: 'menu' })}
        button1={<img src={left_icon} alt={''} />}
      />
      <div className={styles.security_wrp + ' mt-32'}>
        <div className={styles.width_vv}>
          <p className="pb-16">
            {Lang({ str: 'enter_code_email', section: 'menu' })} {email}
          </p>
        </div>
      </div>

      <Form name="normal_login" className="login-form" onFinish={onFinish}>
        <div
          style={{
            display: 'block',
            margin: '20px auto',
            width: '100%',
          }}>
          <ReactCodeInput
            type="number"
            fields={6}
            inputMode="numeric"
            name="code"
            value={code}
            onChange={onChange}
            autoComplete={'one-time-code'}
            style={{ width: 295 }}
            {...propsReactCodeInput}
          />
        </div>

        <Form.Item>
          <Resend email={email} />
        </Form.Item>

        <Form.Item>
          <Button className={styles.button_save} htmlType="submit">
            {Lang({ str: 'send', section: 'default' })}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const ChangeEmail = ({ visible }) => {
  const [email, setEmail] = useState('');
  const [stage, setStage] = useState(1);

  const onFinish = (values) => {
    apiQuery('/private/v1/checkEmail', {
      method: 'POST',
      body: values,
    })
      .then((data) => {
        if (data.error) return AlertStore.error(data.error.msg);
        setEmail(values.email);
        setStage(2);
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  useEffect(() => {
    if (!visible) {
      setEmail('');
      setStage(1);
    }
    urlStore.setSection('');
    window.scroll(0, 0);
  }, [visible]);

  return (
    <div className={styles.personal_information_wrp}>
      <BackButton />
      {stage === 1 && (
        <>
          <div className={styles.security_wrp + ' mb-24'}>
            <p className={styles.titile}>{Lang({ str: 'email_change', section: 'menu' })}</p>
            <div className={styles.width_vv}>
              <p className="pb-16">
                {Lang({ str: 'sessions_terminated_3', section: 'menu' })}{' '}
                {userStore.userData?.email}
              </p>
              <p> {Lang({ str: 'sessions_terminated_22', section: 'menu' })} </p>
            </div>
          </div>
          <div className={styles.personal_information}>
            <Form
              className={styles.personal_pas + ' ' + styles.personal_pasnoborder}
              layout="vertical"
              onFinish={onFinish}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: `${Lang({ str: 'enter_new_email', section: 'menu' })}!`,
                  },
                  {
                    type: 'email',
                  },
                ]}
                hasFeedback>
                <Input placeholder={Lang({ str: 'enter_new_email', section: 'menu' })} />
              </Form.Item>
              <Form.Item
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: `${Lang({ str: 'verify_new_email', section: 'menu' })}!`,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('email') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(Lang({ str: 'emails_dont_match', section: 'menu' }))
                      );
                    },
                  }),
                ]}>
                <Input placeholder={Lang({ str: 'verify_new_email', section: 'menu' })} />
              </Form.Item>
              <Form.Item>
                <Button className={styles.button_save} htmlType="submit">
                  {Lang({ str: 'change_email', section: 'menu' })}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      )}

      {stage === 2 && <VerifyEmail email={email} />}
    </div>
  );
};

export default ChangeEmail;
