import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../UI";
import Lang from "../../components/Lang/index";
import Icon from "../../components/Icon";
import { $class } from "../../Utils";
import './index.scss';

const BackButton = ({
  onClick,
  icon = "arrowLeft",
  label = Lang({ str: "back", section: "default" }),
  style,
  sticky
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={$class('back-button', ['back-button--sticky', sticky])}
      style={{ ...style }}
    >
      <Button
        autoWidth
        variant="clear"
        onClick={onClick || (() => navigate(-1))}
        style={{ margin: "8px 0", transform: "translateX(-8px)" }}
      >
        <Icon style={{ width: 20 }} slug={icon} />
        {label}
      </Button>
    </div>
  );
};

export default BackButton;
