import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Clickable } from '../../UI';
import exit1 from '../../assets/images/exit1.svg';
import { BackButton } from '../../blocks';
import userStore from '../Auth/userStore';
import PDFModal from '../BrokerAccount/PDFModal2';
import DrawerMenuStore from '../DrawerMenu/DrawerMenuStore';
import Lang from '../Lang';
import styles from './index.module.scss';

import { useState } from 'react';
import doc9 from '../../assets/UserAgreement.pdf';
import BrokerAccountStore from '../BrokerAccount/BrokerAccountStore';
import Icon from '../Icon';
import modalStore from "../ModalManager/modalStore";
import Utils from "../../Utils";
import BrokerStatusBlock from "../BrokerStatusBlock";

const links = [
  {
    label: Lang({
      str: 'notifications',
      section: 'menu',
    }),
    link: 'notification',
  },
  {
    label: Lang({
      str: 'safety',
      section: 'menu',
    }),
    link: 'security',
  },
  {
    label: Lang({
      str: 'verification',
      section: 'menu',
    }),
    link: 'verification',
    visible: () => userStore?.userData?.statusData?.ais === 'done',
  },
  {
    label: Lang({
      str: 'bank_accounts',
      section: 'menu',
    }),
    link: 'my_accounts',
    visible: () => userStore?.userData?.statusData?.ais === 'done',
  },
  {
    label: Lang({
      str: 'reports',
      section: 'menu',
    }),
    link: 'reports',
    visible: () => userStore?.userData?.statusData?.ais === 'done',
  },
  {
    label: Lang({
      str: 'contracts',
      section: 'menu',
    }),
    link: 'agreements',
    visible: () => userStore?.userData?.statusData?.ais === 'done',
  },
  {
    label: Lang({
      str: 'lang_change',
      section: 'menu',
    }),
    link: 'language',
  },
];

const User = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const traderData = BrokerAccountStore.traderData;
  const traderDataStatus = BrokerAccountStore.traderDataStatus;

  return (
    <>
      <BackButton label={Lang({ str: 'back', section: 'default' })} sticky />

      <div className={styles.userTitle}>
        {userStore?.userData?.statusData?.alpaca === 'active' ? (
          <>
            <p style={ {textTransform: 'capitalize'} }>{userStore.userData?.fio}</p>
            <p>{userStore.userData?.email}</p>
          </>
        ) : (
          <p>{userStore.userData.email}</p>
        )}
        {/*<b>ID: {userStore.userData.id}</b>*/}
      </div>

      <div>
        {userStore?.userData?.statusData?.alpaca === 'active' ? (
          <p className={styles.userStatus}>
            <Icon slug="verification" /> {Lang({ str: 'verified', section: 'menu' })}
          </p>
        ) : userStore?.userData?.statusData?.brokerForm === 'completed' ? (
          <div className={styles.notVerified}>
            <p>{Lang({str: 'end_verification', section: 'default'})}</p>
            <p>{Lang({str: 'end_verification_2', section: 'default'})}</p>
          </div>
        ) : userStore?.userData?.statusData?.brokerForm === 'in progress' ? (
          <div className={styles.notVerified}>
            <p>{Lang({str: 'continue_verification', section: 'default'})}</p>
            <p>{Lang({str: 'continue_verification_2', section: 'default'})}</p>
            <Button
              autoWidth
              style={ {backgroundColor: '#221F1C', padding: '12px 40px'} }
              onClick={() => navigate(traderData?.view === "info" ? "/slider" : "/broker_account")}
            >
              {Lang({str: 'continue', section: 'onboarding'})}
            </Button>
          </div>
        ) : userStore?.userData?.statusData?.brokerForm === 'blocked' ? (
          <div className={styles.notVerified}>
            <p>{Lang({str: 'block_verification', section: 'default'})}</p>
            <p>{Lang({str: 'block_verification_2', section: 'default'})}</p>
          </div>
        ) : (
          <>
            <p className={styles.userStatus}>
              {Lang({
                str: 'unverified',
                section: 'menu',
              })}
            </p>
            <Button
              variant="primary"
              className={styles.btnVerify}
              onClick={() => {
                navigate(traderData?.view === 'info' ? '/slider' : '/broker_account');
              }}>
              <p>{Lang({ str: 'start_verification', section: 'default' })}</p>
            </Button>
          </>
        )}
        {/*{userStore?.userData?.statusData?.alpaca === 'active' ? (*/}
        {/*  <p className={styles.userStatus}>*/}
        {/*    <Icon slug="verification" /> {Lang({ str: 'verified', section: 'menu' })}*/}
        {/*  </p>*/}
        {/*) : ([0, 1, 3].includes(traderDataStatus) ? (*/}
        {/*  <div className={styles.notVerified}>*/}
        {/*    <p>*/}
        {/*      {traderDataStatus === 3 ? Lang({str: 'block_verification', section: 'default'})*/}
        {/*      : traderDataStatus === 1 ? Lang({str: 'end_verification', section: 'default'})*/}
        {/*      : Lang({str: 'continue_verification', section: 'default'})}*/}
        {/*    </p>*/}
        {/*    <p>*/}
        {/*      {traderDataStatus === 3 ? Lang({str: 'block_verification_2', section: 'default'})*/}
        {/*      : traderDataStatus === 1 ? Lang({str: 'end_verification_2', section: 'default'})*/}
        {/*      : Lang({str: 'continue_verification_2', section: 'default'})}*/}
        {/*    </p>*/}
        {/*    {traderDataStatus === 0 && (*/}
        {/*      <Button*/}
        {/*        autoWidth*/}
        {/*        style={ {backgroundColor: '#221F1C', padding: '12px 88px'} }*/}
        {/*        onClick={() => navigate(traderData?.view === "info" ? "/slider" : "/broker_account")}*/}
        {/*      >*/}
        {/*        {Lang({str: 'continue', section: 'onboarding'})}*/}
        {/*      </Button>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*) : (*/}
        {/*  <>*/}
        {/*    <p className={styles.userStatus}>*/}
        {/*      {Lang({*/}
        {/*        str: 'unverified',*/}
        {/*        section: 'menu',*/}
        {/*      })}*/}
        {/*    </p>*/}
        {/*    <Button*/}
        {/*      variant="primary"*/}
        {/*      className={styles.btnVerify}*/}
        {/*      onClick={() => {*/}
        {/*        navigate(traderData?.view === 'info' ? '/slider' : '/broker_account');*/}
        {/*      }}>*/}
        {/*      <p>{Lang({ str: 'start_verification', section: 'default' })}</p>*/}
        {/*    </Button>*/}
        {/*  </>*/}
        {/*))}*/}
      </div>

      <div className={styles.linksWrap}>
        {links.map(
          (x) =>
            (x?.visible ? x.visible() : true) && (
              <Clickable
                key={x.link}
                className={styles.linkBtn}
                onClick={() => {
                  navigate(`/user/${x.link}`, { state: location });
                }}>
                <div>{x.label}</div>

                <div>
                  {x.link === 'notification' && DrawerMenuStore.inboxes > 0 && (
                    <div className={styles.linkBtnInboxes}>
                      {DrawerMenuStore.inboxes > 99 ? '99+' : DrawerMenuStore.inboxes}
                    </div>
                  )}
                  <Icon slug="arrowLeft" style={{ transform: 'scaleX(-1)' }} />
                </div>
              </Clickable>
            )
        )}
      </div>

      <Button
        align="start"
        variant="outlined"
        className={styles.btnExit}
        onClick={() => {
          userStore.logout();
          navigate('/login');
        }}>
        <img src={exit1} alt="" style={{ marginRight: 5 }} />
        {Lang({ str: 'exit', section: 'default' })}
      </Button>

      <div className={styles.delimiter} />

      <div className={styles.grau_bl + ' grau_bl'}>
        <Button
          align="start"
          autoWidth
          variant="clear"
          onClick={() => modalStore.openModal('pdf', { url: doc9 })}>
          {Lang({ str: 'user_agreement', section: 'menu' })}
        </Button>
        {/* <Button
          align="start"
          autoWidth
          variant="clear"
          onClick={() =>
            setActivePdf2({
              file: doc10,
            })
          }
        >
          Политика конфиденциальности
        </Button>*/}
        <a href="https://www.geekoinvest.kz/privacy-policy" target="_blank">
          {Lang({ str: 'privacy_policy', section: 'menu' })}
        </a>

        <div
          style={{
            textAlign: 'center',
            margin: '10px',
            fontSize: 8,
            color: 'rgb(185, 185, 185)',
          }}>
          App Version: {Utils.apiDomain() === 'app2.geekoinvest.kz' && <>d</>}{window.REACT_APP_VERSION} (
          {moment(window.REACT_APP_BUILD_DATE).format('YYYY-MM-DD HH:mm:ss')})
        </div>
      </div>
    </>
  );
};

export default observer(User);
