import { observer } from 'mobx-react-lite';
import React from 'react';
import Utils from '../../../Utils';
import { BackButton } from '../../../blocks';
import Lang from '../../Lang';
import AccountHistoryBlock from '../AccountHistoryBlock';
import AccountItem from '../AccountItem';
import AccountStore from '../AccountStore';
import styles from '../Accounts.module.scss';

const BrokerageAccount = ({ visible }) => {
  return (
    <>
      <BackButton
        label={Lang({ str: 'accounts', section: 'accounts' })}
        style={{
          position: 'sticky',
          top: 0,
          background: '#f5f6f7',
          zIndex: 10,
        }}
      />

      <div className={styles.account_page}>
        <AccountItem
          title={
            Utils.apiDomain() === 'app2.geekoinvest.kz'
              ? Lang({ str: 'broker_acc2', section: 'accounts' })
              : Lang({ str: 'broker_acc', section: 'accounts' })
          }
          price={AccountStore.accounts.brokerage.price}
          number={AccountStore.accounts.brokerage.number}
          button1
          button2
          button3
          src1={'/accounts/add_broker_account'}
          src2={'/accounts/output_broker_account'}
          src3={'/accounts/between_account'}
          buttons
        />

        <AccountHistoryBlock typeAccount="brokerage" visible={visible} />
      </div>
    </>
  );
};

export default observer(BrokerageAccount);
