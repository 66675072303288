import React, {useEffect} from "react";
import styles from "./index.module.scss";
import {useNavigate} from "react-router-dom";
import {Button} from "../../UI";
import Lang from "../Lang";
import BrokerAccountStore from "../BrokerAccount/BrokerAccountStore";
import {observer} from "mobx-react-lite";

const StartVerificationBlock = () => {
  const navigate = useNavigate();
  const traderData = BrokerAccountStore.traderData;
  const traderDataStatus = BrokerAccountStore.traderDataStatus;

  useEffect(() => {
    BrokerAccountStore.get()
  }, [])

  if (traderDataStatus === null) return;

  if (traderDataStatus === 3) return (
    <div className={styles.block} style={ {backgroundColor: '#FFFBE8'} }>
      <div>
        <h1>{Lang({str: 'block_verification', section: 'default'})}</h1>
        <p>{Lang({str: 'block_verification_2', section: 'default'})}</p>
      </div>
    </div>
  )

  if (traderDataStatus === 1) return (
    <div className={styles.block} style={ {backgroundColor: '#FFFBE8'} }>
      <div>
        <h1>{Lang({str: 'end_verification', section: 'default'})}</h1>
        <p>{Lang({str: 'end_verification_2', section: 'default'})}</p>
      </div>
    </div>
  )

  if (traderDataStatus === 0) return (
    <div className={styles.block} style={ {backgroundColor: '#FFFBE8'} }>
      <div>
        <h1>{Lang({str: 'continue_verification', section: 'default'})}</h1>
        <p>{Lang({str: 'continue_verification_2', section: 'default'})}</p>
      </div>
      <Button
        onClick={() => navigate(traderData?.view === "info" ? "/slider" : "/broker_account")}
        className={styles.btn}
      >
        {Lang({str: 'start_verification_2', section: 'default'})}
      </Button>
    </div>
  );

  return (
    <div className={styles.block}>
      <div>
        <h1>{Lang({str: 'verification_msg', section: 'default'})}</h1>
        <p>{Lang({str: 'verification_msg_3', section: 'default'})}</p>
      </div>
      <Button
        onClick={() => navigate(traderData?.view === "info" ? "/slider" : "/broker_account")}
        className={styles.btn}
      >
        {Lang({str: 'start_verification', section: 'default'})}
      </Button>
    </div>
  );
};

export default observer(StartVerificationBlock);
