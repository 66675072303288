import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';
import AppStore from '../App/AppStore';
import Lang from '../Lang';
import styles from './Collection.module.scss';
import CollectionModalOpenStore from './CollectionModalOpenStore';
import PortfolioStore from './PortfolioStore';

import { MenuOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Input, Table } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { TickerCard } from '../../blocks';
import { Button, IconButton } from '../../UI';
import ModalConfirmButton from '../ModalManager/ModalConfirmButton';
import TickerStore from '../Ticker/TickerStore';
import AlertStore from '../Alert/AlertStore';

const TickerItem = observer(({ tickerId, collectionId, style }) => {
  let navigate = useNavigate();
  const location = useLocation();
  const item = TickerStore.tickerData[tickerId];

  useEffect(() => {
    TickerStore.getTicker(tickerId);
  }, [tickerId]);

  return (
    <div
      className={styles.ticker_item}
      style={{
        cursor: 'pointer',
        zIndex: 2,
        position: 'relative',
        ...style,
      }}>
      <IconButton
        className={styles.del}
        variant="contained"
        size="sm"
        icon="minus"
        onClick={() => PortfolioStore.collectionTickerDel(collectionId, tickerId)}
      />
      <TickerCard tickerId={tickerId} withTrading={false} />
    </div>
  );
});

const tableLocale = {
    emptyText: (
      <span>
        <p>{Lang({ str: 'no_stocks', section: 'portfolio' })}</p>
      </span>
    )
}

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const CollectionModalEdit = ({ collectionId, closeModal }) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [form] = Form.useForm();
  if (!collectionId) collectionId = CollectionModalOpenStore.id;
  const collectionData = PortfolioStore.collectionData[collectionId];
  const tickers = collectionData?.tickers || [];
  const [items, setItems] = useState([]);
  const [name, setName] = useState(collectionData.name);

  const columns = [
    {
      key: 'sort',
    },
    {
      dataIndex: 'ticker',
      key: 'ticker',
      render: (text, record) => {
        const width = AppStore.width > 544 ? 444 : 'auto'; // AppStore.width - 100;
        return <TickerItem tickerId={record._id} collectionId={collectionId} style={{ width }} />;
      },
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onDeleteHandler = () => {
    PortfolioStore.collectionDel(collectionId).catch();
    navigate('/favorites');
    closeModal();
  };

  const onDragEnd = ({ active, over }) => {
    console.log({ active, over });
    if (active.id !== over?.id) {
      setItems((previous) => {
        const activeIndex = previous.findIndex((i) => i._id === active.id);
        const overIndex = previous.findIndex((i) => i._id === over?.id);
        const mass = arrayMove(previous, activeIndex, overIndex);
        PortfolioStore.collectionTickersSave(
          collectionId,
          mass.map((item) => item._id)
        ).catch();
        return mass;
      });
    }
  };

  // const rowRenderer = ({ index, key, style }) => {
  //   return (
  //     <div key={key} style={style} >
  //       <TickerItemFavorite tickerId={tickers[index]} />
  //     </div>
  //   );
  // };

  // const onSaveHandler = () => {
  //   form.submit();
  // };

  const onSaveHandler = () => {
    if (name.trim() === '')
      return AlertStore.error(Lang({ str: 'enter_name_2', section: 'portfolio' }));

    setLoading(true);
    (async () => {
      try {
        await PortfolioStore.collectionSet({
          collectionId,
          name,
          // logo: values.logo,
        });
        await PortfolioStore.collectionMyLoad();
        setTimeout(() => closeModal(), 400);
      } catch (e) {
        console.warn(e);
        AlertStore.error(Lang({ str: 'error', section: 'default' }));
        setLoading(false);
      }
    })();
  };

  useEffect(() => {
    if (collectionId) PortfolioStore.getCollection(collectionId);
  }, [collectionId]);

  useEffect(() => {
    setItems(
      tickers.map((item) => {
        return { _id: item };
      })
    );
  }, [tickers]);

  if (!collectionData) return null;

  return (
    <>
      <div className="p-16">
        <Input
          className={styles.editListInput}
          name="name"
          placeholder={Lang({ str: 'collection_name', section: 'portfolio' })}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <div className={styles.editList}>
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
              // rowKey array
              items={items.map((i) => i._id)}
              strategy={verticalListSortingStrategy}>
              <Table
                components={{
                  body: {
                    row: Row,
                  },
                }}
                locale={tableLocale}
                rowKey="_id"
                dataSource={items}
                columns={columns}
                pagination={false}
                showHeader={false}
                style={{ width: '100%' }}
                rowClassName={styles.tableRow}
                size={'small'}
              />
            </SortableContext>
          </DndContext>
        </div>

        <div className={styles.editListBtns}>
          {collectionData.mode !== 'favorites' && (
            <ModalConfirmButton
              loading={loading}
              className={styles.editListConfirmBtn}
              btnLabel={Lang({ str: 'delete', section: 'portfolio' })}
              onClick={onDeleteHandler}
              confirmTitle={Lang({ str: 'delete_collection', section: 'portfolio' })}
            />
          )}
          <Button
            style={{ width: collectionData.mode !== 'favorites' && '50%', backgroundColor: '#221F1C' }}
            loading={loading}
            onClick={onSaveHandler}
            variant="primary">
            {Lang({ str: 'save', section: 'default' })}
          </Button>
        </div>
      </div>
    </>
  );
};

export default observer(CollectionModalEdit);
