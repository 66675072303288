import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './Trading2.module.scss';
// import TradingModal from "./TradingModal";
// import ChartCandlestick from "../ChartCandlestick/ChartCandlestick";
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import urlStore from '../../stores/urlStore';
import websocket from '../../websocket';
import PortfolioStore from '../Portfolio/PortfolioStore';
import TickerStore from '../Ticker/TickerStore';
//import TradingStore from "../Trading/TradingStore";
import Calendar from './Calendar';
// import TradingTickerModalStore from "./TradingTickerModalStore";
import { $class, apiRequest, shimmer, shortenNumObj, useDelayRender } from '../../Utils';
import NewsItem from '../News/NewsItem';
import TradingModalButton from './TradingModalButton';
// import Lang from "../Lang";
import { Badge, Col, Row, Space } from 'antd';
import { Button, IconButton, Tabs } from '../../UI';
import grarrow from '../../assets/images/grarr.svg';
import redarrow from '../../assets/images/redarr.svg';
import rinok from '../../assets/images/rinok.svg';
import { BackButton, PriceChanging, TickerTitle } from '../../blocks';
import DollarSign from '../../blocks/DollarSign';
import GeekoChart from '../GeekoChart/GeekoChart';
import Lang from '../Lang';
import modalStore from '../ModalManager/modalStore';
import NumberFormat from '../NumberFormat';
import { fields } from '../TradingFinancial/financial';
import AnalystPie from './Financial/AnalystPie';
import AppStore from '../App/AppStore';

let t;

const calendarWorkDate = (date) => {
  if (Calendar[date]) return Calendar[date];
  date = moment(date).subtract(1, 'day').format('YYYY-MM-DD');
  return calendarWorkDate(date);
};

const formatToShortText = (text, cut = 200) => {
  let res = '';
  for (let i = 0; i < text.length; i++) {
    res += text[i];
    if (i > 200 && text[i] === '.' && text[i + 1] === ' ') return res;
  }
  return res;
};

const MarketTime = () => {
  const schedule = calendarWorkDate(moment().utcOffset(-5).format('YYYY-MM-DD'));
  const t_open = `${schedule.date}T${schedule.open}:00-05:00`; //2023-08-11T05:29:47-04:00
  const t_close = `${schedule.date}T${schedule.close}:00-05:00`; //2023-08-11T05:29:47-04:00
  const t_session_open = moment(schedule.session_open, 'HHmm').format('YYYY-MM-DDTHH:mm:ss-05:00'); //2023-08-11T05:29:47-04:00
  const t_session_close = moment(schedule.session_close, 'HHmm').format(
    'YYYY-MM-DDTHH:mm:ss-05:00'
  ); //2023-08-11T05:29:47-04:00
  const time = moment().utcOffset(-4).format();

  // console.log({
  //   t_open, t_close, t_session_open, t_session_close
  // })

  if (moment(t_session_open).isBefore(time) && moment(t_open).isAfter(time))
    return {
      mode: 'Pre-market',
      text: `Pre-market: ${moment(schedule.session_open, 'HHmm').format('HH:mm')} PM EDT`,
    };
  if (moment(t_open).isBefore(time) && moment(t_close).isAfter(time))
    return { mode: 'Market open' };
  if (moment(t_close).isBefore(time) && moment(t_session_close).isAfter(time))
    return { mode: 'Post-market' };

  return { mode: 'After hours' };
};

const Trading = ({ $page, visible }) => {
  let navigate = useNavigate();
  const location = useLocation();
  let params = useParams();

  const tickerId = params.tickerId ? params.tickerId : null;
  let tickerData = TickerStore.tickerData[tickerId];
  const tickerSignal = TickerStore.tickerSignal[tickerId];
  const [blink, setBlink] = useState(false);
  const [symbol, setSymbol] = useState('');

  const eventTradingId = useRef(null);
  const eventNewsId = useRef(null);

  const marketTime = MarketTime();

  // ----------news---------------------
  const [page, setPage] = useState(1);
  const [news, setNews] = useState([]);
  const newsIdMap = useRef({});
  const [oldNews, setOldNews] = useState([]);
  const [changed, setChanged] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const { TabPane } = Tabs;
  const [tab, setTab] = useState('1');
  //------------analytic--------
  const [analytic, setAnalytic] = useState({});
  //----------------------

  useEffect(() => {
    if (tickerData === null || tickerData === undefined) return;
    getAnalytic();
  }, [tickerData?.symbol]);

  const getAnalytic = () => {
    apiRequest({
      url: `/private/v1/get-analytic/${tickerData?.symbol}`,
      method: 'GET',
      success: async (data) => {
        if (data?.error) return; // message.error(data?.error.msg);
        console.log('get-analytic: ', data);
        if (data?.status !== 'ok' || !data?.data) {
          return;
        }
        setAnalytic(data?.data);
      },
    }).catch((error) => {
      console.error({ error });
    });
  };

  const getNews = (pageNumber) => {
    console.log('news', tickerData?.symbol, pageNumber);
    apiRequest({
      url: `/private/v1/get_news/${tickerData?.symbol}/${pageNumber}?lang=${AppStore.lang}`,
      method: 'GET',
      success: async (data) => {
        if (data?.error) return; // message.error(data?.error.msg);
        // console.log("news: ", data);
        if (data?.status !== 'ok' || !Array.isArray(data?.news)) {
          return;
        }

        if (data?.news?.length < 5) {
          setIsEnd(true);
        } else setIsEnd(false);

        data.news.map((x) => (newsIdMap.current[x.id] = 1));

        if (pageNumber === 1) setNews(data?.news);
        else setNews([...news, ...data?.news]);
      },
    }).catch((error) => {
      console.error({ error });
    });
  };

  useEffect(() => {
    if (tickerData === null || tickerData === undefined) return;
    getNews(page);
  }, [page, tickerData?.symbol]);

  useEffect(() => {
    urlStore.setSection('trading');
    window.scroll(0, 0);
    PortfolioStore.favoritesLoad().catch();
    TickerStore.getTicker(tickerId, false);

    setPage(1);
    setIsEnd(false);
    setTab('1');

    return () => {
      clearInterval(t);
    };
  }, [tickerId]);

  useEffect(() => {
    setSymbol(tickerData?.symbol);

    if (visible === false) {
      websocket.unSubscribe(eventTradingId.current);
      websocket.unSubscribe(eventNewsId.current);
      return;
    }

    if (tickerData === null || tickerData === undefined || !tickerData?.symbol) return;
    // console.log({ tickerId, tickerData });

    eventTradingId.current = websocket.subscribe(
      { event: 'updateTrading', key: tickerData.symbol },
      (data) => {
        TickerStore.setTicker(tickerData._id, data);
        TickerStore.setTickerSignal(tickerId, true);
        // TradingPriceStore.priceUpdate(data);
      }
    );

    // ====== SUBSCRIBE TO UPDATE LAST NEWS
    eventNewsId.current = websocket.subscribe({ event: 'updateNews', key: '*' }, ({ news, ref }) => {
      
      if (!news?.symbols?.includes(tickerData?.symbol)) return;
      console.log('FRESH NEWS TRADING', { ref, news });

      news.className = 'highlightEffect';

      if (AppStore.lang === 'en') {
        setNews((prev) => [news, ...prev.map(x => ({ ...x, className: '' }))]);
      }
      else if (!!news?.translate?.content) {
        setNews((prev) => [news, ...prev.map(x => ({ ...x, className: '' }))]);
      }
    });
    // ====== SUBSCRIBE TO UPDATE LAST NEWS

    return () => {
      websocket.unSubscribe(eventTradingId.current);
      websocket.unSubscribe(eventNewsId.current);
    };
  }, [tickerId, tickerData?.symbol, visible]);

  useEffect(() => {
    if (tickerSignal) {
      // div.style.background = "red";
      setBlink(true); // изменяем состояние при клике
      setTimeout(() => {
        setBlink(false);
        TickerStore.setTickerSignal(tickerId, null);
      }, 200);
    }
  }, [tickerId, tickerSignal]);

  if (!tickerData) return null;

  const changePercent =
    tickerData.snapshot?.dailyBar.c !== undefined
      ? (
          ((tickerData.snapshot?.dailyBar.c - tickerData.snapshot?.prevDailyBar.c) /
            tickerData.snapshot?.prevDailyBar.c) *
          100
        ).toFixed(2)
      : null;

  const change = (tickerData.snapshot?.dailyBar.c - tickerData.snapshot?.prevDailyBar.c).toFixed(2);

  let changePercent2 =
    tickerData.snapshot?.latestTrade?.p !== undefined
      ? (
          ((tickerData.snapshot?.latestTrade?.p - tickerData.snapshot?.dailyBar.c) /
            tickerData.snapshot?.dailyBar.c) *
          100
        ).toFixed(2)
      : null;

  let change2 = (tickerData.snapshot?.latestTrade?.p - tickerData.snapshot?.dailyBar.c).toFixed(2);

  let changePercent3 = (
    ((tickerData.snapshot?.latestTrade?.p - tickerData.snapshot?.prevDailyBar.c) /
      tickerData.snapshot?.prevDailyBar.c) *
    100
  ).toFixed(2);

  let change3 = (tickerData.snapshot?.latestTrade?.p - tickerData.snapshot?.prevDailyBar.c).toFixed(
    2
  );

  const showFinancials =
    analytic?.income_statement_quarter?.length && analytic?.balance_sheet_statement_quarter?.length;
  const showAnalysis = ![
    'analystRatingsbuy',
    'analystRatingsHold',
    'analystRatingsSell',
    'analystRatingsStrongSell',
    'analystRatingsStrongBuy',
  ].every((x) => analytic?.analyst?.[x] === 0 || analytic?.analyst?.[x] === undefined);

  return (
    <>
      <div
        className={styles.fixed_but_trade}
        style={{ top: window.innerHeight - 38 - 24, bottom: 'auto' }}>
        <TradingModalButton tickerId={tickerData._id} />
      </div>

      <div className={styles.header}>
        <Row>
          <BackButton />
          <Col flex={'auto'} style={{ textAlign: 'right' }}>
            <div className={styles.marketStatus}>
              {marketTime.mode === 'Market open' && (
                <Badge
                  className={styles.marketBadgeStatus}
                  status="success"
                  text={
                    <span className={styles.marketBadgeSuccess}>
                      {Lang({ str: 'market_open', section: 'trading' })}
                    </span>
                  }
                />
              )}

              {/* Market Closed */}
              {marketTime.mode === 'After hours' && (
                <Badge
                  className={styles.marketBadgeStatus + ' ' + styles.marketBadgeStatus1}
                  status="error"
                  style={{ cursor: 'pointer' }}
                  text={
                    <>
                      <img src={rinok} />
                      <span
                        onClick={() => modalStore.openModal('default', { data: <MarketHelper /> })}
                        className={styles.marketBadgeError}>
                        {Lang({ str: 'after_hours', section: 'trading' })}
                      </span>
                    </>
                  }
                />
              )}
              {marketTime.mode === 'Pre-market' && (
                <Badge
                  className={styles.marketBadgeStatus}
                  status="warning"
                  text={
                    <span className={styles.marketBadgeWarning}>
                      {Lang({ str: 'pre_market', section: 'trading' })}
                    </span>
                  }
                />
              )}
              {marketTime.mode === 'Post-market' && (
                <Badge
                  className={styles.marketBadgeStatus}
                  status="warning"
                  text={
                    <span className={styles.marketBadgeWarning}>
                      {Lang({ str: 'post_market', section: 'trading' })}
                    </span>
                  }
                />
              )}

              {marketTime.mode === 'Pre-market' && (
                <div className={styles.marketStatusLabel}>Расширенные часы</div>
              )}
            </div>
          </Col>
        </Row>
      </div>

      <Row
        className={styles.tickerTitle + ' mt-16'}
        style={{ alignItems: 'center', flexFlow: 'nowrap' }}>
        <TickerTitle size={50} ticker={tickerData} withDesc withSymbol />
        <Space>
          {/* <IconButton variant='clear' icon='share' /> */}
          {/* <FavoriteTicker tickerId={tickerId} /> */}
          <IconButton
            style={{ border: '1px solid #25252599' }}
            variant={'clear'}
            icon={'plus1'}
            iconSize={20}
            onClick={() => modalStore.openModal('addToCollection', { tickerId })}
          />
        </Space>
      </Row>

      <div className={styles.price}>
        {['After hours', 'Pre-market', 'Post-market'].includes(marketTime.mode) && (
          <Row style={{ alignItems: 'end' }}>
            <Col flex={'auto'}>
              <div className={styles.priceText}>
                {Lang({ str: 'at_close', section: 'trading' })}:
              </div>
              <div
                className={$class(
                  styles.priceAmount,
                  shimmer('text', tickerData?.snapshot?.dailyBar?.c === undefined)
                )}>
                <DollarSign size={13} top={3} right={2} />
                {NumberFormat({
                  value: tickerData?.snapshot?.dailyBar?.c ?? '99.99',
                  style: { fontSize: 28 },
                  styleFractionalPart: { color: '#A8A8A8' },
                })}
              </div>
              <PriceChanging
                className={$class(styles.pricePercent, shimmer('text', !changePercent))}
                change={changePercent}
                pnl={change}
                align="start"
              />
            </Col>
            <Col flex={'auto'} style={{ textAlign: 'right' }}>
              <div className={styles.priceText}>
                {marketTime.mode}:{' '}
                {/* {moment(tickerData?.snapshot?.latestTrade?.t)
                  .utcOffset("-04:00")
                  .format("HH:mm")}{" "}
                EDT */}
              </div>
              <div
                className={$class(
                  styles.priceAmount2,
                  shimmer('text', tickerData?.snapshot?.latestTrade?.p === undefined)
                )}
                style={{ margin: '3px 0' }}>
                <DollarSign size={8} top={3} right={1} />
                {NumberFormat({
                  value: tickerData?.snapshot?.latestTrade?.p ?? '99.99',
                  style: { fontSize: 16 },
                  styleFractionalPart: { color: '#A8A8A8' },
                })}
              </div>
              <PriceChanging
                style={{ marginLeft: 'auto' }}
                className={$class(styles.pricePercent, shimmer('text', !changePercent2))}
                change={changePercent2}
                withoutIcon
                pnl={change2}
                align="end"
              />
            </Col>
          </Row>
        )}
        {marketTime.mode === 'Market open' && (
          <>
            <div className={styles.priceText}>
              {marketTime.mode}:{' '}
              {/* {moment(tickerData?.snapshot?.latestTrade?.t)
                .utcOffset("-04:00")
                .format("HH:mm")}{" "}
              EDT */}
            </div>
            <div
              className={[styles.priceAmount, blink ? 'tickerSignalOn' : 'tickerSignalOff'].join(
                ' '
              )}>
              <DollarSign size={14} top={4} right={2} />
              {NumberFormat({
                value: tickerData?.snapshot?.latestTrade?.p,
                styleFractionalPart: { color: '#A8A8A8' },
              })}
            </div>
            <div
              className={[
                styles.blockChangePercent,
                changePercent3 < 0 ? styles.pricePercentMinus : styles.pricePercentPlus,
              ].join(' ')}>
              <span className={styles.change}>{change3}</span>
              <span className={styles.changePercent}>
                {changePercent3}%{' '}
                {change3 < 0 ? (
                  <img src={redarrow} alt={''} />
                ) : Number(change3) === 0 ? (
                  ''
                ) : (
                  <img src={grarrow} alt={''} />
                )}
              </span>
            </div>
          </>
        )}
      </div>

      <div className={styles.graph}>
        {/* <TradingViewWidget symbol={tickerData.symbol} interval={"D"} /> */}
        <GeekoChart tickerId={tickerId} tickerData={tickerData} />
      </div>

      <div className={$class(styles.tabs_analitycs, 'aom-fadeOut')}>
        <div
          className={
            styles.tabs_wrp + (!showAnalysis && !showFinancials ? ' ' + styles.tabs_hidden : '')
          }>
          <Tabs
            value={tab}
            isTabsFlex
            onChange={setTab}
            items={[
              {
                label: Lang({ str: 'summary', section: 'trading' }),
                value: '1',
              },
              {
                label: Lang({ str: 'analysis', section: 'trading' }),
                value: '2',
                hidden: !showAnalysis,
              },
              {
                label: Lang({ str: 'financials', section: 'trading' }),
                value: '3',
                hidden: !showFinancials,
              },
            ]}
          />

          <div style={{ display: tab !== '1' && 'none' }} className={styles.tabs_trading_bl}>
            <div className={styles.tabs_trading_tb}>
              <p className={styles.tabs_titile}>{Lang({ str: 'key_stats', section: 'trading' })}</p>
              {analytic?.historical?.length > 0 && !!analytic?.historical[0]?.dividend && (
                <div>
                  <p>{Lang({ str: 'dividends', section: 'trading' })}</p>
                  <span>
                    {analytic?.historical?.length > 0 &&
                      new Intl.NumberFormat('en-EN').format(
                        parseFloat(analytic?.historical[0]?.dividend?.toString()).toFixed(2)
                      )}
                    {analytic?.historical?.length == 0 && 0}
                  </span>
                </div>
              )}
              {!!analytic?.capitalization?.marketCap && (
                <div>
                  <p>{Lang({ str: 'market_cap', section: 'trading' })}</p>
                  <span>
                    <DollarSign size={8} top={3} right={1} />
                    {shortenNumObj(analytic?.capitalization?.marketCap).number}{' '}
                    {shortenNumObj(analytic?.capitalization?.marketCap).symbol}
                  </span>
                </div>
              )}
              {!!analytic?.ratios_ttm?.marketCap && (
                <div>
                  <p>{Lang({ str: 'p_e', section: 'trading' })}</p>
                  <span>
                    {new Intl.NumberFormat('en-EN').format(
                      parseFloat(analytic?.ratios_ttm?.marketCap?.toString()).toFixed(2)
                    )}
                  </span>
                </div>
              )}
            </div>
            {!!analytic?.profile?.description && (
              <div className={styles.tabs_trading_tb1}>
                <p className={styles.tabs_titile}>
                  {Lang({ str: 'company_description', section: 'trading' })}
                </p>
                <p className={styles.tabs_text}>
                  {formatToShortText(analytic?.profile?.description)}
                </p>
                {/* <a onClick={() => setModalOpen(true)}>Узнать больше</a> */}
              </div>
            )}

            {!analytic?.ratios_ttm?.markketCap &&
              !analytic?.capitalization?.marketCap &&
              !analytic?.profile?.description &&
              !analytic?.historical?.length && (
                <p
                  style={{
                    textAlign: 'center',
                    color: 'var(--basic-dark40)',
                  }}>
                  Нет данных
                </p>
              )}
          </div>

          <div style={{ display: tab !== '2' && 'none' }} className={styles.tabs_trading_bl}>
            <div className={styles.tabs_trading_tb}>
              <p className={styles.tabs_titile}>{Lang({ str: 'indicator', section: 'trading' })}</p>
              <AnalystPie analytic={analytic} />
            </div>
          </div>

          <div style={{ display: tab !== '3' && 'none' }} className={styles.tabs_trading_bl}>
            {[
              {
                label: Lang({ str: 'income', section: 'trading' }),
                cat: 'income_statement',
                value: analytic?.income_statement_year?.length
                  ? analytic.income_statement_year
                  : analytic.income_statement_quarter,
              },
              {
                label: Lang({ str: 'balance_sheet', section: 'trading' }),
                cat: 'balance_sheet_statement',
                value: analytic?.balance_sheet_statement_year?.length
                  ? analytic.balance_sheet_statement_year
                  : analytic.balance_sheet_statement_quarter,
              },
            ].map((block) => (
              <div className={styles.fin_block} key={block.cat}>
                <p className={styles.tabs_titile}>{block.label}</p>
                <div className={styles.fin_list}>
                  {fields[block.cat].slice(0, 2).map((x) => {
                    const [first, second] = [
                      block.value?.[0]?.[x.field],
                      block.value?.[1]?.[x.field],
                    ];
                    const { sign, number, symbol } = shortenNumObj(first || 0);
                    return (
                      <div className={styles.fin_item} key={x.field}>
                        <div className={styles.fin_item_label}>{x.label}</div>
                        <div>
                          <div className={styles.fin_item_price}>
                            {sign}
                            <DollarSign size={8} top={2} right={1} />
                            {number} <span>{symbol}</span>
                          </div>
                          <PriceChanging change={(first - second) / first} />
                        </div>
                      </div>
                    );
                  })}
                  <IconButton
                    onClick={() => {
                      navigate(`./financial?section=${block.cat}&symbol=${tickerData?.symbol}`, {
                        state: location,
                      });
                    }}
                    icon="arrowRightUp"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <div className={styles.error_block}>
        <p>
        Данный материал носит справочно-информационный характер, не является индивидуальной инвестиционной рекомендацией или предложением инвестировать.
        </p>
      </div> */}
      </div>

      <div className={$class(styles.newsBlock, 'aom-fadeOut')}>
        <p className={styles.newsBlockTitle}>{Lang({ str: 'last_news', section: 'trading' })}</p>
        {news.map((item, index) => {
          let img_small = '';
          let img_large = '';
          if (Array.isArray(item?.images) && item?.images.length > 0) {
            for (let i = 0; i < item?.images.length; i++) {
              const img = item?.images[i];
              if (img?.size === 'small') {
                img_large = img?.url;
              }
              if (img?.size === 'thumb') {
                img_small = img?.url;
              }
            }
          }

          return (
            <NewsItem
              key={`${item?._id}-${index}`}
              id={item?.id}
              headline={item?.headline}
              symbols={item?.symbols ? item?.symbols : []}
              datetime={item?.created_at}
              summary={item?.summary}
              translate={item?.translate}
              content={item?.content}
              author={item?.author}
              img_small={img_small}
              img_large={img_large}
              link="news2"
            />
          );
        })}
        {news.length === 0 && (
          <p style={{ textAlign: 'center', margin: 20 }}>
            {Lang({ str: 'no_news', section: 'trading' })}
          </p>
        )}
        {!isEnd && (
          <Button
            className="but_more_news"
            variant="text"
            onClick={() => setPage((prev) => prev + 1)}>
            {Lang({ str: 'show_more', section: 'trading' })}
          </Button>
        )}
      </div>
    </>
  );
};

const MarketHelper = () => (
  <div className={styles.modaltextblock + ' pb-0'}>
    <div>
      <h3>{Lang({ str: 'pre_market', section: 'trading' })}</h3>
      <p>{Lang({ str: 'trading_before_open', section: 'trading' })}</p>
      <p>{Lang({ str: 'pre_market_time', section: 'trading' })}</p>
      <p>{Lang({ str: 'only_limit_orders', section: 'trading' })}</p>
    </div>
    <div>
      <h3>{Lang({ str: 'market_open', section: 'trading' })}</h3>
      <p>{Lang({ str: 'main_trading_session', section: 'trading' })}</p>
      <p>{Lang({ str: 'market_open_time', section: 'trading' })}</p>
    </div>
    <div>
      <h3>{Lang({ str: 'post_market', section: 'trading' })}</h3>
      <p>{Lang({ str: 'trading_after_closed', section: 'trading' })}</p>
      <p>{Lang({ str: 'post_market_time', section: 'trading' })}</p>
      <p>{Lang({ str: 'only_limit_orders', section: 'trading' })}</p>
    </div>
    <div>
      <h3>{Lang({ str: 'market_closed', section: 'trading' })}</h3>
      <p>{Lang({ str: 'market_closed_warning', section: 'trading' })}</p>
    </div>
  </div>
);

export default observer(Trading);
