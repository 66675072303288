import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { apiRequest } from "../../Utils";
import grarrow from "../../assets/images/grarr.svg";
import redarrow from "../../assets/images/redarr.svg";
import styles from "../News/NewsTickerList.module.scss";
import TickerStore from "../Ticker/TickerStore";

const NewsTickerList = ({
  symbols = [],
  style = {},
  interactive,
  closeModal,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tickers, setTickers] = useState([]);

  const getTicker = async (symbol) => {
    const result = await TickerStore.getTickerBySymbol(symbol)
    return result ? result : false;
  }

  const loadTickers = async () => {
    // console.log({ symbols });
    let ar = [];
    for (let i = 0; i < symbols.length; i++) {
      const obj = await getTicker(symbols[i]);
      if (obj) ar.push(obj);
    }
    // console.log({ ar });
    setTickers(ar);
  };

  useEffect(() => {
    loadTickers();
  }, [symbols]);

  return (
    <>
      {/* <ul>
      {tickers.map((item, index) => (
        <li
          key={index}
          className={styles.tagsTickerItem}
          onClick={() => {
            history.push("/trading/" + item._id);
          }}
        >
          <span className={styles.tagsTickerItemContent}>
            {item.ticker}{" "}
            {item.quote && (
              <span
                className={
                  styles[item.quote.changePercent < 0 ? "minus" : "plus"]
                }
              >
                {item.quote.changePercent}%
              </span>
            )}
          </span>
        </li>
      ))}
              </ul>*/}
      <ul>
        {tickers.map((item, index) => {
          // console.log({ item });
          // const ticker = getTicker(item)
          // const percent = (
          //     ((ticker?.snapshot?.dailyBar.c - ticker?.snapshot?.prevDailyBar.c) /
          //         ticker?.snapshot?.prevDailyBar.c) *
          //     100
          // ).toFixed(2);
          //
          let percent = (0).toFixed(2);
          if (item?.snapshot?.dailyBar?.c && item?.snapshot?.prevDailyBar?.c) {
            percent = (
              ((item?.snapshot?.dailyBar?.c - item?.snapshot?.prevDailyBar?.c) /
                item?.snapshot?.prevDailyBar?.c) *
              100
            ).toFixed(2);
          }
          return (
            <li
              key={index}
              className={styles.tagsTickerItem}
              onClick={() => {
                if (!interactive) return;
                if (closeModal) closeModal();

                navigate("/trading/" + item._id, { state: location });
                // history.push("/trading/" + 1);
              }}
            >
              <span className={styles.tagsTickerItemContent}>
                {item?.symbol}
                <span
                  className={percent < 0 ? "minus" : percent > 0 ? "plus" : ""}
                >
                  {percent < 0 && "-"}
                  {Math.abs(percent)}%{" "}
                </span>
              </span>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default NewsTickerList;
