import {observer} from "mobx-react-lite";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {createChart, ColorType} from 'lightweight-charts';
import moment from "moment";
import {Clickable} from "../../UI";
import Icon from "../Icon";
import {$class} from "../../Utils";
import './index.scss';
import TradingStore from "../Trading/TradingStore";
import AppStore from "../App/AppStore";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import websocket from "../../websocket";

const colors = {
  lineColor: '#2962FF',
  areaTopColor: '#2962FF',
  areaBottomColor: 'rgba(41, 98, 255, 0.28)',
  text: '#3d3d3e',
  grid: '#ebedf3',
  up: 'rgb(58, 188, 125)',
  down: 'rgb(241, 106, 106)',
}

const seriesOptions = {
  upColor: colors.up,
  downColor: colors.down,
  wickUpColor: colors.up,
  wickDownColor: colors.down,
  borderVisible: false,
  topLineColor: 'rgba( 122, 36, 234, 0.5)',
  topFillColor1: 'rgba( 122, 36, 234, 0.1)',
  topFillColor2: 'rgba( 122, 36, 234, 0)',
};

/**
 * Приведение данных в нужный формат
 * @param type
 * @param data
 * @returns {*|*[]}
 */
const formatSeriesData = (type, data) => {
  if (!data.length) return [];
  if (type === 'line') return data.map(x => ({ time: new Date(x.t).getTime(), value: +x.c }))
  if (type === 'candle') return data.map(x => ({
    time: new Date(x.t).getTime(),
    open: +x.o,
    high: +x.h,
    low: +x.l,
    close: +x.c,
    value: +x.c,
  }))
}

/**
 * Очищаем от дублей по значению определенного люча
 * @param array
 * @param propertyName
 * @returns {*}
 */
function filterByProperty(array, propertyName) {
  const occurrences = {}
  return array.filter(function(x) {
    const property = x[propertyName]
    if (occurrences[property]) return false;
    occurrences[property] = true;
    return true;
  })
}

const GeekoChart = ({tickerId, tickerData}) => {
  const chartContainerRef = useRef(null);
  const series = useRef();
  const chart = useRef();
  const chartTime = useRef();
  const firstBarTime = useRef();
  const load = useRef(false);
  const loadNew = useRef(false);
  const [loading, setLoading] = useState(false);
  const timeframe= useRef('1H');
  const [chartType, setChartType] = useState('candle');
  const data = useRef([]);
  const chartPage = useRef(1);
  const tickerId_ = useRef(tickerId)

  const setData = useCallback((d = null) => {
    if (d) data.current = d;
    if (!chart.current) {
      setTimeout(() => {
        console.log('setData setTimeout')
        setData(d)
      }, 500)
      return;
    }
    if (series.current) chart.current?.removeSeries(series.current);

    if (chartType === 'line')
      series.current = chart.current?.addBaselineSeries(seriesOptions);
    else
      series.current = chart.current?.addCandlestickSeries(seriesOptions);

    console.log('data', data.current)
    series.current?.setData(formatSeriesData(chartType, data.current));
  }, [chartType])

  const timeframeUpdate = (v) => {
    series.current?.setData([]);
    setLoading(true);
    data.current = [];
    chartPage.current = 1;
    timeframe.current = v;
    chart.current.timeScale().scrollToRealTime()
    loadBars()
  }

  const loadBars = () => {
    if (!chartPage.current) return;
    clearTimeout(chartTime.current)
    chartTime.current = setTimeout( async function () {
      if (load.current) return;
      load.current = true;
      try {
        const barsData = await TradingStore.getBars({
          tickerId: tickerId_.current,
          timeframe: timeframe.current,
          interval: '1Year',
          chartPage: chartPage.current
        });

        if (barsData.bars.length === 0) {
          load.current = false;
          setLoading(false);
          clearTimeout(chartTime.current)
          return;
        }

        chartPage.current = barsData.bars.length === 0 ? 0 : barsData.chartPage
        let d = [...barsData.bars, ...data.current]
        d = filterByProperty(d, 't')
        d = d.sort((a, b) => a.t < b.t ? -1 : 1)
        firstBarTime.current = new Date(d[0].t).getTime();
        setData(d);

        load.current = false;
        setLoading(false);
        clearTimeout(chartTime.current)
      } catch (e) {
        load.current = false;
        setLoading(false);
        clearTimeout(chartTime.current)
      }
    }, 500)
  }

  const loadBarsNew = async () => {
    console.log('loadBarsNew')
    if (loadNew.current) return;
    loadNew.current = true;

    try {
      const barsData = await TradingStore.getBarsNew({
        tickerId: tickerId_.current,
        timeframe: timeframe.current,
        startDate: data.current[data.current.length-1].t
      });

      if (barsData.bars.length === 0) {
        loadNew.current = false;
        return;
      }

      let d = [...data.current, ...barsData.bars]
      d = filterByProperty(d, 't')
      d = d.sort((a, b) => a.t < b.t ? -1 : 1)
      setData(d);

      loadNew.current = false;
      clearTimeout(chartTime.current)
    } catch (e) {
      loadNew.current = false;
    }
  }

  const subscribeVisibleTimeRangeChange = (timeRange) => {
    if (!firstBarTime.current || !timeRange) return;
    if (timeRange.from <= firstBarTime.current) {
      setLoading(true);
      loadBars()
    }
  }

  useEffect(() => {

    const handleResize = () => {
      chart.current.applyOptions({ width: chartContainerRef.current.clientWidth });
    };
    chart.current?.remove();
    chart.current = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: 'transparent' },
        textColor: colors.text,
        fontFamily: 'GolosText, sans-serif',
        fontSize: 10,
        borderColor: '#ff0000',
      },
      width: chartContainerRef.current.clientWidth,
      height: 300,
      handleScroll: {
        vertTouchDrag: false
      },
      grid: {
        horzLines: {
          color: colors.grid,
        },
        vertLines: {
          color: colors.grid,
        },
      },
      localization: {
        timeFormatter: (timestamp) => moment(timestamp).locale(AppStore.lang).format("DD MMM 'YY   HH:mm"),
      },
      timeScale: {
        tickMarkFormatter: (time, tickMarkType) => {
          if (tickMarkType === 1) return moment(time).format('HH:mm');
          if (moment(time).format('MM') === '01' && moment(time).format('DD') === '01')
            return moment(time).format('YYYY');
          if (moment(time).format('MM') !== '01' && moment(time).format('DD') === '01')
            return moment(time).locale(AppStore.lang).format('MMM');
          return moment(time).format('DD');
        },
      },
    });

    chart.current.timeScale().applyOptions({
      borderColor: colors.grid,
      barSpacing: 20,
    });
    chart.current.priceScale('right').applyOptions({
      borderVisible: false,
    });

    // Подгрузка данных с сервера при промотке графика к началу
    chart.current.timeScale().subscribeVisibleTimeRangeChange(subscribeVisibleTimeRangeChange);

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.current?.remove();
    }
  }, []);

  useEffect(() => {
    setData()
  }, [chartType, setData]);

  useEffect(() => {
    tickerId_.current = tickerId;
    timeframeUpdate('1H')
  }, [tickerId]);

  useEffect(() => {
    if (tickerData === null || tickerData === undefined) return;

    let eventId = null;

    if (tickerData && tickerData.symbol) {
      eventId = websocket.subscribe(
        { event: "updateBars", key: tickerData.symbol },
        () => {
          loadBarsNew().catch()
        }
      );
    }

    return () => {
      websocket.unSubscribe(eventId);
    };
  }, [tickerId, tickerData?.symbol]);

  return (
    <div className={$class('geeko-chart', 'aom-fadeOut')}>
      <div ref={chartContainerRef} style={{ width: '100%', height: '300px' }}/>
      <div className="geeko-chart-tools">
        <div>
          <Clickable active={timeframe.current === '15Min'} onClick={() => timeframeUpdate('15Min')}>
            15Min
          </Clickable>
          <Clickable active={timeframe.current === '1H'} onClick={() => timeframeUpdate('1H')}>
            1H
          </Clickable>
          <Clickable active={timeframe.current === '3H'} onClick={() => timeframeUpdate('3H')}>
            3H
          </Clickable>
          <Clickable active={timeframe.current === '1D'} onClick={() => timeframeUpdate('1D')}>
            1D
          </Clickable>
          <Clickable active={timeframe.current === '7D'} onClick={() => timeframeUpdate('7D')}>
            7D
          </Clickable>
        </div>
        <div>
          <Clickable active={chartType === 'line'} onClick={() => setChartType('line')}>
            <Icon slug="linear" />
          </Clickable>
          <Clickable active={chartType === 'candle'} onClick={() => setChartType('candle')}>
            <Icon slug="candle" />
          </Clickable>
        </div>
      </div>
      {loading &&
          <Spin
              className="geeko-chart-loader"
              indicator={<LoadingOutlined style={{ fontSize: 30, color: 'var(--basic-dark)' }} spin />}
          />
      }
    </div>
  );
};

export default observer(GeekoChart);