import { configure, makeAutoObservable } from 'mobx';
import { apiRequest } from '../../Utils';
import { message } from 'antd';
import PortfolioStore from '../Portfolio/PortfolioStore';
import AlertStore from '../Alert/AlertStore';
import dayjs from 'dayjs';

configure({ enforceActions: 'never' });

class AccountStore {
  demoBalance = null;
  cash = null;
  cost_basis = null;
  demoNumber = 0;
  brokerNumber = 0;
  brokerBalance = 0;
  accounts = {
    brokerage: {
      _id: false,
      price: null,
      number: null,
    },
    trading: {
      _id: false,
      price: null,
      number: null,
    },
  };
  tradingAccountHistory = null;
  brokerageAccountHistory = null;
  accountHistoryLoading = false;
  accountHistoryDone = false;
  accountHistoryPDFLoading = false;

  transferFrom = false;
  refreshHistory = false;
  tradingHistoryItemsLoadCheck = true;
  brokerageHistoryItemsLoadCheck = true;
  tradingHistoryItemsFetchCheck = false;
  brokerageHistoryItemsFetchCheck = false;

  constructor() {
    makeAutoObservable(this);
  }

  getTrading() {
    return apiRequest({
      url: '/private/v1/account-trading',
      success: (data) => {
        if (data?.error) {
          // if(data?.status == 'not_registered'){
          //   return
          // }
          return AlertStore.error(data?.error.msg);
        }
        // console.log({ data });
        if (data?.account_number) {
          // setDemoNumber(data?.account_number);
          this.demoNumber = data?.account_number;
        }

        if (!data || !data.cash || !data.long_market_value || !data.short_market_value) {
        } else {
          const cash = data.cash;
          this.cash = cash;
          const long_market_value = data.long_market_value;
          this.cost_basis = long_market_value;
          const short_market_value = data.short_market_value;

          // setCash(parseFloat((cash + long_market_value + short_market_value).toString()).toFixed(2));
          // setDemoBalance(
          //     Number(cash) +
          //     Number(long_market_value) +
          //     Number(short_market_value)
          // );

          this.demoBalance = Number(cash) + Number(long_market_value) + Number(short_market_value);

          // this.accounts.trading.price = this.demoBalance
        }
      },
    }).catch((error) => {
      console.error({ error });
    });
  }
  getAccounts() {
    return new Promise((resolve) => {
      return apiRequest({
        url: '/private/v1/get-accounts',
        success: async (data) => {
          if (data?.error) {
            // if(data?.status == 'not_registered'){
            //   return
            // }
            return AlertStore.error(data?.error.msg);
          }
          console.log({ data });

          // костыль для sign-off
          if (!this.demoBalance || this.demoBalance === 0) {
            await this.getTrading();
          }

          //не костыль
          this.accounts = data;

          // костыль для sign-off
          this.accounts.trading.price = this.demoBalance;

          return resolve();
        },
      }).catch((error) => {
        console.error({ error });
        return resolve();
      });
    });
  }
  getAccountHistoryOld(params, cb) {
    // console.log(params)

    console.log('account-history [Query]', params.accountId);
    if (!params.accountId) {
      AccountStore.tradingHistoryItemsFetchCheck = false;
      return;
    }

    apiRequest({
      url: params.pdf ? '/private/v1/account-history-pdf' : '/private/v1/account-history',
      body: params,
      success: (data) => {
        if (data?.error) {
          // if(data?.status == 'not_registered'){
          //   return
          // }
          this.tradingHistoryItemsFetchCheck = false;
          return AlertStore.error(data?.error.msg);
        }
        // console.log('aaaaaaaaaaaaaaaa')
        // console.log({ data });

        this.tradingHistoryItemsFetchCheck = false;
        this.brokerageHistoryItemsFetchCheck = false;

        if (!params.pdf) {
          if (params.clear) {
            if (params.typeAccount === 'trading') {
              this.tradingAccountHistory = data;
              this.tradingHistoryItemsLoadCheck = data.length > 0;
            }
            if (params.typeAccount === 'brokerage') {
              this.brokerageAccountHistory = data;
              this.brokerageHistoryItemsLoadCheck = data.length > 0;
            }
          } else {
            if (params.typeAccount === 'trading') {
              const set = new Set([...(this.tradingAccountHistory || []), ...data]);
              this.tradingAccountHistory = Array.from(set);
              this.tradingHistoryItemsLoadCheck = data.length > 0;
            }
            if (params.typeAccount === 'brokerage') {
              console.log('Получил записей: ' + data.length);

              const set = new Set([...this.brokerageAccountHistory, ...data]);
              this.brokerageAccountHistory = Array.from(set);
              this.brokerageHistoryItemsLoadCheck = data.length > 0;
            }
          }
        }

        if (cb) {
          cb(data);
        }
      },
    }).catch((error) => {
      this.tradingHistoryItemsFetchCheck = false;
      this.brokerageHistoryItemsFetchCheck = false;
      console.error({ error });
    });
  }

  getAccountHistory(params) {
    const { filter, typeAccount, accountId, offset, clear } = params;
    const { period, sum, types } = filter || {};

    const undArr = [undefined, undefined];

    const [dateStart, dateEnd] = period ? period.map((x) => x ? dayjs(x).format('YYYY-MM-DD') : undefined) : undArr;
    const [amountStart, amountEnd] = sum || undArr;

    this.accountHistoryLoading = true;
    apiRequest({
      url: '/private/v1/account-history',
      body: {
        typeAccount,
        accountId,
        dateStart,
        dateEnd,
        amountStart,
        amountEnd,
        types: types,
        skip: offset,
        clear: clear || false,
      },
      success: (data) => {
        if (data?.error) {
          return AlertStore.error(data?.error.msg);
        }

        this.accountHistoryHasMore = true;
        if ((data.length < 10 || data.length === 0)) {
          this.accountHistoryHasMore = false;
        }

        let res = typeAccount === 'brokerage' ? this.brokerageAccountHistory : this.tradingAccountHistory;

        if (clear) {
          res = data;
        } else {
          res = [...(res || []), ...data];
        }
        
        console.log('items', {clear}, res);

        if (typeAccount === 'brokerage') {
          this.brokerageAccountHistory = res;
        }

        if (typeAccount === 'trading') {
          this.tradingAccountHistory = res;
        }
      },
    })
      .catch((error) => {
        console.error({ error });
      })
      .finally(() => {
        this.accountHistoryLoading = false;
      });
  }

  getAccountHistoryPDF(params, success) {
    const { period, accountId, typeAccount } = params;

    const [dateStart, dateEnd] = period.map((x) => x ? dayjs(x).format('YYYY-MM-DD') : undefined);

    this.accountHistoryPDFLoading = true;
    apiRequest({
      url: '/private/v1/account-history-pdf',
      body: {
        typeAccount,
        accountId,
        dateStart,
        dateEnd,
      },
      success: (data) => {
        if (data?.error) {
          return AlertStore.error(data?.error.msg);
        }

        if (success) success(data);
      },
    })
    .catch((error) => {
      console.error({ error });
    })
    .finally(() => {
      this.accountHistoryPDFLoading = false;
    });
  }
}

export default new AccountStore();
