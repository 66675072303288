import React, { useState, useEffect } from "react";
import ModalCustom from "../Modal";
import { Button, Form, Radio, Space, Modal, message } from "antd";
import Logo from "../Logo";
import TitleCategory from "../TitleCategory";
import icon1 from "../../assets/images/newspaper.svg";
import Header from "../Header";
import icon2 from "../../assets/images/Sanitysvg.svg";
import "./Wallet.scss";

import close1 from "../../assets/images/close1.svg";
import left_icon from "../../assets/images/left_arr.svg";
import { apiQuery, apiQueryError } from "../../Utils";
import userStore from "../Auth/userStore";
import AlertStore from "../Alert/AlertStore";

const categoryConfig = [
  {
    title: "Перевод в Geeko.tech",
    image: `${icon1}`,
  },
];

const WalletTransfer = () => {
  const [visible, setVisible] = React.useState(true);
  const [paySuccessVisible, setPaySuccessVisible] = React.useState(false);
  const [payErrorVisible, setPayErrorVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState(
    "Приложение создано для обучения торговле ценными бумагами. Депозит невозможно пополнить реальными деньгами и невозможно вывести средства. Вы согласны продолжить?"
  );
  const [modalPaySuccessText, setModalPaySuccessText] = React.useState("");
  const [modalPayErrorText, setModalPayErrorText] = React.useState("");
  const [backUrl, setBackUrl] = React.useState("https://v2.geeko.tech/wallet");
  const [modalPdfOpen, setModalPdfOpen] = React.useState(false);
  useEffect(() => {
    if (modalPaySuccessText) {
      setPaySuccessVisible(true);
    }
  }, [modalPaySuccessText]);

  useEffect(() => {
    if (modalPayErrorText) {
      setPayErrorVisible(true);
    }
  }, [modalPayErrorText]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const [fixAmount, setFixAmount] = useState(0);

  const getPaymentUrl = () => {
    const url = apiQuery("/private/payment-url?fix_amount=" + fixAmount, {
      method: "GET",
    })
      .then((data) => {
        console.log({ data });
        if (data.status !== "ok") {
          return false;
        }
        if (!data.url) {
          return false;
        }
        return data.url;
      })
      .catch((error) => {
        apiQueryError({ error });
        return false;
      });
    return url;
  };

  const sendPay = () => {
    const data = apiQuery("/private/demo-pay?fix_amount=" + fixAmount, {
      method: "GET",
    })
      .then((data) => {
        console.log({ data });
        // if(data.status !== 'ok'){
        //     return false
        // }
        // if(!data.url){
        //     return false
        // }
        return data;
      })
      .catch((error) => {
        //apiQueryError({error});
        AlertStore.error("Ошибка! Повторите попытку позже.");
        return false;
      });
    return data;
  };

  const requisiteSendToEmail = () => {
    apiQuery("/private/requisiteSendToEmail", {
      method: "GET",
    })
      .then((data) => {
        AlertStore.success("Реквизиты отправлены на ваш email.");
      })
      .catch((error) => {
        //apiQueryError({error});
        AlertStore.error("Ошибка! Повторите попытку позже.");
        return false;
      });
  };

  const pay = async () => {
    console.log("pay: ", fixAmount);
    setModalPaySuccessText("");
    setPayErrorVisible("");
    if (fixAmount > 0) {
      const data = await sendPay();
      console.log({ data });
      if (data?.status !== "ok") {
        // window.open(paymentUrl)
        // window.location = paymentUrl
        // alert(data?.msg)
        setModalPayErrorText(data?.msg);
        return;
      }
      setModalPaySuccessText(
        "Ваш баланс пополнен. В ближайшее время денежные средства появятся в Вашем кошельке. "
      );
      // alert('Успешно пополнено')
      // window.location = '/wallet/transfer/confirm'
      if (data?.url) {
        setBackUrl(data?.url);
      }
    } else {
      console.log("amount <= 0");
      // alert('Сумма пополнения должна быть больше 0')
      setModalPayErrorText("Сумма пополнения должна быть больше 0");
    }
  };

  const handleChange = (event) => {
    let value = Number(event.target.value);
    if (Number.isNaN(value)) value = 0;
    setFixAmount(value);
  };

  return (
    <>
      <Header button1={<img src={left_icon} alt={""} />} />
      <Logo />

      <div className="wallet_transfer mb-24">
        <TitleCategory
          title={categoryConfig[0].title}
          image={categoryConfig[0].image}
        />
        <div className="add_balance">
          <Form layout="vertical" onFinish="">
            <Form.Item name="s" initialValue="">
              <p>На какую сумму хотите пополнить? </p>
            </Form.Item>
            <Form.Item name="sum" initialValue="">
              {/*<Input addonBefore="$" value={amount} onChange={handleChange} pattern="[0-9]*"/>*/}
              <Radio.Group
                name="radiogroup"
                defaultValue={1}
                onChange={(e) => {
                  console.log({ e });
                  setFixAmount(e?.target?.value);
                }}
              >
                <Space direction="vertical">
                  <Radio value={1}>1 000 USD</Radio>
                  <Radio value={2}>5 000 USD</Radio>
                  <Radio value={3}>10 000 USD</Radio>
                  <Radio onClick={() => setModalPdfOpen(true)} value={4}>
                    Пополнение через банк
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Form>
        </div>
        <Button className="yellow_btn" onClick={pay}>
          Пополнить
        </Button>
      </div>

      <Modal
        title="Уведомление"
        visible={visible}
        onOk={handleOk}
        okText={"Я согласен, продолжить"}
        confirmLoading={confirmLoading}
        footer={false}
        closable={false}
      >
        <p>{modalText}</p>

        <Button className="yellow_btn" onClick={handleOk}>
          Я согласен, продолжить
        </Button>
      </Modal>

      <Modal
        title="Уведомление"
        visible={paySuccessVisible}
        onOk={() => {
          setPaySuccessVisible(false);
          setModalPaySuccessText("");
          window.location = backUrl;
        }}
        okText={"OK"}
        footer={false}
        closable={false}
      >
        <p>{modalPaySuccessText}</p>

        <Button
          className="yellow_btn"
          onClick={() => {
            setPaySuccessVisible(false);
            setModalPaySuccessText("");
            window.location = backUrl;
          }}
        >
          OK
        </Button>
      </Modal>

      <Modal
        title="Уведомление"
        visible={payErrorVisible}
        onOk={() => {
          setPayErrorVisible(false);
          setModalPayErrorText("");
          window.location = backUrl;
        }}
        okText={"OK"}
        footer={false}
        closable={false}
      >
        <p>{modalPayErrorText}</p>

        <Button
          className="yellow_btn"
          onClick={() => {
            setPayErrorVisible(false);
            setModalPayErrorText("");
            window.location = backUrl;
          }}
        >
          OK
        </Button>
      </Modal>
      <ModalCustom
        visible={modalPdfOpen}
        cancelText={
          <div className="width_collect">
            <p className="pl-8">Пополнение счета</p>
          </div>
        }
        okVisible={true}
        okText={<img src={close1} alt="" />}
        onOk={() => setModalPdfOpen(false)}
      >
        <div className="p-16">
          <p className="text-center title_instr">
            Instructions for account funding
          </p>
          <p>1. Download and print a PDF file with requisites</p>
          <p>2. Provide the file with requisites at the bank</p>
          <p>3. Choose the amount for account funding </p>
          <p>
            4. Be sure to include the account ID in the comments for the money
            transfer
          </p>

          <div className="pdf_file">
            <table>
              <tr>
                <td>
                  <img src={icon2} />
                </td>
                <td>
                  <span>Sanity Capital Ltd</span>
                  <span>BIN: 210840900168</span>
                  <span>Z05T3F5</span>
                  <span>Kazakhstan, Nur-Sultan Mangilik El</span>
                  <span>Mangilik El 55/22, 1 floor, 140 office</span>
                </td>
              </tr>

              <tr>
                <td colspan="2">
                  <p className="title_pdf">Requisites</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="text_pdf">Bank</p>
                </td>
                <td>
                  <b className="text_pdf">JSC «Bank Centrecredit»</b>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="text_pdf">BIC:</p>
                </td>
                <td>
                  <b className="text_pdf">KCJBKZKX</b>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="text_pdf">Account number:</p>
                </td>
                <td>
                  <b className="text_pdf">KZ088562203213134730</b>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="text_pdf">Account ID:</p>
                </td>
                <td>
                  <b className="text_pdf">
                    {userStore.userData.id}
                    {/*GG138562204*/}
                  </b>
                </td>
              </tr>
            </table>
          </div>
          <Button
            className="gr_btn btn_pdf"
            onClick={() => requisiteSendToEmail()}
          >
            Send to email
          </Button>
        </div>
      </ModalCustom>
    </>
  );
};

export default WalletTransfer;
