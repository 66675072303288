import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button } from '../../UI';
import AlertStore from '../Alert/AlertStore';
import { PeriodBlock } from '../Filter/Filter.modal';
import Lang from '../Lang';
import AccountStore from './AccountStore';
import styles from './Accounts.module.scss';
import Utils from '../../Utils';

const AccountStatementModal = ({ accountType, accountId }) => {
  const [filter, setFilter] = useState({ period: [null, null] });

  const loading = AccountStore.accountHistoryPDFLoading;

  const getPDFHandler = () => {
    const { period } = filter;

    if (!!period[0] ^ !!period[1] || period[1] <= period[0]) {
      AlertStore.error(Lang({ str: 'correct_period', section: 'wallet' }));
      return;
    }

    AccountStore.getAccountHistoryPDF({ accountType, accountId, period: filter.period }, ({url}) => {
      handleDownload(`https://${Utils.apiDomain()}/rest_api/private/v1/account-history-pdf/${url}`);
    });
  };

  return (
    <div className={styles.acc_statement}>
      <h2 className={styles.acc_statement_title}>
        {Lang({ str: 'get_statement', section: 'accounts' })}
      </h2>
      <PeriodBlock filterInputs={filter} onChange={setFilter} style={{ marginBottom: 120 }} />

      <Button loading={loading} onClick={getPDFHandler}>
        {Lang({ str: 'download_pdf', section: 'default' })}
      </Button>
    </div>
  );
};

async function handleDownload(url) {
  const download = () => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
  };
  if (navigator.share) {
    try {
      const res = await navigator.share({ url });
      console.log({ res });
    } catch (e) {
      console.warn(e);
      download();
    }
  } else download();
}

export default observer(AccountStatementModal);
