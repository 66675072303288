import moment from 'moment';
import 'moment/locale/ru';
import React, { useEffect, useState } from 'react';
import ImagePreview from '../ImagePreview';
import Modal from '../Modal';
import NewsTickerList from '../News/NewsTickerList';
import styles from './NewsItem.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import NewsStore from './NewsStore';
import AppStore from "../App/AppStore";
import { $class } from '../../Utils';

const NewsItem = ({
  id,
  headline,
  symbols = [],
  datetime,
  summary,
  content,
  translate = {},
  author,
  img_small,
  img_large,
  className,
  openTickers,
  link = 'news'
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenFon, setModalOpenFon] = useState(false);

  const handleClick = (event) => {
    // setModalOpen(true);
    // setModalOpenFon(true);

    NewsStore.open({ id, headline, symbols, datetime, summary, translate, content, author, img_small, img_large })
    navigate(`/${link}/${id}${openTickers ? '?interactive=1' : ''}`, { state: location });
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalOpenFon(false);
  };

  useEffect(() => {
    if (!content) return;

    // document.querySelectorAll('.news-item-contentFTT').forEach((el) => {});
  }, [content]);

  moment.locale(AppStore.lang);

  return (
    <>
      <div id={`newsItem-${id}`} className={$class(styles.item_block, className)} onClick={handleClick}>
        <div className={styles.item_block__text}>
          <div className={styles.item_block__datet}>
            <p>{moment(datetime).format('DD MMMM YYYY, HH:mm')}</p>
          </div>
        </div>
        <div className={styles.item_block__bl}>
          <div className={styles.item_block__img}>
            {img_small && (
              <ImagePreview
                src={img_small}
                height={40}
                width={60}
                style={{ border: 0, borderRadius: 8 }}
              />
            )}
          </div>
          <p>
            {translate.headline ? (
                translate.headline?.[AppStore.lang] ? translate.headline[AppStore.lang] : translate.headline.ru
            ) : headline}
          </p>
        </div>
        <div className={styles.item_block__tickers}>
          <NewsTickerList symbols={symbols} />
        </div>
      </div>


      <Modal
        visible={modalOpen}
        okVisible={false}
        headVisible={false}
        cancelVisible={false}
        onCancel={() => {
          setModalOpen(false);
          setModalOpenFon(false);
        }}
        className2={styles.auto_height}
        className={styles.auto_height2}
        newheight="auto"
        newheightcontent="auto"
      >
        <div className="p-16 pb-0" style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            {img_large && (
              <ImagePreview
                src={img_large}
                height={250}
                width={'100%'}
                style={{ border: 0, borderRadius: 8, marginBottom: 8 }}
              />
            )}
          </div>
          <div className={styles.item_block__datet}>
            <p>{moment(datetime).format('DD MMMM YYYY, HH:mm')}</p>
          </div>
          <p className={styles.tit_categ_big + ' mb-8'}>{headline}</p>

          <div className={styles.item_block__tickers}>
            <NewsTickerList symbols={symbols} interactive closeModal={closeModal} />
          </div>
          {!!content && (
            <div
              className={styles.text_main + ' news-item-contentFTT'}
              dangerouslySetInnerHTML={{ __html: contentFormat(content) }}></div>
          )}
        </div>
      </Modal>
    </>
  );
};

const contentFormat = (data) => {
  // data = data.replaceAll(/<p><em>See More:.*<\/p>/gm, '<t>[REPLACED See More]</t>')
  // data = data.replaceAll(/<p><em>See Also:.*<\/p>/gm, '<t>[REPLACED See Also]</t>')
  // data = data.replaceAll(/<p><em>Read Next:.*<\/p>/gm, '<t>[REPLACED Read Next]</t>')
  // data = data.replaceAll(/<p>Read This Next:.*<\/p>/gm, '<t>[REPLACED Read This Next]</t>')
  // data = data.replaceAll(/<p><em>Now Read This:.*<\/p>/gm, '<t>[REPLACED Read This Next]</t>')

  // data = data.replaceAll(/<p>Also Check This Out:.*<\/p>/gm, '<t>[REPLACED Also Check This Out]</t>')
  // data = data.replaceAll(/<p><em>Check out more.*<\/em><\/p>/gm, '<t>[REPLACED Check out more]</t>')

  data = data.replaceAll(/<p><strong><em>Disclaimer.*<\/em><\/p>/gm, '');
  data = data.replaceAll(/<!--Actual content-->.*<!--Actual content end-->/gms, '');

  return data;
};

export default NewsItem;
