import React, { useEffect } from "react";
import styles from "../BrokerAccountNew/BrokerAccountNew.module.scss";
import { observer } from "mobx-react-lite";
import Lang from "../Lang/index";
import BrokerAccountStore from "./BrokerAccountStore";
import {Button} from "../../UI";

const Page1 = ({setBack}) => {

  const onFinish = () => {
    const d = {
      view: "sumSub",
      step: 1,
    }
    BrokerAccountStore.save(d).catch()
  };

  useEffect(() => {
    setBack({
      view: "home",
      step: 1
    })
  }, [])

  return (
    <div className={styles.page_fl}>
      <div className={styles.text_bl}>
        <h4>{Lang({ str: "verification", section: "onboarding" })}</h4>
        <p className={styles.middl_text + " mb-16"}>
          {Lang({ str: "verification_2", section: "onboarding" })}
        </p>
        <ul>
          <li>{Lang({ str: "verification_step_1", section: "onboarding" })}</li>
          <li>{Lang({ str: "verification_step_2", section: "onboarding" })}</li>
          <li>{Lang({ str: "verification_step_3", section: "onboarding" })}</li>
        </ul>
      </div>
      <Button className={styles.start_but} onClick={onFinish}>
        {Lang({ str: "begin", section: "onboarding" })}
      </Button>
    </div>
  );
};

export default observer(Page1);
