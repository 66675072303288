import React from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { $class } from '../../Utils';
import { Clickable } from '../../UI';
import TickerTitle from '../TickerTitle';
import NumberFormat from '../../components/NumberFormat';
import WalletStore from '../../components/Wallet/WalletStore';
import './index.scss';
import DollarSign from '../DollarSign';
import Lang from "../../components/Lang";

const TickerOrderHistoryCard = ({ ticker, order, onClick, style }) => {
  let qty = 0
  let fullPrice = 0
  if(order?.order?.order_type === 'market'){
    if(order?.notional){
      fullPrice = order?.notional
      qty = order?.qty ? order?.qty : (fullPrice /*- order?.commission*/) / order?.price
    }
    else{
      qty = order?.qty
      // fullPrice = (order?.order?.filled_avg_price * order?.order?.filled_qty).toFixed(2);
      fullPrice = (order?.price * order?.qty).toFixed(2);
    }
  }
  else{
    if(order?.order?.order_type === 'limit') {
      if(order?.notional){
        fullPrice = order?.notional
        qty = order?.qty ? order?.qty : (fullPrice /*- order?.commission*/) / order?.price
      }
      else{
        qty = order?.qty
        // fullPrice = (order?.order?.filled_avg_price * order?.order?.filled_qty).toFixed(2);
        fullPrice = (order?.price * order?.qty).toFixed(2);
      }
    }
  }

  const removeLastZeros = (value) => {
    value = Number(value)
    return value
  }

  return (
    <div className={$class('ticker-card', 'ticker-order-card')} style={style}>
      <Clickable className="ticker-card-wrap" onClick={onClick}>
        <TickerTitle size={50} ticker={ticker} withSymbol withDesc>
          <div className={$class('ticker-paper-card-bottom')}>
            <b>
            {/*  {NumberFormat({*/}
            {/*  value: parseFloat(qty?.toString()).toFixed(9),*/}
            {/*}) || 0} */}
              {removeLastZeros(parseFloat(qty?.toString()).toFixed(9))}
              {Lang({ str: "pieces", section: "default" })}</b> <i>(
            <DollarSign color={'var(--basic-dark40)'} size={7} top={2} right={1} />
            {NumberFormat({ value: fullPrice })}
            )</i>
          </div>
        </TickerTitle>
        <div className="ticker-card-price">
          <p>{moment(order?.created_at).format('HH:mm DD.MM.YY')}</p>
          <p>{Lang({ str: order?.side === 'buy' ? 'purchase' : 'sale', section: "wallet" })}</p>
          <p>{Lang({ str: order?.order?.order_type === 'limit' ? 'limit' : 'market', section: "wallet" })}</p>
          <p className='ticker-card-status'>{WalletStore.formatOrderStatus(order?.order_status)}</p>
        </div>
      </Clickable>
    </div>
  );
};

export default observer(TickerOrderHistoryCard);
