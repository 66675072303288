import { useCallback, useMemo, useState } from 'react';
import { $class } from '../../Utils';
import AlertStore from '../Alert/AlertStore';
import Lang from '../Lang';
import modalStore from '../ModalManager/modalStore';
import FilterBtnComponent from './FilterBtn';
import FilterChip from './FilterChip';
import styles from './style.module.scss';

export const useFilter = ({ filterFields, onUpdateRows }) => {
  const defaultFilter = useMemo(() => getDefaultFilter(filterFields), [filterFields]);
  const [filter, setFilter] = useState(defaultFilter);

  const onResetHandler = () => {
    modalStore.closeModal();
    setFilter(defaultFilter);

    if (onUpdateRows) onUpdateRows(defaultFilter);
  };

  const onApplyHandler = (filterInputs) => {
    const { sum, period } = filterInputs;

    if (period.every((x) => x !== null) && (!!period[0] ^ !!period[1] || period[1] <= period[0])) {
      AlertStore.error(Lang({ str: 'correct_period', section: 'wallet' }));
      return;
    }

    if (sum.every((x) => x !== '') && +sum[0] >= +sum[1]) {
      AlertStore.error(Lang({ str: 'correct_amount', section: 'wallet' }));
      return;
    }

    modalStore.closeModal();
    setFilter(filterInputs);
    if (onUpdateRows) onUpdateRows(filterInputs);
  };

  const isFieldChanged = useCallback(
    (field) => {
      if (field.type === 'period') return filter[field.name].some((x) => x !== null);
      if (field.type === 'choice') return filter[field.name] !== defaultFilter[field.name];
      if (field.type === 'sum') return filter[field.name].some((x) => x !== '');
      if (field.type === 'checkbox') return filter[field.name].length > 0;
    },
    [filter, defaultFilter]
  );

  const isFilterDefault = useMemo(() => {
    for (let i = 0; i < filterFields.length; i++) {
      if (isFieldChanged(filterFields[i])) return false;
    }
    return true;
  }, [filterFields, isFieldChanged]);

  const openFilter = () => {
    modalStore.openModal('filter', {
      filter,
      filterFields,
      defaultFilter,
      onResetHandler,
      onApplyHandler,
    });
  };

  const onResetField = (field, val) => {
    const name = field.name;
    let newFilter = { ...filter, [name]: defaultFilter[name] };

    if (field.type === 'checkbox') newFilter[name] = filter[name].filter(x => x !== val)

    setFilter(newFilter);
    if (onUpdateRows) onUpdateRows(newFilter);
  };

  const FilterChipsWrap = () => {
    if (isFilterDefault) return null;

    const CheckboxChips = ({ field, value }) => {
      const labelMap = field.items.reduce((obj, x) => ({ ...obj, [x.value]: x.label }), {});
      return value.map((v) => <FilterChip key={v} value={labelMap[v]} onClick={() => onResetField(field, v)} />);
    };

    return (
      <div className={$class(styles.filterChipWrap, 'filter-chip-wrap')}>
        {filterFields.map((el, i) => {
          if (!isFieldChanged(el)) return null;

          if (el.type === 'checkbox')
            return <CheckboxChips key={i} field={el} value={filter[el.name]} />;

          return (
            <FilterChip
              key={i}
              type={el.type}
              value={filter[el.name]}
              items={el?.items}
              onClick={() => onResetField(el)}
            />
          );
        })}
      </div>
    );
  };

  const FilterBtn = () => {
    return <FilterBtnComponent active={!isFilterDefault} onClick={openFilter} />;
  };

  return { openFilter, filter, isFilterDefault, onResetHandler, FilterChipsWrap, FilterBtn };
};

const getDefaultFilter = (fields) => {
  const def = {};

  fields.forEach((el) => {
    if (el.type === 'period') def[el.name] = [null, null];
    if (el.type === 'sum') def[el.name] = ['', ''];
    if (el.type === 'choice') def[el.name] = el.items[0].value;
    if (el.type === 'checkbox') def[el.name] = [];
  });

  return def;
};

function arraysE(a, b) {
  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}
