import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { shimmer } from '../../Utils';
import { BackButton } from '../../blocks';
import AppStore from '../App/AppStore';
import ImagePreview from '../ImagePreview';
import Lang from '../Lang';
import styles from './NewsItem.module.scss';
import NewsStore from './NewsStore';
import NewsTickerList from './NewsTickerList';
import stylesP from './index.module.scss';

const imageHeight = 250;

const News = ({ closeOverlayPage }) => {
  const [params] = useSearchParams();
  const { newsId } = useParams();

  const newsData = NewsStore.activeNewsData;

  useEffect(() => {
    if (newsData === null) {
      NewsStore.loadNewsByID(newsId);
    }
  }, []);

  return (
    <>
      <BackButton
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          width: '100%',
          background: 'var(--secondary-light)',
        }}
      />
      <div className={stylesP.fixedImg}>
        {newsData?.img_large && (
          <ImagePreview
            src={newsData?.img_large}
            height={imageHeight}
            width={'100%'}
            style={{ border: 0, borderRadius: 8, marginBottom: 8 }}
          />
        )}
      </div>
      <div className={stylesP.newsWrap}>
        {newsData !== null && (
          <div>
            <div className={styles.item_block__datet}>
              <p>{moment(newsData?.datetime).format('DD MMMM YYYY, HH:mm')}</p>
            </div>
            <p className={styles.tit_categ_big + ' mb-8'}>
              {newsData?.translate?.headline
                ? newsData.translate.headline[AppStore.lang]
                  ? newsData.translate.headline[AppStore.lang]
                  : newsData.translate.headline.ru
                : newsData?.headline}
            </p>
            <div className={styles.item_block__tickers}>
              <NewsTickerList
                symbols={newsData?.symbols}
                interactive={!!params.get('interactive')}
              />
            </div>
          </div>
        )}
        {newsData === null && (
          <div style={{ height: 100 }} className={shimmer('block', true)}></div>
        )}

        {!!newsData?.content && (
          <div
            className={styles.text_main + ' news-item-contentFTT'}
            style={{ paddingBottom: 15 }}
            dangerouslySetInnerHTML={{
              __html: contentFormat(
                newsData?.translate?.content
                  ? newsData.translate.content[AppStore.lang]
                    ? newsData.translate.content[AppStore.lang]
                    : newsData.translate.content.ru
                  : newsData.content
              ),
            }}></div>
        )}

        {newsData === null && (
          <div className={styles.text_main} style={{ marginTop: 24 }}>
            <MockContent />
          </div>
        )}

        {newsData !== null && (
          <>
            <p className={styles.news_source}>
              {Lang({ str: 'source', section: 'default' })}: {newsData?.source || newsData?.author}
            </p>
            <p className={styles.news_disclaimer}>
              {Lang({ str: 'news_disclaimer', section: 'home' })}
            </p>
          </>
        )}
      </div>
    </>
  );
};

const contentFormat = (data) => {
  // data = data.replaceAll(/<p><em>See More:.*<\/p>/gm, '<t>[REPLACED See More]</t>')
  // data = data.replaceAll(/<p><em>See Also:.*<\/p>/gm, '<t>[REPLACED See Also]</t>')
  // data = data.replaceAll(/<p><em>Read Next:.*<\/p>/gm, '<t>[REPLACED Read Next]</t>')
  // data = data.replaceAll(/<p>Read This Next:.*<\/p>/gm, '<t>[REPLACED Read This Next]</t>')
  // data = data.replaceAll(/<p><em>Now Read This:.*<\/p>/gm, '<t>[REPLACED Read This Next]</t>')

  // data = data.replaceAll(/<p>Also Check This Out:.*<\/p>/gm, '<t>[REPLACED Also Check This Out]</t>')
  // data = data.replaceAll(/<p><em>Check out more.*<\/em><\/p>/gm, '<t>[REPLACED Check out more]</t>')

  data = data.replaceAll(/<p><strong><em>Disclaimer.*<\/em><\/p>/gm, '');
  data = data.replaceAll(/<!--Actual content-->.*<!--Actual content end-->/gms, '');

  return data;
};

export default observer(News);

const Mock = ({ children }) => (
  <span style={{ margin: '0 5px' }} className={shimmer('text', true)}>
    {Array(+children).fill('_').join('')}
  </span>
);

const MockContent = () => (
  <>
    <p>
      <Mock>14</Mock>
      <Mock>8</Mock>
      <Mock>10</Mock>
    </p>
    <p>
      <Mock>21</Mock>
      <Mock>13</Mock>
      <Mock>8</Mock>
    </p>
    <p>
      <Mock>6</Mock>
      <Mock>12</Mock>
      <Mock>9</Mock>
      <Mock>10</Mock>
    </p>
    <p>
      <Mock>21</Mock>
      <Mock>13</Mock>
      <Mock>8</Mock>
    </p>
    <p>
      <Mock>14</Mock>
      <Mock>8</Mock>
      <Mock>10</Mock>
    </p>
  </>
);
