import { configure, makeAutoObservable } from "mobx";
import { apiQuery, apiQueryError } from "../../Utils";

configure({ enforceActions: "never" });

class DrawerMenuStore {
  openRight = false;
  drawerStyle = {
    background: "#f5f6f7",
    boxShadow:
      "rgba(0, 0, 0, 0.188235) 0px 10px 20px, rgba(0, 0, 0, 0.227451) 0px 6px 6px",
    padding: "16px",
  };
  relativeWidth = false;
  width = 300;
  noTouchOpen = false;
  noTouchClose = false;
  inboxes = false;

  constructor() {
    makeAutoObservable(this);
  }

  setOpenRight(value) {
    this.openRight = value;
  }

  getCountNotReadInboxes() {
    apiQuery("/private/v1/getCountNotReadInboxes", {
      method: "GET",
      body: { filter: "all" },
    })
      .then((data) => {
        console.log(data);

        this.inboxes = data;
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  }
}

const clsDrawerMenuStore = new DrawerMenuStore();
export default clsDrawerMenuStore;
