import React, { useEffect, useState } from "react";
import styles from "./BrokerAccountNew.module.scss";
import { observer } from "mobx-react-lite";
import { Button, Spin } from "antd";
import left_icon from "../../assets/images/arrowmenu.svg";
import Header from "../Header";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step4 from "./Step4";
import Lang from "../Lang/index";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step3 from "./Step3";
import Step7 from "./Step7";
import Step8 from "./Step8";
import { useNavigate } from "react-router-dom";
import BrokerAccountStore from "./BrokerAccountStore";
import SumSub from "./SumSub";
import Step9 from "./Step9";
import Step10 from "./Step10";
import FormW from "./FormW";
import Dogovor from "./Dogovor";
import Sign from "./Sign";
import Sign2 from "./Sign2";

const BrokerAccountNew = () => {
  const traderData = BrokerAccountStore.traderData;
  const navigate = useNavigate();
  const [back, setBack] = useState({ view: "home", step: 1 });

  useEffect(() => {
    BrokerAccountStore.get();
  }, []);

  if (!traderData) return <Spin />;

  return (
    <div className="container">
      <div className={styles.brokerage_wrp}>
        <Header />

        {traderData.view === "brokerSuccess" || traderData.view === "brokerError" ||
        (traderData.view === "questionnaire" && traderData.step === 1) ? (
          ""
        ) : (
          <div className={styles.brokerage_flex}>
            <button
              className={styles.back_bl}
              onClick={() => {
                if (back.view === "home") navigate("/");
                else {
                  BrokerAccountStore.save(back);
                }
              }}
            >
              <img src={left_icon} />
              {Lang({ str: "back", section: "default" })}
            </button>
          </div>
        )}

        {traderData.view === "info" && <Page1 setBack={setBack} />}

        {traderData.view === "sumSub" && <SumSub setBack={setBack} />}

        {traderData.view === "address" && (
          <>
            {traderData.step === 1 && <Page2 setBack={setBack} />}
            {traderData.step === 2 && <Page3 setBack={setBack} />}
            {traderData.step === 3 && (
              <SumSub setBack={setBack} levelName={"geekoinvestAddress"} />
            )}
            {traderData.step === 4 && <Page4 setBack={setBack} />}
          </>
        )}

        {traderData.view === "questionnaire" && (
          <>
            {traderData.step === 1 && <Step1 setBack={setBack} />}
            {traderData.step === 2 && <Step2 setBack={setBack} />}
            {traderData.step === 3 && <Step3 setBack={setBack} />}
            {traderData.step === 4 && <Step4 setBack={setBack} />}
            {traderData.step === 5 && <Step5 setBack={setBack} />}
            {traderData.step === 6 && <Step6 setBack={setBack} />}
            {traderData.step === 7 && <Step7 setBack={setBack} />}
            {traderData.step === 8 && <Step8 setBack={setBack} />}
            {traderData.step === 9 && <FormW setBack={setBack} />}
            {traderData.step === 10 && <Dogovor setBack={setBack} />}
          </>
        )}

        {traderData.view === "sign" && <Sign2 setBack={setBack}/>}
        {traderData.view === "brokerSuccess" && <Step9 />}
        {traderData.view === "brokerError" && <Step10 />}
      </div>
    </div>
  );
};

export default observer(BrokerAccountNew);
