import { Button, Form, message } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useRef, useState } from "react";
import ReactCodeInput from "react-code-input";
import { apiQuery, apiQueryError } from "../../Utils";
import AlertStore from "../Alert/AlertStore";
import HeaderLang from "../Header/HeaderLang";
import Lang from "../Lang/index";
import styles from "./Auth.module.scss";
import userStore from "./userStore";

const propsReactCodeInput = {
  inputStyle: {
    margin: "6px 3% 6px 0",
    width: "40px",
    fontSize: "22px",
    height: 40,
    backgroundColor: "#EAEAEA",
    border: 0,
    borderRadius: "4px",
    textAlign: "center",
    color: "#252525",
  },
};

const Resend = () => {
  const [sec, setSec] = useState(0);
  const [sendCheck, setSendCheck] = useState(true);
  const timer = useRef();

  const funTimer = (s) => {
    clearInterval(timer.current);
    setSec(s);

    apiQuery("/mailVerificationCodeSend")
      .then(() => {
        AlertStore.success(Lang({ str: "email_sent", section: "auth" })).then();
      })
      .catch((error) => {
        apiQueryError({ error });
      });

    timer.current = setInterval(() => {
      if (s === 0) {
        clearInterval(timer.current);
        return;
      }
      if (s === 1) {
        setSendCheck(true);
      }
      s--;
      setSec(s);
    }, 1000);
  };

  useEffect(() => {
    funTimer(30);
    setSendCheck(false);
  }, []);

  if (!sendCheck)
    return (
      <p className={styles.little_text}>
        {Lang({ str: "resend_in", section: "auth" })}
        {" " + sec + " "}
        {Lang({ str: "seconds", section: "auth" })}
      </p>
    );

  return (
    <Button
      type="link"
      className={styles.little_text2}
      onClick={() => {
        funTimer(30);
        setSendCheck(false);
      }}
    >
      {Lang({ str: "resend", section: "auth" })}
    </Button>
  );
};

const VerifyEmail = () => {
  const [code, setCode] = useState("");

  const onFinish = () => {
    if (code === "") return;

    apiQuery("/mailVerification", {
      method: "POST",
      body: {
        code,
      },
    })
      .then(() => {
        AlertStore.success(Lang({ str: "email_confirmed", section: "auth" }))
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };
  const onChange = (v) => {
    console.log(v);
    setCode(v);
  };

  return (
    <div className={styles.verific_page + " flex_block"}>
      <HeaderLang button1={Lang({ str: "back", section: "default" })} src1 />
      <Content className={styles.siteLayout + " pr-0 pl-0"}>
        <div>
          <h2 className={styles.titleAuth}>
            {Lang({ str: "email_confirmation", section: "auth" })}
          </h2>
          <p className={styles.little_text}>
            {Lang({ str: "code_sent_email", section: "auth" })}{" "}
            {userStore.userData.email}
          </p>
        </div>

        <Form
          name="normal_login"
          layout="vertical"
          className={styles.loginForm}
          onFinish={onFinish}
        >
          <div
            style={{
              display: "block",
              margin: "0 auto 24px",
              width: "100%",
            }}
          >
            <ReactCodeInput
              type="number"
              fields={6}
              name="code"
              inputMode="numeric"  
              value={code}
              onChange={onChange}
              autoComplete={"one-time-code"}
              {...propsReactCodeInput}
            />
          </div>

          <Form.Item className={styles.buttonBlockFixed}>
            <Button
              style={{ height: "auto" }}
              type="primary"
              htmlType="submit"
              className={styles.enter_button}
              block
            >
              {Lang({ str: "send", section: "default" })}
            </Button>
          </Form.Item>

          <Resend />

          {/*  <p style={{ textAlign: "center" }}>
            <Link to="/restore" className={styles.link_b}>
              {Lang({ str: "changeyouremail", section: "auth" })}
            </Link>
          </p>*/}
          {/*<div style={{ textAlign: "center" }}>*/}
          {/*  <Link className={styles.link} to="/login">*/}
          {/*    {Lang({ str: "avtorization", section: "auth" })}*/}
          {/*  </Link>*/}
          {/*</div>*/}
        </Form>
      </Content>
    </div>
  );
};

export default VerifyEmail;
