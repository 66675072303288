import {useEffect, useRef, useState} from "react";
import AppStore from "../App/AppStore";

const ReCaptcha = ({
    sitekey = process.env.REACT_APP_RECAPTCHA_SITEKEY,
    callback = f => f,
    expiredCallback = f => f,
    style = {}
}) => {
    const [isLoaded, setLoaded] = useState(false)
    const recaptchaRef = useRef(null)

    const onRecaptchaLoad = () => {
        setLoaded(true)
    }

    useEffect(() => {
        window.onRecaptchaLoad = onRecaptchaLoad
        if(!window.grecaptcha) {
            const script = document.createElement('script')
            script.src = "https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit"
            script.async = true
            script.defer = true
            document.head.appendChild(script)
        } else if(window.grecaptcha && window.grecaptcha.render) {
            setLoaded(true)
        }

        return () => window.onRecaptchaLoad = null
    }, [])

    useEffect(() => {
        if(isLoaded) {
            window.grecaptcha.render(recaptchaRef.current, {
                'sitekey': sitekey,
                // 'size': 'compact',
                'callback': callback,
                'expired-callback': expiredCallback,
                'hl': AppStore.lang === 'en' ? 'en' : 'ru'
            })
        }
    }, [isLoaded])

    return (
       <div ref={recaptchaRef} style={style}></div>
    )
}

export default ReCaptcha