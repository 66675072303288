import React, { useCallback, useEffect, useState } from "react";
import styles from "./Header.module.scss";
import { observer } from "mobx-react-lite";
import { useNavigate, useLocation } from "react-router-dom";
import DrawerMenuStore from "../DrawerMenu/DrawerMenuStore";
import { apiQuery } from "../../Utils";
import urlStore from "../../stores/urlStore";
import userStore from "../Auth/userStore";
import AppStore from "../App/AppStore";
import { Alert } from "antd";

const HeaderTittle = (props) => {
  let history = useNavigate();
  let location = useLocation();
  let section = urlStore.section;

  const [cash, setCash] = useState(0);

  const getTrading = useCallback(() => {
    apiQuery("/private/v1/account-trading", {
      method: "GET",
    })
      .then((data) => {
        console.log("cash:", data);
        if (
          !data.data ||
          !data.data.cash ||
          !data.data.long_market_value ||
          !data.data.short_market_value
        ) {
        } else {
          const cash = data.data.cash;
          const long_market_value = data.data.long_market_value;
          const short_market_value = data.data.short_market_value;

          // setCash(parseFloat((cash + long_market_value + short_market_value).toString()).toFixed(2));
          setCash(
            Number(cash) +
              Number(long_market_value) +
              Number(short_market_value)
          );
        }
      })
      .catch(() => {
        // apiQueryError({error});
      });
  }, []);

  useEffect(() => {
    getTrading();
  }, [getTrading, section]);

  React.useEffect(() => {
    AppStore.setNotificvisible(false);
    if (
      ["/trading", "/wallet", "/", "/accounts"].includes(
        window.location.pathname
      )
    )
      AppStore.setNotificvisible(true);
  }, [location]);

  return (
    <>
      {userStore.auth && userStore?.userData?.profileData?.documentReset && (
        <Alert
          message="Необходимо обновить документы"
          type="warning"
          style={{ margin: "15px 0", textAlign: "center", cursor: "pointer" }}
          onClick={() => {
            history("/sumsub_doc_update");
          }}
        />
      )}
      <div
        className={
          styles.fix_head + " " + styles.head_title + " header b-0 mb-24"
        }
      >
        <div className={styles.header_block}>
          <button
            className={styles.back_bl}
            onClick={() => {
              history(-1);
            }}
          >
            {props.button1}
          </button>

          <p className={styles.header_block__title2}>{props.title}</p>
        </div>
        <div className="border_block"></div>
      </div>
    </>
  );
};

export default observer(HeaderTittle);
