import React, { useState } from "react";
import { Form, Input, Button, Checkbox, Layout, message, Row, Col } from "antd";
import styles from "./Auth.module.scss";
import Lang from "../Lang/index";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { apiQuery, apiQueryError } from "../../Utils";
import userStore from "./userStore";
import HeaderLang from "../Header/HeaderLang";
import iconBack from "../../assets/images/IconBack.svg";
import AlertStore from "../Alert/AlertStore";
import ReCaptcha from "../ReCaptcha";

const { Content } = Layout;

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [buttonActive, setButtonActive] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [captcha, setCaptcha] = useState(true)

  const handleInputChange = (e) => {
    if (e.target.value.length > 0) {
      setInputValue(e.target.value);
      setShow(true);
      setButtonActive(true);
    } else {
      setShow(false);
      setButtonActive(false);
    }
  };
  const handleInputChange2 = (e) => {
    if (e.target.value.length > 0) {
      setInputValue2(e.target.value);
      setShow2(true);
      setButtonActive(true);
    } else {
      setShow2(false);
      setButtonActive(false);
    }
  };
  const onFinish = (values) => {
    apiQuery("/login", {
      method: "POST",
      body: values,
    })
        .then((userData) => {
          if (userData.error) return AlertStore.error(userData.error.msg);
          userStore.setUserData(userData);
          userStore.setAuth(true);
          // window.location.href = "/";
          
          if (location?.authRef) navigate(location.authRef.pathname)
          else navigate('/');
        })
        .catch((error) => {
          apiQueryError({ error });
        });
  };

  return (
    <div className="flex_block">
      <HeaderLang />
      <Content className={styles.siteLayout}>
        <div>
          <h2 className={styles.titleAuth}>
            {Lang({ str: "log_in", section: "auth" })}
          </h2>
          <p className={styles.little_text}>
            {Lang({ str: "no_account", section: "auth" })}
            <Link to="/create" className={styles.link}>
              {Lang({ str: "create_acc", section: "auth" })}
            </Link>
          </p>
        </div>

        <Form
          layout="vertical"
          name="normal_login"
          className={styles.loginForm}
          initialValues={{ rememberMe: true }}
          onFinish={onFinish}
        >
          <Form.Item
            className={styles.login_wrp}
            requiredMark={false}
            name="email"
            label={show && Lang({ str: "email_address", section: "auth" })}
            rules={[
              {
                required: true,
                message: Lang({ str: "enter_email", section: "auth" }),
              },
            ]}
          >
            <Input
              onChange={handleInputChange}
              className={styles.input}
              size={"large"}
              type={"email"}
              placeholder={Lang({ str: "email_address", section: "auth" })}
            />
          </Form.Item>
          <Form.Item
            className={styles.login_wrp}
            name="password"
            label={show2 && Lang({ str: "password", section: "auth" })}
            rules={[
              {
                required: true,
                message: Lang({ str: "enter_pass", section: "auth" }),
              },
            ]}
          >
            <Input
              onChange={handleInputChange2}
              className={styles.input}
              size={"large"}
              type="password"
              placeholder={Lang({ str: "password", section: "auth" })}
            />
          </Form.Item>
          <Form.Item>
            <Row>
              <Col flex={"auto"}>
                <Form.Item name="rememberMe" valuePropName="checked" noStyle>
                  <Checkbox className={styles.checkbox}>
                    {Lang({ str: "remember_me", section: "auth" })}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col flex={"auto"} style={{ textAlign: "right" }}>
                <Link to="/restore" className={styles.link}>
                  {Lang({ str: "forgot_pass", section: "auth" })}
                </Link>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
              name="captcha"
              rules={[{
                validator: () => new Promise(async (resolve, reject) => {
                    if (!captcha) await reject(Lang({ str: "enter_captcha", section: "auth" }))
                    else await resolve()
                })
              }]}
          >
            {/*<ReCaptcha callback={() => setCaptcha(true)} expiredCallback={() => setCaptcha(false)}/>*/}
          </Form.Item>
          <Form.Item className={styles.buttonBlockFixed}>
            <Button
              type="primary"
              htmlType="submit"
              className={
                buttonActive
                  ? styles.button_anactive + " " + styles.login_form_button
                  : styles.buttonFixed + " " + styles.login_form_button
              }
            >
              {Lang({ str: "log_in", section: "auth" })}
            </Button>
          </Form.Item>
          {/* <div className="title">
            <span>или</span>
          </div>

          <div style={{ textAlign: "center" }} className="mt-24">
            <a
              className="but_soc_net"
              href={"https://geekoinvest.kz/rest_api/auth/google"}
            >
              <span>
                <img src={google} alt="google" />
              </span>
              Продолжить с Google
            </a> 
            <a
              className="but_soc_net"
              href={"https://geekoinvest.kz/rest_api/auth/apple"}
            >
              <span>
                <img src={apple} alt="apple" />
              </span>
              Продолжить с Apple
            </a>
          </div>*/}
        </Form>
      </Content>
    </div>
  );
};

export default Login;
