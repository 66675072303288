import moment from 'moment';
import React from 'react';
import Lang from '../Lang';
import styles from './Accounts.module.scss';

const ItemHistoryDetailsModal = ({
  img,
  description,
  createdAt,
  typeTransfer,
  data,
  statusText,
  toAccDesc,
  fromAccDesc,
  transferBlock,
  value,
  displayCommission,
  commissionAmount,
  status,
}) => {
  return (
    <div className={styles.modal_history}>
      <div className={styles.flex}>
        <img src={img} />
        <div>
          <p>{description}</p>
          <span>
            {moment(createdAt).format('HH:mm')}, {moment(createdAt).format('DD.MM.YYYY')}
          </span>
        </div>
      </div>

      <div className={styles.tabl_bl}>
        {![
          'deposit', // пополнение
          'transferFromTradingAccount', // перевод с торгового счета
          'transferToTradingAccount', // перевод на торговый счет
          'transferFromCurrentAccount', // перевод с текущего счета
          'transferToCurrentAccount', // перевод на текущий счета
          'saleOfSecurities', // продажа бумаг
          'purchaseOfSecurities', // покупка бумаг
          'commissionForSaleOfSecurities', // комиссия за продажу бумаг
          'commissionForPurchaseOfSecurities', // комиссия за покупку бумаг
          'commissionForTransferringFromCurrentAccount', // комиссия за перевод с текущего счета
          'commissionForTransferringToCurrentAccount', // комиссия за перевод на текущий счет
          'withdrawal',
        ].includes(typeTransfer) ? (
          <>
            {Object.keys(data)
              .filter(
                (key) =>
                  !['account_id', 'event_id', 'event_ulid', 'id', 'system_date'].includes(key)
              )
              .map((key) => {
                return data[key] ? (
                  <div className={styles.tabl_bl_one}>
                    <p>{key}:</p>
                    <span>
                      {key === 'at'
                        ? moment(data[key]).format('HH:mm') +
                          ', ' +
                          moment(data[key]).format('DD.MM.YYYY')
                        : data[key]}
                    </span>
                  </div>
                ) : (
                  <></>
                );
              })}
          </>
        ) : (
          <>
            {transferBlock ? (
              <>
                <div className={styles.tabl_bl_one}>
                  <p>{Lang({ str: 'from_2', section: 'accounts' })}:</p>
                  <span>
                    {fromAccDesc} №{data?.from || ''}
                  </span>
                </div>
                <div className={styles.tabl_bl_one}>
                  <p>{Lang({ str: 'to_2', section: 'accounts' })}:</p>
                  <span>
                    {toAccDesc} №{data?.to || ''}
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className={styles.tabl_bl_one}>
              <p>{Lang({ str: 'amount', section: 'accounts' })}:</p>
              <span>${value}</span>
            </div>
            {displayCommission ? (
              <div className={styles.tabl_bl_one}>
                <p>{Lang({ str: 'fee', section: 'accounts' })}:</p>
                <span>${commissionAmount}</span>
              </div>
            ) : (
              <></>
            )}
            <div className={styles.tabl_bl_one}>
              <p>{Lang({ str: 'order_date_time', section: 'accounts' })}:</p>
              <span>
                {moment(createdAt).format('HH:mm')}, {moment(createdAt).format('DD.MM.YYYY')}
              </span>
            </div>
            <div className={styles.tabl_bl_one}>
              <p>{Lang({ str: 'order_status', section: 'accounts' })}:</p>
              <span>{statusText}</span>
            </div>
            {[
              'transferFromTradingAccount',
              'transferToTradingAccount',
              'transferFromCurrentAccount',
              'transferToCurrentAccount',
              'withdrawal',
            ].includes(typeTransfer) &&
              status === 'wait' && (
                <button>{Lang({ str: 'cancel_order', section: 'accounts' })}</button>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default ItemHistoryDetailsModal;
