import { Button, Checkbox, Form, Input, Radio, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import styles from '../BrokerAccountNew/BrokerAccountNew.module.scss';
import Lang from '../Lang/index';
import BrokerAccountStore from './BrokerAccountStore';

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

const Step6 = ({ setBack }) => {
  const [radiob, setRadiob] = useState(false);
  const ref = useRef();
  const onScreen = useOnScreen(ref);
  const traderData = BrokerAccountStore.traderData;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [form] = Form.useForm();
  const [investmentServicesOther, setInvestmentServicesOther] = useState('');
  const [expFinancialServicesOther, setExpFinancialServicesOther] = useState('');

  const onFinishForm8 = (values) => {
    values.investmentServicesOther = investmentServicesOther;
    values.expFinancialServicesOther = expFinancialServicesOther;
    const d = {
      view: 'questionnaire',
      step: 1,
      mark: 'riskAppetite',
      questionnaire: {
        investmentExperience: values,
      },
    };
    BrokerAccountStore.save(d).catch();
  };
  const onChange = (checkedValues) => {
    console.log('checked = ', checkedValues);
  };
  let onChangeR = (checkedValuesR) => {
    console.log('checkedR = ', checkedValuesR);
    checkedValuesR.target.value == 'Прочее' ? setRadiob(true) : setRadiob(false);
  };

  useEffect(() => {
    const scroll = window.document.getElementsByClassName('basic-routes');
    if (scroll[0]) scroll[0].scrollTo(0, 0);

    setBack({
      view: 'questionnaire',
      step: 2,
    });
  }, []);

  useEffect(() => {
    onChangeR = (checkedValuesR) => {
      console.log('checkedR = ', checkedValuesR);
      checkedValuesR.target.value == 'Прочее' ? setRadiob(true) : setRadiob(false);
    };
  }, []);

  useEffect(() => {
    if (traderData?.questionnaire?.investmentExperience) {
      form.setFieldsValue(traderData?.questionnaire?.investmentExperience);
    }
    setInvestmentServicesOther(
      traderData?.questionnaire?.investmentExperience?.investmentServicesOther
    );
    setExpFinancialServicesOther(
      traderData?.questionnaire?.investmentExperience?.expFinancialServicesOther
    );
  }, [traderData]);

  return (
    <>
      <div className={styles.page_fl}>
        <div className={styles.text_bl}>
          <h4>
            {Lang({ str: 'risk_appetite', section: 'onboarding' })}
            <span>{Lang({ str: 'select_answer', section: 'onboarding' })}</span>
          </h4>

          <Form className={styles.form} layout="vertical" form={form} onFinish={onFinishForm8}>
            <fieldset>
              <span>
                {Lang({ str: 'investment_experience', section: 'onboarding' })}
                <br />
                {Lang({ str: 'stocks_etfs', section: 'onboarding' })}
              </span>
              <Form.Item name={'investmentExp'} rules={[{ required: true }]}>
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value={'Нет'}>{Lang({ str: 'no', section: 'default' })}</Radio>
                    <Radio value={'Менее 1 года'}>
                      {Lang({ str: 'less_one', section: 'onboarding' })}
                    </Radio>
                    <Radio value={'1-5 лет'}>
                      {Lang({ str: 'one_five', section: 'onboarding' })}
                    </Radio>
                    <Radio value={'Более 5 лет'}>
                      {Lang({ str: 'more_five', section: 'onboarding' })}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </fieldset>
            <fieldset>
              <span>
                {Lang({ str: 'investment_experience', section: 'onboarding' })} <br />
                {Lang({ str: 'digital_assets', section: 'onboarding' })}
              </span>
              <Form.Item name={'digitalAssets'} rules={[{ required: true }]}>
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value={'Нет'}>{Lang({ str: 'no', section: 'default' })}</Radio>
                    <Radio value={'Менее 1 года'}>
                      {Lang({ str: 'less_one', section: 'onboarding' })}
                    </Radio>
                    <Radio value={'1-5 лет'}>
                      {Lang({ str: 'one_five', section: 'onboarding' })}
                    </Radio>
                    <Radio value={'Более 5 лет'}>
                      {Lang({ str: 'more_five', section: 'onboarding' })}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </fieldset>
            <fieldset>
              <span>
                {Lang({ str: 'investment_experience', section: 'onboarding' })}
                <br />
                {Lang({ str: 'mutual_funds', section: 'onboarding' })}
              </span>
              <Form.Item name={'uifs'} rules={[{ required: true }]}>
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value={'Нет'}>{Lang({ str: 'no', section: 'default' })}</Radio>
                    <Radio value={'Менее 1 года'}>
                      {Lang({ str: 'less_one', section: 'onboarding' })}
                    </Radio>
                    <Radio value={'1-5 лет'}>
                      {Lang({ str: 'one_five', section: 'onboarding' })}
                    </Radio>
                    <Radio value={'Более 5 лет'}>
                      {Lang({ str: 'more_five', section: 'onboarding' })}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </fieldset>

            <fieldset>
              <span>
                {Lang({ str: 'investment_experience', section: 'onboarding' })} <br />
                {Lang({ str: 'products', section: 'onboarding' })}
              </span>
              <Form.Item name={'products'} rules={[{ required: true }]}>
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value={'Нет'}>{Lang({ str: 'no', section: 'default' })}</Radio>
                    <Radio value={'Менее 1 года'}>
                      {Lang({ str: 'less_one', section: 'onboarding' })}
                    </Radio>
                    <Radio value={'1-5 лет'}>
                      {Lang({ str: 'one_five', section: 'onboarding' })}
                    </Radio>
                    <Radio value={'Более 5 лет'}>
                      {Lang({ str: 'more_five', section: 'onboarding' })}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </fieldset>
            <fieldset>
              <span>{Lang({ str: 'risk_question_1', section: 'onboarding' })}</span>
              <Form.Item name={'investmentServices'} rules={[{ required: true }]}>
                <Checkbox.Group onChange={onChange}>
                  <Checkbox value="Брокерское обслуживание">
                    {Lang({ str: 'brokerage', section: 'onboarding' })}
                  </Checkbox>
                  <Checkbox value="Депозиты">
                    {Lang({ str: 'deposits', section: 'onboarding' })}
                  </Checkbox>
                  <Checkbox value="Доверительное управление">
                    {Lang({ str: 'trust_management', section: 'onboarding' })}
                  </Checkbox>
                  <Checkbox className={styles.input_text} value="Прочее">
                    {Lang({ str: 'other', section: 'onboarding' })}
                    <Input
                      value={investmentServicesOther}
                      onChange={(e) => setInvestmentServicesOther(e.target.value)}
                    />
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </fieldset>
            <fieldset>
              <span>{Lang({ str: 'risk_question_6', section: 'onboarding' })} </span>
              <Form.Item name={'firstThought'} rules={[{ required: true }]}>
                <Radio.Group>
                  <Space direction="vertical">
                    {[
                      {
                        value: 'Я беспокоюсь, что могу остаться ни с чем',
                        label: Lang({ str: 'left_with_nothing', section: 'onboarding' }),
                      },
                      {
                        value: 'Я понимаю, что это неотъемлемая часть процесса инвестирования',
                        label: Lang({ str: 'integral_of_investment', section: 'onboarding' }),
                      },
                      {
                        value: 'Я вижу возможность получить большие доходы',
                        label: Lang({ str: 'make_profits', section: 'onboarding' }),
                      },
                      {
                        value: 'Я думаю о том, как захватывающе инвестировать',
                        label: Lang({ str: 'exciting_to_invest', section: 'onboarding' }),
                      },
                    ].map((item, index) => (
                      <Radio key={index} value={item.value}>
                        {item.label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </fieldset>
            <fieldset>
              <span>{Lang({ str: 'risk_question_2', section: 'onboarding' })}</span>
              <Form.Item name={'numDealsYear'} rules={[{ required: true }]}>
                <Radio.Group>
                  <Space direction="vertical">
                    {[
                      { value: '0-50', label: '0-50' },
                      { value: '51-100', label: '51-100' },
                      { value: '101-500', label: '101-500' },
                      { value: '>500', label: '>500' },
                    ].map((item, index) => (
                      <Radio key={index} value={item.value}>
                        {item.label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </fieldset>
            <fieldset>
              <span>{Lang({ str: 'risk_question_3', section: 'onboarding' })}</span>
              <Form.Item name={'eduFinancialServices'} rules={[{ required: true }]}>
                <Radio.Group>
                  <Space direction="vertical">
                    {[
                      {
                        value: 'Нет',
                        label: Lang({
                          str: 'no',
                          section: 'default',
                        }),
                      },
                      {
                        value: 'Да, высшее образование в области финансов или экономики',
                        label: Lang({
                          str: 'yes_higher_education',
                          section: 'onboarding',
                        }),
                      },
                      {
                        value:
                          'Да, сдал профессиональный экзамен или прошел профессиональные курсы (экзамен на брокера, профессиональное образование для регистрации инвестиционного брокера, связанного агента и т.д.)',
                        label: Lang({
                          str: 'yes_prof_exam',
                          section: 'onboarding',
                        }),
                      },
                    ].map((item, index) => (
                      <Radio key={index} value={item.value}>
                        {item.label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </fieldset>
            <fieldset>
              <span>{Lang({ str: 'risk_question_4', section: 'onboarding' })}</span>
              <Form.Item name={'expFinancialServices'} rules={[{ required: true }]}>
                <Radio.Group onChange={onChangeR}>
                  <Space direction="vertical">
                    <Radio value={'Нет'}>{Lang({ str: 'no', section: 'default' })}</Radio>
                    <Radio value={'Брокерская компания'}>
                      {Lang({
                        str: 'brokerage_company',
                        section: 'onboarding',
                      })}
                    </Radio>
                    <Radio value={'Банковская сфера'}>
                      {Lang({ str: 'banking_sector', section: 'onboarding' })}
                    </Radio>
                    <Radio className={styles.input_text} value={'Прочее'}>
                      {Lang({ str: 'other_company', section: 'onboarding' })}
                      {radiob && (
                        <Input
                          required
                          value={expFinancialServicesOther}
                          onChange={(e) => {
                            setExpFinancialServicesOther(e.target.value);
                          }}
                        />
                      )}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </fieldset>
            <fieldset>
              <span>{Lang({ str: 'risk_question_5', section: 'onboarding' })}</span>
              <Form.Item name={'investmentKnowledge'} rules={[{ required: true }]}>
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value={'Я очень мало знаю об инвестициях и финансовых рынках'}>
                      {Lang({ str: 'know_little', section: 'onboarding' })}
                    </Radio>
                    <Radio
                      value={'Я обладаю средним уровнем знаний об инвестициях и финансовых рынках'}>
                      {Lang({ str: 'know_average', section: 'onboarding' })}
                    </Radio>
                    <Radio
                      value={
                        'Я обладаю обширными знаниями в области инвестиций, разбираюсь в различных инвестиционных продуктах и внимательно слежу за финансовыми рынками'
                      }>
                      {Lang({ str: 'know_extensive', section: 'onboarding' })}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </fieldset>

            <p style={{ marginBottom: 50 }} ref={ref}>
              {Lang({ str: 'true_info_confirmation', section: 'onboarding' })}
            </p>

            <Form.Item className={styles.buttonBlockFixed + ' m-0 b-0'}>
              <Button
                type="primary"
                htmlType="submit"
                className={onScreen ? styles.gr_btn + ' ' + styles.activ : styles.gr_btn}
                disabled={!onScreen && true}>
                {Lang({ str: 'continue', section: 'onboarding' })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default observer(Step6);
