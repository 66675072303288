import { makeAutoObservable } from 'mobx';
import { apiRequest } from '../../Utils';

class TradingStore {
  marketData = {};

  fetchCheck = true;

  futuresRows = [];
  futuresLoadCheck = true;

  indicesRows = [];
  indicesLoadCheck = true;

  dynamicRows = [];
  dynamicLoadCheck = true;

  bestRows = [];
  bestLoadCheck = true;

  noBestRows = [];
  noBestLoadCheck = true;

  popularRows = [];
  popularLoadCheck = true;

  bondsRows = [];
  bondsLoadCheck = true;

  cryptoRows = [];
  cryptoLoadCheck = true;

  isSearchOpen = false;

  barsData = null;
  barsTimeframe = '5Min';
  barsInterval = '1Day';

  constructor() {
    makeAutoObservable(this);
  }

  openSearch() {
    this.isSearchOpen = true;
  }

  closeSearch() {
    this.isSearchOpen = false;
  }

  setTickerData(value) {
    this.tickerData = value;
  }

  loadFutures({ clear = false }) {
    if (clear) {
      this.fetchCheck = false;
      this.futuresLoadCheck = true;
      // this.futuresRows = [];
    }

    if (this.fetchCheck) return;
    this.fetchCheck = true;

    if (!this.futuresLoadCheck) {
      this.fetchCheck = false;
      return;
    }

    apiRequest({
      url: '/private/v1/tickers/futures',
      method: 'POST',
      body: {
        skip: clear ? 0 : this.futuresRows.length,
      },
      success: (data) => {
        if (clear) {
          const mass = new Set([]);
          for (let d of data) mass.add(d._id);
          this.futuresRows = [...mass];
        } else {
          const mass = new Set([...this.futuresRows]);
          for (let d of data) mass.add(d._id);
          this.futuresRows = [...mass];
        }
        this.futuresLoadCheck = data !== false && data.length > 0;
        this.fetchCheck = false;
      },
      error: () => {
        this.fetchCheck = false;
      },
    }).catch();
  }

  loadIndices({ clear = false }) {
    if (clear) {
      this.fetchCheck = false;
      this.indicesLoadCheck = true;
      // this.indicesRows = [];
    }

    if (this.fetchCheck) return;
    this.fetchCheck = true;

    if (!this.indicesLoadCheck) {
      this.fetchCheck = false;
      return;
    }

    apiRequest({
      url: '/private/v1/tickers/indices',
      method: 'POST',
      body: {
        skip: clear ? 0 : this.indicesRows.length,
      },
      success: (data) => {
        if (clear) {
          const mass = new Set([]);
          for (let d of data) mass.add(d._id);
          this.indicesRows = [...mass];
        } else {
          const mass = new Set([...this.indicesRows]);
          for (let d of data) mass.add(d._id);
          this.indicesRows = [...mass];
        }
        this.indicesLoadCheck = data !== false && data.length > 0;
        this.fetchCheck = false;
      },
      error: () => {
        this.fetchCheck = false;
      },
    }).catch();
  }

  loadBonds({ clear = false }) {
    if (clear) {
      this.fetchCheck = false;
      this.bondsLoadCheck = true;
      // this.bondsRows = [];
    }

    if (this.fetchCheck) return;
    this.fetchCheck = true;

    if (!this.bondsLoadCheck) {
      this.fetchCheck = false;
      return;
    }

    apiRequest({
      url: '/private/v1/tickers/bonds',
      method: 'POST',
      body: {
        skip: clear ? 0 : this.bondsRows.length,
      },
      success: (data) => {
        if (clear) {
          const mass = new Set([]);
          for (let d of data) mass.add(d._id);
          this.bondsRows = [...mass];
        } else {
          const mass = new Set([...this.bondsRows]);
          for (let d of data) mass.add(d._id);
          this.bondsRows = [...mass];
        }
        this.bondsLoadCheck = data !== false && data.length > 0;
        this.fetchCheck = false;
      },
      error: () => {
        this.fetchCheck = false;
      },
    }).catch();
  }

  loadCrypto({ clear = false }) {
    if (clear) {
      this.fetchCheck = false;
      this.cryptoLoadCheck = true;
      // this.cryptoRows = [];
    }

    if (this.fetchCheck) return;
    this.fetchCheck = true;

    if (!this.cryptoLoadCheck) {
      this.fetchCheck = false;
      return;
    }

    apiRequest({
      url: '/private/v1/tickers/crypto',
      method: 'POST',
      body: {
        skip: clear ? 0 : this.cryptoRows.length,
      },
      success: (data) => {
        if (clear) {
          const mass = new Set([]);
          for (let d of data) mass.add(d._id);
          this.cryptoRows = [...mass];
        } else {
          const mass = new Set([...this.cryptoRows]);
          for (let d of data) mass.add(d._id);
          this.cryptoRows = [...mass];
        }
        this.cryptoLoadCheck = data !== false && data.length > 0;
        this.fetchCheck = false;
      },
      error: () => {
        this.fetchCheck = false;
      },
    }).catch();
  }

  loadBest({ clear = false }) {
    if (clear) {
      this.fetchCheck = false;
      this.bestLoadCheck = true;
      // this.bestRows = [];
    }

    if (this.fetchCheck) return;
    this.fetchCheck = true;

    if (!this.bestLoadCheck) {
      this.fetchCheck = false;
      return;
    }

    apiRequest({
      url: '/private/v1/tickers/best',
      method: 'POST',
      body: {
        skip: clear ? 0 : this.bestRows.length,
      },
      success: (data) => {
        if (clear) {
          const mass = new Set([]);
          for (let d of data) mass.add(d._id);
          this.bestRows = [...mass];
        } else {
          const mass = new Set([...this.bestRows]);
          for (let d of data) mass.add(d._id);
          this.bestRows = [...mass];
        }
        this.bestLoadCheck = data !== false && data.length > 0;
        this.fetchCheck = false;
      },
      error: () => {
        this.fetchCheck = false;
      },
    }).catch();
  }

  loadNoBest({ clear = false }) {
    if (clear) {
      this.fetchCheck = false;
      this.noBestLoadCheck = true;
      // this.noBestRows = [];
    }

    if (this.fetchCheck) return;
    this.fetchCheck = true;

    if (!this.noBestLoadCheck) {
      this.fetchCheck = false;
      return;
    }

    apiRequest({
      url: '/private/v1/tickers/noBest',
      method: 'POST',
      body: {
        skip: clear ? 0 : this.noBestRows.length,
      },
      success: (data) => {
        if (clear) {
          const mass = new Set([]);
          for (let d of data) mass.add(d._id);
          this.noBestRows = [...mass];
        } else {
          const mass = new Set([...this.noBestRows]);
          for (let d of data) mass.add(d._id);
          this.noBestRows = [...mass];
        }
        this.noBestLoadCheck = data !== false && data.length > 0;
        this.fetchCheck = false;
      },
      error: () => {
        this.fetchCheck = false;
      },
    }).catch();
  }

  loadPopular({ clear = false }) {
    if (clear) {
      this.fetchCheck = false;
      this.popularLoadCheck = true;
      // this.popularRows = [];
    }

    if (this.fetchCheck) return;
    this.fetchCheck = true;

    if (!this.popularLoadCheck) {
      this.fetchCheck = false;
      return;
    }

    apiRequest({
      url: '/private/v1/tickers/popular',
      method: 'POST',
      body: {
        skip: clear ? 0 : this.popularRows.length,
      },
      success: (data) => {
        if (clear) {
          const mass = new Set([]);
          for (let d of data) mass.add(d._id);
          this.popularRows = [...mass];
        } else {
          const mass = new Set([...this.popularRows]);
          for (let d of data) mass.add(d._id);
          this.popularRows = [...mass];
        }
        this.popularLoadCheck = data !== false && data.length > 0;
        this.fetchCheck = false;
      },
      error: () => {
        this.fetchCheck = false;
      },
    }).catch();
  }

  getBars({ tickerId, timeframe, chartPage }) {
    return new Promise((res, rej) => {
      apiRequest({
        url: '/private/v1/getBars',
        method: 'POST',
        body: {
          tickerId,
          timeframe,
          chartPage,
        },
        success: (data) => {
          res(data);
          if (this.barsTimeframe === data.timeframe && this.barsInterval === data.interval) {
            this.barsData = data.bars;
          }
        },
        error: () => {
          rej();
        },
      }).catch();
    });
  }

  getBarsNew({ tickerId, timeframe, startDate }) {
    return new Promise((res, rej) => {
      apiRequest({
        url: '/private/v1/getBarsNew',
        method: 'POST',
        body: {
          tickerId,
          timeframe,
          startDate,
        },
        success: (data) => {
          res(data);
        },
        error: () => {
          rej();
        },
      }).catch();
    });
  }

  getMarket({ tickerId }) {
    apiRequest({
      url: `/private/v1/ticker/${tickerId}/market`,
      success: (data) => {
        this.marketData[tickerId] = data;
      },
      error: () => {},
    }).catch();
  }
}

const clsTradingStore = new TradingStore();
export default clsTradingStore;
