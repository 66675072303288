import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logomodal from '../../assets/images/logo_w.svg';
import userStore from '../Auth/userStore';
import styles from '../BrokerAccountNew/BrokerAccountNew.module.scss';
import Lang from '../Lang';
import BrokerAccountStore from './BrokerAccountStore';
import blocked_warning from '../../assets/images/blocked_warning.svg'
import {Button} from "../../UI";

const OpenBrokerAccountModal = ({ closeModal }) => {
  let history = useNavigate();
  const traderData = BrokerAccountStore.traderData;

  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  useEffect(() => {
    BrokerAccountStore.get();
  }, []);
  return (
    <div className={styles.flex_modal + ' p-16 mb-24'}>
      {(userStore.userData?.statusData?.trader === 'lock' || userStore.userData?.statusData?.trader === 'blocked') &&
        <div className={styles.acc_blocked_modal}>
          <img src={blocked_warning} alt=""/>
          <p>{Lang({str: "acc_blocked", section: "trading"})}</p>
          <p>{Lang({str: "with_support", section: "trading"})}</p>
        </div>
      }
      {(!userStore.userData?.statusData?.trader ||
        userStore.userData?.statusData?.trader === 'inactive') && (
        <div className={styles.acc_closed_modal}>
          <img style={{ height: '24px' }} src={logomodal}/>
          <p>
            {Lang({ str: 'verification_msg', section: 'default' })}
            <br />
            {Lang({
              str: 'verification_msg_2',
              section: 'default',
            })}
          </p>
          <Button
            autoWidth
            onClick={() => {
              closeModal();
              history(traderData?.view === 'info' ? '/slider' : '/broker_account');
            }}
          >
            {Lang({ str: 'open_broker_acc', section: 'trading' })}
          </Button>
        </div>
      )}
    </div>
  );
};

export default observer(OpenBrokerAccountModal);
