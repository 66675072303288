import { Button, Form, InputNumber } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Countdown from 'react-countdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { Clickable, Tabs } from '../../UI';
import Utils, { $class, apiRequest } from '../../Utils';
import file from '../../assets/Cash SSI_Client.pdf';
import centercredit from '../../assets/images/centercredit.svg';
import copyicon from '../../assets/images/copyicon.svg';
import eub from '../../assets/images/eub_logo2str.svg';
import halyk from '../../assets/images/halyk.svg';
import { BackButton } from '../../blocks';
import websocket from '../../websocket';
import AccountStore from '../Accounts/AccountStore';
import styles from '../Accounts/Accounts.module.scss';
import AlertStore from '../Alert/AlertStore';
import userStore from '../Auth/userStore';
import Lang from '../Lang/index';
import TitleCategory from '../TitleCategory';

const AddBrokerAccount = () => {
  //tab3

  const [amount, setAmount] = useState(5000);
  const [history, setHistory] = useState([]);
  const [disabled, setDisable] = useState(false);
  const [timer, setTimer] = useState(null);

  const onFinish = (values) => {
    console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onChange = (value) => {
    console.log('changed', value);
    setAmount(value);
  };

  useEffect(() => {
    console.log('userStore.userData: ', userStore.userData._id);

    let eventId = null;

    eventId = websocket.subscribe(
      { event: 'walletBalanceChange', key: userStore.userData._id },
      (data) => {
        console.log('websocket data: ', data);
        demoPayHistory();
      }
    );

    return () => {
      websocket.unSubscribe(eventId);
    };
  }, []);

  useEffect(() => {
    apiRequest({
      url: '/private/v1/last-deposit',
      body: {},
      success: (data) => {
        // console.log(data);
        if (!data) {
          console.log('олптила');
          setDisable(false);
          return;
        }
        setDisable(true);
        let myDate = new Date(data);
        ////myDate.setHours(myDate.getHours() + 24);
        myDate = myDate.getTime();
        myDate = myDate + 86400000;
        setTimer(myDate);
        console.log('нельзя оплатить', myDate);
        if (Date.now() > myDate) {
          setDisable(false);
        }
      },
    }).catch((error) => {
      console.error({ error });
    });
  }, []);

  const pay = () => {
    apiRequest({
      url: `/private/v1/demo-pay/`,
      method: 'POST',
      body: {
        amount,
      },
      success: (data) => {
        if (data?.error) return AlertStore.error(data?.error.msg);
        console.log({ data });
        if (data?.status == 'ok') {
          AlertStore.orderSuccess(Lang({ str: 'replenishment_completed', section: 'accounts' }));
          setAmount(5000);
          demoPayHistory();
        }
      },
    }).catch((error) => {
      console.error({ error });
    });
  };

  const demoPayHistory = () => {
    apiRequest({
      url: `/private/v1/demo-pay-history/`,
      method: 'GET',
      success: (data) => {
        if (data?.error) return AlertStore.error(data?.error.msg);
        console.log({ data });
        setHistory(data);
      },
    }).catch((error) => {
      console.error({ error });
    });
  };

  useEffect(() => {
    demoPayHistory();
  }, []);

  // close tab3

  const navigate = useNavigate();
  const location = useLocation();
  console.log(userStore.userData);
  const [tab, setTab] = useState('1');
  const categoryConfig = [
    {
      title: Lang({ str: 'replenish_broker', section: 'accounts' }),
    },
  ];
  function callback(key) {
    setTab(key);
  }

  const copy = useCallback(() => {
    AlertStore.copy();
  }, []);

  const tabs = [
    {
      label: Lang({ str: 'in_kzt', section: 'accounts' }),
      value: '1',
    },
    {
      label: Lang({ str: 'in_usd', section: 'accounts' }),
      value: '2',
    },
  ];

  if (Utils.apiDomain() === 'app2.geekoinvest.kz') {
    tabs.push({
      label: Lang({ str: 'demo', section: 'accounts' }),
      value: '3',
    });
  }

  return (
    <>
      <div className={styles.fixed_bl}>
        <BackButton label={Lang({ str: 'back', section: 'default' })} />
      </div>

      <div className={styles.account_page}>
        <div className={styles.center_text}>
          <TitleCategory title={categoryConfig[0].title} />
        </div>
        <div className={styles.tabs_block + ' mt-8'}>
          <Tabs onChange={callback} value={tab} className={styles.tabs_input} items={tabs} />
          {tab === '1' && (
            <>
              <div className={styles.tabs1}>
                <p className={styles.titl_lit}>
                  {Lang({ str: 'replenishment_instructions', section: 'accounts' })}
                </p>
                <div className={styles.buttons_gr + ' mb-32'}>
                  <Clickable
                    rippleColor="#008669"
                    onClick={() => navigate('/accounts/manual_halyk', { state: location })}
                    className={$class(styles.halyk, styles.guide_link_btn)}>
                    <img src={halyk} />
                    <span>
                      {Lang({ str: 'replenishment_instructions_in', section: 'accounts' })} Halyk
                      Bank
                    </span>
                    <div className={styles.chip}>
                      {Lang({ str: 'favorable_fees', section: 'accounts' })}
                    </div>
                  </Clickable>
                </div>
                <p className={styles.titl_lit}>
                  {Lang({ str: 'requisites', section: 'accounts' })}
                </p>
                <div className={styles.requisites}>
                  <CopyToClipboard onCopy={copy} text="KZ64601A861024970331">
                    <div>
                      <div>
                        <span>{Lang({ str: 'iban', section: 'accounts' })}</span>
                        <p>KZ64601A861024970331</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                  <CopyToClipboard onCopy={copy} text="Geeko Invest Limited">
                    <div>
                      <div>
                        <span>{Lang({ str: 'recipient', section: 'accounts' })}</span>
                        <p>Geeko Invest Limited</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                  <CopyToClipboard onCopy={copy} text="210840900168">
                    <div>
                      <div>
                        <span>{Lang({ str: 'bin', section: 'accounts' })}</span>
                        <p>210840900168</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                  <CopyToClipboard onCopy={copy} text="АО «Народный Банк Казахстана»">
                    <div>
                      <div>
                        <span>{Lang({ str: 'beneficiary_bank', section: 'accounts' })}</span>
                        <p>АО «Народный Банк Казахстана»</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                  <CopyToClipboard onCopy={copy} text="361">
                    <div>
                      <div>
                        <span>{Lang({ str: 'knp', section: 'accounts' })}</span>
                        <p>361</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                  <CopyToClipboard onCopy={copy} text="17">
                    <div>
                      <div>
                        <span>{Lang({ str: 'cbe', section: 'accounts' })}</span>
                        <p>17</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                  <CopyToClipboard
                    onCopy={copy}
                    text={
                      ' IIN ' +
                      userStore.userData?.iin?.replace('******', '') +
                      userStore.userData?.nii?.replace('******', '') +
                      '. Account № ' +
                      AccountStore.accounts.brokerage.number
                    }>
                    <div>
                      <div>
                        <span>{Lang({ str: 'payment_purpose', section: 'accounts' })}</span>
                        <p>
                          IIN {''}
                          {userStore.userData?.iin?.replace('******', '') +
                            userStore.userData?.nii?.replace('******', '')}
                          .<p> Account № {AccountStore.accounts.brokerage.number}</p>
                        </p>
                      </div>
                      {console.log(AccountStore.accounts.brokerage.number, 'dfdfdfdfdffdfdfdfdf')}
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                </div>

                <div className={styles.buttonBlockFixed}>
                  <Button className={styles.button_bl} downloadUrl={file}>
                    {Lang({ str: 'share_requisites', section: 'accounts' })}
                  </Button>
                </div>
              </div>
            </>
          )}
          {tab === '2' && (
            <>
              <div className={styles.tabs1}>
                <p className={styles.titl_lit}>
                  {Lang({ str: 'replenishment_instructions', section: 'accounts' })}
                </p>
                <div className={styles.buttons_gr + ' mb-32'}>
                  <Clickable
                    rippleColor="#DAAB6B"
                    onClick={() => navigate('/accounts/manual_bcc', { state: location })}
                    className={$class(styles.centercredit, styles.guide_link_btn)}>
                    <img src={centercredit} />
                    <span>
                      {Lang({ str: 'replenishment_instructions_in', section: 'accounts' })}{' '}
                      {Lang({ str: 'bcc', section: 'accounts' })}
                    </span>
                  </Clickable>

                  {/* <Clickable
                    rippleColor="#24B6C7"
                    onClick={() => navigate('/accounts/manual_smartbank', { state: location })}
                    className={$class(styles.forte, styles.guide_link_btn)}>
                    <img src={forte} />
                    <span>
                      {Lang({ str: 'replenishment_instructions_in', section: 'accounts' })} Forte
                      Bank
                    </span>
                  </Clickable>*/}

                  <Clickable
                    rippleColor="#E1056D"
                    onClick={() => navigate('/accounts/manual_smartbank', { state: location })}
                    className={$class(styles.eub, styles.guide_link_btn)}>
                    <img src={eub} />
                    <span>
                      {Lang({ str: 'replenishment_instructions_in', section: 'accounts' })} Eurasian
                      Bank
                    </span>
                  </Clickable>
                  <Clickable
                    rippleColor="#008669"
                    onClick={() => navigate('/accounts/manual_other', { state: location })}
                    className={$class(styles.guide_link_btn, styles.dr)}>
                    <p>{Lang({ str: 'other_banks', section: 'accounts' })}</p>
                    <span>
                      {Lang({ str: 'replenishment_instructions_in', section: 'accounts' })}{' '}
                      {Lang({ str: 'other_banks_2', section: 'accounts' })}
                    </span>
                  </Clickable>
                </div>
                <p className={styles.titl_lit}>
                  {Lang({ str: 'requisites', section: 'accounts' })}
                </p>
                <div className={styles.requisites}>
                  <CopyToClipboard onCopy={copy} text="KZ64601A861024970331">
                    <div>
                      <div>
                        <span>{Lang({ str: 'iban', section: 'accounts' })}</span>
                        <p>KZ64601A861024970331</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                  <CopyToClipboard onCopy={copy} text="Geeko Invest Limited">
                    <div>
                      <div>
                        <span>{Lang({ str: 'recipient', section: 'accounts' })}</span>
                        <p>Geeko Invest Limited</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                  <CopyToClipboard onCopy={copy} text="210840900168">
                    <div>
                      <div>
                        <span>{Lang({ str: 'bin', section: 'accounts' })}</span>
                        <p>210840900168</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                  <CopyToClipboard onCopy={copy} text="АО «Народный Банк Казахстана»">
                    <div>
                      <div>
                        <span>{Lang({ str: 'beneficiary_bank', section: 'accounts' })}</span>
                        <p>АО «Народный Банк Казахстана»</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                  <CopyToClipboard onCopy={copy} text="HSBKKZKX">
                    <div>
                      <div>
                        <span>{Lang({ str: 'bic', section: 'accounts' })}</span>
                        <p>HSBKKZKX</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                  <CopyToClipboard onCopy={copy} text="361">
                    <div>
                      <div>
                        <span>{Lang({ str: 'knp', section: 'accounts' })}</span>
                        <p>361</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                  <CopyToClipboard onCopy={copy} text="17">
                    <div>
                      <div>
                        <span>{Lang({ str: 'cbe', section: 'accounts' })}</span>
                        <p>17</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                  <CopyToClipboard
                    onCopy={copy}
                    text={
                      ' IIN ' +
                      userStore.userData?.iin?.replace('******', '') +
                      userStore.userData?.nii?.replace('******', '') +
                      '. Account № ' +
                      AccountStore.accounts.brokerage.number
                    }>
                    <div>
                      <div>
                        <span>{Lang({ str: 'payment_purpose', section: 'accounts' })}</span>
                        <p>
                          IIN {''}
                          {userStore.userData?.iin?.replace('******', '') +
                            userStore.userData?.nii?.replace('******', '')}
                          .<p> Account № {AccountStore.accounts.brokerage.number}</p>
                        </p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                </div>
                <div className={styles.buttonBlockFixed}>
                  <Button className={styles.button_bl} downloadUrl={file}>
                    {Lang({ str: 'share_requisites', section: 'accounts' })}
                  </Button>
                </div>
              </div>
            </>
          )}
          {tab === '3' && (
            <>
              <div className={styles.summ_block}>
                <div className={styles.block_valute}>
                  <Form
                    className={styles.flex_form}
                    name="basic"
                    onFinish={onFinish}
                    labelCol={{
                      span: 24,
                    }}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">
                    <Form.Item
                      name="username"
                      rules={[
                        {
                          required: false,
                          message: Lang({
                            str: 'enter_amount',
                            section: 'accounts',
                          }),
                        },
                      ]}>
                      <InputNumber
                        defaultValue={amount}
                        min={0}
                        formatter={(value) => `$${value}`}
                        parser={(value) => value.replace('$', '')}
                        onChange={onChange}
                        value={amount}
                        disabled={true}
                      />
                    </Form.Item>

                    <Form.Item className={styles.buttonBlockFixed + ' mt-32'}>
                      {disabled && (
                        <p className={styles.text_timer}>
                          Осталось:{' '}
                          <Countdown
                            date={timer}
                            onComplete={() => {
                              setDisable(false);
                            }}
                          />
                        </p>
                      )}
                      <Button
                        disabled={disabled}
                        className={
                          disabled ? styles.dis_but + ' ' + styles.add_balance : styles.add_balance
                        }
                        type="primary"
                        htmlType="submit"
                        onClick={pay}>
                        {Lang({ str: 'replenish', section: 'accounts' })}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default observer(AddBrokerAccount);
