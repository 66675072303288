import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Input, InputNumber, message } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Clickable } from "../../../UI";
import { PriceChanging, TickerPaperCard, TickerTitle } from "../../../blocks";
import Icon from "../../Icon";
import ModalSwipe from "../../ModalSwipe/ModalSwipe";
import Lang from "../../Lang";
import Modal from "../../Modal";
import NumberFormat from "../../NumberFormat";
import TickerStore from "../../Ticker/TickerStore";
import stylesW from "../Wallet.module.scss";
import WalletStore from "../WalletStore";
import styles from "./index.module.scss";
import DollarSign from "../../../blocks/DollarSign";
import { Slider } from "antd";
import { apiRequest } from "../../../Utils";
import VList from "../../VList";
import AlertStore from "../../Alert/AlertStore";
import modalStore from "../../ModalManager/modalStore";

const Papers = () => {
  useEffect(() => {
    WalletStore.getPapers();
  }, []);

  return (
    <>
      {WalletStore?.my_papers === null && (
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 24, color: "var(--basic-dark)" }}
                spin
              />
            }
          />
        </div>
      )}

      {WalletStore?.my_papers?.length === 0 && (
        <div className={stylesW.empty_block}>
          {/* <Icon slug="paperSearch" size={100} /> */}
          <p>{Lang({ str: "no_positions", section: "wallet" })}</p>
        </div>
      )}

      <div>
        {!!WalletStore?.my_papers?.length &&
          WalletStore?.my_papers?.map((item) => (
            <div className="pb-16">
              <TickerPaperCard
                onClick={() =>
                  modalStore.openModal("paperDetails", { details: item.obj })
                }
                paper={item?.obj}
                tickerId={item?.obj?._id}
              />
            </div>
          ))}
      </div>
    </>
  );
};

export default observer(Papers);
