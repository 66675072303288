import React, { useEffect, useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import { $class } from '../../Utils';
import Clickable from '../Clickable';
import './index.scss';

const Tabs = ({ value, items, onChange, className, style, children, isTabsFlex }) => {
  const itemsRef = useRef(Array(items.length).fill());
  const [springStyles, springApi] = useSpring(() => ({
    left: 0,
    width: 0,
    height: 0,
  }));

  const animatePointer = () => {
    const index = items.findIndex((x) => x.value === value);
    if (index === -1) return;

    if (!itemsRef.current[index]) return;
    const { x, width, height } = itemsRef.current[index]?.getBoundingClientRect();
    springApi.start({ left: itemsRef.current[index].offsetLeft, width, height });
  };

  // Fix
  useEffect(() => {
    // setTimeout(() => {
    //   animatePointer();
    // }, 500);
    // setTimeout(() => {
    //   animatePointer();
    // }, 1000);
  }, []);

  useEffect(() => {
    animatePointer();
  }, [value, items]);

  return (
    <div className={$class('ui-tabs', className)} style={style}>
      <div className="ui-tabs-wrap">
        <animated.div style={springStyles} className="ui-tabs-pointer"></animated.div>
        {items.map(({ value: v, label, hidden }, i) => (
          <div
            key={v}
            style={{ flex: isTabsFlex ? 1 : undefined, display: hidden && 'none' }}
            className={$class('ui-tabs__item', ['active', v === value])}
            ref={(el) => (itemsRef.current[i] = el)}>
            <Clickable onClick={() => onChange(v)}>{label}</Clickable>
          </div>
        ))}
      </div>
      <div className="ui-tabs-content">{children}</div>
    </div>
  );
};

export default Tabs;
