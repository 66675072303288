import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import Utils from '../../../Utils';
import { BackButton } from '../../../blocks';
import Lang from '../../Lang';
import modalStore from '../../ModalManager/modalStore';
import AccountHistoryBlock from '../AccountHistoryBlock';
import AccountItem from '../AccountItem';
import AccountStore from '../AccountStore';
import styles from '../Accounts.module.scss';

const TradingAccount = ({ visible }) => {
  useEffect(() => {
    if (!visible) return;

    AccountStore.getTrading();
    AccountStore.getAccounts();
  }, [visible]);

  return (
    <>
      <BackButton
        label={Lang({ str: 'accounts', section: 'accounts' })}
        style={{
          position: 'sticky',
          top: 0,
          background: '#f5f6f7',
          zIndex: 10,
        }}
      />

      <div className={styles.account_page}>
        <AccountItem
          title={
            Utils.apiDomain() === 'app2.geekoinvest.kz'
              ? Lang({ str: 'trading_acc2', section: 'accounts' })
              : Lang({ str: 'trading_acc', section: 'accounts' })
          }
          price={AccountStore.demoBalance}
          invest1={AccountStore.cost_basis}
          link1={Lang({ str: 'investments', section: 'onboarding' })}
          invest2={AccountStore.cash}
          link2={Lang({ str: 'cash', section: 'wallet' })}
          number={AccountStore.accounts.brokerage.number}
          button3
          src3={'/accounts/between_account'}
          buttons
          links
          link1click={() =>
            modalStore.openModal('helper', {
              title: Lang({ str: 'my_investments', section: 'wallet' }),
              text: Lang({ str: 'my_investments_are', section: 'wallet' }),
            })
          }
          link1click2={() =>
            modalStore.openModal('helper', {
              title: Lang({ str: 'cash', section: 'wallet' }),
              text: Lang({ str: 'cash_is', section: 'wallet' }),
            })
          }
        />

        <AccountHistoryBlock typeAccount="trading" visible={visible} />
      </div>
    </>
  );
};

export default observer(TradingAccount);
