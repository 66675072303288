import { LoadingOutlined } from '@ant-design/icons';
import SumsubWebSdk from '@sumsub/websdk-react';
import { Button, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { apiQueryError, apiRequest } from '../../Utils';
import AppStore from '../App/AppStore';
import styles from '../BrokerAccountNew/BrokerAccountNew.module.scss';
import Lang from '../Lang';

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#7A24EA' }} spin />;

const SumSubUpdate = ({ levelName, resetStep, onFinish }) => {
  const [updateBtn, setUpdateBtn] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [continueBtn, setContinueBtn] = useState(false);
  const [load, setLoad] = useState(false);
  const [sumSubData, setSumSubData] = useState({});

  const errorHandler = (e) => {
    console.log(e);
    setContinueBtn(true);
    apiRequest({
      url: '/private/v1/SumSubError',
      method: 'POST',
      body: e,
    }).catch();
  };
  const updateAccessToken = useCallback(() => {
    console.log('updateAccessToken');
    apiRequest({
      url: '/private/v1/trader/SumSubUpdateAccessToken',
      method: 'POST',
      body: { levelName, resetStep },
      success: (data) => {
        setAccessToken(data.accessToken);
      },
      error: (error) => {
        apiQueryError({ error });
      },
    }).catch();
  }, [levelName, resetStep]);

  const handler = (e) => {
    console.log(e);
    setUpdateBtn(true);
  };
  const messageHandler = (type, payload) => {
    console.log({ type, payload });
    let data = {};
    if (type === 'idCheck.applicantStatus' || type === 'idCheck.onApplicantLoaded') {
      data[type] = payload;
    }
    console.log({ type });

    if (type === 'idCheck.applicantStatus') {
      if (payload.reviewStatus === 'completed' && payload?.reviewResult?.reviewAnswer === 'GREEN') {
        setContinueBtn(true);
      }
      // if (payload.reviewStatus === 'pending' ) {
      //   setContinueBtn(true)
      // }
    }
    setSumSubData((val) => {
      for (let key of Object.keys(data)) val[key] = data[key];
      console.log(val);
      return val;
    });
  };

  const onComplete = () => {
    setLoad(true);
    onFinish(sumSubData, setLoad);
  };

  useEffect(() => {
    updateAccessToken();
  }, [updateAccessToken]);

  if (!accessToken)
    return (
      <div
        style={{
          textAlign: 'center',
          padding: 20,
        }}>
        <Spin indicator={antIcon} />
      </div>
    );

  return (
    <div className="bacc-section bacc-section-sumsub">
      {updateBtn && (
        <Button
          onClick={() => {
            setUpdateBtn(false);
            updateAccessToken();
          }}>
          Обновить
        </Button>
      )}

      <SumsubWebSdk
        accessToken={accessToken}
        expirationHandler={handler}
        config={{
          lang: AppStore.lang,
        }}
        options={{}}
        onMessage={messageHandler}
        onError={errorHandler}
        updateAccessToken={updateAccessToken}
      />
      {continueBtn && (
        <Button
          type="primary"
          htmlType="submit"
          onClick={onComplete}
          className={styles.gr_btn_more}
          loading={load}>
          {Lang({ str: 'continue', section: 'onboarding' })}
        </Button>
      )}
    </div>
  );
};

export default SumSubUpdate;
