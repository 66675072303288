import React, { useRef } from 'react';
import './index.scss';

const NumberInput = ({
  id,
  name,
  inputMode = 'decimal',
  onChange,
  value,
  fractionalMax = 2,
  ...props
}) => {
  const ref = useRef();
  const prev = useRef();

  const formatSpaces = (v) => {
    if (!v) return '0';
    const regex = /\B(?=(\d{3})+(?!\d))/g;

    v = v.toString();

    if (v.includes('.')) {
      const [a, b] = v.split('.')
      return `${a.replace(regex, ' ')}.${b}`
    }

    return v.replace(regex, ' ');
  };

  const onChangeHandler = (e) => {
    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;
    let value = e.target.value;
    
    if (value === '') return onChange('0');

    value = value.replaceAll(',', '.');
    value = value.replaceAll(' ', '');
    value = value.replaceAll('+', '');
    value = value.replaceAll('-', '');
    // if (value.length > 2) value = value.replace(/^0+/, "")

    if (value.length > 20) return;
    if (value.includes('.') && value.split('.')[1].length > fractionalMax) return;
    if (isNaN(value)) return console.warn({ value });

    if (value.length === 2 && value[0] === '0' && value[1] !== '.') value = value[1];

    onChange(value);

    // Reset the cursor
    if (value !== '')
      setTimeout(() => {
        const num = formatSpaces(value).split(' ').length - 1;
        const prevNum = formatSpaces(prev.current).split(' ').length - 1;

        ref.current.setSelectionRange(start + num - prevNum, end + num - prevNum);

        prev.current = value;
      }, 0);
  };

  return (
    <div className="ui-number-input">
      <input
        ref={ref}
        id={id}
        name={name}
        type="text"
        inputMode={inputMode}
        value={formatSpaces(value)}
        onChange={onChangeHandler}
        {...props}
      />
    </div>
  );
};

export default NumberInput;
