import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import Utils from '../../Utils';
import addicon from '../../assets/images/addplus.svg';
import perevod2 from '../../assets/images/perevod2.svg';
import perevod3 from '../../assets/images/perevod3.svg';
import DollarSign from '../../blocks/DollarSign';
import Lang from '../Lang';
import modalStore from '../ModalManager/modalStore';
import NumberFormat from '../NumberFormat';
import styles from './Accounts.module.scss';

const ItemHistoryBlock = (params) => {
  const { writeOff, credit, commissionAmount, typeTransfer, createdAt, data, status } = params.data;

  let description, value, mark, statusText, img, fontcolor, fromAccDesc, toAccDesc;

  let displayCommission = false;
  let transferBlock = false;

  switch (status) {
    case 'wait':
      statusText = Lang({ str: 'pending', section: 'accounts' });
      break;
    case 'cancel':
      statusText = Lang({ str: 'cancelled', section: 'accounts' });
      break;
    case 'error':
      statusText = Lang({ str: 'error', section: 'default' });
      break;
    case 'done':
      statusText = Lang({ str: 'done', section: 'accounts' });
      break;
  }

  switch (typeTransfer) {
    case 'deposit':
      description =
        Utils.apiDomain() === 'app2.geekoinvest.kz'
          ? Lang({ str: 'replenishment_current_acc2', section: 'accounts' })
          : Lang({ str: 'replenishment_current_acc', section: 'accounts' });
      value = credit;
      mark = '+';
      // statusText = "Исполнено";
      img = addicon;
      fontcolor = 'plus';
      break;
    case 'transferFromTradingAccount':
      description =
        Utils.apiDomain() === 'app2.geekoinvest.kz'
          ? Lang({ str: 'transfer_from_trading2', section: 'accounts' })
          : Lang({ str: 'transfer_from_trading', section: 'accounts' });
      value = credit;
      mark = '+';
      displayCommission = true;
      transferBlock = true;
      // statusText = "Ожидает";
      img = perevod2;
      fontcolor = 'plus';
      toAccDesc = Lang({ str: 'broker_acc', section: 'accounts' });
      fromAccDesc = Lang({ str: 'trading_acc', section: 'accounts' });
      break;
    case 'transferToTradingAccount':
      description =
        Utils.apiDomain() === 'app2.geekoinvest.kz'
          ? Lang({ str: 'transfer_to_trading2', section: 'accounts' })
          : Lang({ str: 'transfer_to_trading', section: 'accounts' });
      value = writeOff;
      mark = '-';
      displayCommission = true;
      transferBlock = true;
      fontcolor = 'minus';
      // statusText = "Ожидает";
      img = perevod2;
      fromAccDesc = Lang({ str: 'broker_acc', section: 'accounts' });
      toAccDesc = Lang({ str: 'trading_acc', section: 'accounts' });
      break;

    case 'transferFromCurrentAccount':
      description =
        Utils.apiDomain() === 'app2.geekoinvest.kz'
          ? Lang({ str: 'transfer_from_current2', section: 'accounts' })
          : Lang({ str: 'transfer_from_current', section: 'accounts' });

      value = credit;
      mark = '+';
      displayCommission = true;
      transferBlock = true;
      // statusText = "Ожидает";
      fontcolor = 'plus';
      img = perevod2;
      fromAccDesc = Lang({ str: 'broker_acc', section: 'accounts' });
      toAccDesc = Lang({ str: 'trading_acc', section: 'accounts' });
      break;

    case 'transferToCurrentAccount':
      description =
        Utils.apiDomain() === 'app2.geekoinvest.kz'
          ? Lang({ str: 'transfer_to_current2', section: 'accounts' })
          : Lang({ str: 'transfer_to_current', section: 'accounts' });
      value = writeOff;
      mark = '-';
      displayCommission = true;
      transferBlock = true;
      // statusText = "Ожидает";
      img = perevod2;
      fontcolor = 'minus';
      toAccDesc = Lang({ str: 'broker_acc', section: 'accounts' });
      fromAccDesc = Lang({ str: 'trading_acc', section: 'accounts' });
      break;
    case 'saleOfSecurities':
      description = Lang({ str: 'sale_securities', section: 'accounts' }) + ' ' + data.symbol;
      value = credit;
      mark = '+';
      displayCommission = true;
      // statusText = "Ожидает";
      img = perevod3;
      fontcolor = 'plus';
      break;
    case 'purchaseOfSecurities':
      description = Lang({ str: 'purchase_securities', section: 'accounts' }) + ' ' + data.symbol;
      value = writeOff;
      mark = '-';
      displayCommission = true;
      // statusText = "Ожидает";
      img = addicon;
      fontcolor = 'minus';

      break;
    case 'commissionForSaleOfSecurities':
      description = Lang({ str: 'sale_fee', section: 'accounts' }) + ' ' + data.symbol;
      value = writeOff;
      mark = '-';
      // statusText = "Ожидает";
      img = addicon;
      fontcolor = 'minus';
      break;
    case 'commissionForPurchaseOfSecurities':
      description = Lang({ str: 'purchase_fee', section: 'accounts' }) + ' ' + data.symbol;
      value = writeOff;
      mark = '-';
      // statusText = "Ожидает";
      img = addicon;
      fontcolor = 'minus';
      break;
    case 'commissionForTransferringFromCurrentAccount':
      description = Lang({
        str: 'transfer_from_current_fee',
        section: 'accounts',
      });
      value = writeOff;
      mark = '-';
      // statusText = "Ожидает";
      img = perevod2;
      fontcolor = 'minus';
      break;
    case 'commissionForTransferringToCurrentAccount':
      description = Lang({
        str: 'transfer_to_current_fee',
        section: 'accounts',
      });
      value = writeOff;
      mark = '-';
      // statusText = "Ожидает";
      img = perevod2;
      fontcolor = 'minus';
      break;
    case 'withdrawal':
      description = Lang({ str: 'withdrawal_funds', section: 'accounts' });
      value = writeOff;
      mark = '-';
      // statusText = "Ожидает";
      img = perevod3;
      fontcolor = 'minus';
      break;
    case 'FILL': //	Исполнение заказов (как частичное, так и полное исполнение)
      description = 'Order fills (both partial and full fills)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'TRANS': //	Кассовые операции (как CSD, так и CSW)
      description = 'Cash transactions (both CSD and CSW)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'MISC': //	Разные или редко используемые типы действий (все типы, кроме типов TRANS, DIV или FILL)
      description =
        'Miscellaneous or rarely used activity types (All types except those in TRANS, DIV, or FILL)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'ACATC': //	ACATS ВХОД/ВЫХОД (Наличными)
      description = 'ACATS IN/OUT (Cash)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'ACATS': //	ACATS IN/OUT (Ценные бумаги)
      description = 'ACATS IN/OUT (Securities)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'CFEE': //	Крипто комиссия
      description = 'Crypto fee';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'CSD': //	Залог наличными(+)
      description = 'Cash deposit(+)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'CSW': //	Выдача наличных(-)
      description = 'Cash withdrawal(-)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'DIV': //	Дивиденды
      description = 'Dividends';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'DIVCGL': //	Дивиденды (долгосрочный прирост капитала)
      description = 'Dividend (capital gain long term)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'DIVCGS': //	Дивиденды (краткосрочный прирост капитала)
      description = 'Dividend (capital gain short term)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'DIVFEE': //	Дивидендная плата
      description = 'Dividend fee';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'DIVFT': //	Скорректированные дивиденды (удержанный иностранный налог)
      description = 'Dividend adjusted (Foreign Tax Withheld)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'DIVNRA': //	Дивиденды скорректированы (удержано НРА)
      description = 'Dividend adjusted (NRA Withheld)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'DIVROC': //	Дивидендный возврат капитала
      description = 'Dividend return of capital';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'DIVTW': //	Скорректированные дивиденды (удержанная Тефра)
      description = 'Dividend adjusted (Tefra Withheld)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'DIVTXEX': //	Дивиденды (освобождены от налога)
      description = 'Dividend (tax exempt)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'FEE': //	Комиссия выражена в долларах США
      description = 'Fee denominated in USD';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'INT': //	Проценты (кредит/маржа)
      description = 'Interest (credit/margin)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'INTNRA': //	Скорректированные проценты (удержано НРА)
      description = 'Interest adjusted (NRA Withheld)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'INTTW': //	Скорректированная процентная ставка (удержанная Тефра)
      description = 'Interest adjusted (Tefra Withheld)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'JNL': //	запись в журнале
      description = 'Journal entry';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'JNLC': //	Запись в журнале (наличные)
      description = 'Journal entry (cash)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'JNLS': //	Запись в журнале (акции)
      description = 'Journal entry (stock)';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'MA': //	Слияние/поглощение
      description = 'Merger/Acquisition';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'NC': //	Изменение имени
      description = 'Name change';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'OPASN': //	Назначение опции
      description = 'Option assignment';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'OPEXP': //	Срок действия опциона
      description = 'Option expiration';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'OPXRC': //	Опционное исполнение
      description = 'Option exercise';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'PTC': //	Пройти через зарядку
      description = 'Pass Thru Charge';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'PTR': //	Сквозная скидка
      description = 'Pass Thru Rebate';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'REORG': //	Реорг Калифорния
      description = 'Reorg CA';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'SC': //	Изменение символа
      description = 'Symbol change';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'SSO': //	Дополнительный доход акций
      description = 'Stock spinoff';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
    case 'SSP': //	Дробление акций
      description = 'Stock split';
      value = Math.abs(data.net_amount);
      mark = data.net_amount < 0 ? '-' : '+';
      // statusText = "Ожидает";
      img = data.net_amount < 0 ? perevod3 : addicon;
      fontcolor = data.net_amount < 0 ? 'minus' : 'plus';
      break;
  }

  const handleClick = (event) => {
    modalStore.openModal('itemHistoryDetails', {
      img,
      description,
      createdAt,
      typeTransfer,
      data,
      statusText,
      toAccDesc,
      fromAccDesc,
      transferBlock,
      value,
      displayCommission,
      commissionAmount,
      status,
    });
  };

  return (
    <>
      <div onClick={handleClick} className={styles.output_bl_item}>
        <div className={styles.flex}>
          <div className={styles.flex} style={{ paddingRight: 5 }}>
            <img src={img} alt="" />
            <div>
              <p>{description}</p>
              <span>{moment(createdAt).format('DD.MM.YYYY')}</span>
            </div>
          </div>
          <b className={fontcolor} style={{ whiteSpace: 'nowrap' }}>
            {mark}{' '}
            <DollarSign
              color={fontcolor === 'plus' ? 'var(--misk-success)' : 'var(--misk-error)'}
              size={7}
              top={2}
              right={1}
            />
            {NumberFormat({
              value: value,
            })}
          </b>
        </div>
      </div>
      {/* <Modal
        visible={modalOpen}
        okVisible={false}
        onOk={() => {
          setModalOpen(false);
        }}
        onCancel={onCancel}
        cancelVisible={false}
        className2={styles.auto_height}
        className={styles.auto_height2}
        newheight="auto"
        newheightcontent="auto"
      >
        <div className={styles.modal_history}>
          <div className={styles.flex}>
            <img src={img} />
            <div>
              <p>{description}</p>
              <span>
                {moment(createdAt).format("HH:mm")},{" "}
                {moment(createdAt).format("DD.MM.YYYY")}
              </span>
            </div>
          </div>

          <div className={styles.tabl_bl}>
            {![
              "deposit", // пополнение
              "transferFromTradingAccount", // перевод с торгового счета
              "transferToTradingAccount", // перевод на торговый счет
              "transferFromCurrentAccount", // перевод с текущего счета
              "transferToCurrentAccount", // перевод на текущий счета
              "saleOfSecurities", // продажа бумаг
              "purchaseOfSecurities", // покупка бумаг
              "commissionForSaleOfSecurities", // комиссия за продажу бумаг
              "commissionForPurchaseOfSecurities", // комиссия за покупку бумаг
              "commissionForTransferringFromCurrentAccount", // комиссия за перевод с текущего счета
              "commissionForTransferringToCurrentAccount", // комиссия за перевод на текущий счет
              "withdrawal",
            ].includes(typeTransfer) ? (
              <>
                {Object.keys(data)
                  .filter(
                    (key) =>
                      ![
                        "account_id",
                        "event_id",
                        "event_ulid",
                        "id",
                        "system_date",
                      ].includes(key)
                  )
                  .map((key) => {
                    return data[key] ? (
                      <div className={styles.tabl_bl_one}>
                        <p>{key}:</p>
                        <span>
                          {key === "at"
                            ? moment(data[key]).format("HH:mm") +
                              ", " +
                              moment(data[key]).format("DD.MM.YYYY")
                            : data[key]}
                        </span>
                      </div>
                    ) : (
                      <></>
                    );
                  })}
              </>
            ) : (
              <>
                {transferBlock ? (
                  <>
                    <div className={styles.tabl_bl_one}>
                      <p>{Lang({ str: "from_2", section: "accounts" })}:</p>
                      <span>
                        {fromAccDesc} №{data?.from || ""}
                      </span>
                    </div>
                    <div className={styles.tabl_bl_one}>
                      <p>{Lang({ str: "to_2", section: "accounts" })}:</p>
                      <span>
                        {toAccDesc} №{data?.to || ""}
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className={styles.tabl_bl_one}>
                  <p>{Lang({ str: "amount", section: "accounts" })}:</p>
                  <span>${value}</span>
                </div>
                {displayCommission ? (
                  <div className={styles.tabl_bl_one}>
                    <p>{Lang({ str: "fee", section: "accounts" })}:</p>
                    <span>${commissionAmount}</span>
                  </div>
                ) : (
                  <></>
                )}
                <div className={styles.tabl_bl_one}>
                  <p>
                    {Lang({ str: "order_date_time", section: "accounts" })}:
                  </p>
                  <span>
                    {moment(createdAt).format("HH:mm")},{" "}
                    {moment(createdAt).format("DD.MM.YYYY")}
                  </span>
                </div>
                <div className={styles.tabl_bl_one}>
                  <p>{Lang({ str: "order_status", section: "accounts" })}:</p>
                  <span>{statusText}</span>
                </div>
                {[
                  "transferFromTradingAccount",
                  "transferToTradingAccount",
                  "transferFromCurrentAccount",
                  "transferToCurrentAccount",
                  "withdrawal",
                ].includes(typeTransfer) &&
                  status === "wait" && (
                    <button>
                      {Lang({ str: "cancel_order", section: "accounts" })}
                    </button>
                  )}
              </>
            )}
          </div>
        </div>
      </Modal> */}
    </>
  );
};

export default observer(ItemHistoryBlock);
