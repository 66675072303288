import React, {useEffect} from "react";
import styles from "./BrokerAccountNew.module.scss";
import {Space, Button} from "antd";
import { observer } from "mobx-react-lite";
import BrokerAccountStore from "./BrokerAccountStore";
import Lang from "../Lang";

const Page2 = ({setBack}) => {

  const onStepComplete = (step) => {
    let body = {
      view: "address",
      step: step,
    };
    BrokerAccountStore.save(body);
  };

  useEffect(() => {
    setBack({
      view: "sumSub",
      step: 1
    })
  }, [])

  return (
    <div className={styles.page_fl}>
      <div className={styles.text_bl}>
        <h4 className="bacc-title">{Lang({ str: "address_verification", section: "onboarding" })}</h4>
        <p className={styles.black_text}>
          {Lang({ str: "address_verification_2", section: "onboarding" })}
        </p>
        <div style={{ marginTop: 20 }}>
          <Space className={styles.bacc_block}>
            <Button className={styles.bacc_block_no} style={{width: 100}} onClick={() => onStepComplete(3)}>{Lang({ str: "no", section: "default" })}</Button>
            <Button className={styles.bacc_block_yes} style={{width: 100}} onClick={() => onStepComplete(2)}>{Lang({ str: "yes", section: "default" })}</Button>
          </Space>
        </div>
        <div className={styles.error_block}>
          <p>
            {Lang({ str: "address_verification_warning", section: "onboarding" })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default observer(Page2);
