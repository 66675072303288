import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from '../../UI';
import iconFavoriteActive from '../../assets/images/iconFavoriteActive.svg';
import iconFavoriteLoad from '../../assets/images/iconFavoriteLoad.svg';
import iconFavoriteNoActive from '../../assets/images/iconFavoriteNoActive.svg';
import icondelete from '../../assets/images/icondelete.svg';
import { BackButton } from '../../blocks';
import urlStore from '../../stores/urlStore';
import Lang from '../Lang/index';
import BankAccountsStore from './BankAccountsStore';
import styles from './PersonalInformation.module.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 18, color: '#959595' }} spin />;

const MyAccounts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const items = BankAccountsStore.items;
  const [load, setLoad] = useState(false);
  const [favoriteLoad, setFavoriteLoad] = useState(false);

  useEffect(() => {
    urlStore.setSection('');
    window.scroll(0, 0);
    setLoad(true);
    BankAccountsStore.list()
      .then(() => setLoad(false))
      .catch(() => setLoad(false));
  }, []);

  return (
    <div className={styles.personal_information_wrp}>
      <BackButton sticky />

      <div className={styles.personal_information + ' ' + styles.horizont_in + ' mb-24'}>
        <Row justify={'space-between'} className="mb-8">
          <Col>
            <p className={styles.titile}>{Lang({ str: 'bank_accounts', section: 'menu' })}</p>
          </Col>
          <Col>
            <IconButton
              variant="contained"
              icon="plus"
              iconSize={16}
              style={{ backgroundColor: '#7A24EA', borderColor: '#7A24EA' }}
              onClick={() => navigate('/user/my_accounts/add_account', { state: location })}
            />
          </Col>
        </Row>
        <div className={styles.security_link}>
          {load && <Spin indicator={antIcon} />}
          {!load &&
            items.map((item, index) => (
              <div
                key={index}
                className={styles.one_account}
                style={
                  item.favorite
                    ? { backgroundColor: '#fff', borderRadius: index === 0 && '16px 16px 0 0' }
                    : null
                }>
                <div className={styles.but_gr1}>
                  {item.favorite && <div>{Lang({ str: 'main', section: 'menu' })}</div>}
                  <p>{Lang({ str: 'acc', section: 'menu' })} {BankAccountsStore.banks[item.bankRecipient]}</p>
                  <p>****{item.iban.slice(-4)}</p>
                </div>
                <div className={styles.but_gr}>
                  {favoriteLoad && (
                    <button style={{ position: 'relative' }}>
                      <img src={iconFavoriteLoad} alt={''} />
                      <Spin indicator={antIcon} style={{ position: 'absolute', left: 8, top: 8 }} />
                    </button>
                  )}
                  {!favoriteLoad && (
                    <>
                      <button
                        onClick={() => {
                          setFavoriteLoad(true);
                          BankAccountsStore.favorite({
                            id: item._id,
                            favorite: !item.favorite,
                          })
                            .then(() => {
                              setFavoriteLoad(false);
                            })
                            .catch(() => {
                              setFavoriteLoad(false);
                            });
                        }}>
                        <img
                          src={item.favorite ? iconFavoriteActive : iconFavoriteNoActive}
                          alt={''}
                        />
                      </button>
                    </>
                  )}

                  <button
                    onClick={() => {
                      navigate('/user/my_accounts/edit_account/' + item._id, { state: location });
                    }}>
                    <img src={icondelete} alt={''} />
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default observer(MyAccounts);
