import { LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useDelayRender } from '../../Utils';
import CollectionCard from '../../blocks/CollectionCard';
import urlStore from '../../stores/urlStore';
import userStore from '../Auth/userStore';
import CollectionItem2 from '../CollectionItem/CollectionItem2';
import Header from '../Header';
import Lang from '../Lang/index';
import TitleCategory from '../TitleCategory';
import styles from './Portfolio.module.scss';
import PortfolioStore from './PortfolioStore';

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#7A24EA' }} spin />;

const Portfolio = () => {
  useEffect(() => {
    urlStore.setSection('favorites');
    window.scroll(0, 0);
    PortfolioStore.setCollectionMyEdit(false);
  }, []);

  if (userStore.auth === false) return null;

  return (
    <div className={styles.favorit_wrp}>
      <Header />

      <GeekoCollections />
      <div className={'mt-16'}>
        <div className={styles.tabs_panell}>
          <TitleCategory
            title={<p>{Lang({ str: 'my_collections', section: 'portfolio' })}</p>}
            buttons={true}
            type={'collectionMy'}
            edit={'collectionMyEdit'}
          />
        </div>
        <MyCollections />
      </div>
    </div>
  );
};

const GeekoCollections = observer(() => {
  const show = useDelayRender();
  const geekoCollections = PortfolioStore.collectionGeekoRows || Array(10).fill();

  useEffect(() => {
    PortfolioStore.collectionGeekoLoad().catch();
  }, []);

  return (
    <div className={styles.geekoCollections}>
      <TitleCategory title={<p>{Lang({ str: 'geeko_collections', section: 'portfolio' })}</p>} />
      <div className={styles.geekoCollectionsWrap}>
        {geekoCollections.slice(0, 10).map((item, index) => (
          <CollectionItem2 key={index} tickerId={item} type={2} />
        ))}
      </div>
    </div>
  );
});

const MyCollections = observer(() => {
  const show = useDelayRender();
  const myCollections = PortfolioStore.collectionMyRows || Array(10).fill();

  useEffect(() => {
    PortfolioStore.collectionMyLoad().catch();
  }, []);

  if (!show) return null;

  return (
    <div className={styles.myCollections}>
      {myCollections.map((item, index) => (
        <CollectionCard key={index} tickerId={item} />
      ))}
    </div>
  );
});

export default observer(Portfolio);
