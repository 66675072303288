import { Carousel } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import close from '../../assets/images/close123.svg';
import icon1 from '../../assets/images/iconslider1.svg';
import icon2 from '../../assets/images/iconslider2.svg';
import icon3 from '../../assets/images/iconslider3.svg';
import styles from '../BrokerAccountNew/BrokerAccountNew.module.scss';
import Logo from '../Logo';
import Lang from "../Lang";

const Slider = () => {
  const location = useLocation();

  const [currentPath, setCurrentPath] = useState('');

  const navigate = useNavigate();
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  {
    /*useEffect(() => {
    setCurrentPath(location.pathname);
    if (currentPath == "/slider") {
      document.body.style.background = "red";
    } else{
      document.body.style.background = "blue";
    }
  }, [location]);
*/
  }

  return (
    <div className={styles.container}>
      <div className={styles.fonn}></div>
      <div className={styles.page_slider}>
        <div className={styles.hesd_slide}>
          <Logo />
          <a onClick={() => navigate('/')}>
            <img src={close} />
          </a>
        </div>
        <Carousel
          dotPosition={'top'}
          className={styles.slider_b}
          afterChange={onChange}
          autoplay
          swipeToSlide
          draggable
          waitForAnimate={true}>
          <div>
            <div className={styles.flex_bl}>
              <p>
                <b>{Lang({ str: 'slider_1', section: 'onboarding' })}</b>
                {Lang({ str: 'slider_2', section: 'onboarding' })}
              </p>
              <img src={icon1} />
            </div>
          </div>
          <div>
            <div className={styles.flex_bl}>
              <p>
                <b>{Lang({ str: 'slider_3', section: 'onboarding' })}</b>
                {Lang({ str: 'slider_4', section: 'onboarding' })}
              </p>
              <img src={icon2} />
            </div>
          </div>
          <div>
            <div className={styles.flex_bl}>
              <p>
                <b>{Lang({ str: 'slider_5', section: 'onboarding' })}</b>
                {Lang({ str: 'slider_6', section: 'onboarding' })}
              </p>
              <img src={icon3} />
            </div>
          </div>
        </Carousel>

        <div className={styles.buttonBlockFixed}>
          <button onClick={() => navigate('/broker_account')} className={styles.start_but1}>
            {Lang({ str: "start_verification", section: "default" })}
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(Slider);
