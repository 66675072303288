import { Form, Input } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../UI';
import { apiQuery, apiQueryError } from '../../Utils';
import left_icon from '../../assets/images/arrowmenu.svg';
import { BackButton } from '../../blocks';
import urlStore from '../../stores/urlStore';
import AlertStore from '../Alert/AlertStore';
import userStore from '../Auth/userStore';
import HeaderTitle from '../Header/HeaderTitle';
import Lang from '../Lang';
import styles from './PersonalInformation.module.scss';

const propsReactCodeInput = {
  inputStyle: {
    margin: '6px 3% 6px 0',
    width: '40px',
    fontSize: '22px',
    height: 40,
    backgroundColor: '#EAEAEA',
    border: 0,
    borderRadius: '4px',
    textAlign: 'center',
    color: '#252525',
  },
};

const Resend = () => {
  const [sec, setSec] = useState(0);
  const [btnVisible, setBtnVisible] = useState(false);

  const onLoad = useCallback((s) => {
    setSec(s);
    apiQuery('/private/v1/email_captcha', {
      method: 'POST',
      body: {
        mode: 'password',
      },
    })
      .then(() => {
        AlertStore.success(Lang({ str: 'email_sent', section: 'menu' }));
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  }, []);

  useEffect(() => {
    const time = setInterval(() => {
      setSec((v) => {
        if (v === 0) return 0;
        if (v === 1) {
          setBtnVisible(true);
          return v - 1;
        }
        setBtnVisible(false);
        return v - 1;
      });
    }, 1000);

    return () => {
      clearInterval(time);
    };
  }, []);

  useEffect(() => {
    onLoad(30);
  }, [onLoad]);

  return (
    <>
      {!btnVisible && (
        <p style={{ color: '#A8A9AD' }}>
          {Lang({ str: 'resend_in', section: 'menu' })} {sec}{' '}
          {Lang({ str: 'seconds', section: 'menu' })}
        </p>
      )}
      {btnVisible && (
        <p>
          <span style={{ color: '#A8A9AD', cursor: 'pointer' }} onClick={() => onLoad(30)}>
            {Lang({ str: 'resend', section: 'menu' })}
          </span>
        </p>
      )}
    </>
  );
};

const VerifyEmail = observer(({ values }) => {
  const navigate = useNavigate();
  const [code, setCode] = useState('');

  const onFinish = () => {
    if (code.length !== 6) return AlertStore.error(Lang({ str: 'enter_code', section: 'menu' }));

    values.code = code;

    apiQuery('/private/v1/updatePassword', {
      method: 'POST',
      body: values,
    })
      .then((data) => {
        if (data?.error) return AlertStore.error(data.error.msg);
        AlertStore.success(Lang({ str: 'pass_changed', section: 'menu' }));
        userStore.authCheck().catch();
        navigate('/user/security');
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };
  const onChange = (v) => {
    setCode(v);
  };

  return (
    <div>
      <HeaderTitle
        title={Lang({ str: 'enter_conf_code', section: 'menu' })}
        button1={<img src={left_icon} alt={''} />}
      />
      <div className={styles.security_wrp + ' mt-32'}>
        <div className={styles.width_vv}>
          <p className="pb-16">
            {Lang({ str: 'enter_code_email', section: 'menu' })} {userStore.userData?.email}
          </p>
        </div>
      </div>

      <Form name="normal_login" className="login-form" onFinish={onFinish}>
        <div
          style={{
            display: 'block',
            margin: '20px auto',
            width: '100%',
            textAlign: 'center',
          }}>
          <ReactCodeInput
            type="number"
            fields={6}
            name="code"
            inputMode="numeric"
            value={code}
            onChange={onChange}
            autoComplete={'one-time-code'}
            style={{ width: 295 }}
            {...propsReactCodeInput}
          />
        </div>

        <Form.Item>
          <Resend />
        </Form.Item>

        <Form.Item>
          <Button className={styles.button_save} htmlType="submit">
            {Lang({ str: 'send', section: 'default' })}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
});

const ChangePassword = ({ visible }) => {
  const [values, setValues] = useState({});
  const [stage, setStage] = useState(1);
  const [form] = Form.useForm();

  const onFinish = (v) => {
    apiQuery('/private/v1/checkPassword', {
      method: 'POST',
      body: v,
    })
      .then((data) => {
        if (data.error) {
          return AlertStore.error(data.error.msg);
        }
        setValues(v);
        setStage(2);
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  useEffect(() => {
    urlStore.setSection('');
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (!visible) {
      setValues({});
      setStage(1);
    }
    urlStore.setSection('');
    window.scroll(0, 0);
  }, [visible]);

  return (
    <div className={styles.personal_information_wrp}>
      <BackButton />

      {stage === 1 && (
        <>
          <div className={styles.security_wrp}>
            <p className={styles.titile}>{Lang({ str: 'pass_change', section: 'menu' })}</p>
            <div className={styles.width_vv}>
              <p className="pb-16">{Lang({ str: 'sessions_terminated', section: 'menu' })}</p>
            </div>
          </div>
          <div className={styles.personal_information}>
            <Form
              form={form}
              onFinish={onFinish}
              className={styles.personal_pas + ' ' + styles.personal_pasnoborder}
              layout="vertical">
              <Form.Item
                name="oldPassword"
                rules={[
                  { required: true, message: `${Lang({ str: 'current_pass', section: 'menu' })}!` },
                ]}>
                <Input.Password placeholder={Lang({ str: 'current_pass', section: 'menu' })} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: `${Lang({ str: 'new_pass', section: 'menu' })}!`,
                  },
                  {
                    min: 6,
                    message: Lang({ str: 'pass_must_contain', section: 'menu' }),
                  },
                  {
                    validator: (_, value) => {
                      // Проверьте, содержит ли пароль буквы, цифры и специальные символы
                      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{6,}/;
                      if (!regex.test(value)) {
                        return Promise.reject(
                          Lang({ str: 'pass_must_contain_2', section: 'menu' })
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}>
                <Input.Password placeholder={Lang({ str: 'new_pass', section: 'menu' })} />
              </Form.Item>
              <Form.Item
                name="confirm"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: `${Lang({ str: 'new_pass_2', section: 'menu' })}!`,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(Lang({ str: 'passwords_dont_match', section: 'menu' }))
                      );
                    },
                  }),
                ]}>
                <Input.Password placeholder={Lang({ str: 'new_pass_2', section: 'menu' })} />
              </Form.Item>
              <div className={styles.error_block}>
                <p>{Lang({ str: 'pass_must_contain_3', section: 'menu' })}</p>
              </div>
              <Form.Item className={styles.margin_bottom24}>
                <Button htmlType="submit">{Lang({ str: 'change_pass', section: 'menu' })}</Button>
              </Form.Item>
            </Form>
          </div>
        </>
      )}

      {stage === 2 && <VerifyEmail values={values} />}
    </div>
  );
};

export default ChangePassword;
