import {observer} from 'mobx-react-lite';
import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {$class, shimmer} from '../../Utils';
import fav from '../../assets/images/fav.svg';
import ImagePreview from '../ImagePreview';
import PortfolioStore from '../Portfolio/PortfolioStore';
import styles from './CollectionItem2.module.scss';
import CollectionTickerLogo from './CollectionTickerLogo';

function CollectionItem2({tickerId, type = 1, favorites = false}) {
  const navigate = useNavigate();
  const location = useLocation();
  const item = !favorites
    ? PortfolioStore.collectionData[tickerId]
    : {
      logo: fav,
      name: 'Избранное',
      tickers: PortfolioStore.favoritesRows,
      percent: 0,
    };

  // if (!item) return null;
  // if (item.load) return <Spin />;

  console.log(item);

  return (
    <div
      className={$class(styles.geekoCollection, shimmer('block', tickerId === undefined))}
      style={{
        backgroundImage: item?.background ? `url(${item?.background})` : `url(${item?.logo})`,
        backgroundSize: 'cover',
        backgroundRepeat: item?.background && 'repeat',
        backgroundPosition: item?.background && 'center',
      }}
      onClick={() => (tickerId ? navigate(`/favorites/${tickerId}`, {state: location}) : null)}>
      <div>
        <ImagePreview
          src={item?.logo}
          iconNoPhoto={false}
          name={item?.name}
          style={{borderRadius: 4, width: 60, height: 60}}
        />
        <div>
          <p>{item?.name}</p>
          <div>
            {item?.tickers &&
              item?.tickers
                ?.slice(0, 3)
                .map((ticker, i) => <CollectionTickerLogo key={i} tickerId={ticker}/>)}
            {item?.tickers && item?.tickers?.length - 3 > 0 && (
              <div>
                <p>{`+${item?.tickers?.length - 3}`}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        {item?.tickers && item?.tickers?.length > 0 && (
          <>
            <p className={item.percent <= 0 ? 'minus' : item.percent > 0 ? 'plus' : ''}>
              {item.percent <= 0 && '-'}
              {Math.abs(item.percent)}%
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default observer(CollectionItem2);
