import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Modal from "../Modal";
import close1 from "../../assets/images/close1.svg";
import styles from "./Notification.module.scss";
import {Upload, Form, Tabs, message} from "antd";
import {apiQuery, apiQueryError, getBase64} from "../../Utils";
import left_icon from "../../assets/images/erer.png";
import type4 from "../../assets/images/type4.svg";
import moment from "moment";
import DrawerMenuStore from "../DrawerMenu/DrawerMenuStore";
import NotificationStore from "./NotificationStore";
import AppStore from "../App/AppStore";
// import ruLocale from"moment/locale/ru";

// moment.updateLocale('ru', ruLocale);

const NotificationItem = (params) => {
  const {data, getInboxes} = params;

  const navigate = useNavigate()
  const location = useLocation();

  moment.locale(localStorage.getItem("appLang"));
  // console.log(data);
  //   console.log(moment.locale())
  //   console.log('momentaaaaaaaaaaaaaaaaaaaaaaa')
  // const [modalOpen, setModalOpen] = useState(false);
  const [isActive, setIsActive] = useState(data.read);

  const handleClick = (event) => {
    // setModalOpen(true);
    NotificationStore.open({
      id: data._id,
      createdAt: data.createdAt,
      text: data.text || false,
      textV2: data?.textV2 || false
    })
    navigate(`/user/notification/${data._id}`, {state: location})

    if (data.read) {
      return;
    }
    // setTimeout(() => {
    apiQuery("/private/v1/inboxMarkAsRead", {
      method: "POST",
      body: {id: data._id},
    })
      .then((data) => {
        console.log(data);
        getInboxes({});
        setIsActive(true);
        DrawerMenuStore.getCountNotReadInboxes();
      })
      .catch((error) => {
        apiQueryError({error});
      });
    // }, 1000);
  };

  useEffect(() => {
    setIsActive(data.read);
  }, [data.read]);

  return (
    <>
      <div
        onClick={handleClick}
        className={
          isActive
            ? styles.notification_item
            : styles.notification_item + " " + styles.notification_new
        }
      >
        <div className={styles.flex_b}>
          <div className={styles.notification_item__sc}>
            <span>{moment(data.createdAt).format("DD MMMM YYYY, HH:mm")}</span>
            <p>{data?.textV2 && data?.textV2[AppStore.lang]?.short ? data?.textV2[AppStore.lang]?.short : (data?.text?.short || 'Нет данных')}</p>
          </div>
        </div>
      </div>
      {/*<Modal*/}
      {/*  headVisible={false}*/}
      {/*  visible={modalOpen}*/}
      {/*  cancelText={*/}
      {/*    <div className="width_collect">*/}
      {/*      <p className="pl-8">{data?.text?.subject || ""}</p>*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*  cancelVisible={false}*/}
      {/*  okVisible={false}*/}
      {/*  onOk={() => {*/}
      {/*    setModalOpen(false);*/}
      {/*    getInboxes({});*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div className="p-16">*/}
      {/*    <span className={styles.modal_date}>*/}
      {/*      {moment(data.createdAt).format("DD MMMM YYYY, HH:mm")}*/}
      {/*    </span>*/}
      {/*    {data?.text?.long || ""}*/}
      {/*  </div>*/}
      {/*</Modal>*/}
    </>
  );
};

export default NotificationItem;
