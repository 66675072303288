import { useLocation } from 'react-router-dom';
import AppStore from '../App/AppStore';
import { useEffect } from 'react';

const Header = () => {
  const location = useLocation();
  
  useEffect(() => {
    AppStore.showHeader(location.pathname);
  }, [location]);

  return null;
};

export default Header;
