import { React } from 'react';
import logo from '../../assets/images/logo_w.svg';
import styles from './Logo.module.scss';

function Logo() {
  const element = document.querySelector('.basic-routes');
  console.log(element);
  const scrollToTop = () => {
    element.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className={styles.logo}>
      <button onClick={scrollToTop}>
        <img src={logo} alt="logo" />
      </button>
    </div>
  );
}

export default Logo;
