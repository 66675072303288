import React, {useEffect, useRef, useState} from 'react';
import { $class } from '../../Utils';
import Clickable from '../Clickable';
import './index.scss';

const Button = ({
  onClick = f => f,
  className,
  align,
  rippleColor,
  autoWidth,
  size = 'md',
  variant,
  style,
  loading,
  disabled,
  downloadUrl,
  isFixed = false,
  children,
}) => {
  const [keyboardHeight, setKeyboardHeight] = useState(0)
  const [downloading, setDownloading] = useState(false)

  useEffect(() => {
    const handleResize = () => setKeyboardHeight(window.innerHeight - window.visualViewport.height)
    window.visualViewport.addEventListener('resize', handleResize)
    window.addEventListener('blur', () => setKeyboardHeight(0))

    return () => {
      window.visualViewport.removeEventListener('resize', handleResize)
      window.removeEventListener('blur', () => setKeyboardHeight(0))
    }
  }, [])

  // async function handleDownload(url) {
  //   const download = () => {
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.target = "_blank";
  //     link.click();
  //   };
  //   if (navigator.share) {
  //     try {
  //       const res = await navigator.share({ url });
  //       console.log({ res });
  //     } catch (e) {
  //       console.warn(e);
  //       download();
  //     }
  //   } else download();
  // }

  async function handleDownload(url) {
    const download = () => {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.click();
    };
    if (navigator.share) {
      setDownloading(true)
      try {
        fetch(url, {
          credentials: "include"
        })
          .then(async response => {
            // Получаем заголовок Content-Disposition из ответа сервера
            const contentDisposition = response.headers.get('content-disposition');
            console.log('contentDisposition', response)
            let filename = 'file.pdf'; // Имя файла по умолчанию
            try {
              filename = response.headers.get('content-disposition')
                .split(';')
                .find(n => n.includes('filename='))
                .replace('filename=', '')
                .trim()
            } catch (e) {
              console.error(e)
            }

            // Возвращаем объект Blob для дальнейшего использования
            return {filename, blob: await response.blob()};
          })
          .then(async ({filename, blob}) => {

            console.log(blob)
            console.log(filename)
            console.log(blob.type)
            setDownloading(false)

            const file = new File([blob], filename, { type: blob.type });
            await navigator.share({ files: [file] });
          })
          .catch(error => {
            console.error('Ошибка при загрузке файла:', error);
          });
      } catch (e) {
        console.warn(e);
        download();
      }
    } else download();
  }

  return (
    <Clickable
      rippleColor={rippleColor}
      disabled={loading || disabled}
      className={$class(
        'ui-button',
        `ui-button--size_${size}`,
        ['ui-button--text', variant === 'text'],
        ['ui-button--primary', variant === 'primary'],
        ['ui-button--secondary', variant === 'secondary'],
        ['ui-button--outlined', variant === 'outlined'],
        ['ui-button--clear', variant === 'clear'],
        ['ui-button--loading', loading],
        ['ui-button--loading', downloading],
        className
      )}
      onClick={() => {
        downloadUrl && handleDownload(downloadUrl)
        onClick()
      }}
      style={{
        width: autoWidth ? 'fit-content' : undefined,
        position: isFixed && 'fixed',
        bottom: isFixed && `${10 + keyboardHeight}px`,
        ...style
      }}>
      <div className="ui-button-wrap" style={{ justifyContent: align }}>{children}</div>
      <div className="ui-button-loading">
        <span></span>
      </div>
    </Clickable>
  );
};

export default Button;
