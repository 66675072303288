import React, { useEffect, useRef, useState } from 'react';
import { animated, easings, useSpring } from 'react-spring';
import { Clickable, IconButton } from '../../UI';

import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import { TickerCard } from '../../blocks';
import Lang from '../Lang';
import SearchList from '../SearchList';
import TickersCategorySlider from '../TickersCategorySlider';
import TradingStore from '../Trading/TradingStore';
import styles from './index.module.scss';

const SearchScreen = ({ search, topOffset = 0 }) => {
  const open = TradingStore.isSearchOpen;
  const navigate = useNavigate();
  const location = useLocation();

  const [recent, setRecent] = useState(JSON.parse(localStorage.getItem('recent-search')) || []);

  const [screen, setScreen] = useState(window.innerHeight);
  const { height, opacity } = useSpring({
    height: open ? screen - topOffset : 0,
    opacity: +open,
    config: {
      easing: easings.easeInOutSine,
      duration: 200,
      bounce: 0,
    },
  });

  const addRecent = (ticker) => {
    if (!recent.includes(ticker)) setRecent([...recent, ticker]);
  };

  useEffect(() => {
    const changeScreen = () => setScreen(window.innerHeight);
    document.addEventListener('resize', changeScreen);
    return () => document.removeEventListener('resize', changeScreen);
  }, []);

  useEffect(() => {
    localStorage.setItem('recent-search', JSON.stringify(recent));
  }, [recent]);

  return (
    <animated.div style={{ height }} className={styles.screen}>
      {!!recent.length && search.trim() === '' && (
        <div className={styles.recent}>
          <div className={styles.recentTop}>
            <h4>{Lang({ str: 'recent_searches', section: 'home' })}</h4>
            <IconButton onClick={() => setRecent([])} variant="clear" icon="trash" />
          </div>
          <div className={styles.recentList}>
            {recent.map((t) => (
              <TickerChip
                key={t.symbol}
                symbol={t.symbol}
                onClick={() => navigate(`/trading/${t._id}`, { state: location })}
              />
            ))}
          </div>
        </div>
      )}

      {search.trim() === '' && open && (
        <TickersCategorySlider
          delay
          style={{ height: '100%' }}
          ComponentCard={TickerCard}
          count={20}
          virtualized
          items={[
            {
              label: Lang({ str: 'growth', section: 'home' }),
              icon: 'growing',
              category: 'best',
            },
            {
              label: Lang({ str: 'decline', section: 'home' }),
              icon: 'falling',
              category: 'nobest',
            },
            {
              label: 'ETF',
              icon: 'etf',
              category: 'indices',
            },
          ]}
        />
      )}

      {search.trim() !== '' && open && (
        <SearchList search={search} height={'100%'} itemHeight={72 + 16}>
          {(t, key, style) => (
            <TickerCard style={style} onClick={() => addRecent(t)} key={key} tickerId={t._id} />
          )}
        </SearchList>
      )}
    </animated.div>
  );
};

const TickerChip = ({ symbol, onClick }) => (
  <Clickable className={styles.tickerChip} onClick={onClick}>
    {symbol}
  </Clickable>
);

export default observer(SearchScreen);
