import { animated, useSpring } from '@react-spring/web';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import alert_copy from '../../assets/images/alert_copy.svg';
import alert_error from '../../assets/images/alert_error.svg';
import alert_info from '../../assets/images/alert_info.svg';
import alert_success from '../../assets/images/alert_success.svg';
import Lang from '../Lang';
import AlertStore from './AlertStore';
import styles from './index.module.scss';

const AlertList = () => {
  const alertData = AlertStore.activeAlertData;

  return (
    <div className={styles.wrapper}>
      {alertData.map(item => (
        <Alert key={item.id} {...item} />
      ))}
    </div>
  );
};

const Alert = ({ id, type, msg = '', link = '/user/notification', fixedHeight, onClick }) => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const { padding, height, marginBottom, opacity, y } = useSpring({
    opacity: +isOpen,
    height: fixedHeight ? (isOpen ? fixedHeight : 0) : undefined,
    marginBottom: fixedHeight ? (isOpen ? 8 : 0) : undefined,
    padding: fixedHeight ? (isOpen ? 10 : 0) : undefined,
    y: isOpen ? 0 : -4,
  });

  const handleOpen = () => {
    onClick ? onClick() : navigate(link);
    AlertStore.close(id);
  };

  useEffect(() => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
      console.log('CLOSE alert');
    }, 3000);
    setTimeout(() => {
      AlertStore.close(id);
    }, 3500);
  }, []);

  return (
    <animated.div
      className={styles.alert}
      style={{
        marginBottom,
        height,
        opacity,
        y: y.to((x) => `${x}rem`),
        paddingTop: padding,
        paddingBottom: padding,
      }}>
      <div>
        <div>
          <img
            src={
              type.includes('success')
                ? alert_success
                : type.includes('err')
                ? alert_error
                : type.includes('info')
                ? alert_info
                : alert_copy
            }
            alt=""
          />
          <p>
            {type.includes('order') ? (
              Lang({
                str: type.includes('success') ? 'order_issued' : 'order_rejected',
                section: 'trading_modal',
              })
            ) : msg}
          </p>
        </div>
        {type.includes('order') && <p>{msg}</p>}
      </div>
      {type.includes('order') || onClick || link !== '/user/notification' ? (
        <button onClick={() => handleOpen()}>{Lang({ str: 'open', section: 'default' })}</button>
      ) : null}
    </animated.div>
  );
};

export default observer(AlertList);
