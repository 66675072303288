import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Clickable } from "../../UI";
import { $class } from "../../Utils";
import NumberFormat from "../../components/NumberFormat";
import TickerStore from "../../components/Ticker/TickerStore";
import PriceChanging from "../PriceChanging";
import PriceNumber from "../PriceNumber";
import TickerTitle from "../TickerTitle";
import "./index.scss";
import DollarSign from "../DollarSign";
import Lang from "../../components/Lang";

const TickerPaperCard = ({ onClick, className, style, tickerId, paper }) => {
  const ticker = TickerStore.tickerData[tickerId];

  useEffect(() => {
    TickerStore.getTicker(tickerId);
    //setDelCheck(false);
  }, [tickerId]);

  return (
    <div
      className={$class("ticker-card", "ticker-paper-card", className)}
      style={style}
    >
      <Clickable className="ticker-card-wrap" onClick={onClick}>
        <TickerTitle size={50} ticker={ticker} withSymbol withDesc>
          <div className={$class("ticker-paper-card-bottom")}>
            <b>
              {parseFloat((+paper.qty).toString()).toFixed(9)}{" "}
              {/*+(+paper.qty).toFixed(2)*/}
              {Lang({ str: "pieces", section: "default" })}
            </b>{" "}
            <i>
              (<DollarSign color="var(--basic-dark40)" size={7} top={2} />
              {NumberFormat({ value: paper.qty * paper.current_price })})
            </i>
          </div>
        </TickerTitle>
        <div className="ticker-card-price">
          <PriceNumber price={+paper.current_price} align="right" />
          <PriceChanging align="end" change={paper.unrealized_plpc * 100} />
        </div>
      </Clickable>
    </div>
  );
};

export default observer(TickerPaperCard);
