import React from "react";
import Modal from "../ModalSwipe/ModalSwipe";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import Lang from "../Lang";

const PDFModal = ({ visible, onClose, file }) => {
  return (
    <Modal
      visible={visible}
      cancelText=""
      okVisible={true}
      onCancel={onClose}
      okText={Lang({ str: "download_pdf", section: "default" })}
      okStyle={{
        backgroundColor: "#7A24EA",
        border: 0,
        color: "#ffffff",
      }}
      downloadUrl={file}
    >
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
        <Viewer
          fileUrl={file}
          theme={{
            theme: "light",
          }}
          withCredentials={true}
          plugins={[]}
        />
      </Worker>
    </Modal>
  );
};

export default PDFModal;
