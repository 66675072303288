import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import Utils from '../../Utils';
import addplus from '../../assets/images/addplus.svg';
import urlStore from '../../stores/urlStore';
import AccountItem from '../Accounts/AccountItem';
import userStore from '../Auth/userStore';
import BrokerAccountStore from '../BrokerAccount/BrokerAccountStore';
import Header from '../Header';
import Lang from '../Lang/index';
import TitleCategory from '../TitleCategory';
import AccountStore from './AccountStore';
import styles from './Accounts.module.scss';
import BrokerStatusBlock from "../BrokerStatusBlock";

const Accounts = () => {
  const [brokerBalance, setBrokerBalance] = useState(0);
  const [depositBalance, setDepositBalance] = useState(0);
  // const [demoBalance, setDemoBalance] = useState(0);
  // const [brokerNumber, setBrokerNumber] = useState(0);
  const [depositNumber, setDepositNumber] = useState(0);
  // const [demoNumber, setDemoNumber] = useState(0);

  //parseFloat(brokerBalance.toString()).toFixed(2)
  const [width, setWidth] = useState();

  const ref = useRef();
  const traderData = BrokerAccountStore.traderData;

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      if (ref.current.offsetWidth !== width) {
        setWidth(ref.current.offsetWidth);
      }
    });

    resizeObserver.observe(ref.current);

    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, [ref.current]);

  const getTrading = useCallback(() => {
    AccountStore.getTrading();
  }, []);
  const getAccounts = useCallback(() => {
    AccountStore.getAccounts();
  }, []);
  const onClickHandler = (route) => {
    AccountStore.transferFrom = route;
  };

  useEffect(() => {
    getTrading();
    getAccounts();
  }, []);

  useEffect(() => {
    urlStore.setSection('accounts');
  }, []);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const accounts = [
    {
      title:
        Utils.apiDomain() === 'app2.geekoinvest.kz'
          ? Lang({str: 'broker_acc2', section: 'accounts'})
          : Lang({str: 'broker_acc', section: 'accounts'}),
      link: '/accounts/brokerage',
      price: AccountStore.accounts.brokerage.price,
      buttons: false,
      button1: true,
      button2: true,
      button3: true,
      // number: "№123456789",
      number: AccountStore.accounts.brokerage.number,
      type: 'brokerage',
      // src1: "/accounts/brokerage_account",
      // src2: "/brokerage_account",
      //src3: "/brokerage_account",
    },
    {
      title:
        Utils.apiDomain() === 'app2.geekoinvest.kz'
          ? Lang({str: 'trading_acc2', section: 'accounts'})
          : Lang({str: 'trading_acc', section: 'accounts'}),
      price: AccountStore.accounts.trading.price,
      button2: true,
      buttons: true,
      button1: true,
      link: '/accounts/trading',
      // number: "№123456789",
      number: AccountStore.accounts.trading.number,
      type: 'trading',
    },
  ];
  const categoryConfig = [
    {
      title: Lang({str: 'my_accounts', section: 'accounts'}),
    },
  ];

  return (
    <>
      <Header/>
      {userStore?.userData?.statusData?.alpaca !== 'active' ? (
        <BrokerStatusBlock/>
      ) : (
        <div className={styles.account_page} ref={ref}>
          <TitleCategory title={categoryConfig[0].title}/>

          {userStore?.userData?.statusData?.alpaca === 'active' && (
            <div>
              <AccountItem
                title={accounts[0].title}
                number={accounts[0].number}
                price={accounts[0].price}
                link={accounts[0].link}
                clickHandler={() => {
                  onClickHandler(accounts[0].type);
                }}
              />
              <div className="mt-24">
                <AccountItem
                  title={accounts[1].title}
                  price={accounts[1].price}
                  number={accounts[1].number}
                  link={accounts[1].link}
                  clickHandler={() => {
                    onClickHandler(accounts[1].type);
                  }}
                />
              </div>
            </div>
          )}

          {userStore?.userData?.statusData?.alpaca !== 'active' && (
            <div className={styles.abs_bl}>
              <Link
                to={'/broker_account'}
                className={styles.block_one_btn}
                style={{width: width}}>
                <img src={addplus}/>
                {Lang({str: 'open_account', section: 'accounts'})}
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default observer(Accounts);
