import React, { useEffect, useRef, useState } from "react";
import "./ImagePreview.css";
import { Spin } from "antd";
import { FileImageOutlined, LoadingOutlined } from "@ant-design/icons";
import randomColor from "randomcolor";

const antIcon = (
  <LoadingOutlined style={{ color: "var(--basic-dark)", padding: 0, margin: 0 }} spin />
);

const ImagePreview = (props) => {
  const refDiv = useRef();
  const [load, setLoad] = useState(props.src && props.src !== '' && props.src.match(/^data:/g) !== null ? true : null);
  const [dimensions, setDimensions] = useState({
    width: props.width || 96,
    height: props.height || 96,
  });

  const style = { width: props.width, height: props.height, ...props.style };
  const className = props.className
    ? "image-preview " + props.className
    : "image-preview";
  const src = props.src;
  const iconNoPhoto =
    props.iconNoPhoto === undefined ? true : props.iconNoPhoto;

  function imageLoad(e) {
    let img = { height: 1, width: 1 };
    if (e.target) {
      img.height = e.target.height;
      img.width = e.target.width;
    } else if (e.path) {
      img.height = e.path[0].height;
      img.width = e.path[0].width;
    }

    if (!refDiv.current?.clientHeight || !refDiv.current?.clientWidth) return;

    if (
      refDiv.current.clientHeight / refDiv.current.clientWidth <
      img.height / img.width
    ) {
      setDimensions({
        width: "100%",
        height: "initial",
      });
    } else {
      setDimensions({
        width: "initial",
        height: "100%",
      });
    }
    setLoad(true);
  }

  function imageError() {
    setLoad(false);
  }

  useEffect(() => {
    if (!src) return;
    if (src.match(/^data:/g) !== null) {
      setLoad(true);
    } else {
      setLoad(null);
    }

    const image = new Image();
    image.addEventListener("load", imageLoad);
    image.addEventListener("error", imageError);
    image.src = src;
    return () => {
      image.removeEventListener("load", imageLoad);
      image.removeEventListener("error", imageError);
    };
  }, [src]);

  if (!src && props.name && !iconNoPhoto) {
    style.backgroundColor = randomColor({
      seed: props.name,
      luminosity: "dark",
      format: "rgb", // e.g. 'rgba(9, 1, 107, 0.6482447960879654)'
    });
    style.color = "#ffffff";
    style.fontWeight = "bold";
    return (
      <div className={className} style={style} ref={refDiv}>
        {props.name[0]}
      </div>
    );
  }

  if (load === null)
    return (
      <div className={className} style={style} ref={refDiv}>
        <Spin indicator={antIcon} />
      </div>
    );

  if (load === false)
    return (
      <div className={className} style={style} ref={refDiv}>
        {iconNoPhoto && <FileImageOutlined />}
      </div>
    );

  return (
    <div className={className} style={style} ref={refDiv}>
      <img
        src={src}
        alt={""}
        style={{
          width: dimensions.width,
          height: dimensions.height,
        }}
      />
    </div>
  );
};

export default ImagePreview;
