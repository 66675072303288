import { observer } from 'mobx-react-lite';
import React from 'react';
import userStore from '../Auth/userStore';

import Lang from '../Lang';

import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../UI';
import modalStore from '../ModalManager/modalStore';

const TradingModalButton = ({ tickerId }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Button
      variant="primary"
      // className={styles.button}
      onClick={() => {
        if (!userStore.userData.role.trader) {
          modalStore.openModal('openBrokerAccount')
          return;
        }
        // TradingTickerModalStore.modalTradingOpen(tickerId)
        navigate('./trade', { state: location });
      }}>
      {Lang({ str: 'trade', section: 'trading' })}
    </Button>
  );
};

export default observer(TradingModalButton);
