import { configure, makeAutoObservable } from "mobx";
import { apiQueryError, apiRequest } from "../../Utils";
import AlertStore from "../Alert/AlertStore";

configure({ enforceActions: "never" });

class PortfolioStore {
  collectionData = {};

  favoritesEdit = false;
  favoritesRows = null;

  collectionMyEdit = false;
  collectionMyRows = null;

  collectionGeekoRows = null;

  collectionCloneLoad = false;

  my_papers = [];
  portfolio_history = null;
  portfolio_value = null; //"0.00";
  today_percent = null; //"0.00";
  today_dif = "0.00";
  isTrader = false;
  cash = "0.00";
  market_value = "0.00";

  logos = [

    "https://static.geekoinvest.kz/file/658e87215c2e4afc87154bf9",
    "https://static.geekoinvest.kz/file/658e87385c2e4afc87154bfc",
    "https://static.geekoinvest.kz/file/658e874a5c2e4afc87154bff",
    "https://static.geekoinvest.kz/file/658e875a5c2e4afc87154c02",
    "https://static.geekoinvest.kz/file/658e87685c2e4afc87154c05",
    "https://static.geekoinvest.kz/file/658e87775c2e4afc87154c08",
    "https://static.geekoinvest.kz/file/658e87835c2e4afc87154c0b",
    "https://static.geekoinvest.kz/file/658e87925c2e4afc87154c0e",
    "https://static.geekoinvest.kz/file/658e879e5c2e4afc87154c11",
    "https://static.geekoinvest.kz/file/658e87aa5c2e4afc87154c14",
    "https://static.geekoinvest.kz/file/658e87b85c2e4afc87154c17",
    "https://static.geekoinvest.kz/file/658e87c55c2e4afc87154c1a",
    "https://static.geekoinvest.kz/file/658e87d35c2e4afc87154c1d",
    "https://static.geekoinvest.kz/file/658e87e65c2e4afc87154c20",
    "https://static.geekoinvest.kz/file/658e87f35c2e4afc87154c23",
    "https://static.geekoinvest.kz/file/658e88015c2e4afc87154c26",
    "https://static.geekoinvest.kz/file/658e88105c2e4afc87154c29",
    "https://static.geekoinvest.kz/file/658e88255c2e4afc87154c2c",
    "https://static.geekoinvest.kz/file/658e883c5c2e4afc87154c2f",
    "https://static.geekoinvest.kz/file/658e884a5c2e4afc87154c32",
    "https://static.geekoinvest.kz/file/658e88585c2e4afc87154c35",
    "https://static.geekoinvest.kz/file/658e88645c2e4afc87154c38",
    "https://static.geekoinvest.kz/file/658e88795c2e4afc87154c3b",
    "https://static.geekoinvest.kz/file/658e88875c2e4afc87154c3e",
    "https://static.geekoinvest.kz/file/658e88a05c2e4afc87154c41",
    "https://static.geekoinvest.kz/file/658e88ae5c2e4afc87154c44",
    "https://static.geekoinvest.kz/file/658e88c35c2e4afc87154c47",
    "https://static.geekoinvest.kz/file/658e88d15c2e4afc87154c4a",
    "https://static.geekoinvest.kz/file/658e88de5c2e4afc87154c4d",
    "https://static.geekoinvest.kz/file/658e88f25c2e4afc87154c50",
    "https://static.geekoinvest.kz/file/658e88fe5c2e4afc87154c53",
    "https://static.geekoinvest.kz/file/658e891d5c2e4afc87154c56",
    "https://static.geekoinvest.kz/file/658e892b5c2e4afc87154c59",
    "https://static.geekoinvest.kz/file/658e893a5c2e4afc87154c5c",
    "https://static.geekoinvest.kz/file/658e89475c2e4afc87154c5f",
    "https://static.geekoinvest.kz/file/658e89525c2e4afc87154c62",
    "https://static.geekoinvest.kz/file/658e895f5c2e4afc87154c65",
    "https://static.geekoinvest.kz/file/658e89955c2e4afc87154c68",
    "https://static.geekoinvest.kz/file/658e89a05c2e4afc87154c6b",
    "https://static.geekoinvest.kz/file/658e89ab5c2e4afc87154c6e",
    "https://static.geekoinvest.kz/file/658e89b85c2e4afc87154c71",
    "https://static.geekoinvest.kz/file/658e89c35c2e4afc87154c74",
    "https://static.geekoinvest.kz/file/658e89cd5c2e4afc87154c77",
    "https://static.geekoinvest.kz/file/658e89d95c2e4afc87154c7a",
    "https://static.geekoinvest.kz/file/658e89e55c2e4afc87154c7d",
    "https://static.geekoinvest.kz/file/658e89f25c2e4afc87154c80",
    "https://static.geekoinvest.kz/file/658e8a005c2e4afc87154c83",
    "https://static.geekoinvest.kz/file/658e8a345c2e4afc87154c86",
    "https://static.geekoinvest.kz/file/658e8a415c2e4afc87154c89",
    "https://static.geekoinvest.kz/file/658e8a4d5c2e4afc87154c8c",
    "https://static.geekoinvest.kz/file/658e8a5b5c2e4afc87154c8f",
    "https://static.geekoinvest.kz/file/658e8a665c2e4afc87154c92",
    "https://static.geekoinvest.kz/file/658e8a735c2e4afc87154c95",
    "https://static.geekoinvest.kz/file/658e8a7e5c2e4afc87154c98",
    "https://static.geekoinvest.kz/file/658e8a8a5c2e4afc87154c9b",
    "https://static.geekoinvest.kz/file/658e8a975c2e4afc87154c9e",
    "https://static.geekoinvest.kz/file/658e8aa35c2e4afc87154ca1",
    "https://static.geekoinvest.kz/file/658e8aaf5c2e4afc87154ca4",
    "https://static.geekoinvest.kz/file/658e8ac55c2e4afc87154ca8",
    "https://static.geekoinvest.kz/file/658e8ad05c2e4afc87154cab",
    "https://static.geekoinvest.kz/file/658e8add5c2e4afc87154cae",
    "https://static.geekoinvest.kz/file/658e8ae85c2e4afc87154cb1",

    // "https://static.geekoinvest.kz/file/65797bad5c2e4afc87148bac",
    // "https://static.geekoinvest.kz/file/65797bc25c2e4afc87148baf",
    // "https://static.geekoinvest.kz/file/65797bd25c2e4afc87148bb2",
    // "https://static.geekoinvest.kz/file/65797be55c2e4afc87148bb5",
    // "https://static.geekoinvest.kz/file/65797bf55c2e4afc87148bb8",
    // "https://static.geekoinvest.kz/file/65797c035c2e4afc87148bbb",
    // "https://static.geekoinvest.kz/file/65797c115c2e4afc87148bbe",
    // "https://static.geekoinvest.kz/file/65797c275c2e4afc87148bc1",
    // "https://static.geekoinvest.kz/file/65797c385c2e4afc87148bc4",
    // "https://static.geekoinvest.kz/file/65797c495c2e4afc87148bc7",

    // "https://static.geekoinvest.kz/file/654388c0df19c101f4115f5d",
    // "https://static.geekoinvest.kz/file/654388d8df19c101f4115f60",
    // "https://static.geekoinvest.kz/file/654388e9df19c101f4115f63",
    // "https://static.geekoinvest.kz/file/65438905df19c101f4115f66",
    // "https://static.geekoinvest.kz/file/65438914df19c101f4115f69",
    // "https://static.geekoinvest.kz/file/65438923df19c101f4115f74",
    // "https://static.geekoinvest.kz/file/65438931df19c101f4115f78",
    // "https://static.geekoinvest.kz/file/6543893fdf19c101f4115f7b",
    // "https://static.geekoinvest.kz/file/65438950df19c101f4115f7e",
    // "https://static.geekoinvest.kz/file/65438960df19c101f4115f81",
    // "https://static.geekoinvest.kz/file/6543896fdf19c101f4115f84",
    // "https://static.geekoinvest.kz/file/6543897ddf19c101f4115f87",
    // "https://static.geekoinvest.kz/file/6543898cdf19c101f4115f8a",
    // "https://static.geekoinvest.kz/file/6543899adf19c101f4115f8d",
    // "https://static.geekoinvest.kz/file/654389a9df19c101f4115f90",
    // "https://static.geekoinvest.kz/file/654389b8df19c101f4115f93",
    // "https://static.geekoinvest.kz/file/654389c6df19c101f4115f96",
    // "https://static.geekoinvest.kz/file/654389d4df19c101f4115f99",
    // "https://static.geekoinvest.kz/file/654389e2df19c101f4115f9c",
    // "https://static.geekoinvest.kz/file/654389f0df19c101f4115f9f",
    // "https://static.geekoinvest.kz/file/65438a00df19c101f4115fa2",
    // "https://static.geekoinvest.kz/file/65438a0ddf19c101f4115fa5",
    // "https://static.geekoinvest.kz/file/65438a1bdf19c101f4115fa9",
    // "https://static.geekoinvest.kz/file/65438a2adf19c101f4115fac"
  ]

  constructor() {
    makeAutoObservable(this);
  }

  /*
  * Загрузка бумаг
  * */
  getPapers(page = 0){
    let url = `/private/v1/account-papers/`;

    apiRequest({
      url: url,
      success: (data) => {
        if (data?.error) return; // message.error(data?.error.msg);
        // console.log({ data });

        if (!data || !Array.isArray(data) || data.length === 0) {
          // setMyPapers([]);
          this.my_papers = []
        } else {
          let ar = [];
          for (const item of data) {
            // console.log({ item });
            const percent =
                ((Number(item.current_price) - Number(item.lastday_price)) *
                    100) /
                Number(item.lastday_price);
            const obj = {
              _id: item._id,
              obj: {
                _id: item._id,
                symbol: item.symbol,
                name: item.name,
                cost_basis: item.cost_basis,
                current_price: item.current_price,
                avg_entry_price: item?.avg_entry_price,
                leaves_qty: item?.leaves_qty,
                unrealized_pl: item?.unrealized_pl,
                unrealized_plpc: item?.unrealized_plpc,
                side: item?.side == "long" ? "Long" : "Short",
                name_ticker: { name: item.name, symbol: item.symbol },
                quote: {
                  close: parseFloat(item.current_price.toString()).toFixed(2),
                  changePercent: parseFloat(percent.toString()).toFixed(2),
                },
                // chartMini: item.chartMini,
                // bar: item.bar,
                market_value: parseFloat(item.market_value.toString()).toFixed(
                    2
                ),
                qty: parseFloat(item.qty.toString()).toFixed(2),
                // logo: item.logo,
                tickerData: {
                  tickerId: item._id,
                  type: "collectionMy",
                },
              },
            };
            ar.push(obj);
          }
          // setMyPapers(ar);
          this.my_papers = ar
        }
      },
    }).catch((error) => {
      console.error({ error });
      // setMyPapers([]);
      this.my_papers = []
    });

    return () => {
      // for (const eventId of events) {
      //   websocket.unSubscribe(eventId);
      // }
      // events = [];
    };
  };

  /*
  * Загрзука данных аккаунта
  * */
  getTrading(){
    apiRequest({
      url: "/private/v1/account-trading",
      success: (data) => {
        if (data?.error) return; // message.error(data?.error.msg);
        console.log('account-trading', { data });
        if (
            !data ||
            !data.cash ||
            !data.long_market_value ||
            !data.short_market_value ||
            !data?.portfolio_value
        ) {
          // setIsTrader(false);
          this.isTrader = false
        } else {
          const cash = data.cash;
          // setCash(parseFloat(cash.toString()).toFixed(2));
          this.cash = parseFloat(cash.toString()).toFixed(2)

          const position_market_value = data?.position_market_value;

          let market_value = Number(position_market_value);
          if (!market_value) market_value = 0;
          // setMarketValue(parseFloat(market_value.toString()).toFixed(2));
          this.market_value = parseFloat(market_value.toString()).toFixed(2)

          const last_long_market_value = data.last_long_market_value
              ? data.last_long_market_value
              : 0;

          const last_short_market_value = data.last_short_market_value
              ? data.last_short_market_value
              : 0;
          const last_market_value =
              Number(last_long_market_value) + Number(last_short_market_value);
          let percent = 0;
          if (last_market_value && last_market_value !== 0) {
            percent =
                ((Number(market_value) - Number(last_market_value)) * 100) /
                Number(last_market_value);
          }

          console.log("unrealized_pl: ", data);
          // setPortfolioValue(data?.portfolio_value);
          this.portfolio_value = data?.portfolio_value
          // setTodayDif(data?.unrealized_pl ? data?.unrealized_pl : 0);
          this.today_dif = data?.unrealized_pl ? data?.unrealized_pl : 0

          // setTodayPercent(
          //     data?.unrealized_pl_percent ? data?.unrealized_pl_percent : 0
          // );
          this.today_percent = data?.unrealized_pl_cash_percent ? data?.unrealized_pl_cash_percent : 0

          // setIsTrader(true);
          this.isTrader = true
        }
      },
    }).catch((error) => {
      console.error({ error });
      // setIsTrader(false);
      this.isTrader = false
    });
  };

  getPortfolioHistory = (chartPeriod = '1M') => {
    const nullChart = [
      { x: -5, y: 0 },
      { x: -1, y: 60 },
      { x: 100, y: 60 },
    ]

    apiRequest({
      url: `/private/v1/get_portfolio_history/?period=${chartPeriod}`,
      method: 'GET',
      success: (data) => {
        console.log('getPortfolioHistory', { data });
        if (data.status !== 'ok' || !data.data?.equity) {
          this.portfolio_history = [];
          return;
        }

        if (data.data.equity.every(x => x === 0)) {
          this.portfolio_history = nullChart;
          return;
        }

        let ar = [];
        let i = 0;
        for (const item of data.data?.equity) {
          const obj = {
            x: i, //data.data?.timestamp[i],
            y: item,
          };
          ar.push(obj);
          i++;
        }

        this.portfolio_history = ar;
      },
      error: () => {
        this.portfolio_history = nullChart;
      },
    }).catch();
  }

  /*
  Загрузка данных коллекции
   */
  getCollection(collectionId, cache = true) {
    if (!this.collectionData[collectionId])
      this.collectionData[collectionId] = { load: true };
    if (this.collectionData[collectionId].loadingTime && cache) {
      const t = new Date().getTime() - 120000;
      if (this.collectionData[collectionId].loadingTime > t) return;
    }

    apiRequest({
      url: "/private/v1/portfolio/" + collectionId,
      success: (data) => {
        this.collectionData[collectionId] = data;
        this.collectionData[collectionId].loadingTime = new Date().getTime();
      },
    }).catch();
  }

  /*
  Включить/выключить редактирование избранного
   */
  setFavoritesEdit(v) {
    this.favoritesEdit = v;
  }
  /*
  Включить/выключить редактирование колекций
  */
  setCollectionMyEdit(v) {
    this.collectionMyEdit = v;
  }

  /*
  Загрузка избранного
   */
  favoritesLoad() {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/portfolio/favorites",
        success: (data) => {
          if (data?.error) return AlertStore.error(data?.error?.msg)
          this.favoritesRows = data;
          resolve(data);
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
        },
      }).catch();
    });
  }
  // /*
  // Удаление символа из избранного
  //  */
  // favoritesDel(tickerId) {
  //   return new Promise((resolve) => {
  //     apiRequest({
  //       url: "/private/v1/portfolio/favorites/" + tickerId,
  //       method: "DELETE",
  //       success: () => {
  //         this.favoritesRows = this.favoritesRows?.filter(
  //           (tId) => tId !== tickerId
  //         );
  //         resolve();
  //         this.favoritesLoad().catch();
  //       },
  //       error: (error) => {
  //         apiQueryError({ error });
  //         resolve();
  //       },
  //     }).catch();
  //   });
  // }
  // /*
  // Добавление символа в избранное
  //  */
  // favoritesSet(tickerId) {
  //   return new Promise((resolve) => {
  //     apiRequest({
  //       url: "/private/v1/portfolio/favorites",
  //       method: "POST",
  //       body: {
  //         tickerId,
  //       },
  //       success: () => {
  //         this.favoritesLoad().catch();
  //         resolve();
  //       },
  //       error: (error) => {
  //         apiQueryError({ error });
  //         resolve();
  //       },
  //     }).catch();
  //   });
  // }

  /*
  Загруска коллекций пользователя
   */
  collectionMyLoad() {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/portfolio",
        success: (data) => {
          const rows = [];
          for (let d of data) {
            this.collectionData[d._id] = d;
            this.collectionData[d._id].loadingTime = new Date().getTime();
            rows.push(d._id);
          }

          this.collectionMyRows = rows;
          resolve(rows);
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
        },
      }).catch();
    });
  }

  collectionSet(data) {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/portfolio",
        method: "POST",
        body: data,
        success: (data) => {
          this.collectionMyLoad().catch();
          resolve(data);
        },
        error: (error) => {
          apiQueryError({ error });
          resolve(false);
        },
      }).catch();
    });
  }

  collectionDel(collectionId) {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/portfolio",
        method: "DELETE",
        body: {
          id: collectionId,
        },
        success: () => {
          resolve();
          AlertStore.success('Коллекция удалена')
          this.collectionMyLoad().catch();
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
        },
      }).catch();
    });
  }

  collectionClone(collectionId) {
    if (this.collectionCloneLoad) return;
    this.collectionCloneLoad = true;
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/portfolio/clone",
        method: "POST",
        body: {
          id: collectionId,
        },
        success: res => {
          resolve();
          this.collectionMyLoad().catch()
          this.collectionCloneLoad = false;
          AlertStore.success('Коллекция скопирована', null, `/favorites/${res._id}`)
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
          this.collectionCloneLoad = false;
        },
      }).catch();
    });
  }

  collectionTickerSet(collectionId, tickerId) {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/portfolio/ticker",
        method: "POST",
        body: {
          collectionId,
          tickerId,
        },
        success: () => {
          this.getCollection(collectionId, false);
          this.favoritesLoad().catch()
          resolve();
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
        },
      }).catch();
    });
  }

  collectionTickerDel(collectionId, tickerId) {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/portfolio/ticker",
        method: "DELETE",
        body: {
          collectionId,
          tickerId,
        },
        success: () => {
          this.getCollection(collectionId, false);
          resolve();
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
        },
      }).catch();
    });
  }

  collectionTickersSave(collectionId, tickers) {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/portfolio/tickersSave",
        method: "POST",
        body: {
          collectionId,
          tickers,
        },
        success: () => {
          this.getCollection(collectionId, false);
          resolve();
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
        },
      }).catch();
    });
  }

  /*
  Загруска коллекций гико
   */
  collectionGeekoLoad() {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/portfolio/geeko",
        success: (data) => {
          const rows = [];
          for (let d of data) {
            this.collectionData[d._id] = d;
            this.collectionData[d._id].loadingTime = new Date().getTime();
            rows.push(d._id);
          }

          this.collectionGeekoRows = rows;
          resolve();
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
        },
      }).catch();
    });
  }

  ModalSearchVisible = false;

  //Modal Search
  openModalSearch() {
    this.ModalSearchVisible = true;
  }

  closeModalSearch() {
    this.ModalSearchVisible = false;
  }
}

export default new PortfolioStore();
