import React, {useEffect, useRef, useState} from "react";
import styles from "../BrokerAccountNew/BrokerAccountNew.module.scss";
import { Button, Form, Input, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import Lang from "../Lang";
import BrokerAccountStore from "./BrokerAccountStore";
import locale_ru from 'antd/es/date-picker/locale/ru_RU';
import locale_en from 'antd/es/date-picker/locale/en_US';
import AppStore from "../App/AppStore";

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

const Step3 = ({ setBack }) => {
  const ref = useRef();
  const onScreen = useOnScreen(ref);
  const traderData = BrokerAccountStore.traderData;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [form] = Form.useForm();

  const onFinishForm = (values) => {
    const d = {
      view: "questionnaire",
      step: 4,
      mark: 'personalData',
      questionnaire: {
        additionalInformation: {
          numberType: values.numberType,
        },
      },
    };

    delete values.numberType;
    d.questionnaire.personalProfile = values;
    console.log(d);

    BrokerAccountStore.save(d).catch();
    window.scrollTo(0, 0);
  };

  const locale = AppStore.lang === 'en' ? locale_en : locale_ru

  useEffect(() => {
    const scroll = window.document.getElementsByClassName("basic-routes");
    if (scroll[0]) scroll[0].scrollTo(0, 0);

    setBack({
      view: "questionnaire",
      step: 2,
    });
  }, []);

  useEffect(() => {
    console.log(traderData);
    if (traderData?.questionnaire?.personalProfile)
      for (let key of Object.keys(traderData?.questionnaire?.personalProfile)) {
        if (["dob"].includes(key)) {
          form.setFieldValue(
            key,
            traderData?.questionnaire?.personalProfile[key]
              ? dayjs(traderData?.questionnaire?.personalProfile[key])
              : null
          );
          continue;
        }

        form.setFieldValue(
          key,
          traderData?.questionnaire?.personalProfile[key]
        );
      }

    if (traderData?.questionnaire?.additionalInformation?.numberType)
      form.setFieldValue(
        "numberType",
        traderData?.questionnaire?.additionalInformation?.numberType
      );
  }, [traderData]);

  console.log(traderData);

  return (
    <>
      <div className={styles.page_fl}>
        <div className={styles.text_bl}>
          <h4>{Lang({ str: "personal_data", section: "onboarding" })}</h4>

          <Form
            className={styles.form + " " + styles.form2}
            form={form}
            layout="vertical"
            onFinish={onFinishForm}
            requiredMark={false}
          >
            <fieldset>
              <Form.Item
                label={Lang({ str: "firstname", section: "onboarding" })}
                name="name"
                rules={[{ required: true }]}
              >
                <Input placeholder="Имя" />
              </Form.Item>
            </fieldset>
            <fieldset>
              <Form.Item
                name="surname"
                label={Lang({ str: "lastname", section: "onboarding" })}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </fieldset>
            <fieldset>
              <Form.Item
                name="patronymic"
                label={Lang({ str: "lastname_2", section: "onboarding" })}
              >
                <Input />
              </Form.Item>
            </fieldset>
            <fieldset>
              <Form.Item
                name="iin"
                label={Lang({ str: "tax_id", section: "onboarding" })}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </fieldset>
            <fieldset>
              <Form.Item
                name="dob"
                label={Lang({ str: "birth_date", section: "onboarding" })}
                rules={[{ required: true }]}
              >
                <DatePicker format={"YYYY-MM-DD"} locale={locale} />
              </Form.Item>
            </fieldset>
            <fieldset>
              <Form.Item
                name="docType"
                label={Lang({ str: "doc_type", section: "onboarding" })}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </fieldset>
            <fieldset>
              <Form.Item
                name="validity"
                label={Lang({ str: "validity", section: "onboarding" })}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </fieldset>
            <fieldset>
              <Form.Item
                name="docNumber"
                label={Lang({ str: "doc_number", section: "onboarding" })}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </fieldset>
            <fieldset>
              <Form.Item
                name="issueAuthority"
                label={Lang({
                  str: "issuing_authority",
                  section: "onboarding",
                })}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </fieldset>
            <fieldset>
              <Form.Item
                name="citizenship"
                label={Lang({ str: "citizenship", section: "onboarding" })}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </fieldset>
            <fieldset>
              <Form.Item
                name="country"
                label={Lang({ str: "country", section: "onboarding" })}
                rules={[{ required: true }]}
              >
                <Select options={BrokerAccountStore.countriesOptions} />
              </Form.Item>
            </fieldset>
            <fieldset>
              <Form.Item
                name="numberType"
                label={Lang({
                  str: "taxpayer_number_type",
                  section: "onboarding",
                })}
                rules={[{ required: true }]}
              >
                <Select options={BrokerAccountStore.countriesOptions} />
              </Form.Item>
            </fieldset>
            <fieldset>
              <Form.Item
                name="district"
                label={Lang({ str: "region", section: "onboarding" })}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </fieldset>
            <fieldset>
              <Form.Item
                name="city"
                label={Lang({ str: "city", section: "onboarding" })}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </fieldset>
            <fieldset>
              <Form.Item
                name="addressOfResidence"
                label={Lang({
                  str: "residence_address",
                  section: "onboarding",
                })}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </fieldset>
            <fieldset>
              <Form.Item
                name="houseApartment"
                label={Lang({
                  str: "house_apartment",
                  section: "onboarding",
                })}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </fieldset>
            <fieldset ref={ref}>
              <Form.Item
                name="postcode"
                label={Lang({ str: "postcode", section: "onboarding" })}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </fieldset>
            <Form.Item className={styles.buttonBlockFixed + " m-0 b-0"}>
              <Button
                type="primary"
                htmlType="submit"
                className={
                  onScreen ? styles.gr_btn + " " + styles.activ : styles.gr_btn
                }
                disabled={!onScreen && true}
              >
                {Lang({ str: "continue", section: "onboarding" })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default observer(Step3);
