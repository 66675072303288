import { configure, makeAutoObservable } from 'mobx';
import { apiRequest } from '../../Utils';
import AppStore from '../App/AppStore';

configure({ enforceActions: 'never' });

class NewsStore {
  activeNewsData = null;

  constructor() {
    makeAutoObservable(this);
  }

  open(newsData) {
    this.activeNewsData = newsData;
  }

  close() {
    this.activeNewsData = null;
  }

  loadNewsByID(id) {
    apiRequest({
      url: `/private/v1/get_news/1?lang=${AppStore.lang}&id=${id}`,
      method: 'GET',
      success: (data) => {
        if (data?.error || data?.news?.length === 0) return;

        this.activeNewsData = data.news[0];
      },
    }).catch((error) => {
      console.error({ error });
    });
  }
}

export default new NewsStore();
