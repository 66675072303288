import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Notification.module.scss";
import { Switch, Form, Input, Button, Spin } from "antd";
import userStore from "../Auth/userStore";
import { apiQuery, apiQueryError, getBase64 } from "../../Utils";
import urlStore from "../../stores/urlStore";
import PortfolioStore from "../Portfolio/PortfolioStore";
import CollectionItem from "../CollectionItem";
import icon1 from "../../assets/images/iconizb.svg";
import { LoadingOutlined } from "@ant-design/icons";
import TitleCategory from "../TitleCategory";
import HeaderTitle from "../Header/HeaderTitle";
import left_icon from "../../assets/images/arrowmenu.svg";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#e6f800" }} spin />
);
const Signals = () => {
  const categoryConfig = [
    {
      title: "Мои сигналы",
      image: `${icon1}`,
      buttons: true,
    },
  ];
  return (
    <div className={styles.personal_information_wrp + " " + styles.page_sign}>
      <HeaderTitle
        title={"Мои сигналы"}
        button1={<img src={left_icon} alt={""} />}
      />
      <div className="mt-24">
        <TitleCategory
          //title={categoryConfig[0].title}
          //image={categoryConfig[0].image}
          buttons={categoryConfig[0].buttons}
          type={"collectionMy"}
          edit={"collectionMyEdit"}
        />
        <div className="mt-24">
          {PortfolioStore.collectionMyRows === null && (
            <Spin indicator={antIcon} />
          )}
          {PortfolioStore.collectionMyRows &&
            !!PortfolioStore.collectionMyRows.length &&
            PortfolioStore.collectionMyRows.map((item, index) => (
              <CollectionItem key={index} id={item} type={"favorites"} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Signals;
