import React, { useEffect, useState } from "react";
import styles from "../BrokerAccountNew/BrokerAccountNew.module.scss";
import {Button, Form, Radio, Space, Spin} from "antd";
import { observer } from "mobx-react-lite";
import BrokerAccountStore from "./BrokerAccountStore";
import Lang from "../Lang";
import modalStore from "../ModalManager/modalStore";

const moneyOptions = [
  { value: "0 - $20,000", label: "0 - $20,000" },
  { value: "$20,000 - $49,999", label: "$20,000 - $49,999" },
  { value: "$50,000 - $99,999", label: "$50,000 - $99,999" },
  { value: "$100,000 - $499,999", label: "$100,000 - $499,999" },
  { value: "$500,000 - $999,999", label: "$500,000 - $999,999" },
  { value: "$1,000,000 - $9,999,999", label: "$1,000,000 - $9,999,999" },
];

const Step5 = ({setBack}) => {
  const traderData = BrokerAccountStore.traderData;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [form] = Form.useForm();

  const onFinishForm = (values) => {
    const d = {
      view: "questionnaire",
      step: 6,
      mark: 'financialProfile',
      questionnaire: {
        financialProfile: values
      }
    }
    BrokerAccountStore.save(d).catch()
  };

  useEffect(() => {
    const scroll = window.document.getElementsByClassName('basic-routes');
    if (scroll[0]) scroll[0].scrollTo(0, 0);

    setBack({
      view: "questionnaire",
      step: 2
    })
  },[])

  useEffect(() => {
    if (traderData?.questionnaire?.financialProfile)
      form.setFieldsValue(traderData?.questionnaire?.financialProfile)
  }, [traderData])

  if (!traderData) return <Spin/>

  return (
    <>
      <div className={styles.page_fl}>
        <div className={styles.text_bl}>
          <h4>
            {Lang({ str: "financial_profile", section: "onboarding" })} <span>{Lang({ str: "select_answer", section: "onboarding" })}</span>
          </h4>

          <Form
            className={styles.form}
            layout="vertical"
            form={form}
            onFinish={onFinishForm}
          >
            <fieldset>
              <p
                className={styles.brock_link}
                onClick={() => {
                  modalStore.openModal('helper', {
                    title: Lang({ str: "annual_income_2", section: "onboarding" }),
                    text: Lang({ str: "annual_income_3", section: "onboarding" })
                  })
                }}
              >
                {Lang({ str: "annual_income", section: "onboarding" })}
              </p>
              <Form.Item name={'annualIncome'}>
                <Radio.Group>
                  <Space direction="vertical">
                    {moneyOptions.map((item, index) => <Radio key={index} value={item.value}>{item.label}</Radio>)}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </fieldset>
            <fieldset>
              <p
                className={styles.brock_link}
                onClick={() => {
                  modalStore.openModal('helper', {
                    title: Lang({ str: "net_assets_2", section: "onboarding" }),
                    text: Lang({ str: "net_assets_3", section: "onboarding" })
                  })
                }}
              >
                {Lang({ str: "net_assets", section: "onboarding" })}
              </p>
              <Form.Item name={'netAssets'}>
                <Radio.Group>
                  <Space direction="vertical">
                    {moneyOptions.map((item, index) => <Radio key={index} value={item.value}>{item.label}</Radio>)}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </fieldset>
            <fieldset>
              <p
                className={styles.brock_link}
                onClick={() => {
                  modalStore.openModal('helper', {
                    title: Lang({ str: "liquid_assets_2", section: "onboarding" }),
                    text: Lang({ str: "liquid_assets_3", section: "onboarding" })
                  })
                }}
              >
                {Lang({ str: "liquid_assets", section: "onboarding" })}
              </p>
              <Form.Item name={'liquidAssets'}>
                <Radio.Group>
                  <Space direction="vertical">
                    {moneyOptions.map((item, index) => <Radio key={index} value={item.value}>{item.label}</Radio>)}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </fieldset>
            <Form.Item className={styles.buttonBlockFixed + " m-0 b-0"}>
              <Button
                type="primary"
                htmlType="submit"
                className={
                  !buttonDisabled
                    ? styles.gr_btn + " " + styles.activ
                    : styles.gr_btn
                }
                disabled={buttonDisabled}
              >
                {Lang({ str: "continue", section: "onboarding" })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default observer(Step5);
