import React, { useState, useEffect } from "react";
import ModalCustom from "../Modal";
import { Button, Form, Radio, Space, Modal, message } from "antd";
import Logo from "../Logo";
import TitleCategory from "../TitleCategory";
import icon1 from "../../assets/images/newspaper.svg";
import HeaderTitle from "../Header/HeaderTitle";
import "./Wallet.scss";
import styles from "./Wallet.module.scss";
import close1 from "../../assets/images/close1.svg";
import left_icon from "../../assets/images/arrowmenu.svg";
import { apiQuery, apiQueryError } from "../../Utils";
import userStore from "../Auth/userStore";
import img22 from "../../assets/images/_4.jpg";
import BorderBlock from "../BorderBlock/index";
import AlertStore from "../Alert/AlertStore";

const categoryConfig = [
  {
    title: "Перевод в Geeko.tech",
    image: `${icon1}`,
  },
];

const AddBalanceBank = () => {
  const [visible, setVisible] = React.useState(true);
  const [paySuccessVisible, setPaySuccessVisible] = React.useState(false);
  const [payErrorVisible, setPayErrorVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState(
    "Приложение создано для обучения торговле ценными бумагами. Депозит невозможно пополнить реальными деньгами и невозможно вывести средства. Вы согласны продолжить?"
  );
  const [modalPaySuccessText, setModalPaySuccessText] = React.useState("");
  const [modalPayErrorText, setModalPayErrorText] = React.useState("");
  const [backUrl, setBackUrl] = React.useState("https://v2.geeko.tech/wallet");
  const [modalPdfOpen, setModalPdfOpen] = React.useState(false);
  useEffect(() => {
    if (modalPaySuccessText) {
      setPaySuccessVisible(true);
    }
  }, [modalPaySuccessText]);

  useEffect(() => {
    if (modalPayErrorText) {
      setPayErrorVisible(true);
    }
  }, [modalPayErrorText]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const [fixAmount, setFixAmount] = useState(0);

  const getPaymentUrl = () => {
    const url = apiQuery("/private/payment-url?fix_amount=" + fixAmount, {
      method: "GET",
    })
      .then((data) => {
        console.log({ data });
        if (data.status !== "ok") {
          return false;
        }
        if (!data.url) {
          return false;
        }
        return data.url;
      })
      .catch((error) => {
        apiQueryError({ error });
        return false;
      });
    return url;
  };

  const sendPay = () => {
    const data = apiQuery("/private/demo-pay?fix_amount=" + fixAmount, {
      method: "GET",
    })
      .then((data) => {
        console.log({ data });
        // if(data.status !== 'ok'){
        //     return false
        // }
        // if(!data.url){
        //     return false
        // }
        return data;
      })
      .catch((error) => {
        //apiQueryError({error});
        AlertStore.error("Ошибка! Повторите попытку позже.");
        return false;
      });
    return data;
  };

  const requisiteSendToEmail = () => {
    apiQuery("/private/requisiteSendToEmail", {
      method: "GET",
    })
      .then((data) => {
        AlertStore.success("Реквизиты отправлены на ваш email.");
      })
      .catch((error) => {
        //apiQueryError({error});
        AlertStore.error("Ошибка! Повторите попытку позже.");
        return false;
      });
  };

  const pay = async () => {
    console.log("pay: ", fixAmount);
    setModalPaySuccessText("");
    setPayErrorVisible("");
    if (fixAmount > 0) {
      const data = await sendPay();
      console.log({ data });
      if (data?.status !== "ok") {
        // window.open(paymentUrl)
        // window.location = paymentUrl
        // alert(data?.msg)
        setModalPayErrorText(data?.msg);
        return;
      }
      setModalPaySuccessText(
        "Ваш баланс пополнен. В ближайшее время денежные средства появятся в Вашем кошельке. "
      );
      // alert('Успешно пополнено')
      // window.location = '/wallet/transfer/confirm'
      if (data?.url) {
        setBackUrl(data?.url);
      }
    } else {
      console.log("amount <= 0");
      // alert('Сумма пополнения должна быть больше 0')
      setModalPayErrorText("Сумма пополнения должна быть больше 0");
    }
  };

  const handleChange = (event) => {
    let value = Number(event.target.value);
    if (Number.isNaN(value)) value = 0;
    setFixAmount(value);
  };

  return (
    <>
      <HeaderTitle
        button1={<img src={left_icon} alt={""} />}
        title={"Создать поручение"}
      />

      <div className="mt-24 pb-64">
        <div className={styles.add_balance}>
          <p className={styles.add_balance_title}>Пополнить счет через банк</p>
          <BorderBlock />
          <div className={styles.text_b + " mt-24"}>
            <p>
              Все банковские сборы должны быть оплачены отправителем. На счет
              клиента зачисляется та сумма, которая поступила на счета брокера.
              Брокер не удерживает комиссий за зачисление
            </p>
            <p>
              Обратите внимание: перевод на ваш торговый счет должен
              осуществляться с вашего собственного счета. Перевод со счетов
              другого лица будет возвращен и не зачислен на ваш счет брокера.
            </p>
          </div>
          <div className={styles.text_red + " mt-24"}>
            <p>
              Обратите внимание: назначение платежа нужно заполнять обязательно
              и именно так, как указано в реквизитах. Номер счета 7B4527 должен
              обязательно присутствовать в назначении платежа, в противном
              случае будет задержка по причинам выяснения деталей платежа и в
              случае отрицательного результата по поиску реквизитов клиента
              деньги будут возвращены обратно отправителю.
            </p>
          </div>

          {/* <div className={styles.block_valute}>
            <p>Для KZT (казахстанский тенге)</p>
            <div className={styles.tabl_date}>
              <div className={styles.line_one}>
                <span>БИК</span>
                <p>KSNVKZKA</p>
              </div>
              <div className={styles.line_one}>
                <span>БИН</span>
                <p>200240900095</p>
              </div>
              <div className={styles.line_one}>
                <span>ИИК</span>
                <p>KZ57551A12345001KZT</p>
              </div>
              <div className={styles.line_one}>
                <span>КБе</span>
                <p>15</p>
              </div>
              <div className={styles.line_one}>
                <span>Получатель</span>
                <p>Публичная компания Geeko Invest </p>
              </div>
              <div className={styles.line_one}>
                <span>Банк Бенефициара</span>
                <p>АО «Банк Фридом Финанс Казахстан»</p>
              </div>
              <div className={styles.line_one}>
                <span>Назначение платежа</span>
                <p>
                  Brokerage account replenishment According to agreement number
                  7F7686, dated 2023-05-09{" "}
                </p>
              </div>
              <div className={styles.line_one}>
                <p className={styles.line_one_widt}>
                  Обратите внимание: назначение платежа нужно заполнять
                  обязательно и именно так, как указано в реквизитах. Номер
                  счета должен обязательно присутствовать в назначении платежа,
                  в противном случае деньги будут возвращены отправителю.
                </p>
              </div>
            </div>
            <button className="mt-24">Распечатать</button>
          </div>*/}
          <div className={styles.block_valute}>
            <p>Для USD (Доллар США)</p>
            <div className={styles.tabl_date}>
              <div className={styles.line_one}>
                <span>Beneficiary</span>
                <p>-</p>
              </div>
              <div className={styles.line_one}>
                <span>Payment details</span>
                <p>-</p>
              </div>
              <div className={styles.line_one}>
                <span>Beneficiary Bank</span>
                <p>-</p>
              </div>
              <div className={styles.line_one}>
                <span>Correspondent Bank</span>
                <p>-</p>
              </div>
              <div className={styles.line_one}>
                <span>Beneficiary’s account</span>
                <p>-</p>
              </div>
              <div className={styles.line_one}>
                <span>Correspondent Bank SWIFT </span>
                <p>-</p>
              </div>
              <div className={styles.line_one}>
                <span>SWIFT of beneficiary bank</span>
                <p>-</p>
              </div>
              <div className={styles.line_one}>
                <span> Correspondent Bank account</span>
                <p>-</p>
              </div>

              <div className={styles.line_one}>
                <p className={styles.line_one_widt}>
                  Обратите внимание: назначение платежа нужно заполнять
                  обязательно и именно так, как указано в реквизитах. Номер
                  счета должен обязательно присутствовать в назначении платежа,
                  в противном случае деньги будут возвращены отправителю.
                </p>
              </div>
            </div>
            <button className="mt-24">Распечатать</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBalanceBank;
