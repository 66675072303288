import React from 'react';
import { Clickable } from '../../../UI';

import styles from './index.module.scss';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import AlertStore from '../../Alert/AlertStore';
import Icon from '../../Icon';
import Lang from '../../Lang';
import PortfolioStore from '../../Portfolio/PortfolioStore';
import './index.module.scss';

const AddToCollectionModal = ({ tickerId, variant = 'clear', icon = 'plus1', iconSize = 20 }) => {
  useEffect(() => {
    PortfolioStore.collectionGeekoLoad().catch();
    PortfolioStore.collectionMyLoad().catch();
  }, []);

  return (
    <>
      <h3
        className={styles.title}
        style={{
          textAlign: 'center',
          color: 'var(--basic-dark)',
          fontFamily: 'GolosText',
        }}>
        {Lang({ str: 'add_to_watchlist', section: 'trading_modal' })}
      </h3>

      <div className={styles.list}>
        {!PortfolioStore.collectionMyRows && (
          <Spin
            className={styles.loader}
            indicator={
              <LoadingOutlined style={{ fontSize: 40, color: 'var(--basic-dark)' }} spin />
            }
          />
        )}
        {PortfolioStore.collectionMyRows &&
          PortfolioStore.collectionMyRows.map((id, i) => {
            return <CollectionChoiceItem key={i} id={id} tickerId={tickerId} />;
          })}
      </div>
    </>
  );
};

const CollectionChoiceItem = observer(({ id, tickerId }) => {
  const collection = PortfolioStore.collectionData[id];
  if (!collection) return null;

  const choiced = collection.tickers?.includes(tickerId);

  console.log({ choiced });

  const onClickHandler = async () => {
    try {
      if (choiced) {
        await PortfolioStore.collectionTickerDel(id, tickerId);
        // message.success('Акция удалена из коллекции');
      } else {
        await PortfolioStore.collectionTickerSet(id, tickerId);
        // message.success('Акция добавлена в коллекцию');
      }
    } catch (e) {
      console.warn(e);
      AlertStore.error('Произошла ошибка');
    }
  };

  return (
    <Clickable className={styles.item} onClick={onClickHandler}>
      <h4>{collection.name || Lang({ str: 'untitled', section: 'portfolio' })}</h4>
      <div className={styles.itemIcon}>
        {choiced ? <Icon slug="check1" /> : <Icon slug="plus1" />}
      </div>
    </Clickable>
  );
});

export default observer(AddToCollectionModal);
