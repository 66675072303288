import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./PersonalInformation.module.scss";
import { Switch, Form, Input, Button, message } from "antd";
import userStore from "../Auth/userStore";
import { apiQuery, apiQueryError, getBase64 } from "../../Utils";
import urlStore from "../../stores/urlStore";

import HeaderTitle from "../Header/HeaderTitle";
import left_icon from "../../assets/images/arrowmenu.svg";
import bezop from "../../assets/images/uil_lock.svg";
import ReactCodeInput from "react-code-input";
import PushNotificationButton from "../PushNotification/PushNotificationButton";
import AlertStore from "../Alert/AlertStore";

const SettingsNotification = () => {
  const [fileList, setFileList] = useState(
    userStore.userData?.userData?.avatar
      ? [
          {
            uid: userStore.userData.userData.avatar,
            name: userStore.userData.userData.avatar,
            status: "done",
            url: userStore.userData.userData.avatar,
          },
          // {thumbUrl:data.data[0].preview}])
        ]
      : []
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [modalChangePass, setModalChangePass] = useState(false);
  const [restoreCode, setRestoreCode] = useState("");

  const [modalConfirm, setModalConfirm] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [list, setList] = useState(null);
  const formData = useRef();

  const onFinish = (values) => {
    values.file_base64 = fileList[0] ? fileList[0]?.thumbUrl || null : null;
    // values.date        = values.date.format('YYYY-MM-DD')
    // values.time        = values.time.format('HH:mm')
    values.preview = fileList[0] ? fileList[0]?.url || null : null;
    // values.roomId = match.params.room_id

    apiQuery("/private/profile", {
      method: "POST",
      body: values,
    })
      .then((data) => {
        if (data.emailVeriCode) {
          formData.current = values;
          setModalConfirm(true);
          return;
        }

        console.log(data);
        AlertStore.success("Сохранено");
        userStore.authCheck().catch();
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  const onFinish2 = () => {
    const data = formData.current;
    setModalConfirm(false);
    data.emailVeriCode = restoreCode;
    onFinish(data);
  };

  const onChangeFile = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  useEffect(() => {
    urlStore.setSection("");
    window.scroll(0, 0);
  }, []);

  return (
    <div className={styles.personal_information_wrp}>
      <HeaderTitle
        title={"Уведомления"}
        button1={<img src={left_icon} alt={""} />}
      />

      <div
        className={
          styles.personal_information + " mt-16" + " " + styles.horizont_in
        }
      >
        <div className={styles.security_link}>
          <div className={styles.switch_bl}>
            <div>
              <p>Приостановить все</p>
              <span>Приостановить уведомления</span>
            </div>

            <Switch defaultChecked />
          </div>
          <div className={styles.switch_bl}>
            <div>
              <p>Напоминания о повышении цены</p>
              <span>Если функция напоминания о повышений цены включена, </span>
            </div>

            <Switch defaultChecked />
          </div>
          {/*<div className={styles.switch_bl + " b-0"}>
            <div>
              <p>Оповещения в приложении</p>
              <span>
                При включении вы будете получать входящие уведомления на сайте
                или через приложения.
              </span>
            </div>

            <Switch defaultChecked />
          </div>*/}
          <div className={styles.switch_bl + " b-0"}>
            <PushNotificationButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsNotification;
