import { LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import AppStore from '../App/AppStore';
import Lang from '../Lang/index';
import styles from './Modal.module.scss';

import { useDrag } from '@use-gesture/react';
import { Spin } from 'antd';
import { animated, config, useSpring } from 'react-spring';

const antIcon = <LoadingOutlined style={{ fontSize: 20, color: '#e6f800' }} spin />;

const Modal = ({
  title,
  visible = false,
  headVisible = true,
  onOk,
  // hideModal = false,
  okText = Lang({ str: 'apply', section: 'default' }),
  okVisible = true,
  okLoading = false,
  onCancel,
  cancelText = Lang({ str: 'close', section: 'default' }),
  cancelVisible = true,
  children,
  className,
  paddingBottom,
  className2,
  newheight = AppStore.height - 110,
  newheightcontent = AppStore.height - 50,
}) => {
  const prevBodyOverflow = useRef();
  const [create, setCreate] = useState(visible);

  const [springProps, springApi] = useSpring(() => ({
    y: window.innerHeight,
    scale: 1,
    radius: 24,
    opacity: 0,
    config: {
      velocity: 2,
      friction: 24,
    }
  }));

  const bind = useDrag(({ type, down, movement: [mx, my] }) => {
    if (type === 'pointerdown')
      springApi.start({ scale: 0.9, radius: 30, config: { duration: 100 } });
    else if (type === 'pointerup') springApi.start({ scale: 1, radius: 20 });

    if (my < 0) return;

    if (my > 80 && !down) {
      closeModal();
      return;
    }

    springApi.start({
      y: down ? my : 0,
      opacity: down ? Math.max(1 - my / 120, 0) : 1,
      immediate: down,
    });
  });

  useEffect(() => {
    if (visible) {
      openModal();
    } else {
      closeModal();
    }
  }, [visible]);

  const openModal = () => {
    setCreate(true);
    setTimeout(() => {
      springApi.start({ y: 0, opacity: 1 });
    }, 10);

    prevBodyOverflow.current = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    document.body.classList.add('modal--active');
  };

  const closeModal = () => {
    springApi.start({ y: window.innerHeight, opacity: 0 });

    setTimeout(() => {
      if (onCancel) onCancel();
      setCreate(false);
    }, 200);

    document.body.classList.remove('modal--active');
    document.body.style.overflow = prevBodyOverflow.current;
  };

  if (!create) return null;

  const mOk = () => {
    if (okLoading) return;
    if (onOk) onOk();
  };

  // if(!hideModal){
  //   hideModal = ()=>{closeModal()}
  // }

  return (
    <>
      <animated.div
        style={{ opacity: springProps.opacity }}
        className={styles.overflow}></animated.div>
      <div
        className={
          styles.modal + ' ' + (visible ? styles.active : styles.unactive) + ' ' + className2
        }
        onClick={closeModal}
        style={{ height: AppStore.height }}>
        <animated.div
          className={styles.modal__content + ' ' + className}
          onClick={(e) => e.stopPropagation()}
          style={{
            // minHeight: newheightcontent,
            y: springProps.y,
            borderRadius: springProps.radius.to((x) => `${x}px ${x}px 0 0`),
          }}>
          <animated.div
            className={styles.modal__handle}
            {...bind()}
            style={{ scale: springProps.scale }}>
            <div></div>
          </animated.div>

          <div className="fon_add">
            <div className={styles.modal__head}>
              {(cancelVisible || okVisible) && (
                <div className={styles.modal__head_flex}>
                  <div className="min_width">
                    {cancelVisible && <button onClick={closeModal}>{cancelText}</button>}
                  </div>
                  <div>
                    <p>{title}</p>
                  </div>
                  <div className="min_width" style={{ textAlign: 'right' }}>
                    {okVisible && (
                      <button onClick={mOk}>
                        {okLoading ? <Spin indicator={antIcon}>{okText}</Spin> : okText}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div
              className={styles.modal__body}
              style={
                {
                  // height: newheight,
                  paddingBottom
                }
              }>
              {children}
            </div>
          </div>
        </animated.div>
      </div>
    </>
  );
};

export default observer(Modal);
