import React, { useEffect, useRef, useState } from "react";
import styles from "./Notification.module.scss";
import Lang from "../Lang/index";
import Modal from "../Modal";
import close1 from "../../assets/images/x_blue_icon2.svg";
import ModalItemStore from "./ModalItemStore";
import { observer } from "mobx-react-lite";
import DrawerMenuStore from "../DrawerMenu/DrawerMenuStore";
import { apiQuery, apiQueryError, getBase64 } from "../../Utils";
import moment from "moment";
import Notification from "./NotificationItem";
import "moment/locale/ru";

const ModalItem = (params) => {
  const { data, getInboxes } = params;

  return (
    <Modal
      visible={ModalItemStore.isModalVisibleDev}
      cancelText={
        <div className={styles.norific_header}>
          <h3 className="pl-8">{data?.text?.subject || "Уведомление"}</h3>
          <span>{moment(data?.createdAt).format("DD MMMM YYYY, HH:mm")}</span>
        </div>
      }
      okVisible={true}
      okText={<img src={close1} alt="" />}
      onOk={() => {
        // setModalOpen(false);
        ModalItemStore.close();
        // getInboxes();
      }}
    >
      <div className={styles.text_notific + " p-16"}>
        {data?.text?.long || ""}
      </div>
    </Modal>
  );
};

export default observer(ModalItem);
