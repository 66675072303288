import { useDrag } from '@use-gesture/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { $class } from '../../Utils';
import {Viewer, Worker} from "@react-pdf-viewer/core";
import {Button} from "../../UI";
import AppStore from "../App/AppStore";
import styles from './index.module.scss';
import modalStore from './modalStore';
import Lang from '../Lang';

// MODALS
import AccountStatementModal from '../Accounts/AccountStatement.modal';
import ItemHistoryDetailsModal from '../Accounts/ItemHistoryDetails.modal';
import FilterModal from '../Filter/Filter.modal';
import CollectionAddTickerModal from '../Portfolio/CollectionAddTickerModal/index';
import CollectionModalAdd from '../Portfolio/CollectionModalAdd/index';
import OrderDetailsModal from '../Wallet/Orders/OrderDetails.modal';
import PaperDetailsModal from '../Wallet/Papers/PaperDetails.modal';
import AddToCollectionModal from '../Trading/AddToCollection';
import OpenBrokerAccountModal from '../BrokerAccount/OpenBrokerAccountModal';
import SMSVerifyModal from '../SMSVerifyModal/SMSVerify.modal';
import CollectionEditModal from '../Portfolio/CollectionEdit.modal';

const modals = {
  default: ({data}) => data,
  helper: HelperModal,
  filter: FilterModal,
  confirm: ConfirmModal,
  pdf: PDFModal,

  paperDetails: PaperDetailsModal,
  orderDetails: OrderDetailsModal,

  collectionAdd: CollectionModalAdd,
  collectionEdit: CollectionEditModal,
  collectionAddTicker: CollectionAddTickerModal,
  addToCollection: AddToCollectionModal,

  accountStatement: AccountStatementModal,
  itemHistoryDetails: ItemHistoryDetailsModal,

  openBrokerAccount: OpenBrokerAccountModal,
  SMSVerify: SMSVerifyModal,
};

const ModalManager = observer(() => {
  const active = modalStore.activeModal;
  const open = modalStore._open;
  const location = useLocation();

  useEffect(() => {
    modalStore.closeModal();
  }, [location]);

  const ModalBody = active && modals[active?.name] ? modals[active.name] : () => null;

  return (
    <div className={$class(styles.modalManager, [styles.modalManagerActive, open])}>
      <div className={styles.overlay} onClick={() => modalStore.closeModal()}></div>

      <BasicModal open={open}>
        <ModalBody {...(active?.data || {})} closeModal={() => modalStore.closeModal()} />
      </BasicModal>
    </div>
  );
});

const BasicModal = ({ open, children }) => {
  const [springProps, springApi] = useSpring(() => ({
    y: 0,
  }));

  useEffect(() => {
    if (open) springApi.start({ y: 0 });
  }, [open]);

  const bind = useDrag(({ type, down, movement: [mx, my] }) => {
    if (my < 0) return;

    if (my > 80 && !down) {
      modalStore.closeModal();
      return;
    }

    springApi.start({
      y: down ? my : 0,
      immediate: down,
    });
  });

  return (
    <div className={$class(styles.basicModal, [styles.basicModalOpened, open])}>
      <animated.div style={springProps}>
        <animated.div className={styles.basicModalHandle} {...bind()}>
          <div></div>
        </animated.div>

        <div className={styles.basicModalBody}>{children}</div>
      </animated.div>
    </div>
  );
};

export default ModalManager;

function HelperModal({ title, text = '' }) {
  return (
    <div className={'p-16 pt-0 pb-56'}>
      {title && <h3 className={styles.helperTitle}>{title}</h3>}
      <div className={styles.helperText} >
        {text.includes('\n') ? text.split('\n').map(i => <p>{i}</p>) : <p>{text}</p>}
      </div>
    </div>
  );
};

function ConfirmModal({
  text,
  yesText = Lang({ str: 'yes', section: 'default' }),
  noText = Lang({ str: 'no', section: 'default' }),
  onYes = (f) => f,
}) {
  const close = () => modalStore.closeModal();

  return (
    <div className={styles.confirmModal}>
      <p>{text}</p>
      <div>
        <button
          onClick={() => {
            onYes();
            close();
          }}>
          {yesText}
        </button>
        <button onClick={close}>{noText}</button>
      </div>
    </div>
  );
};

function PDFModal({url}) {
  return (
    <div className={styles.PDFModal} style={ {height: AppStore.height - 120} }>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
        <Viewer
          fileUrl={url}
          theme={{ theme: "light" }}
          withCredentials={true}
        />
      </Worker>
      <div>
        <Button
            style={ {backgroundColor: '#221F1C'} }
            downloadUrl={url}
        >
          {Lang({ str: "download_pdf", section: "default" })}
        </Button>
      </div>
    </div>
  )
}