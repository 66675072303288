import { Form, Radio, Space, Tabs } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../UI';
import Utils, { apiQueryError, apiRequest } from '../../Utils';
import { BackButton } from '../../blocks';
import Lang from '../Lang/index';
import modalStore from '../ModalManager/modalStore';
import styles from '../PersonalInformation/PersonalInformation.module.scss';

const Reports = () => {
  const [documents, setDocuments] = useState([]);
  const [visible, setVisible] = useState(false);

  const [width, setWidth] = useState();
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      if (ref.current.offsetWidth !== width) {
        setWidth(ref.current.offsetWidth);
      }
    });

    resizeObserver.observe(ref.current);

    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, [ref.current]);

  const download = (document_id) => {
    let url = `https://${Utils.apiDomain()}/rest_api/private/v1/download_document/${document_id}`;
    modalStore.openModal('pdf', { url });

    // let url = `https://${Utils.apiDomain()}/rest_api/private/v1/download_document/${document_id}`
    // const link = document.createElement('a')
    // link.href = url
    // link.target = '_blank'
    // link.click()
  };

  const getDocuments = () => {
    apiRequest({
      url: `/private/v1/get_documents`,
      method: 'GET',
      success: async (data) => {
        if (data?.error) return; // message.error(data?.error.msg);
        console.log('documents: ', data);
        if (Array.isArray(data)) {
          setDocuments(data);
        } else {
          setDocuments([]);
        }
      },
    }).catch((error) => {
      apiQueryError({ error });
    });
  };

  useEffect(() => {
    getDocuments();
  }, []);

  const onChange = ({ target: { value } }) => {
    console.log('radio checked', value);
    setVisible(true);
    return value;
  };
  const onFinish = (onChange) => {
    download(onChange.mode);
    console.log(onChange);
  };
  function callback(key) {
    setTab(key);
  }
  const { TabPane } = Tabs;
  const [tab, setTab] = useState('1');

  return (
    <div className={styles.personal_information_wrp}>
      <BackButton sticky />

      <div className={styles.personal_language + ' ' + styles.horizont_in}>
        <p className={styles.titile}>{Lang({ str: 'reports', section: 'menu' })}</p>
        {/*<div className={styles.output_bl}>
         <p className={styles.output_bl_title}>
            {Lang({ str: "period", section: "accounts" })}
          </p>
          <Form>
            <Space direction="horizontal">
              <DatePicker onChange={onChange} />
              <DatePicker onChange={onChange} />
              <Button>
                <CheckOutlined />
              </Button>
            </Space>
            <Button className={styles.button_w}>
              {Lang({ str: "getreports", section: "menu" })}
            </Button>
          </Form>
        </div>*/}
        {documents.length == 0 ? (
          <p className={styles.text_reports}>{Lang({ str: 'no_statements', section: 'menu' })}</p>
        ) : (
          <div className={styles.reports} ref={ref}>
            <div className={styles.reports_tab}>
              <Tabs
                className="scroll"
                defaultActiveKey={tab}
                onChange={callback}
                activeKey={tab}
                centered="true">
                <TabPane tab={Lang({ str: 'statements', section: 'menu' })} key="1">
                  <Form onFinish={onFinish}>
                    <Form.Item name={'mode'}>
                      <Radio.Group onChange={onChange}>
                        <Space direction="vertical">
                          {documents.map((item, index) => {
                            return (
                              item?.type == 'account_statement' && (
                                <Radio value={item?.id}>
                                  <p>
                                    {item?.type}
                                    <span>{moment(item?.date).format('DD.MM.YYYY')}</span>
                                  </p>
                                </Radio>
                              )
                            );
                          })}
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                    {visible && (
                      <Form.Item>
                        <Button style={{ width: width }} className={styles.but_report}>
                          {Lang({ str: 'open_pdf', section: 'menu' })}
                        </Button>
                      </Form.Item>
                    )}
                  </Form>
                </TabPane>
                <TabPane tab={Lang({ str: 'confirmations', section: 'menu' })} key="2">
                  <Form onFinish={onFinish}>
                    <Form.Item name={'mode'}>
                      <Radio.Group onChange={onChange}>
                        <Space direction="vertical">
                          {documents.map((item, index) => {
                            return (
                              item?.type == 'trade_confirmation' && (
                                <Radio value={item?.id}>
                                  <p>
                                    {item?.type}
                                    <span>{moment(item?.date).format('DD.MM.YYYY')}</span>
                                  </p>
                                </Radio>
                              )
                            );
                          })}
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                    {visible && (
                      <Form.Item className={styles.buttonBlockFixed}>
                        <Button style={{ width: width }} className={styles.but_report}>
                          {Lang({ str: 'open_pdf', section: 'menu' })}
                        </Button>
                      </Form.Item>
                    )}
                  </Form>
                </TabPane>
              </Tabs>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Reports;
