import React, { useEffect, useState } from "react";
import styles from "../BrokerAccountNew/BrokerAccountNew.module.scss";
import { Button, Form } from "antd";
import { observer } from "mobx-react-lite";
import Lang from "../Lang/index";
import BrokerAccountStore from "./BrokerAccountStore";

const Step2 = ({setBack}) => {

  const traderData = BrokerAccountStore.traderData;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [form] = Form.useForm();
  const onFinishForm = () => {
    let body = {
      view: "questionnaire",
      step: 1,
    };
    BrokerAccountStore.save(body);
  };

  const setStep = (step) => {
    let body = {
      view: "questionnaire",
      step,
    };
    BrokerAccountStore.save(body);
  }

  useEffect(() => {
    const scroll = window.document.getElementsByClassName('basic-routes');
    if (scroll[0]) scroll[0].scrollTo(0, 0);

    setBack({
      view: "questionnaire",
      step: 1
    })
  },[])

  return (
    <div className={styles.page_fl}>
      <div className={styles.text_bl}>
        <h4>{Lang({ str: "broker_profile", section: "onboarding" })}</h4>
        <div className={styles.group_link + " " + styles.group_link1}>
          <span onClick={() => setStep(3)}>
            {Lang({ str: "personal_data", section: "onboarding" })}
            {!traderData?.mark?.personalData && <b/>}
          </span>
          <span onClick={() => setStep(4)}>
            {Lang({ str: "aml_cft", section: "onboarding" })}
            {!traderData?.mark?.pdo && <b/>}
          </span>
          <span onClick={() => setStep(5)}>
            {Lang({ str: "financial_profile", section: "onboarding" })}
            {!traderData?.mark?.financialProfile && <b/>}
          </span>
          <span onClick={() => setStep(6)}>
            {Lang({ str: "risk_appetite", section: "onboarding" })}
            {!traderData?.mark?.riskAppetite && <b/>}
          </span>
        </div>
      </div>
      <Form className={styles.form} form={form} onFinish={onFinishForm}>
        <Form.Item className={styles.buttonBlockFixed + " m-0 b-0"}>
          <Button
            type="primary"
            htmlType="submit"
            className={styles.gr_btn + " " + styles.activ}
            disabled={buttonDisabled}
          >
            {Lang({ str: "continue", section: "onboarding" })}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(Step2);
