import { Carousel, message } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import arrow_left from '../../assets/images/arrow_left.svg';
import arrow_right from '../../assets/images/arrow_right.svg';
import copyicon from '../../assets/images/copyicon.svg';
import manual14 from '../../assets/images/manual14.png';
import manual15 from '../../assets/images/manual15.png';
import manual13 from '../../assets/images/manual13.png';
import manual12 from '../../assets/images/manual6.svg';
import { BackButton } from '../../blocks';
import styles from '../Accounts/Accounts.module.scss';
import Lang from '../Lang/index';
import TitleCategory from '../TitleCategory';

const ManualSmart = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [active, setActive] = useState(false);
  const categoryConfig = [
    {
      title: Lang({ str: 'replenishment_instructions_in', section: 'accounts' }) + ' SmartBank',
    },
  ];

  const onChange = (currentSlide) => {
    console.log(currentSlide);
    currentSlide == '5' && setActive(true);
  };

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.next();
  };
  const previous = () => {
    sliderRef.prev();
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const onCopy = useCallback(() => {
    messageApi.open({
      type: 'success',
      content: 'Скопировано в буфер обмена',
    });
  }, []);
  return (
    <>
      <div className={styles.account_page_sl}>
        {contextHolder}
        <BackButton label={Lang({ str: 'back', section: 'default' })} />
        <TitleCategory title={categoryConfig[0].title} />
        <div className={styles.info_bl + ' mt-8'}>
          <Carousel
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...settings}
            afterChange={onChange}>
            <div className={styles.info_bl_one}>
              <ul>
                <li>{Lang({ str: 'open_smartbank', section: 'accounts' })}</li>
                <li>{Lang({ str: 'go_to_transfers', section: 'accounts' })}</li>
                <li>{Lang({ str: 'select_international_transfers', section: 'accounts' })}</li>
              </ul>
              <img className={styles.center_img} src={manual13} />
            </div>
            <div className={styles.info_bl_one}>
              <ul>
                <li>{Lang({ str: 'in_international_transfers', section: 'accounts' })}</li>
                <li>{Lang({ str: 'enter_data', section: 'accounts' })}</li>
                <li>
                  {Lang({ str: 'copy', section: 'accounts' })} {Lang({ str: 'iin', section: 'accounts' })}:
                  <CopyToClipboard onCopy={onCopy} text="210840900168">
                    <div className={styles.requisites_one}>
                      <div>
                        <span>{Lang({ str: 'iin', section: 'accounts' })}/{Lang({ str: 'bin', section: 'accounts' })}</span>
                        <p>210840900168</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                </li>
                <li>
                  {Lang({ str: 'copy', section: 'accounts' })} {Lang({ str: 'full_name', section: 'accounts' })}:
                  <CopyToClipboard onCopy={onCopy} text="Geeko Invest Limited">
                    <div className={styles.requisites_one}>
                      <div>
                        <span>{Lang({ str: 'recipient', section: 'accounts' })}/{Lang({ str: 'full_name', section: 'accounts' })}</span>
                        <p>Geeko Invest Limited</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                </li>
                <li>
                  {Lang({ str: 'copy', section: 'accounts' })} {Lang({ str: 'iban', section: 'accounts' })}:
                  <CopyToClipboard onCopy={onCopy} text="KZ64601A861024970331 ">
                    <div className={styles.requisites_one}>
                      <div>
                        <span>{Lang({ str: 'iban', section: 'accounts' })}</span>
                        <p>KZ64601A861024970331 </p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                </li>
                <li>
                  {Lang({ str: 'copy_address', section: 'accounts' })}:
                  <CopyToClipboard onCopy={onCopy} text="Astana ">
                    <div className={styles.requisites_one}>
                      <div>
                        <span>{Lang({ str: 'address', section: 'accounts' })}</span>
                        <p>Astana </p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                </li>
                <li>
                  {Lang({ str: 'select_country', section: 'accounts' })}:
                  <span>{Lang({ str: 'kazakhstan', section: 'accounts' })}</span>
                </li>
                <li>
                  {Lang({ str: 'select_bank_recipient', section: 'accounts' })}:
                  <span>АО «Народный Банк Казахстан»</span>
                </li>
                <li>{Lang({ str: 'click_continue', section: 'accounts' })}</li>
              </ul>
              <img className={styles.center_img} src={manual14} />
            </div>

            <div className={styles.info_bl_one}>
              <ul>
                <li>Выберите счет для перевода на брокерский счет</li>
                <li>{Lang({ str: 'enter_transfer_amount', section: 'accounts' })}</li>
                <li>
                  {Lang({ str: 'select', section: 'accounts' })} {Lang({ str: 'knp', section: 'accounts' })}:
                  <span>343 </span>
                </li>
              </ul>
              <img className={styles.center_img} src={manual15} />
            </div>

            <div className={styles.info_bl_one}>
              <ul>
                <li>{Lang({ str: 'transfer_completed', section: 'accounts' })}</li>
              </ul>
              <img className={styles.center_img} src={manual12} />
            </div>
          </Carousel>

          <div className={styles.info_buttons}>
            <button className="btn" onClick={previous}>
              <img src={arrow_left} />
            </button>
            <button className="btn" onClick={next}>
              <img src={arrow_right} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(ManualSmart);
