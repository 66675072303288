export const fields = {
  income_statement: [
    { field: 'revenue', label: 'Revenue' },
    { field: 'grossProfit', label: 'Gross Profit' },
    { field: 'costAndExpenses', label: 'Total Operating Expense' },
    { field: 'operatingIncome', label: 'Operating Income' },
    { field: 'incomeBeforeTax', label: 'Net Income Before Taxes' },
    { field: 'netIncome', label: 'Net Income' },
    { field: 'eps', label: 'Earnings per share (EPS)', isRatio: true },
    { field: 'weightedAverageShsOut', label: 'Weighted Average of Outstanding Shares ' },
  ],
  balance_sheet_statement: [
    { field: 'totalAssets', label: 'Total Assets' },
    { field: 'totalCurrentAssets', label: 'Total Current Assets' },
    { field: 'totalLiabilities', label: 'Total Liabilities' },
    { field: 'totalCurrentLiabilities', label: 'Total Current Liabilities' },
    { field: 'totalEquity', label: 'Total Equity' },
    { field: 'cashAndCashEquivalents', label: 'Cash Equivalents' },
    { field: 'longTermDebt', label: 'Long Term Debt' },
    { field: 'netDebt', label: 'Net Debt' },
  ]
};