import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { $class } from '../../../Utils';
import { TickerOrderCard, TickerOrderHistoryCard } from '../../../blocks';
import FilterBtn from '../../Filter/FilterBtn';
import Lang from '../../Lang';
import modalStore from '../../ModalManager/modalStore';
import stylesW from '../Wallet.module.scss';
import WalletStore from '../WalletStore';
import styles from './index.module.scss';

const Orders = ({ history }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const items = history ? WalletStore?.activities : WalletStore?.orders;
  console.log(items)

  useEffect(() => {
    if (history) WalletStore.getActivities({ limit: 15 });
    else WalletStore.getOrders();
  }, [history]);

  return (
    <>
      <FilterBtn className={styles.filterBtn} onClick={() => navigate('/wallet/history?openFilter=1', { state: location })} />

      <div
        className={$class(styles.list, 'orders-list')}
        style={{ height: window.innerHeight - 290 }}>
        {items === null && (
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <Spin
              indicator={
                <LoadingOutlined style={{ fontSize: 24, color: 'var(--basic-dark)' }} spin />
              }
            />
          </div>
        )}

        {items?.length === 0 && (
          <div className={stylesW.empty_block}>
            {/* <Icon slug="paperSearch" size={100} /> */}
            {!history && <p>{Lang({ str: 'no_orders', section: 'wallet' })}</p>}
            {history && <p>{Lang({ str: 'no_history', section: 'wallet' })}</p>}
          </div>
        )}

        {history && (
          <div style={{ paddingBottom: '140px' }}>
            {items?.slice(0, 10)?.map((item, i) => (
              <div style={{ marginBottom: '16px' }} key={i}>
                <TickerOrderHistoryCard
                  onClick={() =>
                    modalStore.openModal('orderDetails', {
                      details: item,
                      isHistory: true,
                    })
                  }
                  ticker={item.ticker}
                  order={item}
                />
              </div>
            ))}

            {items !== null && items?.length > 10 && (
              <button
                className={stylesW.btn_all}
                onClick={() => navigate('/wallet/history', { state: location })}>
                {Lang({ str: 'show_more', section: 'home' })}
              </button>
            )}
          </div>
        )}

        {!history &&
          items?.map((item) => (
            <div className="pb-16">
              <TickerOrderCard
                onClick={() =>
                  modalStore.openModal('orderDetails', {
                    details: item.obj,
                    isHistory: false,
                  })
                }
                tickerId={item.obj.tickerId}
                order={item.obj}
              />
            </div>
          ))}
      </div>
    </>
  );
};

export default observer(Orders);
