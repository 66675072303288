import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import fav from '../../assets/images/fav.svg';
import PortfolioStore from '../../components/Portfolio/PortfolioStore';

import { Clickable } from '../../UI';
import { $class, shimmer } from '../../Utils';
import CollectionTickerLogo from '../../components/CollectionItem/CollectionTickerLogo';
import Lang from '../../components/Lang';
import PriceChanging from '../PriceChanging';
import './index.scss';

const CollectionCard = ({ tickerId, favorites = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const item = !favorites
    ? PortfolioStore.collectionData[tickerId]
    : {
        logo: fav,
        name: 'Избранное',
        tickers: PortfolioStore.favoritesRows,
        percent: 0,
      };

  return (
    <div className={$class('collection-card', shimmer('block', tickerId === undefined))}>
      <Clickable onClick={() => navigate(`/favorites/${tickerId}`, { state: location })}>
        <div style={ {width: '72%'} }>
          <h4 className="collection-card-name">{item?.name || Lang({ str: 'untitled', section: 'portfolio' })}</h4>
        </div>
        <div>
          <div className="collection-card-symbols mb-4">
            {item?.tickers &&
              item?.tickers
                ?.slice(0, 3)
                .map((ticker, i) => <CollectionTickerLogo key={i} tickerId={ticker} />)}
            {item?.tickers && item?.tickers?.length - 3 > 0 && (
              <div className="collection-card-symbols-more">
                <p>{`+${item?.tickers?.length - 3}`}</p>
              </div>
            )}

            {!item?.tickers?.length && (
              <p style={{ height: 30, lineHeight: 'normal', whiteSpace: 'nowrap' }}>
                {Lang({ str: 'no_stocks_2', section: 'portfolio' })}
              </p>
            )}
          </div>
          <PriceChanging change={item?.percent} size="md" align="end" />
        </div>
      </Clickable>
    </div>
  );
};

export default CollectionCard;
