import {observer} from "mobx-react-lite";
import {useEffect, useRef, useState} from "react";
import userStore from "../Auth/userStore";
import OneSignal from 'react-onesignal';
import PushNotificationStore from "./PushNotificationStore";
import Lang from "../Lang";

async function geekoAppListener(event){

  if (window.navigator.userAgent !== 'GeekoTech') return;
  console.log({event})
  try {
    let data = JSON.parse(event.data)
    if (data.pushNotification){

      switch (data.mode) {
        case 'subscriptionChange':

          PushNotificationStore.updateMangeWebPushSubscriptionButton();
          break;

        case 'getSubscriptionState':

          PushNotificationStore.webPushGetSubscriptionState(data.data);
          if(window.navigator.userAgent === 'GeekoTech'){
            try{
              window.ReactNativeWebView.postMessage("WebPushGetUserId");
            }
            catch (e) {
              console.error({e})
            }
          }

          break;

        case 'checkPush':
            // await userStore.checkPush('3bdc6ced-2627-4b66-9915-c1dcfb125123')
            try{
              if(!userStore?.auth){
                window.ReactNativeWebView.postMessage("error auth pushId " + data.data?.pushId);
              }
              let push_result = await userStore.checkPush(data.data?.pushId)
              // if(push_result?.status == 'ok'){
              //   window.ReactNativeWebView.postMessage("checkPush " + push_result?.is_subscribed);
              // }
              // else{
              //   window.ReactNativeWebView.postMessage("checkPush error status");
              // }
            }
            catch (e) {
              window.ReactNativeWebView.postMessage(`checkPush error try`);
              console.error({e})
            }
          break;

        case 'getUserId':

          await userStore.pushSave(data.data?.pushId, data.data?.isEnabled, 'getUserId')
          break;
      }

    }
  } catch (e) {
  }
}

async function runOneSignal(initCheck) {
  console.log('runOneSignal: ', initCheck)
  if(window.navigator.userAgent === 'GeekoTech'){
    PushNotificationStore.updateMangeWebPushSubscriptionButton();
    document.addEventListener("message", event => {
      console.log('addEventListener message: ', event)
      geekoAppListener(event)
    });
    window.addEventListener("message", event => {
      console.log('addEventListener message: ', event)
      geekoAppListener(event)
    });
    window.ReactNativeWebView.postMessage(`runOneSignalStarted`);
    return;
  }

  let appId;
  let safari_web_id;
  if(window.location.origin === 'http://dev.geekoinvest.kz:3101') {
    appId = "f48f65b4-e99a-4d6d-ba7a-789085d71157";
    safari_web_id = "web.onesignal.auto.18c6dc90-7633-4ce6-8875-ae2763214094";
  }
  else if(window.location.origin === 'http://dev.geekoinvest.kz:3102') {
    // appId = "f48f65b4-e99a-4d6d-ba7a-789085d71157";
    // safari_web_id = "web.onesignal.auto.18c6dc90-7633-4ce6-8875-ae2763214094";
    appId = "cdd34871-5f11-464c-8e21-84764736fec9";
    safari_web_id = "web.onesignal.auto.20000090-ae08-4d2f-988d-291912c8a9bf";
  }
  else if(window.location.origin === 'https://f8d3k6z1.geekoinvest.kz') {
    appId = "cdd34871-5f11-464c-8e21-84764736fec9";
    safari_web_id = "web.onesignal.auto.20000090-ae08-4d2f-988d-291912c8a9bf";
  }
  else if(window.location.origin === 'https://app.geekoinvest.kz') {
    appId = "e1d526c2-a101-4965-a7ad-7ad282bee3e2";
    safari_web_id = "web.onesignal.auto.4787ada6-f101-40da-894e-0a68fad84e0f";
  } else {
    appId = "47713986-4913-4b94-a3ca-2da640a255a9";
    safari_web_id = "web.onesignal.auto.3b8b9214-66ac-44d1-a7fb-a9dc856242cb";
  }

  console.log({appId, safari_web_id})

  try {
    await OneSignal.init({
      appId: appId,
      safari_web_id: safari_web_id,
        "promptOptions": {
          "slidedown": {
            "prompts": [
              {
                type: "push", // current types are "push" & "category"
                text: {
                  /* limited to 90 characters */
                  actionMessage: Lang({str: "one_signal_msg", section: "home"}),
                  /* acceptButton limited to 15 characters */
                  acceptButton: Lang({str: "subscribe", section: "home"}),
                  /* cancelButton limited to 15 characters */
                  cancelButton: Lang({str: "later", section: "home"})
                },
                delay: {
                  pageViews: 1,
                  timeDelay: 5
                }
              }
            ]
          },
        },
    })

    console.log('OneSignal init: ', {userStore})
    await OneSignal.login(userStore.userData._id);

    initCheck.current = true;
    PushNotificationStore.setInit(true);

    OneSignal.User.PushSubscription.addEventListener("change", async function(event) {
      console.log('addEventListener change:', event)
      /* If the user's subscription state changes during the page's session, update the button text */
      const pushId = event.current.id
      console.log({pushId})
      if (!pushId) {

        return;
      }

      await userStore.pushSave(pushId, event.current.optedIn)

      // PushNotificationStore.updateMangeWebPushSubscriptionButton();
      PushNotificationStore.updateButton(event.current.optedIn)

    });

    OneSignal.Notifications.addEventListener('permissionChange', async function(event) {
      console.log('addEventListener permissionChange:', event)
      console.log({OneSignal})

      const pushId = OneSignal.User.PushSubscription.id
        console.log({pushId})
        if (!pushId) {

          return;
        }

        await userStore.pushSave(pushId)

    });

    PushNotificationStore.updateMangeWebPushSubscriptionButton();

  } catch (e) {
    console.error(e);
  }
}

const PushNotification = () => {

  const initCheck = useRef(false);
  const auth = userStore.auth;

  console.log({userData: userStore.userData})

  useEffect(() =>{
    console.log('userStore.auth changed: ', userStore.auth)
    if (!userStore.auth || initCheck.current) return;

    runOneSignal(initCheck);
  }, [/*auth, */userStore.auth])

  return null;
}

export default observer(PushNotification);
