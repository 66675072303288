import React, { useState, useEffect } from "react";
import ModalCustom from "../Modal";
import { Button, Form, Radio, Space, Modal, message } from "antd";
import Logo from "../Logo";
import TitleCategory from "../TitleCategory";
import icon1 from "../../assets/images/newspaper.svg";
import HeaderTitle from "../Header/HeaderTitle";
import icon2 from "../../assets/images/Sanitysvg.svg";
import { Link } from "react-router-dom";
import "./Wallet.scss";
import styles from "./Wallet.module.scss";
import close1 from "../../assets/images/close1.svg";
import left_icon from "../../assets/images/arrowmenu.svg";
import { apiQuery, apiQueryError } from "../../Utils";
import userStore from "../Auth/userStore";

import bank_deposit from "../../assets/images/bank_deposit.svg";
import AlertStore from "../Alert/AlertStore";

const categoryConfig = [
  {
    title: "Перевод в Geeko.tech",
    image: `${icon1}`,
  },
];

const AddBalance = () => {
  const [visible, setVisible] = React.useState(true);
  const [paySuccessVisible, setPaySuccessVisible] = React.useState(false);
  const [payErrorVisible, setPayErrorVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState(
    "Приложение создано для обучения торговле ценными бумагами. Депозит невозможно пополнить реальными деньгами и невозможно вывести средства. Вы согласны продолжить?"
  );
  const [modalPaySuccessText, setModalPaySuccessText] = React.useState("");
  const [modalPayErrorText, setModalPayErrorText] = React.useState("");
  const [backUrl, setBackUrl] = React.useState("https://v2.geeko.tech/wallet");
  const [modalPdfOpen, setModalPdfOpen] = React.useState(false);
  useEffect(() => {
    if (modalPaySuccessText) {
      setPaySuccessVisible(true);
    }
  }, [modalPaySuccessText]);

  useEffect(() => {
    if (modalPayErrorText) {
      setPayErrorVisible(true);
    }
  }, [modalPayErrorText]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const [fixAmount, setFixAmount] = useState(0);

  const getPaymentUrl = () => {
    const url = apiQuery("/private/payment-url?fix_amount=" + fixAmount, {
      method: "GET",
    })
      .then((data) => {
        console.log({ data });
        if (data.status !== "ok") {
          return false;
        }
        if (!data.url) {
          return false;
        }
        return data.url;
      })
      .catch((error) => {
        apiQueryError({ error });
        return false;
      });
    return url;
  };

  const sendPay = () => {
    const data = apiQuery("/private/demo-pay?fix_amount=" + fixAmount, {
      method: "GET",
    })
      .then((data) => {
        console.log({ data });
        // if(data.status !== 'ok'){
        //     return false
        // }
        // if(!data.url){
        //     return false
        // }
        return data;
      })
      .catch((error) => {
        //apiQueryError({error});
        AlertStore.error("Ошибка! Повторите попытку позже.");
        return false;
      });
    return data;
  };

  const requisiteSendToEmail = () => {
    apiQuery("/private/requisiteSendToEmail", {
      method: "GET",
    })
      .then((data) => {
        AlertStore.success("Реквизиты отправлены на ваш email.");
      })
      .catch((error) => {
        //apiQueryError({error});
        AlertStore.error("Ошибка! Повторите попытку позже.");
        return false;
      });
  };

  const pay = async () => {
    console.log("pay: ", fixAmount);
    setModalPaySuccessText("");
    setPayErrorVisible("");
    if (fixAmount > 0) {
      const data = await sendPay();
      console.log({ data });
      if (data?.status !== "ok") {
        // window.open(paymentUrl)
        // window.location = paymentUrl
        // alert(data?.msg)
        setModalPayErrorText(data?.msg);
        return;
      }
      setModalPaySuccessText(
        "Ваш баланс пополнен. В ближайшее время денежные средства появятся в Вашем кошельке. "
      );
      // alert('Успешно пополнено')
      // window.location = '/wallet/transfer/confirm'
      if (data?.url) {
        setBackUrl(data?.url);
      }
    } else {
      console.log("amount <= 0");
      // alert('Сумма пополнения должна быть больше 0')
      setModalPayErrorText("Сумма пополнения должна быть больше 0");
    }
  };

  const handleChange = (event) => {
    let value = Number(event.target.value);
    if (Number.isNaN(value)) value = 0;
    setFixAmount(value);
  };

  return (
    <>
      <HeaderTitle
        button1={<img src={left_icon} alt={""} />}
        title={"Пополнить баланс"}
      />

      <div className="mt-24">
        <Link to="/wallet/add_balance_bank" className={styles.block_one}>
          <div className={styles.block_one_wrp}>
            <div className="width_collect">
              <img className={styles.img_fff} src={bank_deposit} />
              <p>Пополнить счет через банк</p>
            </div>
            <img src={left_icon} />
          </div>
        </Link>
      </div>
    </>
  );
};

export default AddBalance;
