import React, { useState, useEffect } from "react";
import ModalCustom from "../Modal";
import { Button, Form, Radio, Input, Checkbox, message } from "antd";
import Logo from "../Logo";
import TitleCategory from "../TitleCategory";
import icon1 from "../../assets/images/newspaper.svg";
import HeaderTitle from "../Header/HeaderTitle";
import "./Wallet.scss";
import styles from "./Wallet.module.scss";
import polygon from "../../assets/images/polygon.svg";
import rejected from "../../assets/images/n_close.svg";
import executed from "../../assets/images/downarr.svg";
import left_icon from "../../assets/images/arrowmenu.svg";
import { apiQuery, apiQueryError } from "../../Utils";
import BorderBlock from "../BorderBlock/index";
import userStore from "../Auth/userStore";
import img22 from "../../assets/images/_4.jpg";
import AlertStore from "../Alert/AlertStore";

const categoryConfig = [
  {
    title: "Перевод в Geeko.tech",
    image: `${icon1}`,
  },
];

const WithdrawalBalance = () => {
  const [visible, setVisible] = React.useState(true);
  const [paySuccessVisible, setPaySuccessVisible] = React.useState(false);
  const [payErrorVisible, setPayErrorVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState(
    "Приложение создано для обучения торговле ценными бумагами. Депозит невозможно пополнить реальными деньгами и невозможно вывести средства. Вы согласны продолжить?"
  );
  const [modalPaySuccessText, setModalPaySuccessText] = React.useState("");
  const [modalPayErrorText, setModalPayErrorText] = React.useState("");
  const [backUrl, setBackUrl] = React.useState("https://v2.geeko.tech/wallet");
  const [modalPdfOpen, setModalPdfOpen] = React.useState(false);
  useEffect(() => {
    if (modalPaySuccessText) {
      setPaySuccessVisible(true);
    }
  }, [modalPaySuccessText]);

  useEffect(() => {
    if (modalPayErrorText) {
      setPayErrorVisible(true);
    }
  }, [modalPayErrorText]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const [fixAmount, setFixAmount] = useState(0);

  const getPaymentUrl = () => {
    const url = apiQuery("/private/payment-url?fix_amount=" + fixAmount, {
      method: "GET",
    })
      .then((data) => {
        console.log({ data });
        if (data.status !== "ok") {
          return false;
        }
        if (!data.url) {
          return false;
        }
        return data.url;
      })
      .catch((error) => {
        apiQueryError({ error });
        return false;
      });
    return url;
  };

  const sendPay = () => {
    const data = apiQuery("/private/demo-pay?fix_amount=" + fixAmount, {
      method: "GET",
    })
      .then((data) => {
        console.log({ data });
        // if(data.status !== 'ok'){
        //     return false
        // }
        // if(!data.url){
        //     return false
        // }
        return data;
      })
      .catch((error) => {
        //apiQueryError({error});
        AlertStore.error("Ошибка! Повторите попытку позже.");
        return false;
      });
    return data;
  };

  const requisiteSendToEmail = () => {
    apiQuery("/private/requisiteSendToEmail", {
      method: "GET",
    })
      .then((data) => {
        AlertStore.success("Реквизиты отправлены на ваш email.");
      })
      .catch((error) => {
        //apiQueryError({error});
        AlertStore.error("Ошибка! Повторите попытку позже.");
        return false;
      });
  };

  const pay = async () => {
    console.log("pay: ", fixAmount);
    setModalPaySuccessText("");
    setPayErrorVisible("");
    if (fixAmount > 0) {
      const data = await sendPay();
      console.log({ data });
      if (data?.status !== "ok") {
        // window.open(paymentUrl)
        // window.location = paymentUrl
        // alert(data?.msg)
        setModalPayErrorText(data?.msg);
        return;
      }
      setModalPaySuccessText(
        "Ваш баланс пополнен. В ближайшее время денежные средства появятся в Вашем кошельке. "
      );
      // alert('Успешно пополнено')
      // window.location = '/wallet/transfer/confirm'
      if (data?.url) {
        setBackUrl(data?.url);
      }
    } else {
      console.log("amount <= 0");
      // alert('Сумма пополнения должна быть больше 0')
      setModalPayErrorText("Сумма пополнения должна быть больше 0");
    }
  };

  const handleChange = (event) => {
    let value = Number(event.target.value);
    if (Number.isNaN(value)) value = 0;
    setFixAmount(value);
  };

  return (
    <div className="fon_black">
      <HeaderTitle
        button1={<img src={left_icon} alt={""} />}
        title={"Создать поручение"}
      />

      <div className="mt-24 pb-64">
        <div className={styles.add_balance}>
          <p className={styles.add_balance_title}>Вывод денег</p>
          <BorderBlock />
          <div className={styles.text_b + " mt-24"}>
            <p className={styles.table_title}>Текущие остатки</p>
            <table className={styles.table_block}>
              <tr>
                <td>Валюта</td>
                <td>USD</td>
              </tr>
              <tr>
                <td>Доступно на торгах</td>
                <td>24.10</td>
              </tr>
              <tr>
                <td>Избыток обеспечения</td>
                <td>53.47</td>
              </tr>
              <tr>
                <td>Заблок. с карт</td>
                <td>0.00</td>
              </tr>
              <tr>
                <td>Доступно к выводу</td>
                <td>53.47</td>
              </tr>
            </table>
            <p>
              * Избыток обеспечения - максимальная сумма, на которую может быть
              уменьшен счет без нарушения параметров риска по портфелю. При
              расчете доступной к выводу суммы, берется минимум между остатком
              на торгах и избытком обеспечения. Избыток обеспечения может быть
              меньше фактического остатка на торгах - например, если есть
              активные приказы.
            </p>
          </div>
          <Form layout="vertical">
            <div className={styles.text_b + " mt-24"}>
              <div className={styles.table_bord}>
                <span className={styles.table_title_gr}>Списать со счета</span>
                <p>Торговый</p>
              </div>
              <div className={styles.table_bord}>
                <span className={styles.table_title_gr}>Валюта</span>
                <p>USD (доступно к выводу 900 $)</p>
              </div>
              <div className={styles.table_bord}>
                <span className={styles.table_title_gr}>ИИН клиента</span>
                <p>010911600043</p>
              </div>
              <div className={styles.table_bord + " pb-16"}>
                <span className={styles.table_title_gr}>Способ выдачи</span>
                <Checkbox>
                  Прошу перевести средства на указанные реквизиты
                </Checkbox>
              </div>

              <div className={styles.table_bord}>
                <p className={styles.table_titl}>Выберите новые реквизиты</p>
                <Input placeholder="Указать новые реквизиты" />
                <span>
                  В целях безопасности мы выводим средства только на банковские
                  реквизиты владельца счета. Вывод на счета третьих лиц
                  невозможен.
                </span>
              </div>
              <div className={styles.table_bord}>
                <p className={styles.table_titl + " " + styles.color_d}>
                  Реквизиты
                </p>
                <p className={styles.table_titl}>Банк получателя</p>

                <Form.Item name="" label="Страна в которую выводятся деньги">
                  <Input placeholder="Выберите страну" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="Ваш IBAN или номер счета в банке">
                  <Input placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="Наименование банка">
                  <Input placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="SWIFT">
                  <Input placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="КБЕ">
                  <Input placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="КНП">
                  <Input placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="Получатель">
                  <Input placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="Адрес получателя">
                  <Input placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <p className={styles.table_titl}>Банк посредник</p>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="Номер счета в банке корреспонденте">
                  <Input placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="Название банка корреспондента">
                  <Input placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="Адрес банка корреспондента">
                  <Input placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="SWIFT Банка корреспондента">
                  <Input placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="Назначение платежа">
                  <Input
                    name="sd"
                    value="Withdrawl of clients funds account 7F78647. F"
                  />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <p className={styles.table_titl}>Сумма вывода</p>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="Укажите сумму, $">
                  <Input name="summ" />
                </Form.Item>
                <span>
                  Вывод денежных средств на банковский счет Клиента производится
                  в течение 10 (десяти) рабочих дней с момента подачи поручения
                  с корректными данными
                </span>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="Доступный остаток, $">
                  <Input placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="Комиссия, $ ">
                  <Input placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="Валюта списания комиссии">
                  <Input placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="Итого к получению, $">
                  <Input placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <p className={styles.table_titl}>в том числе</p>
              </div>
              <div className={styles.table_bord}>
                <Form.Item name="" label="Комиссия за вывод средств, $">
                  <Input placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.table_bord}>
                <Form.Item
                  name=""
                  label="Комиссия за вывод иностранной валюты, $"
                >
                  <Input placeholder="" />
                </Form.Item>
              </div>
            </div>
          </Form>
          <div className={styles.table_bord + " b-0 mt-24"}>
            <span>
              Мы отправим сумму, указанную в поле «итого к получению». При этом
              комиссия за вывод средств не взимается при отправке денег на счет
              в АО «Geeko invest». Вы можете открыть бесплатную цифрофую карту
              банка, подав поручение в личном кабинете.
            </span>
          </div>
          <button className={styles.butt_b + " mt-24"}>Подтвердить</button>
          <div className={styles.old_transfer}>
            <p className={styles.table_title}>Ранее поданные поручения</p>
            <div className={styles.old_transfer_table}>
              <div className={styles.table_item}>
                <p>
                  <img src={polygon} /> 2023-02-23
                </p>
                <p>15:50:23</p>
                <p>
                  <img src={executed} /> Исполнено
                </p>
              </div>
              <div className={styles.table_item}>
                <p>
                  <img src={polygon} /> 2023-02-23
                </p>
                <p>15:50:23</p>
                <p>
                  <img src={rejected} /> Отклонено
                </p>
              </div>
              <div className={styles.table_item}>
                <p>
                  <img src={polygon} /> 2023-02-23
                </p>
                <p>15:50:23</p>
                <p>
                  <img src={executed} /> Исполнено
                </p>
              </div>
              <div className={styles.table_item}>
                <p>
                  <img src={polygon} /> 2023-02-23
                </p>
                <p>15:50:23</p>
                <p>
                  <img src={executed} /> Исполнено
                </p>
              </div>
              <div className={styles.table_item}>
                <p>
                  <img src={polygon} /> 2023-02-23
                </p>
                <p>15:50:23</p>
                <p>
                  <img src={rejected} /> Отклонено
                </p>
              </div>
              <div className={styles.table_item}>
                <p>
                  <img src={polygon} /> 2023-02-23
                </p>
                <p>15:50:23</p>
                <p>
                  <img src={executed} /> Исполнено
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalBalance;
