import React, { useCallback, useEffect, useState } from "react";
import styles from "../Accounts/Accounts.module.scss";
import left_icon from "../../assets/images/arrowmenu.svg";
import { observer } from "mobx-react-lite";
import { Tabs, InputNumber, Form, Button, message } from "antd";
import Header from "../Header";
import polygon from "../../assets/images/polygon.svg";
import rejected from "../../assets/images/n_close.svg";
import ButtonBack from "../ButtonBack";
import executed from "../../assets/images/downarr.svg";
import BorderBlock from "../BorderBlock/index";
import Lang from "../Lang/index";
import { CheckOutlined } from "@ant-design/icons";
import { apiRequest } from "../../Utils";
import userStore from "../Auth/userStore";
import websocket from "../../websocket";
import { BackButton } from "../../blocks";
import AlertStore from "../Alert/AlertStore";
const onFinish = (values) => {
  console.log("Success:", values);
};
const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const AddDemoAccount = () => {
  const [amount, setAmount] = useState(0);
  const [history, setHistory] = useState([]);
  const [disabled, setDisable] = useState(false);

  const CountDown = ({ hours = 0, minutes = 0, seconds = 0 }) => {
    const [paused, setPaused] = useState(false);
    const [over, setOver] = useState(false);
    const [[h, m, s], setTime] = useState([hours, minutes, seconds]);

    const tick = () => {
      if (paused || over) return;

      if (h === 0 && m === 0 && s === 0) {
        setOver(true);
      } else if (m === 0 && s === 0) {
        setTime([h - 1, 59, 59]);
      } else if (s == 0) {
        setTime([h, m - 1, 59]);
      } else {
        setTime([h, m, s - 1]);
      }
    };

    const reset = () => {
      setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);
      setPaused(false);
      setOver(false);
    };

    const dofkdofk = () => {
      setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);
    };

    useEffect(() => {
      const timerID = setInterval(() => tick(), 1000);
      return () => clearInterval(timerID);
    });

    return (
      <div>
        <p style={{ textAlign: "center" }}>{`${h
          .toString()
          .padStart(2, "0")}:${m.toString().padStart(2, "0")}:${s
          .toString()
          .padStart(2, "0")}`}</p>
        <div>{over ? "Time's up!" : ""}</div>
      </div>
    );
  };
  const onChange = (value) => {
    console.log("changed", value);
    setAmount(value);
  };

  useEffect(() => {
    console.log("userStore.userData: ", userStore.userData._id);

    let eventId = null;

    eventId = websocket.subscribe(
      { event: "walletBalanceChange", key: userStore.userData._id },
      (data) => {
        console.log("websocket data: ", data);
        demoPayHistory();
      }
    );

    return () => {
      websocket.unSubscribe(eventId);
    };
  }, []);

  const pay = () => {
    //console.log({ amount });
    /// setDisable(true);
    apiRequest({
      url: `/private/v1/demo-pay/`,
      method: "POST",
      body: {
        amount,
      },
      success: (data) => {
        if (data?.error) return AlertStore.error(data?.error.msg);
        console.log({ data });
        if (data?.status == "ok") {
          AlertStore.orderSuccess(
            Lang({ str: "replenishment_completed", section: "accounts" })
          );
          setAmount(0);
          demoPayHistory();
        }
      },
    }).catch((error) => {
      console.error({ error });
    });
  };

  const demoPayHistory = () => {
    apiRequest({
      url: `/private/v1/demo-pay-history/`,
      method: "GET",
      success: (data) => {
        if (data?.error) return AlertStore.error(data?.error.msg);
        console.log({ data });
        setHistory(data);
      },
    }).catch((error) => {
      console.error({ error });
    });
  };

  useEffect(() => {
    demoPayHistory();
  }, []);

  return (
    <>
      <BackButton label={Lang({ str: "back", section: "default" })} />
      <div className={styles.account_page}>
        <div className={styles.add_balance}>
          <p className={styles.add_balance_title + " mb-56"}>
            {Lang({ str: "replenish_broker", section: "accounts" })}{" "}
          </p>

          <div className={styles.summ_block}>
            <div className={styles.block_valute}>
              <Form
                className={styles.flex_form}
                name="basic"
                onFinish={onFinish}
                labelCol={{
                  span: 24,
                }}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: Lang({
                        str: "enter_amount",
                        section: "accounts",
                      }),
                    },
                  ]}
                >
                  <InputNumber
                    defaultValue={0}
                    min={0}
                    formatter={(value) => `$${value}`}
                    parser={(value) => value.replace("$", "")}
                    onChange={onChange}
                    value={amount}
                  />
                </Form.Item>

                <Form.Item className={styles.buttonBlockFixed + " mt-32"}>
                  {disabled && (
                    <CountDown hours={23} minutes={59} seconds={59} />
                  )}
                  <Button
                    disabled={disabled}
                    className={
                      disabled == "true"
                        ? styles.add_balance
                        : styles.dis_but + " " + styles.add_balance
                    }
                    type="primary"
                    htmlType="submit"
                    onClick={pay}
                  >
                    {Lang({ str: "replenish", section: "accounts" })}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(AddDemoAccount);
