import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import urlStore from "../../stores/urlStore";
import "./SideBarBottom.scss";
import { Clickable } from "../../UI";
import { $class } from "../../Utils";
import Icon from "../Icon";

const links = [
  {
    slug: "portfolio",
    link: "/",
    icon: "home",
  },
  {
    slug: "favorites",
    link: "/favorites",
    icon: "star",
  },
  {
    slug: "wallet",
    link: "/wallet",
    icon: "wallet",
  },
  {
    slug: "accounts",
    link: "/accounts",
    icon: "accounts",
  },
];

function SideBarBottom() {
  const location = useLocation();
  const navigate = useNavigate();
  const prevLocation = useRef(null);
  const refs = useRef(Array(links.length).fill());

  const onLinkAnimate = () => {
    let indexFrom = links.findIndex((x) =>
      matchPath(x.link, prevLocation.current?.pathname || "")
    );
    let indexTo = links.findIndex((x) => matchPath(x.link, location.pathname));

    if (indexFrom === -1) indexFrom = undefined;
    if (indexTo === -1) indexTo = undefined;

    const from = refs.current[indexFrom];
    const to = refs.current[indexTo];

    if (indexTo > indexFrom) {
      console.log("refs", "right");

      from.style.animation = "BBF_hide_right 0.3s ease 1 forwards";
      to.style.animation = "BBF_show_left 0.3s ease 1 forwards";
    } else if (indexTo < indexFrom) {
      console.log("refs", "left");

      from.style.animation = "BBF_hide_left 0.3s ease 1 forwards";
      to.style.animation = "BBF_show_right 0.3s ease 1 forwards";
    } else if (to) {
      to.style.transform = "scale(1)";
    }
  };

  // Set active class to active link
  useEffect(() => {
    onLinkAnimate();

    if (links.find((x) => matchPath(x.link, location.pathname))) {
      prevLocation.current = location;
    }
  }, [location]);

  // if (!links.find((x) => matchPath(x.link, location.pathname))) {
  //   return document.body.classList.remove("body_padding"), null;
  // } else {
  //   document.body.classList.add("body_padding");
  // }

  return (
    <div className={$class('bottombar', ['hidden', !links.find((x) => matchPath(x.link, location.pathname))])}>
      <div className="bottombar-wrap">
        {links.map(({ slug, link, icon }, i) => (
          <Clickable
            key={slug}
            className={$class("bottombar__item", [
              "active",
              urlStore.section === slug,
            ])}
            onClick={() => navigate(link)}
          >
            <div
              className="bottombar__item-fill"
              ref={(el) => (refs.current[i] = el)}
            ></div>
            <Icon slug={icon} />
          </Clickable>
        ))}
      </div>
    </div>
  );
}

export default observer(SideBarBottom);
