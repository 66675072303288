import SumSubUpdate from "./SumSubUpdate";
import {apiQueryError, apiRequest} from "../../Utils";
import {message} from "antd";
import {useNavigate} from "react-router-dom";
import Header from "../Header";
import Lang from "../Lang";
import AlertStore from "../Alert/AlertStore";

const ChangeDocument = () => {
  const navigate = useNavigate();

  const onFinish = (data, setLoad = () => {}) => {
    console.log(data)
    apiRequest({
      url: "/private/v1/trader/SumSubDocumentUpdate",
      method: "POST",
      body: data,
      success: (data) => {
        if (data?.error) return AlertStore.error(data.error.msg)
        AlertStore.success(Lang({ str: "data_updated", section: "menu" }))
        navigate(-1)
        setLoad(false)
      },
      error: (error) => {
        apiQueryError({ error });
        setLoad(false)
      },
    }).catch();
  }

  return <>
    <Header/>
    <SumSubUpdate levelName={'geekoinvest'} resetStep={'IDENTITY'} onFinish={onFinish}/>
  </>
}
export default ChangeDocument;