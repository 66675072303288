import { configure, makeAutoObservable } from 'mobx';
import { v4 } from "uuid";
import Lang from "../Lang";

configure({ enforceActions: 'never' });

class AlertStore {
    activeAlertData = [];

    prevAlertTime = null;

    constructor() {
        makeAutoObservable(this);
    }

    success(msg, onClick, link) {
        this.activeAlertData = [...this.activeAlertData, {id: v4(), type: 'success', msg, onClick, link}]
    }
    error(msg, onClick, options = {}) {
        if (options?.supressMany && this.prevAlertTime && Date.now() - this.prevAlertTime < 200) {
            this.prevAlertTime = Date.now();
            return;
        }
        this.activeAlertData = [...this.activeAlertData, {id: v4(), type: 'err', msg, onClick, ...options}]

        this.prevAlertTime = Date.now();
    }
    info(msg, onClick) {
        this.activeAlertData = [...this.activeAlertData, {id: v4(), type: 'info', msg, onClick}]
    }
    copy() {
        this.activeAlertData = [...this.activeAlertData, {id: v4(), type: 'copy', msg: Lang({ str: 'copied', section: 'default' })}]
    }
    orderSuccess(msg, link) {
        this.activeAlertData = [...this.activeAlertData, {id: v4(), type: 'order_success', msg, link}]
    }
    orderError(msg, link) {
        this.activeAlertData = [...this.activeAlertData, {id: v4(), type: 'order_err', msg, link}]
    }
    close(id) {
        this.activeAlertData = this.activeAlertData.filter(item => item.id !== id);
    }
}

export default new AlertStore();
