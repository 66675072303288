import { observer } from 'mobx-react-lite';
import React from 'react';
import { IconButton } from '../../UI';
import modalStore from '../ModalManager/modalStore';
import styles from './TitleCategory.module.scss';

function TitleCategory(props) {
  return (
    <div className={styles.title_category}>
      <div className={styles.title_category__one}>
        <h4 className={styles.title_category__text}>
          {props.image && (
            <img className={styles.title_category__img} src={props.image} alt={props.title} />
          )}
          {props.title}
        </h4>
      </div>
      {props.buttons && (
        <div className={styles.title_category__one + ' ' + styles.group_buttons}>
          {props.type === 'collectionMy' && (
            <IconButton
              icon="plus"
              variant="primary"
              size="sm"
              onClick={() => modalStore.openModal('collectionAdd')}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default observer(TitleCategory);
