import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import Clickable from "../../UI/Clickable";
import TickerStore from "../../components/Ticker/TickerStore";
import { useLocation, useNavigate } from "react-router-dom";
import userStore from "../../components/Auth/userStore";
import { PriceChanging, PriceNumber, TickerTitle } from "../";
import { IconButton } from "../../UI";
import { $class } from "../../Utils";
import "./index.scss";
import Icon from "../../components/Icon";
import modalStore from "../../components/ModalManager/modalStore";

const TickerCard = ({
  tickerId,
  className,
  style,
  wrapStyle,
  favorite,
  onFavorite,
  onClick,
  withTrading = true,
}) => {
  const ticker = TickerStore.tickerData[tickerId];
  const navigate = useNavigate();
  const location = useLocation();

  const openTickerPage = () => {
    if (!tickerId) return;
    if (onClick) onClick();
    navigate(`/trading/${tickerId}`, { state: location });
  };

  const openTradePage = () => {
    if (!tickerId) return;
    if (onClick) onClick();
    if (!userStore.userData.role.trader) {
      modalStore.openModal('openBrokerAccount')
      return;
    }
    navigate(`/trading/${tickerId}/trade`, { state: location });
    // navigate('./trade', { state: location });
  };

  useEffect(() => {
    TickerStore.getTicker(tickerId);
  }, [tickerId]);

  const change =
    ticker?.snapshot &&
    ((ticker.snapshot?.dailyBar?.c - ticker.snapshot?.prevDailyBar?.c) /
      ticker.snapshot?.prevDailyBar?.c) *
      100;

  const price = ticker && ticker.snapshot?.dailyBar?.c;

  return (
    <div
      className={$class(
        "ticker-card",
        ["ticker-card--withTrading", withTrading],
        className
      )}
      style={style}
    >
      <Clickable className={$class('ticker-card-wrap')} style={wrapStyle} onClick={openTickerPage}>
        <TickerTitle ticker={ticker} withSymbol withDesc />
        <div className="ticker-card-price">
          <PriceNumber price={price} align="right" />
          <PriceChanging align="end" change={change} />
        </div>
      </Clickable>
      {withTrading && !onFavorite && (
        <IconButton
          className="ticker-card-button"
          onClick={openTradePage}
          iconSize={20}
          icon={"arrowRightUp"}
        />
      )}
      {onFavorite && <FavoriteToggle active={favorite} onClick={onFavorite} />}
    </div>
  );
};

export const FavoriteToggle = ({ active, onClick }) => {
  return (
    <div
      className={$class("ticker-card-button", "ticker-card-favorite", [
        "active",
        active,
      ])}
    >
      <Icon slug="minus123" className="ticker-card-favorite-icon minus_bl" />
      <IconButton onClick={onClick} icon={"plusborder"} />
    </div>
  );
};

export default observer(TickerCard);
