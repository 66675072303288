import { Input } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../../UI';
import { TickerCard } from '../../../blocks';
import AlertStore from '../../Alert/AlertStore';
import Lang from '../../Lang';
import SearchList from '../../SearchList';
import PortfolioStore from '../PortfolioStore';
import styles from './index.module.scss';

const CollectionModalAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);

  const [collection, setCollection] = useState({
    name: '',
    image: '',
  });

  const showModal = () => {
    setOpen(true);
  };

  const onChangeImage = (image) => {
    setCollection({ ...collection, image });
  };

  const onChangeName = (e) => {
    setCollection({ ...collection, name: e.target.value });
  };

  const onFavoriteHandler = (id) => {
    const newSelected = { ...selected };
    if (id in selected) {
      delete newSelected[id];
    } else {
      newSelected[id] = 1;
    }
    setSelected(newSelected);
  };

  const onAddCollection = async () => {
    if (collection.name.trim() === '') {
      AlertStore.info(Lang({ str: "enter_name_1", section: "portfolio" }));
      return;
    }

    setLoading(true);

    const res = await PortfolioStore.collectionSet({
      name: collection.name,
      logo: collection.logo,
      tickers: Object.keys(selected),
    });

    if (res) {
      const res = await PortfolioStore.collectionMyLoad();
      navigate('/favorites/' + res[res.length - 1], { state: location });
      setOpen(false);
    }

    setLoading(false);
  };

  return (
    <>

      <div className={styles.wrap}>
        {/* <h4 className={styles.label}>Введите название</h4> */}
        <Input
          placeholder={Lang({ str: 'new_collection', section: 'portfolio' })}
          value={collection.name}
          onChange={onChangeName}
          className={styles.SearchInput}
        />
        {/* <div className="mt-16">
            <h4 className={styles.label}>Выберите обложку</h4>
            <SelectIcon value={collection.image} items={PortfolioStore.logos} onChange={onChangeImage} />
          </div> */}

        <div className="mt-16">
          <SearchList
            search={search}
            withInput
            onChange={setSearch}
            itemHeight={72 + 10}
            content={
              search.trim() === '' && (
                <div className={styles.choicedWrap}>
                  {!Object.keys(selected).length && (
                    <div className={styles.choicedEmpty}>
                      <h4 className={styles.label}>
                        {Lang({ str: 'no_select', section: 'portfolio' })}
                      </h4>
                    </div>
                  )}
                  {Object.keys(selected).map((_id) => (
                    <TickerCard
                      style={{ margin: '5px 0' }}
                      onFavorite={() => onFavoriteHandler(_id)}
                      favorite={selected[_id]}
                      onClick={() => null}
                      key={_id}
                      tickerId={_id}
                    />
                  ))}
                </div>
              )
            }
            height={window.innerHeight * 0.6}>
            {/* {(t) => <TickerItemFavorite onClick={() => null} key={t._id} tickerId={t._id} />} */}
            {(t, key, style) => (
              <TickerCard
                onFavorite={() => onFavoriteHandler(t._id)}
                favorite={selected[t._id]}
                onClick={() => null}
                key={key}
                tickerId={t._id}
                style={style}
              />
            )}
          </SearchList>
        </div>
        <Button variant="primary" loading={loading} onClick={onAddCollection}>
          {Lang({ str: 'create', section: 'portfolio' })}
        </Button>
      </div>
    </>
  );
};

export default observer(CollectionModalAdd);
