import React, { useState } from "react";
import { Form, Input, Button, Layout, message } from "antd";
import Lang from "../Lang/index";
import PDFModal from "../BrokerAccount/PDFModal";
import PDFModal2 from "../BrokerAccount/PDFModal2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./Auth.module.scss";
//import doc10 from "https://www.geekoinvest.kz/geeko-privecy-policy";
import doc9 from "../../assets/UserAgreement.pdf";
import Utils, { apiQuery, apiQueryError } from "../../Utils";
import HeaderLang from "../Header/HeaderLang";
import userStore from "./userStore";
import ReCaptcha from "../ReCaptcha";
import AlertStore from "../Alert/AlertStore";
const { Content } = Layout;

const Create = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // useMount(() => {
  //   // let selector = document.getElementById("normal_login_phone");
  //   // Inputmask({ alias: "phone", placeholder: " " }).mask(selector);
  // });
  const [buttonActive, setButtonActive] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showpass, setShowpass] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [passValue, setPassValue] = useState("");
  const [captcha, setCaptcha] = useState(false)

  const handleInputChange = (e) => {
    if (e.target.value.length > 0) {
      setInputValue(e.target.value);
      setButtonActive(true);
      setShow(true);
    } else {
      setShow(false);
      setButtonActive(false);
    }
  };

  const handleInputChange2 = (e) => {
    if (e.target.value.length > 0) {
      setButtonActive(true);
      setInputValue2(e.target.value);
      setShow2(true);
    } else {
      setShow2(false);
      setButtonActive(false);
    }
  };
  const handlePassChange = (e) => {
    setPassValue(e.target.value);
    if (e.target.value.length > 0) {
      setButtonActive(true);
      setShowpass(true);
    } else {
      setButtonActive(false);
      setShowpass(false);
    }
  };

  const validatePwd = (rule, value) => {
    return new Promise(async (resolve, reject) => {
      if (!value) {
        await reject(Lang({ str: "enter_pass", section: "auth" }));
      } else if (value.length < 8) {
        await reject(Lang({ str: "pass_8_characters", section: "auth" }));
      } else if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{8,}/.test(value)
      ) {
        await reject(Lang({ str: "pass_unsafe", section: "auth" }));
      } else {
        await resolve();
      }
    });
  };
  const onFinish = (values) => {
    apiQuery("/create_user", {
      method: "POST",
      body: values,
    })
        .then((userData) => {
          userStore.authCheck();
          
          if (location?.authRef) navigate(location.authRef.pathname)
          else navigate('/');
        })
        .catch((error) => {
          apiQueryError({ error });
        });
  };
  const [activePdf, setActivePdf] = useState(null);
  const [activePdf2, setActivePdf2] = useState(null);

  return (
    <>
      <div className="flex_block">
        <HeaderLang />
        <Content className={styles.siteLayout}>
          <div>
            <h2 className={styles.titleAuth}>
              {" "}
              {Lang({ str: "lets_start", section: "auth" })}
            </h2>
            <p className={styles.little_text}>
              {Lang({ str: "already_reg", section: "auth" })}
              <Link to="/login" className={styles.link}>
                {Lang({ str: "log_in", section: "auth" })}
              </Link>
            </p>{" "}
          </div>

          <Form
            name="normal_login"
            className={styles.loginForm}
            onFinish={onFinish}
            layout={"vertical"}
            requiredMark={false}
          >
            {/* <Form.Item
              name="displayName"
              className={styles.login_wrp}
              label={show && Lang({ str: "fio", section: "auth" })}
              rules={[
                {
                  required: true,
                  message: Lang({ str: "fio_rules", section: "auth" }),
                },
              ]}
            >
              <Input
                value={inputValue}
                onChange={handleInputChange}
                placeholder={Lang({ str: "fio", section: "auth" })}
              />
            </Form.Item> */}

            <Form.Item
              className={styles.login_wrp}
              name="email"
              label={show2 && "Email"}
              rules={[
                {
                  required: true,
                  message: Lang({ str: "enter_email", section: "auth" }),
                },
              ]}
            >
              <Input
                value={inputValue2}
                onChange={handleInputChange2}
                type={"email"}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              className={styles.login_wrp}
              name="password"
              rules={[{ validator: validatePwd }]}
              label={showpass && Lang({ str: "password", section: "auth" })}

              //rules={[
              // {
              //  required: true,
              //  message: Lang({ str: "inputpass", section: "auth" }),
              // },
              //]}
            >
              <Input
                value={passValue}
                onChange={handlePassChange}
                type="password"
                placeholder={Lang({ str: "password", section: "auth" })}
              />
            </Form.Item>
            <p className={styles.little_text2}>
              {Lang({ str: "agree_terms", section: "auth" })}{" "}
              <Link onClick={() => setActivePdf(doc9)}>
                {Lang({ str: "user_agreement", section: "auth" })}
              </Link>{" "}
              {Lang({ str: "and", section: "auth" })}{" "}
              <Link
                to="https://www.geekoinvest.kz/privacy-policy"
                target="_blank"
              >
                {Lang({ str: "privacy_policy", section: "auth" })}
              </Link>
            </p>
            {showpass && (
              <div className={styles.error_block}>
                <p>
                  <span>{Lang({ str: "ensure_pass", section: "auth" })}</span>
                </p>
                <p>{Lang({ str: "ensure_pass_2", section: "auth" })}</p>
                <p>
                  {Lang({ str: "ensure_pass_3", section: "auth" })}
                </p>
                <p>
                  {Lang({ str: "ensure_pass_4", section: "auth" })}
                </p>
              </div>
            )}
            <Form.Item
                name="captcha"
                rules={[{
                  validator: () => new Promise(async (resolve, reject) => {
                    if (!captcha) await reject(Lang({ str: "enter_captcha", section: "auth" }))
                    else await resolve()
                  })
                }]}
            >
              <ReCaptcha
                  style={ {marginTop: '16px'} }
                  callback={() => setCaptcha(true)}
                  expiredCallback={() => setCaptcha(false)}
              />
            </Form.Item>
            <Form.Item className={styles.buttonBlockFixed}>
              <Button
                type="primary"
                htmlType="submit"
                className={
                  buttonActive
                    ? styles.button_anactive + " " + styles.login_form_button
                    : styles.buttonFixed + " " + styles.login_form_button
                }
              >
                {Lang({ str: "create_account", section: "auth" })}
              </Button>
            </Form.Item>
          </Form>
        </Content>
      </div>
      <PDFModal2
        visible={activePdf !== null}
        file={doc9}
        onClose={() => setActivePdf(null)}
      />
      <PDFModal2
        visible={activePdf2 !== null}
        file={
          "https://"+Utils.apiDomain()+"/rest_api/private/v1/getCrossUrl?url=" +
          encodeURIComponent("https://www.geekoinvest.kz/geeko-privecy-policy")
        }
        onClose={() => setActivePdf2(null)}
      />
    </>
  );
};

export default Create;
