import { Checkbox, DatePicker, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button, Tabs } from '../../UI';
import Lang from '../Lang';
import styles from './style.module.scss';
import AppStore from "../App/AppStore";
import locale_ru from 'antd/es/date-picker/locale/ru_RU';
import locale_en from 'antd/es/date-picker/locale/en_US';
import dayjs from 'dayjs';

const FilterModal = ({
  filter,
  filterFields,
  defaultFilter,
  onResetHandler,
  onApplyHandler,
  closeModal,
}) => {
  const [filterInputs, setFilterInputs] = useState(defaultFilter);

  useEffect(() => {
    setFilterInputs(filter);
  }, [filter]);

  return (
    <div className={styles.filter}>
      {filterFields.map((el, i) => {
        if (el.type === 'period')
          return <PeriodBlock key={i} name={el.name} filterInputs={filterInputs} onChange={setFilterInputs} />;
        if (el.type === 'checkbox')
          return (
            <CheckboxBlock
              key={i}
              name={el.name}
              filterInputs={filterInputs}
              onChange={setFilterInputs}
              items={el.items}
            />
          );
        if (el.type === 'choice')
          return (
            <ChoiceBlock
              key={i}
              name={el.name}
              filterInputs={filterInputs}
              onChange={setFilterInputs}
              items={el.items}
            />
          );
        if (el.type === 'sum')
          return <SumBlock key={i} name={el.name} filterInputs={filterInputs} onChange={setFilterInputs} />;
        return null;
      })}

      <div className={styles.filterBlock} style={{ display: 'flex', columnGap: 10, marginTop: 30 }}>
        <Button variant="secondary" onClick={onResetHandler}>
          {Lang({ str: 'reset', section: 'wallet' })}
        </Button>
        <Button onClick={() => onApplyHandler(filterInputs)}>
          {Lang({ str: 'apply', section: 'wallet' })}
        </Button>
      </div>
    </div>
  );
};

// ============================================================
// ============================================================
// ===========           FILTER BLOCKS           ==============
// ============================================================
// ============================================================

export const PeriodBlock = ({ filterInputs, onChange, name = 'period', style }) => {
  const onPeriodHandler = (period) => {
    onChange({ ...filterInputs, [name]: [dayjs().subtract(period, 'month'), dayjs()] });
  };

  const locale = AppStore.lang === 'en' ? locale_en : locale_ru

  return (
    <div className={styles.filterBlock} style={style}>
      <p>{Lang({ str: 'period', section: 'wallet' })}</p>
      <div className={styles.datePeriod}>
        <Button variant="clear" onClick={() => onPeriodHandler(1)}>
          {Lang({ str: 'one_month', section: 'wallet' })}
        </Button>
        <Button variant="clear" onClick={() => onPeriodHandler(3)}>
          {Lang({ str: 'three_months', section: 'wallet' })}
        </Button>
        <Button variant="clear" onClick={() => onPeriodHandler(6)}>
          {Lang({ str: 'six_months', section: 'wallet' })}
        </Button>
      </div>
      <div className={styles.filterInputBlock}>
        <DatePicker
          locale={locale}
          value={filterInputs[name][0]}
          showTime={false}
          placeholder={Lang({ str: 'from', section: 'wallet' })}
          inputReadOnly={true}
          onChange={(v) => {
            onChange({ ...filterInputs, [name]: [v, filterInputs[name][1]] });
          }}
        />
        <DatePicker
          locale={locale}
          value={filterInputs[name][1]}
          showTime={false}
          placeholder={Lang({ str: 'to', section: 'wallet' })}
          inputReadOnly={true}
          onChange={(v) => {
            onChange({ ...filterInputs, [name]: [filterInputs[name][0], v] });
          }}
        />
      </div>
    </div>
  );
};

const ChoiceBlock = ({ filterInputs, onChange, name = 'action', items = [] }) => {
  return (
    <div className={styles.filterBlock}>
      <p>{Lang({ str: 'action', section: 'wallet' })}</p>
      <div className={styles.filterInputBlock}>
        <Tabs
          value={filterInputs[name]}
          onChange={(v) => onChange({ ...filterInputs, [name]: v })}
          items={items}
        />
      </div>
    </div>
  );
};

const CheckboxBlock = ({ filterInputs, onChange, name = 'actions', items = [] }) => {
  return (
    <div className={styles.filterBlock}>
      <p>{Lang({ str: 'action', section: 'wallet' })}</p>
      <div className={styles.filterInputBlock}>
        <Checkbox.Group
          options={items}
          value={filterInputs[name]}
          onChange={(checkedValues) => {
            onChange({ ...filterInputs, [name]: checkedValues });
          }}></Checkbox.Group>
      </div>
    </div>
  );
};

const SumBlock = ({ filterInputs, onChange, name = 'sum' }) => {
  return (
    <div className={styles.filterBlock}>
      <p>{Lang({ str: 'amount', section: 'wallet' })}</p>
      <div className={styles.filterInputBlock}>
        <Input
          type="text"
          inputMode="decimal"
          placeholder={Lang({ str: 'from', section: 'wallet' })}
          className={styles.inputNumber}
          value={filterInputs[name][0]}
          onChange={(e) => {
            if (isNaN(e?.target?.value)) return;
            onChange({
              ...filterInputs,
              [name]: [e?.target?.value, filterInputs[name][1]],
            });
          }}
        />

        <Input
          type="text"
          inputMode="decimal"
          placeholder={Lang({ str: 'to', section: 'wallet' })}
          className={styles.inputNumber}
          value={filterInputs[name][1]}
          onChange={(e) => {
            if (isNaN(e?.target?.value)) return;
            onChange({
              ...filterInputs,
              [name]: [filterInputs[name][0], e?.target?.value],
            });
          }}
        />
      </div>
    </div>
  );
};

export default FilterModal;
