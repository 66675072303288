import React, {useEffect, useState} from "react";
import styles from "../BrokerAccountNew/BrokerAccountNew.module.scss";
import {Button, Form, Spin} from "antd";
import {observer} from "mobx-react-lite";
import moment from "moment";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Utils, {apiQuery, apiQueryError} from "../../Utils";
import Step7 from "./Step7";
import BrokerAccountStore from "./BrokerAccountStore";
import Lang from "../Lang";
import AlertStore from "../Alert/AlertStore";
import modalStore from "../ModalManager/modalStore";

const Dogovor = ({setBack}) => {
  const docData = BrokerAccountStore.docData;
  const [form] = Form.useForm();
  const [phone, setPhone] = useState('');
  const [step, setStep] = useState(1);

  const onFinish = (value) => {
    apiQuery("/private/v1/getDocUrl", {
      method: "POST",
      body: value,
    })
      .then((data) => {
        if (data?.error) return AlertStore.error(data.error.msg);
        // urlOpen(data.url)
        modalStore.openModal('pdf', { url: `https://${Utils.apiDomain() + data.url}` })
      })
      .catch((error) => {
        apiQueryError({error});
      });
  };

  const singSmsSend = () => {
    if (!phone || phone.length !== 12) return AlertStore.error(Lang({ str: "phone_number_err", section: "onboarding" }));
    setStep(2)
  }

  useEffect(() => {
    const scroll = window.document.getElementsByClassName('basic-routes');
    if (scroll[0]) scroll[0].scrollTo(0, 0);

    setBack({
      view: "questionnaire",
      step: 1
    })
  }, [setBack])

  if(!docData) return <Spin/>

  return (
    <>
      {step === 2 && <Step7 phone={phone}/>}

      {step === 1 && <div className={styles.page_fl}>
        <div className={styles.text_bl}>
          <h4>{Lang({ str: "brokerage_service_agreement", section: "onboarding" })}</h4>
          {!!docData?.brokerageServiceAgreement?.signDate && <p className={styles.lit_text + " mt-16 mb-16"}>
            Договор подписан SMS-подтверждением: {moment(docData?.brokerageServiceAgreement?.signDate).format('DD.MM.YYYY, HH:mm')}, {docData?.brokerageServiceAgreement?.signPhone}
          </p>}
          {!docData?.brokerageServiceAgreement?.signDate && <p className={styles.lit_text + " mt-16 mb-16"}>
            {Lang({ str: "brokerage_service_agreement_2", section: "onboarding" })}
          </p>}
          <div className={styles.group_link}>
            <span onClick={() => onFinish({mode: 'brokerageServiceAgreement'})}>
              {Lang({ str: "brokerage_service_agreement", section: "onboarding" })}
            </span>
          </div>
          <div className={styles.group_link}>
            <span onClick={() => onFinish({mode: 'brokerageServiceAgreementAnnex1'})}>
              {Lang({ str: "application_membership", section: "onboarding" })}
            </span>
          </div>
          {!docData?.brokerageServiceAgreement?.signDate && <Form
            className={styles.form}
            layout="vertical"
            form={form}
          >
            <p className={styles.lit_text1 + " mt-40 mb-8"}>
              {Lang({ str: "brokerage_services_sms", section: "onboarding" })}
            </p>
            <Form.Item className={styles.flex_number}>
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="KZ"
                value={phone}
                onChange={setPhone}
              />
            </Form.Item>
          </Form>}

        </div>
        {!docData?.brokerageServiceAgreement?.signDate && <Form.Item className={styles.buttonBlockFixed + " m-0 b-0"}>
          <Button
            type="primary"
            htmlType="submit"
            className={styles.gr_btn + " " + styles.activ}
            onClick={singSmsSend}
          >
            {Lang({ str: "sign", section: "onboarding" })}
          </Button>
        </Form.Item>}
      </div>}
    </>
  );
};

export default observer(Dogovor);
