import React from "react";
import { Outlet } from "react-router-dom";
import { observer } from "mobx-react-lite";

const AuthLayout = () => {
  return (
    <div className="height_all">
      <Outlet />
    </div>
  );
};

export default observer(AuthLayout);
