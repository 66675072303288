import React, { useEffect, useRef, useState } from "react";
// import LinkTitle from "../LinkTitle";
//simport "react-multi-carousel/lib/styles.css";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import ReactCodeInput from "react-code-input";
import { apiQuery, apiQueryError, getBase64 } from "../../Utils";
import left_icon from "../../assets/images/arrowmenu.svg";
import urlStore from "../../stores/urlStore";
import AlertStore from "../Alert/AlertStore";
import userStore from "../Auth/userStore";
import HeaderTitle from "../Header/HeaderTitle";
import styles from "./PersonalInformation.module.scss";

const propsReactCodeInput = {
  inputStyle: {
    margin: 6,
    width: 36,
    fontSize: "22px",
    height: 40,
    backgroundColor: "#0a0a0a",
    border: 0,
    borderBottom: "1px solid #fff",
    textAlign: "center",
  },
};

const PersonalInformation = () => {
  const [fileList, setFileList] = useState(
    userStore.userData?.profileData?.avatar
      ? [
          {
            uid: userStore.userData.profileData.avatar,
            name: userStore.userData.profileData.avatar,
            status: "done",
            url: userStore.userData.profileData.avatar,
          },
          // {thumbUrl:data.data[0].preview}])
        ]
      : []
  );
  const [previewVisible, setPreviewVisible] = useState(false);
  const [modalChangePass, setModalChangePass] = useState(false);
  const [restoreCode, setRestoreCode] = useState("");
  const [modalConfirm, setModalConfirm] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const formData = useRef();

  const onFinishPassword = (values) => {
    apiQuery("/private/profile_password_edit", {
      method: "POST",
      body: values,
    })
      .then((data) => {
        console.log(data);
        AlertStore.success("Пароль изменен");
        userStore.authCheck().catch();
        setModalChangePass(false);
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  const onFinish = (values) => {
    values.file_base64 = fileList[0] ? fileList[0]?.thumbUrl || null : null;
    // values.date        = values.date.format('YYYY-MM-DD')
    // values.time        = values.time.format('HH:mm')
    values.preview = fileList[0] ? fileList[0]?.url || null : null;
    // values.roomId = match.params.room_id

    apiQuery("/private/v1/profile", {
      method: "POST",
      body: values,
    })
      .then((data) => {
        if (data.emailVeriCode) {
          formData.current = values;
          setModalConfirm(true);
          return;
        }

        console.log(data);
        AlertStore.success("Сохранено");
        userStore.authCheck().catch();
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  const onFinish2 = () => {
    const data = formData.current;
    setModalConfirm(false);
    data.emailVeriCode = restoreCode;
    onFinish(data);
  };

  const onChangeFile = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const modalHandleCancel = () => setPreviewVisible(false);
  const modalChangePassCancel = () => setModalChangePass(false);

  const restoreCodeOnChange = (value) => {
    setRestoreCode(value);
  };

  useEffect(() => {
    urlStore.setSection("");
    window.scroll(0, 0);
  }, []);

  return (
    <div className={styles.personal_information_wrp}>
      <HeaderTitle
        button1={<img src={left_icon} alt={""} />}
        title={"Информация об аккаунте"}
      />
      <Modal
        visible={previewVisible}
        title={"Изображение"}
        footer={null}
        onCancel={modalHandleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>

      <div
        className={
          styles.personal_information + " mt-16" + " " + styles.horizont_in
        }
      >
        {/*<LinkTitle key={linkTitles.index} title={linkTitles.title}/>*/}
        <Form layout="horizontal" onFinish={onFinish}>
          <Form.Item>
            <div className={styles.img_block}>
              <ImgCrop rotate>
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChangeFile}
                  onPreview={onPreview}
                >
                  {fileList.length === 0 && (
                    <div>
                      <PlusOutlined />
                      <div>
                        Загрузить
                        <br /> фото
                      </div>
                    </div>
                  )}
                </Upload>
              </ImgCrop>
            </div>
          </Form.Item>
          <Form.Item
            name="name"
            label="ФИО"
            initialValue={userStore.userData.profileData?.name}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Телефон"
            initialValue={userStore.userData.profileData?.phone}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="E-mail"
            initialValue={userStore.userData.email}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Client ID">
            <p>{userStore.userData.id}</p>
          </Form.Item>

          <Form.Item>
            <Button className="yellow_btn" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Modal
        visible={modalChangePass}
        title={"Изменить пароль"}
        footer={null}
        onCancel={modalChangePassCancel}
      >
        <div className={styles.personal_information}>
          <Form layout="vertical" onFinish={onFinishPassword}>
            <Form.Item
              name="oldpass"
              label="Введите текущий пароль"
              rules={[{ required: true, message: "Введите текущий пароль!" }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="password"
              label="Введите новый пароль"
              rules={[
                {
                  required: true,
                  message: "Введите новый пароль!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirm"
              label="Повторите новый пароль"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Повторите новый пароль!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Два введенных вами пароля не совпадают!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button className="yellow_btn" htmlType="">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        visible={modalConfirm}
        title={"Код подтверждения"}
        footer={null}
        onCancel={() => setModalConfirm(false)}
      >
        <div className={styles.personal_information}>
          <p>
            На новый электронный адрес отправлен проверочный код, введите его:
          </p>
          <Form layout="vertical" onFinish={onFinish2}>
            <div
              style={{
                display: "block",
                margin: "20px auto",
                width: "100%",
              }}
            >
              <ReactCodeInput
                type="number"
                fields={6}
                name="code"
                inputMode="numeric"
                value={restoreCode}
                onChange={restoreCodeOnChange}
                autoComplete={"one-time-code"}
                {...propsReactCodeInput}
              />
            </div>

            <Form.Item>
              <Button className="yellow_btn" htmlType="">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default PersonalInformation;
