import { ConfigProvider, Result } from 'antd';
import locale from 'antd/locale/ru_RU';
import React, { useEffect } from 'react';
import userStore from '../Auth/userStore';
import AppStore from './AppStore';
import 'dayjs/locale/ru';
import PushNotification from '../PushNotification';
import Router from './Router';
import AlertList from '../Alert';
import preval from 'preval.macro';
import packageJson from '../../../package.json';

window.REACT_APP_VERSION = packageJson.version;
window.REACT_APP_BUILD_DATE = preval`module.exports = new Date()`;

function App() {
  useEffect(() => {
    userStore
      .authCheck()
      .then((user) => {
        if (user) userStore.langSave();
      })
      .catch(console.error);

    // Fix for input focus and shifting body,html
    document.documentElement.addEventListener('scroll', (e) =>
      document.documentElement.scrollTo({ top: 0 })
    );
    document.body.addEventListener('scroll', (e) => document.body.scrollTo({ top: 0 }));

    // Fix for 100vh = calc(var(--vh) * 100)
    // True Fullscreen for mobile

    const resizeHandler = () =>
      document.body.style.setProperty('--vh', window.innerHeight / 100 + 'px');
    document.addEventListener('resize', resizeHandler);
    resizeHandler();
  }, []);

  return (
    <>
      <PushNotification />
      <ConfigProvider locale={locale}>
        <Router />
      </ConfigProvider>
      <AlertList />
    </>
  );
}

export default App;

AppStore.resize(window.innerWidth, window.innerHeight);

(function () {
  window.addEventListener('resize', actualResizeHandler, false);

  let resizeTimeout;

  function resizeThrottler() {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(() => {
        resizeTimeout = null;
        actualResizeHandler(this);

        // The actualResizeHandler will execute at a rate of 15fps
      }, 500);
    }
  }

  function actualResizeHandler() {
    AppStore.resize(window.innerWidth, window.innerHeight);
  }
})();
