import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import AppStore from "../App/AppStore";
import Lang from "../Lang/index";
import styles from "./ModalSwipe.module.scss";
import { useDrag } from "@use-gesture/react";
import { animated, useSpring } from "react-spring";
import { Col, Row } from "antd";
import { Button } from "../../UI";

const ModalSwipe = ({
  visible = true,
  footVisible = true,
  btnConfirm = false,
  confirmTitle = "",
  onBtn,
  btnText = "",
  btnVisible = false,
  btnClass = null,
  onOk,
  okText = Lang({ str: "apply", section: "default" }),
  okVisible = true,
  okClass = null,
  okStyle = null,
  okLoading = false,
  onCancel,
  cancelText = Lang({ str: "close", section: "default" }),
  cancelVisible = false,
  cancelClass = null,
  downloadUrl,
  children,
}) => {
  const [create, setCreate] = useState(visible);

  const [springProps, springApi] = useSpring(() => ({
    y: window.innerHeight,
    scale: 1,
    radius: 24,
    opacity: 0,
  }));

  const bind = useDrag(({ type, down, movement: [mx, my] }) => {
    if (type === "pointerdown")
      springApi.start({ scale: 0.9, radius: 30, config: { duration: 100 } });
    else if (type === "pointerup") springApi.start({ scale: 1, radius: 20 });

    if (my < 0) return;

    springApi.start({ opacity: down ? Math.max(1 - my / 80, 0) : 1 });

    if (my > 80 && !down) {
      closeModal();
      return;
    }

    springApi.start({ y: down ? my : 0, immediate: down });
  });

  useEffect(() => {
    if (visible) {
      openModal();
    } else {
      closeModal();
    }
  }, [visible]);

  const openModal = () => {
    setCreate(true);
    setTimeout(() => {
      springApi.start({ y: 0, opacity: 1 });
    }, 10);

    document.querySelector("body").style.overflow = "hidden";
  };

  const closeModal = () => {
    springApi.start({ y: window.innerHeight, opacity: 0 });

    setTimeout(() => {
      if (onCancel) onCancel();
      setCreate(false);
    }, 300);

    document.querySelector("body").style.overflow = "unset";
    document.body.classList.remove("modal--active");
  };

  const mOk = () => {
    if (okLoading) return;
    if (onOk) onOk();
  };

  if (!create) return null;

  const ConfirmDel = ({
    children,
    onClick,
    confirmTitle = "",
    okText = Lang({ str: "yes", section: "default" }),
    cancelText = Lang({ str: "no", section: "default" }),
    okClass,
    cancelClass,
  }) => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <div
          onClick={() => {
            setOpen(true);
          }}
        >
          {children}
        </div>
        {open && (
          <div className={styles.confirmBlock}>
            <p className={styles.confirmTitle}>{confirmTitle}</p>
            <Row gutter={20} style={{ margin: "8px 16px 24px 16px" }}>
              <Col flex={"auto"}>
                <Button
                  className={[styles.btnOk, okClass].join(" ")}
                  onClick={() => {
                    setOpen(false);
                    if (onClick) onClick();
                    closeModal();
                  }}
                >
                  {okText}
                </Button>
              </Col>
              <Col flex={"auto"}>
                <Button
                  className={[styles.btnCancel, cancelClass].join(" ")}
                  onClick={() => {
                    setOpen(false);
                    closeModal();
                  }}
                >
                  {cancelText}
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <animated.div
        style={{ opacity: springProps.opacity }}
        className={styles.overflow}
      ></animated.div>
      <div
        className={
          styles.modal + " " + (visible ? styles.active : styles.unactive)
        }
        onClick={closeModal}
        style={{ height: AppStore.height }}
      >
        <animated.div
          className={styles.modal__content}
          onClick={(e) => e.stopPropagation()}
          style={{
            // minHeight: newheightcontent,
            y: springProps.y,
            borderRadius: springProps.radius.to((x) => `${x}px ${x}px 0 0`),
          }}
        >
          <animated.div
            className={styles.modal__handle}
            {...bind()}
            style={{ scale: springProps.scale }}
          >
            <div></div>
          </animated.div>

          <div>
            <div
              className={styles.modal__body}
              style={{
                maxHeight: AppStore.height - 100 - (footVisible ? 50 : 0),
              }}
            >
              {children}
            </div>
            {footVisible && (
              <Row gutter={10} style={{ margin: "10px 10px 24px 10px" }}>
                {btnVisible && (
                  <Col flex={"auto"}>
                    {btnConfirm && (
                      <ConfirmDel onClick={onBtn} confirmTitle={confirmTitle}>
                        <Button
                          variant="secondary"
                          className={[styles.btnCancel, btnClass].join(" ")}
                          block
                        >
                          {btnText}
                        </Button>
                      </ConfirmDel>
                    )}
                    {!btnConfirm && (
                      <Button
                        variant="secondary"
                        className={[styles.btnCancel, btnClass].join(" ")}
                        onClick={onBtn}
                        block
                      >
                        {btnText}
                      </Button>
                    )}
                  </Col>
                )}
                {okVisible && (
                  <Col flex={"auto"}>
                    <Button
                      className={[styles.btnOk, okClass].join(" ")}
                      style={okStyle}
                      onClick={mOk}
                      block
                      loading={okLoading}
                      downloadUrl={downloadUrl && downloadUrl}
                    >
                      {okText}
                    </Button>
                  </Col>
                )}
              </Row>
            )}
          </div>
        </animated.div>
      </div>
    </>
  );
};

export default observer(ModalSwipe);
