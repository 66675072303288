import React, {useCallback, useEffect, useRef, useState} from "react";
import styles from "../BrokerAccountNew/BrokerAccountNew.module.scss";
import {Button, message, Space, Spin} from "antd";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {apiRequest} from "../../Utils";
import BrokerAccountStore from "./BrokerAccountStore";
import Lang from "../Lang";
import AlertStore from "../Alert/AlertStore";

const ButtonTime = ({time = 15, btnActive, btnNoActive, onClick}) => {
  const [active, setActive] = useState(false)
  const [sec, setSec] = useState(time)

  const onClick2 = () => {
    setActive(false)
    setSec(time)
  }

  useEffect(() => {

    const t = setInterval(() => {
      console.log(sec)
      setSec(v => {
        if (v === 1) {
          setActive(true)
        }
        return v <= 1 ? 0 : v - 1
      })
    }, 1000)

    return () => {
      clearInterval(t)
    }

  }, [])

  if (active) return <div className={styles.bacc_block} style={{marginTop: 20}}>
      <Button className={styles.bacc_block_no} style={{width: '48%', marginRight: '2%'}} onClick={() => {
        onClick2()
        onClick()
      }}>{Lang({
        str: "update_status",
        section: "onboarding"
      })}</Button>
      <Button className={styles.bacc_block_yes} style={{width: '48%', marginLeft: '2%'}} onClick={() => {
        onClick()
      }}>{Lang({
        str: "replied_to_SMS",
        section: "onboarding"
      })}</Button>
  </div>
  return <div className={styles.bacc_block} style={{marginTop: 20}}>
    <p style={{
      marginBottom: 15,
      color: '#D5B610',
      fontSize: 12
    }}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{verticalAlign:'text-bottom', marginRight: 2}}>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M6.99978 2.00003C4.23836 2.00003 1.99978 4.2386 1.99978 7.00003C1.99978 9.76145 4.23836 12 6.99978 12C9.76121 12 11.9998 9.76145 11.9998 7.00003C11.9998 4.2386 9.76121 2.00003 6.99978 2.00003ZM0.888672 7.00003C0.888672 3.62495 3.62471 0.888916 6.99978 0.888916C10.3749 0.888916 13.1109 3.62495 13.1109 7.00003C13.1109 10.3751 10.3749 13.1111 6.99978 13.1111C3.62471 13.1111 0.888672 10.3751 0.888672 7.00003ZM6.99978 3.11114C7.30661 3.11114 7.55534 3.35987 7.55534 3.66669V6.65667L9.47046 7.61423C9.74489 7.75145 9.85613 8.08516 9.71891 8.35959C9.58169 8.63402 9.24799 8.74526 8.97355 8.60804L6.75133 7.49693C6.56312 7.40282 6.44423 7.21046 6.44423 7.00003V3.66669C6.44423 3.35987 6.69296 3.11114 6.99978 3.11114Z"
              fill="#D5B610"/>
      </svg>
      {btnNoActive.replace('{{sec}}', sec)}
    </p>

    <Button className={styles.bacc_block_no} style={{width: '48%', marginRight: '2%', opacity: 0.3}}
            disabled={true}>{Lang({
      str: "update_status",
      section: "onboarding"
    })}</Button>
    <Button className={styles.bacc_block_yes} style={{width: '48%', marginLeft: '2%'}} onClick={() => {
      onClick()
    }}>{Lang({
      str: "replied_to_SMS",
      section: "onboarding"
    })}</Button>

  </div>
}

const Page3 = ({setBack}) => {
  const [load, setLoad] = useState();
  const [error, setError] = useState();
  const count = useRef(0);
  const time = useRef();

  const getPKBInfo = useCallback((t = false) => {
    // Кол-во автообновлений
    if (!t && count.current > 4) return;

    setLoad(true);
    apiRequest({
      url: "/private/v1/trader/PKBInfo",
      success: (data) => {
        count.current++;
        if (data.error) {
          setError(data.error.msg)
          setLoad(false);
          return AlertStore.error(data.error.msg);
        }

        if (data.info) {
          setLoad(false);
          time.current = setTimeout(() => {
            getPKBInfo()
          }, 15000)
          setError(false)
          return AlertStore.info(data.info.msg);
        }

        let body = {
          view: "address",
          step: 4,
        };
        BrokerAccountStore.save(body);
        setLoad(false);
      },
      error: (error) => {
        console.error(error);
        setLoad(false);
      },
    }).catch();
  }, []);

  useEffect(() => {
    getPKBInfo();
    return () => {
      clearInterval(time.current);
    };
  }, [getPKBInfo]);

  useEffect(() => {
    setBack({
      view: "address",
      step: 1
    })
  }, [])

  return (
    <div className={styles.page_fl}>
      <div className={styles.text_bl}>
        <h4 className="bacc-title">{Lang({str: "address_verification", section: "onboarding"})}</h4>
        <p className={styles.status_text}>
          {Lang({str: "status", section: "onboarding"})}:{" "}
          <span style={{color: "#888"}}>
            {error ? <span>{error}</span> : load ? <Spin/> : Lang({str: "status_1", section: "onboarding"})}
          </span>
        </p>
        <p className={styles.black_text + " mt-16"}>
          {Lang({str: "sms", section: "onboarding"})}
        </p>

        <ButtonTime
          time={15}
          onClick={() => getPKBInfo(true)}
          btnActive={<Link className={styles.link_all + " mt-16"} onClick={() => getPKBInfo(true)}>{Lang({
            str: "update_status",
            section: "onboarding"
          })}</Link>}
          btnNoActive={Lang({str: "request_again_in", section: "onboarding"}) + ': {{sec}} ' + Lang({str: "seconds", section: "onboarding"})}
        />

      </div>
    </div>
  );
};

export default observer(Page3);
