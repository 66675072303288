import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from '../../UI';
import FilterBtn from '../Filter/FilterBtn';
import Lang from '../Lang';
import modalStore from '../ModalManager/modalStore';
import AccountStore from './AccountStore';
import styles from './Accounts.module.scss';
import ItemHistoryBlock from './ItemHistoryBlock';

const AccountHistoryBlock = ({ typeAccount = '', visible }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const accountId = AccountStore?.accounts[typeAccount]?._id;
  const items = AccountStore[typeAccount + 'AccountHistory'];

  const openHistoryPage = (withFilter = false) => {
    navigate(`/accounts/${typeAccount}/history${withFilter ? '?openFilter=1' : ''}`, {
      state: location,
    });
  };

  useEffect(() => {
    if (visible && accountId)
      AccountStore.getAccountHistory({ accountId, typeAccount, offset: 0, clear: true });
  }, [visible, accountId]);

  return (
    <div className={styles.tabs_account + ' mt-16'}>
      <div className={styles.flex + ' mb-16'}>
        <p className={styles.title}>{Lang({ str: 'history', section: 'accounts' })}</p>
        <div className={styles.flex}>
          <FilterBtn className={styles.filter} onClick={() => openHistoryPage(true)} />
          <IconButton
            style={{ marginLeft: 8 }}
            icon="document"
            disabled={accountId === false}
            size="sm"
            onClick={() => {
              modalStore.openModal('accountStatement', { typeAccount, accountId });
            }}
          />
        </div>
      </div>
      <div className={styles.output_bl}>
        <div
          className={styles.block_items}
          style={{
            'padding-bottom': '50px',
          }}>
          {items === null && (
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
              <Spin
                indicator={
                  <LoadingOutlined style={{ fontSize: 24, color: 'var(--basic-dark)' }} spin />
                }
              />
            </div>
          )}
          {items?.length === 0 && (
            <div className={styles.empty_block}>
              <p>{Lang({ str: 'no_history', section: 'wallet' })}</p>
            </div>
          )}
          {items?.slice(0, 10).map((el, index) => {
            return <ItemHistoryBlock key={index} data={el} />;
          })}
          {items?.length >= 10 && (
            <button className={styles.btn_all} onClick={() => openHistoryPage(false)}>
              {Lang({ str: 'show_more', section: 'home' })}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(AccountHistoryBlock);
