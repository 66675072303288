import { ResponsivePie } from "@nivo/pie";
import React, { useMemo } from "react";
import styles from "../Trading2.module.scss";

const AnalystPie = ({ analytic }) => {
  const [sum, data] = useMemo(() => {
    const sumAnalysis =
      analytic?.analyst?.analystRatingsHold +
        analytic?.analyst?.analystRatingsSell +
        analytic?.analyst?.analystRatingsStrongBuy +
        analytic?.analyst?.analystRatingsStrongSell +
        analytic?.analyst?.analystRatingsbuy || 0;

    const analyst_data = [];
    if (analytic?.analyst?.analystRatingsStrongBuy) {
      analyst_data.push({
        id: `${
          analytic?.analyst?.analystRatingsStrongBuy && sumAnalysis
            ? (
                (100 * analytic?.analyst?.analystRatingsStrongBuy) /
                sumAnalysis
              ).toFixed(2)
            : (0).toFixed(2)
        }%\nStrong buy`,
        label: `Strong buy (${
          analytic?.analyst?.analystRatingsStrongBuy
            ? analytic?.analyst?.analystRatingsStrongBuy
            : 0
        })`,
        value:
          analytic?.analyst?.analystRatingsStrongBuy && sumAnalysis
            ? (
                (100 * analytic?.analyst?.analystRatingsStrongBuy) /
                sumAnalysis
              ).toFixed(2)
            : (0).toFixed(2),
        color: "hsl(162, 89%, 38%)",
      });
    }

    if (analytic?.analyst?.analystRatingsbuy) {
      analyst_data.push({
        id: `${
          analytic?.analyst?.analystRatingsbuy && sumAnalysis
            ? (
                (100 * analytic?.analyst?.analystRatingsbuy) /
                sumAnalysis
              ).toFixed(2)
            : (0).toFixed(2)
        }%\nBuy`,
        label: `Buy (${
          analytic?.analyst?.analystRatingsbuy
            ? analytic?.analyst?.analystRatingsbuy
            : 0
        })`,
        value:
          analytic?.analyst?.analystRatingsbuy && sumAnalysis
            ? (
                (100 * analytic?.analyst?.analystRatingsbuy) /
                sumAnalysis
              ).toFixed(2)
            : (0).toFixed(2),
        color: "hsl(148, 82%, 70%)",
      });
    }

    if (analytic?.analyst?.analystRatingsHold) {
      analyst_data.push({
        id: `${
          analytic?.analyst?.analystRatingsHold && sumAnalysis
            ? (
                (100 * analytic?.analyst?.analystRatingsHold) /
                sumAnalysis
              ).toFixed(2)
            : (0).toFixed(2)
        }%\nHold`,
        label: `Hold (${
          analytic?.analyst?.analystRatingsHold
            ? analytic?.analyst?.analystRatingsHold
            : 0
        })`,
        value:
          analytic?.analyst?.analystRatingsHold && sumAnalysis
            ? (
                (100 * analytic?.analyst?.analystRatingsHold) /
                sumAnalysis
              ).toFixed(2)
            : (0).toFixed(2),
        color: "hsl(340, 2%, 74%)",
      });
    }

    if (analytic?.analyst?.analystRatingsSell) {
      analyst_data.push({
        id: `${
          analytic?.analyst?.analystRatingsSell && sumAnalysis
            ? (
                (100 * analytic?.analyst?.analystRatingsSell) /
                sumAnalysis
              ).toFixed(2)
            : (0).toFixed(2)
        }%\nSell`,
        label: `Sell (${
          analytic?.analyst?.analystRatingsSell
            ? analytic?.analyst?.analystRatingsSell
            : 0
        })`,
        value:
          analytic?.analyst?.analystRatingsSell && sumAnalysis
            ? (
                (100 * analytic?.analyst?.analystRatingsSell) /
                sumAnalysis
              ).toFixed(2)
            : (0).toFixed(2),
        color: "hsl(359, 100%, 71%)",
      });
    }

    if (analytic?.analyst?.analystRatingsStrongSell) {
      analyst_data.push({
        id: `${
          analytic?.analyst?.analystRatingsStrongSell && sumAnalysis
            ? (
                (100 * analytic?.analyst?.analystRatingsStrongSell) /
                sumAnalysis
              ).toFixed(2)
            : (0).toFixed(2)
        }%\nStrong sell`,
        label: `Strong sell (${
          analytic?.analyst?.analystRatingsStrongSell
            ? analytic?.analyst?.analystRatingsStrongSell
            : 0
        })`,
        value:
          analytic?.analyst?.analystRatingsStrongSell && sumAnalysis
            ? (
                (100 * analytic?.analyst?.analystRatingsStrongSell) /
                sumAnalysis
              ).toFixed(2)
            : (0).toFixed(2),
        color: "hsl(7, 96%, 64%)",
      });
    }

    return [sumAnalysis, analyst_data];
  }, [analytic]);

  return (
    <div
      className={styles.graph_blocks + " graph_blocks"}
      style={{ height: 300, width: "100%" }}
    >
      {!sum && <div>Нет данных</div>}
      {sum > 0 && (
        <ResponsivePie
          data={data}
          colors={[
            "hsl(162, 89%, 38%)",
            "hsl(148, 82%, 70%)",
            "hsl(340, 2%, 74%)",
            "hsl(359, 100%, 71%)",
            "hsl(7, 96%, 64%)",
          ]}
          margin={{ top: 30, right: 20, bottom: 80, left: 20 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor={{
            from: "color",
            modifiers: [],
          }}
          arcLinkLabelsThickness={2}
          arcLinkLabelsOffset={4}
          arcLinkLabelsTextOffset={4}
          arcLinkLabelsDiagonalLength={10}
          arcLinkLabelsStraightLength={10}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["brighter", 2]],
          }}
          arcLabel={function (e) {
            return e.value + "%";
          }}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 90,
              itemsSpacing: 5,
              itemWidth: 59,
              itemHeight: 55,
              itemTextColor: "#999",
              itemDirection: "top-to-bottom",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#999",
                  },
                },
              ],
            },
          ]}
        />
      )}
    </div>
  );
};

export default AnalystPie;
