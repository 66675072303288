import { Form, Input, Select, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../UI';
import { BackButton } from '../../blocks';
import styles from '../../components/Accounts/Accounts.module.scss';
import AlertStore from '../Alert/AlertStore';
import Lang from '../Lang';
import BankAccountsStore from './BankAccountsStore';

const { TabPane } = Tabs;

const AddAccounts = ({ visible }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [createLoad, setCreateLoad] = useState(false);
  const [currency, setCurrency] = useState('KZT');

  const onFinish = (values) => {
    setCreateLoad(true);
    values.currency = currency;
    BankAccountsStore.create(values)
      .then(() => {
        setCreateLoad(false);
        AlertStore.success('Счет создан');
        navigate(-1);
      })
      .catch(() => setCreateLoad(false));
  };

  useEffect(() => {
    if (visible === false) {
      form.resetFields();
      form2.resetFields();
    }
  }, [visible]);

  return (
    <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <div className={styles.fixed_bl}>
        <BackButton label={Lang({ str: 'back', section: 'default' })} />
      </div>

      <div className={styles.personal_information + ' ' + styles.horizont_in}>
        <p className={styles.titile}>{Lang({ str: 'add_new_acc_2', section: 'menu' })}</p>

        <div className={styles.tabs_block + ' mt-8'}>
          {/*<Tabs
          centered="true"
          onChange={setCurrency}
          activeKey={currency}
        >
          <TabPane tab={Lang({str: "in_kzt", section: "menu"})} key="KZT">
            <div className={styles.tabs1}>
              <div className={styles.block_valute}>
                <Form
                  form={form}
                  layout="vertical"
                  name="basic"
                  requiredMark={false}
                  onFinish={onFinish}
                  labelCol={{
                    span: 24,
                  }}
                  autoComplete="off"
                >
                  <div className={styles.block_valute1}>
                    <Form.Item
                      label="IBAN"
                      name="iban"
                      rules={[{required: true, message: Lang({str: "enter_iban", section: "menu"})}]}
                    >
                      <Input placeholder={Lang({str: "enter_iban", section: "menu"})}/>
                    </Form.Item>
                    <Form.Item
                      label={Lang({str: "bank_recipient", section: "menu"})}
                      name="bankRecipient"
                      rules={[{required: true, message: Lang({str: "select_bank_recipient", section: "menu"})}]}
                    >
                      <Select
                        showSearch
                        placeholder={Lang({str: "select_bank_recipient", section: "menu"})}
                        optionFilterProp="children"
                        options={Object.keys(BankAccountsStore.banks).map((key) => {
                          return {
                            value: key,
                            label: BankAccountsStore.banks[key]
                          }
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      label={Lang({str: "bic", section: "menu"})}
                      name="bankRecipientBik"
                      rules={[{required: true, message: Lang({str: "enter_bic", section: "menu"})}]}
                    >
                      <Input placeholder={Lang({str: "enter_bic", section: "menu"})}/>
                    </Form.Item>
                  </div>
                  <Form.Item className={styles.buttonBlockFixed + " m-0 p-0"}>
                    <Button htmlType="submit" loading={createLoad}>{Lang({str: "add_new_acc", section: "menu"})}</Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </TabPane>
          <TabPane tab={Lang({str: "in_usd", section: "menu"})} key="USD">
            
          </TabPane>
        </Tabs>*/}
          <div className={styles.tabs1}>
            <div className={styles.block_valute}>
              <Form
                form={form2}
                layout="vertical"
                name="basic"
                requiredMark={false}
                onFinish={onFinish}
                labelCol={{
                  span: 24,
                }}
                autoComplete="off">
                <div className={styles.block_valute1}>
                  <Form.Item
                    label={Lang({ str: 'iban', section: 'menu' })}
                    name="iban"
                    rules={[
                      { required: true, message: Lang({ str: 'enter_iban', section: 'menu' }) },
                    ]}>
                    <Input placeholder={Lang({ str: 'enter_iban1', section: 'menu' })} />
                  </Form.Item>
                  <Form.Item
                    label={Lang({ str: 'bank_recipient', section: 'menu' })}
                    name="bankRecipient"
                    rules={[
                      {
                        required: true,
                        message: Lang({ str: 'select_bank_recipient', section: 'menu' }),
                      },
                    ]}>
                    <Select
                      placeholder={Lang({ str: 'select_bank_recipient', section: 'menu' })}
                      optionFilterProp="children"
                      options={Object.keys(BankAccountsStore.banks).map((key) => {
                        return {
                          value: key,
                          label: BankAccountsStore.banks[key],
                        };
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    label={Lang({ str: 'bic', section: 'menu' })}
                    name="bankRecipientBik"
                    rules={[
                      { required: true, message: Lang({ str: 'enter_bic', section: 'menu' }) },
                    ]}>
                    <Input placeholder={Lang({ str: 'enter_bic', section: 'menu' })} />
                  </Form.Item>
                  {/* <Form.Item
                    label={Lang({ str: 'correspondent_bank', section: 'menu' })}
                    name="bankCorrespondent"
                    rules={[
                      {
                        required: true,
                        message: Lang({ str: 'enter_correspondent_bank', section: 'menu' }),
                      },
                    ]}>
                    <Input
                      placeholder={Lang({ str: 'enter_correspondent_bank', section: 'menu' })}
                    />
                  </Form.Item>
                  <Form.Item
                    label={Lang({ str: 'bic', section: 'menu' })}
                    name="bankCorrespondentBik"
                    rules={[
                      { required: true, message: Lang({ str: 'enter_bic', section: 'menu' }) },
                    ]}>
                    <Input placeholder={Lang({ str: 'enter_bic', section: 'menu' })} />
                  </Form.Item>*/}
                </div>
                <Form.Item className={'m-0 p-0'}>
                  <Button htmlType="submit" loading={createLoad}>
                    {Lang({ str: 'add_new_acc', section: 'menu' })}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAccounts;
