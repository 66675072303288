import { Button, Form, Input, Layout } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import ReactCodeInput from "react-code-input";
import { Link } from "react-router-dom";
import { apiQuery, apiQueryError } from "../../Utils";
import AlertStore from "../Alert/AlertStore";
import HeaderLang from "../Header/HeaderLang";
import Lang from "../Lang/index";
import styles from "./Auth.module.scss";
import userStore from "./userStore";
const { Content } = Layout;

const propsReactCodeInput = {
  inputStyle: {
    margin: "6px 3% 6px 0",
    width: "14%",
    fontSize: "22px",
    height: 40,
    backgroundColor: "#EAEAEA",
    border: 0,
    borderRadius: "4px",
    textAlign: "center",
    color: "#252525",
  },
};

const ButtonTime = ({time, btnActive, btnNoActive}) => {

  return <></>
}

const Restore = () => {
  const [buttonActive, setButtonActive] = useState(false);
  const [form, setForm] = useState("email");
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [count, setCount] = useState(60);
  const timer = useRef();
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  // const [formbl] = Form.useForm();

  const handleInputChange = (e) => {
    if (e.target.value.length > 0) {
      setInputValue(e.target.value);
      setShow(true);
      setButtonActive(true);
    } else {
      setShow(false);
      setButtonActive(false);
    }
  };

  const handleInputChange1 = (e) => {
    if (e.target.value.length > 0) {
      setInputValue1(e.target.value);
      setShow1(true);
      setButtonActive(true);
    } else {
      setShow1(false);
      setButtonActive(false);
    }
  };
  const handleInputChange2 = (e) => {
    if (e.target.value.length > 0) {
      setInputValue2(e.target.value);
      setShow2(true);
      setButtonActive(true);
    } else {
      setShow2(false);
      setButtonActive(false);
    }
  };

  useEffect(() => {
    userStore.setRestoreEmail("");
    userStore.setRestoreCode("");
  }, []);

  const Counter = (c = 60) => {
    clearInterval(timer.current);
    setCount(c);
    timer.current = setInterval(() => {
      setCount((count) => count === 0 ? 0 : count - 1);
    }, 1000);
  };

  const onFinish = (values) => {
    Counter()
    apiQuery("/restore", {
      method: "POST",
      body: values,
    })
      .then((userData) => {
        console.log(values);
        userStore.setRestoreEmail(values.email);
        setForm("code");
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  const validatePwd = (rule, value) => {
    return new Promise(async (resolve, reject) => {
      if (!value) {
        await reject(Lang({ str: "enter_pass", section: "auth" }));
      } else if (value.length < 8) {
        await reject(Lang({ str: "pass_8_characters", section: "auth" }));
      } else if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{8,}/.test(value)
      ) {
        await reject(Lang({ str: "pass_unsafe", section: "auth" }));
      } else {
        await resolve();
      }
    });
  };

  const onFinish2 = () => {
    if (!userStore.restoreEmail)
      return apiQueryError({
        error: {
          body: {
            msg: Lang({ str: "request_err", section: "auth" }),
          },
        },
      });

    apiQuery("/restore_code", {
      method: "POST",
      body: {
        email: userStore.restoreEmail,
        code: userStore.restoreCode,
      },
    })
      .then((userData) => {
        setForm("password");
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  const onFinish3 = (values) => {
    if (userStore.restoreEmail === "" || userStore.restoreCode === "")
      return apiQueryError({
        error: {
          body: {
            msg: Lang({ str: "request_err", section: "auth" }),
          },
        },
      });

    if (userStore.restoreCode.length !== 6) {
      AlertStore.error(Lang({ str: "enter_conf_code", section: "auth" }));
      return;
    }

    apiQuery("/restore_password", {
      method: "POST",
      body: {
        email: userStore.restoreEmail,
        code: userStore.restoreCode,
        ...values,
      },
    })
      .then((userData) => {
        setForm("success");
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  const onChange = (value) => {
    if (value.length > 0) {
      setButtonActive(true);
    } else {
      setButtonActive(false);
    }
    if (value.length === 6) {
      // formbl?.submit();
      document.getElementById("buttonSubmit").click();
    }
    userStore.setRestoreCode(value);
  };

  if (form === "success")
    return (
      <div className="flex_block">
        <HeaderLang />
        <Content className={styles.siteLayout}>
          <div>
            <h2 className={styles.titleAuth}>
              {Lang({ str: "pass_recovery", section: "auth" })}
            </h2>
            <p className={styles.little_text}>
              {Lang({ str: "pass_restored", section: "auth" })}
            </p>
          </div>

          <div style={{ textAlign: "center", marginTop: "16px" }}>
            <Link className={styles.enter_button} to="/login">
              {Lang({ str: "log_in", section: "auth" })}
            </Link>
          </div>
        </Content>
      </div>
    );

  if (form === "password")
    return (
      <div className="flex_block">
        <HeaderLang />
        <Content className={styles.siteLayout}>
          <div>
            <h2 className={styles.titleAuth}>
              {Lang({ str: "pass_recovery", section: "auth" })}
            </h2>
          </div>

          <Form
            layout="vertical"
            name="normal_login"
            className={styles.loginForm}
            onFinish={onFinish3}
          >
            <Form.Item
              className={styles.login_wrp}
              label={show1 && Lang({ str: "enter_new_pass", section: "auth" })}
              name="password"
              rules={[{ validator: validatePwd }]}
              hasFeedback
            >
              <Input.Password
                onChange={handleInputChange1}
                placeholder={Lang({ str: "enter_new_pass", section: "auth" })}
              />
            </Form.Item>
            <Form.Item
              name="confirm"
              className={styles.login_wrp}
              label={show2 && Lang({ str: "reenter_new_pass", section: "auth" })}
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: Lang({
                    str: "confirm_pass",
                    section: "auth",
                  }),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        Lang({ str: "passwords_dont_match", section: "auth" })
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                onChange={handleInputChange2}
                placeholder={Lang({ str: "reenter_new_pass", section: "auth" })}
              />
            </Form.Item>
            <Form.Item className={styles.buttonBlockFixed}>
              <Button
                type="primary"
                htmlType="submit"
                className={
                  buttonActive
                    ? styles.button_anactive + " " + styles.login_form_button
                    : styles.buttonFixed + " " + styles.login_form_button
                }
              >
                {Lang({ str: "send", section: "default" })}
              </Button>
            </Form.Item>
            {/* <Link
              style={{ textAlign: "right" }}
              className={styles.link}
              to="/login"
            >
              {Lang({ str: "enter", section: "auth" })}
            </Link>*/}
          </Form>
        </Content>
      </div>
    );

  if (form === "code")
    return (
      <div className="flex_block">
        <HeaderLang />
        <Content className={styles.siteLayout}>
          <div>
            <h2 className={styles.titleAuth}>
              {Lang({ str: "six_code", section: "auth" })}
            </h2>
            <p className={styles.color_gr}>
              {Lang({ str: "enter_six_code", section: "auth" })}{" "}
              {userStore.restoreEmail}
            </p>
          </div>

          <Form
            // form={formbl}
            onFinish={onFinish2}
            name="normal_login"
            className={styles.loginForm}
            layout="vertical"
            initialValues={{ rememberMy: true }}
          >
            <div
              style={{
                display: "block",
                margin: "0px auto 24px",
                width: "100%",
              }}
            >
              <ReactCodeInput
                type="number"
                fields={6}
                inputMode="numeric"
                name="code"
                value={userStore.restoreCode}
                onChange={onChange}
                autoComplete={"one-time-code"}
                {...propsReactCodeInput}
              />
            </div>

            <Form.Item className={styles.buttonBlockFixed}>
              <Button
                type="primary"
                htmlType="submit"
                className={
                  buttonActive
                    ? styles.button_anactive + " " + styles.login_form_button
                    : styles.buttonFixed + " " + styles.login_form_button
                }
                id={"buttonSubmit"}
              >
                {Lang({ str: "send", section: "default" })}
              </Button>
            </Form.Item>

            {/*<Divider />

            <div style={{ textAlign: "center", marginTop: "16px" }}>
              <Link className="gray_button" to="/login">
                Авторизоваться
              </Link>
            </div>*/}

            {count > 0 &&
              <p className={styles.again_kod}>
                {Lang({ str: "resend", section: "auth" })} 00:
                {count}
              </p>
            }
            {count === 0 && (
              <p
                className={styles.text_grb}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onFinish({ email: userStore.restoreEmail });
                }}
              >
                {Lang({ str: "resend", section: "auth" })}
              </p>
            )}
          </Form>
        </Content>
      </div>
    );

  return (
    <div className="flex_block">
      <HeaderLang button1={Lang({ str: "back", section: "default" })} />
      <Content className={styles.siteLayout}>
        <div>
          <h2 className={styles.titleAuth + " mb-0"}>
            {Lang({ str: "pass_recovery", section: "auth" })}
          </h2>
        </div>
        <Form
          name="normal_login"
          layout="vertical"
          className={styles.loginForm}
          initialValues={{ rememberMy: true }}
          onFinish={onFinish}
        >
          <Form.Item
            className={styles.login_wrp}
            label={show && Lang({ str: "email_address", section: "auth" })}
            name="email"
            rules={[
              {
                required: true,
                message: Lang({ str: "enter_email", section: "auth" }),
              },
            ]}
          >
            <Input
              onChange={handleInputChange}
              type={"email"}
              placeholder={Lang({ str: "email_address", section: "auth" })}
            />
          </Form.Item>

          <Form.Item className={styles.buttonBlockFixed}>
            <Button
              type="primary"
              onClick={Counter}
              htmlType="submit"
              className={
                buttonActive
                  ? styles.button_anactive + " " + styles.login_form_button
                  : styles.buttonFixed + " " + styles.login_form_button
              }
            >
              {Lang({ str: "restore_pass", section: "auth" })}
            </Button>
          </Form.Item>

          {/*<div className="title">
            <span>{Lang({ str: "or", section: "auth" })}</span>
          </div>

          <div style={{ textAlign: "center", marginTop: "16px" }}>
            <Link className="gray_button" to="/login">
              {Lang({ str: "enter", section: "auth" })}
            </Link>
          </div>*/}
        </Form>
      </Content>
    </div>
  );
};

export default observer(Restore);
