import { Form, Radio, Space } from 'antd';
import React, { useState } from 'react';
import Utils, { apiQuery, apiQueryError } from '../../Utils';
import Lang from '../Lang/index';
import styles from './PersonalInformation.module.scss';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { observer } from 'mobx-react-lite';
import { Button } from '../../UI';
import { BackButton } from '../../blocks';
import AlertStore from '../Alert/AlertStore';
import modalStore from '../ModalManager/modalStore';

const Agreements = () => {
  const [value, setValue] = useState('traderAgreement');
  const [modalOpen, setModalOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [url, setUrl] = useState();

  const onChange = ({ target: { value } }) => {
    setValue(value);
    console.log('radio checked', value);
    setVisible(true);
    return value;
  };

  const onFinish = (value) => {
    apiQuery('/private/v1/getDocUrl', {
      method: 'POST',
      body: value,
    })
      .then((data) => {
        if (data?.error) return AlertStore.error(data.error.msg);
        // urlOpen(data.url)
        setUrl('https://' + Utils.apiDomain() + data.url);
        modalStore.openModal('pdf', { url: `https://${Utils.apiDomain() + data.url}` });
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  return (
    <div className={styles.personal_information_wrp}>
      <BackButton />

      <div className={styles.personal_language}>
        <p className={styles.titile}>{Lang({ str: 'contracts', section: 'menu' })}</p>
        <Form onFinish={onFinish}>
          <Form.Item name={'mode'} initialValue={value}>
            <Radio.Group onChange={onChange}>
              <Space direction="vertical">
                {/*<Radio className={styles.radioo_act} value={"traderAgreement"}>*/}
                {/*  <p>Договор брокерского обслуживания</p>*/}
                {/*</Radio>*/}
                <Radio className={styles.radioo_act} value={'alpacaCustomerAgreement'}>
                  <p>{Lang({ str: 'alpaca_customer_agreement', section: 'menu' })}</p>
                </Radio>
                <Radio className={styles.radioo_act} value={'brokerageServiceAgreement'}>
                  <p>{Lang({ str: 'brokerage_service_agreement', section: 'menu' })}</p>
                </Radio>
                <Radio className={styles.radioo_act} value={'brokerageServiceAgreementAnnex1'}>
                  <p>{Lang({ str: 'annex_1', section: 'menu' })}</p>
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {visible && (
            <Form.Item className={styles.buttonBlockFixed}>
              <Button>{Lang({ str: 'download_pdf', section: 'default' })}</Button>
            </Form.Item>
          )}
        </Form>
      </div>
    </div>
  );
};

export default observer(Agreements);
