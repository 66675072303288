import { configure, makeAutoObservable } from "mobx";
import OneSignal from "react-onesignal";
import userStore from "../Auth/userStore";
import Lang from "../Lang";

configure({ enforceActions: "never" });

class PushNotificationStore {
  init = false;
  loading = false;
  buttonText = Lang({ str: "notifications_allow", section: "menu" });

  constructor() {
    makeAutoObservable(this);
  }

  setInit(data) {
    this.init = data;
  }

  setButtonText(data) {
    this.buttonText = data;
  }

  setLoading(data) {
    this.loading = data;
  }

  async webPushGetSubscriptionState(state) {
    console.log('webPushGetSubscriptionState')
    const subscribeText = Lang({ str: "notifications_allow", section: "menu" });
    const unsubscribeText = Lang({
      str: "notifications_off",
      section: "menu",
    });
    if(window.navigator.userAgent === 'GeekoTech'){
      this.setButtonText(
          (!state.isPushEnabled || state.isOptedOut  ) ? unsubscribeText : subscribeText
      );
    }
    else{
      let pushId = OneSignal.User.PushSubscription.id

      let is_subscribed = false
      if(pushId){
        let subscribed_data = await userStore.checkPush(pushId)
        console.log({subscribed_data})
        if(Array.isArray(subscribed_data) && subscribed_data.length > 1){
          if(subscribed_data[1]?.is_subscribed){
            is_subscribed = true
          }
        }
      }
      console.log({isPushEnabled: state.isPushEnabled, isOptedOut: state?.isOptedOut, is_subscribed})
      this.setButtonText(
          (!state.isPushEnabled || state.isOptedOut  || !is_subscribed) ? unsubscribeText : subscribeText
      );
    }
  }

  async updateButton(optedIn){
    console.log('updateButton')
    const subscribeText = Lang({ str: "notifications_allow", section: "menu" });
    const unsubscribeText = Lang({
      str: "notifications_off",
      section: "menu",
    });

    if (window.navigator.userAgent === "GeekoTech") {
      try {
        window.ReactNativeWebView.postMessage("WebPushGetSubscriptionState");
      } catch (e) {
        console.error({ e });
      }

      return;
    }
    let is_subscribed = false
    let isPushEnabled = optedIn
    const permission = OneSignal.Notifications.permission;
    let pushId = OneSignal.User.PushSubscription.id
    if(!pushId){
      return false;
    }

    if(!permission){
      await userStore.pushSave(pushId, false)
      if(optedIn){
        await OneSignal.User.PushSubscription.optOut();
      }
    }

    // let subscribed_data = await userStore.checkPush(pushId)
    // console.log('updateButton subscribed_data: ', subscribed_data)
    // if(Array.isArray(subscribed_data) && subscribed_data.length > 1){
    //   if(subscribed_data[1]?.is_subscribed){
    //     is_subscribed = true
    //   }
    // }
    console.log({permission, optedIn/*, is_subscribed*/})
    // if(is_subscribed){
    //   console.log({isPushEnabled})
    //   if(!isPushEnabled){
    //     is_subscribed = false
    //     await userStore.pushSave(pushId, is_subscribed)
    //   }
    // }
    console.log({permission, isPushEnabled/*, is_subscribed*/})

    this.setButtonText(
        (!permission || !isPushEnabled /*|| !is_subscribed*/) ? unsubscribeText : subscribeText
    );
  }

  async updateMangeWebPushSubscriptionButton() {
    console.log('updateMangeWebPushSubscriptionButton')
    const subscribeText = Lang({ str: "notifications_allow", section: "menu" });
    const unsubscribeText = Lang({
      str: "notifications_off",
      section: "menu",
    });

    if (window.navigator.userAgent === "GeekoTech") {
      try {
        window.ReactNativeWebView.postMessage("WebPushGetSubscriptionState");
      } catch (e) {
        console.error({ e });
      }

      return;
    }
    let is_subscribed = false
    let isPushEnabled = false
    const permission = OneSignal.Notifications.permission;
    let pushId = OneSignal.User.PushSubscription.id
    if(!pushId){
      return false;
    }

    if(!permission){
      await userStore.pushSave(pushId, false)
    }

    let subscribed_data = await userStore.checkPush(pushId)
    console.log({subscribed_data})
    if(Array.isArray(subscribed_data) && subscribed_data.length > 1){
      if(subscribed_data[1]?.is_subscribed){
        is_subscribed = true
      }
    }
    else{
      await userStore.pushSave(pushId, false)
    }

    if(is_subscribed){
      isPushEnabled = OneSignal.User.PushSubscription.optedIn;
      console.log({isPushEnabled})
      if(!isPushEnabled){
        await userStore.pushSave(pushId, false)
      }
    }
    else{
      await OneSignal.User.PushSubscription.optOut();
    }
    console.log({permission, isPushEnabled, is_subscribed})

    this.setButtonText(
        (!permission || !isPushEnabled || !is_subscribed) ? unsubscribeText : subscribeText
    );
    // })
  }

  async onManageWebPushSubscriptionButtonClicked() {
    console.log("onManageWebPushSubscriptionButtonClicked");
    this.setLoading(true);
    console.log(
      "UserID " + (userStore?.userData?._id ? userStore?.userData?._id : "")
    );
    if (window.navigator.userAgent === "GeekoTech") {
      try {
        window.ReactNativeWebView.postMessage(
          "WebPushSubscriptionButtonClicked"
        );
      } catch (e) {
        console.error({ e });
      }

      this.setLoading(false);
      return;
    }

    let permission = OneSignal.Notifications.permission;
    console.log({permission});
    if (!permission) {
      /* Unsubscribed, subscribe them */
      await OneSignal.Notifications.requestPermission();
    }
    permission = OneSignal.Notifications.permission;
    if(!permission){
      return false;
    }
    let pushId = OneSignal.User.PushSubscription.id
    let is_subscribed = false
    let isPushEnabled = false

    let subscribed_data = await userStore.checkPush(pushId)
    console.log({subscribed_data})
    if(Array.isArray(subscribed_data) && subscribed_data.length > 1){
      if(subscribed_data[1]?.is_subscribed){
        is_subscribed = true
      }
    }

    if(is_subscribed){
      is_subscribed = false
      await OneSignal.User.PushSubscription.optOut();
    }
    else{
      is_subscribed = true

      await OneSignal.User.PushSubscription.optIn();
    }

    isPushEnabled = OneSignal.User.PushSubscription.optedIn;
    console.log({isPushEnabled});

    this.setLoading(false);
  }

}

export default new PushNotificationStore();