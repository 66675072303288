import React, { useCallback, useEffect, useState } from "react";
import styles from "./ButtonBack.module.scss";
import { observer } from "mobx-react-lite";
import left_icon from "../../assets/images/arrowmenu.svg";
import { useNavigate, useLocation } from "react-router-dom";

const ButtonBack = (props) => {
  let location = useLocation();
  let history = useNavigate();

  return (
    <button className={styles.back_bl} onClick={() => history(-1)}>
      <img src={left_icon} /> {props.button1}
    </button>
  );
};

export default observer(ButtonBack);
