import { observer } from 'mobx-react-lite';
import moment from 'moment/moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../../blocks';
import AppStore from '../App/AppStore';
import Lang from '../Lang';
import styles from './Notification.module.scss';
import NotificationStore from './NotificationStore';

const Notification = () => {
  const navigate = useNavigate();

  let { id, createdAt, text, textV2 } = NotificationStore.activeNotificationData;

  return (
    <>
      <BackButton
        // onClick={() => navigate('/user/notification')}
        label={Lang({ str: 'all_notifications', section: 'menu' })}
        style={{ position: 'sticky', top: 0, zIndex: 10, background: 'var(--secondary-light)' }}
      />
      <div className={styles.notification_page}>
        <div>
          <p className={styles.text_bold}>{Lang({ str: 'notification', section: 'menu' })}</p>
          <p className={styles.text_date}>{moment(createdAt).format('DD MMMM YYYY, HH:mm')}</p>
        </div>
        <p>{textV2[AppStore.lang]?.subject || text?.subject || ''}</p>
        <p>{textV2[AppStore.lang]?.long || text?.long || 'Нет данных'}</p>
        <p>
          {Lang({ str: 'thanks_for_trading', section: 'menu' })}:{' '}
          <a href="mailto:support@geekoinvest.kz">support@geekoinvest.kz</a>
        </p>
      </div>
    </>
  );
};

export default observer(Notification);
