import React, { useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import { $class } from '../../Utils';
import './index.scss';

const Clickable = ({
  onClick,
  id,
  className,
  style,
  children,
  disabled,
  active,
  rippleColor = 'var(--basic-dark40)',
}) => {
  const rippleRef = useRef(null);
  const elRef = useRef(null);

  const [{ scale, opacity }, springApi] = useSpring(() => ({
    scale: 0,
    opacity: 0,
  }));

  const onDown = (e) => {
    if (!elRef.current || disabled) return;
    const clientX = e?.touches?.length ? e.touches[0].clientX : e.clientX;
    const clientY = e?.touches?.length ? e.touches[0].clientY : e.clientY;

    springApi.set({
      scale: 0,
      opacity: 0.5,
    });

    springApi.start({
      scale: 5,
      config: { duration: 400 },
    });

    const { x, y } = elRef.current.getBoundingClientRect();

    rippleRef.current.style.top = `${clientY - y}px`;
    rippleRef.current.style.left = `${clientX - x}px`;
  };

  const onUp = (e) => {
    if (disabled) return;

    springApi.start({
      opacity: 0,
      config: { duration: 400 },
    });
  };

  return (
    <animated.button
      id={id}
      ref={elRef}
      onTouchStart={onDown}
      onMouseDown={onDown}
      onTouchEnd={onUp}
      onTouchCancel={onUp}
      onMouseUp={onUp}
      onMouseLeave={onUp}
      onClick={onClick && !disabled ? onClick : null}
      className={$class('ui-clickable', className, ['active', active])}
      disabled={disabled}
      style={style}>
      <animated.div
        ref={rippleRef}
        style={{
          transform: scale.to((v) => `scale(${v}) translate(-50%,-50%)`),
          opacity,
        }}
        className="ui-clickable-ripple">
        <animated.div style={{ backgroundColor: rippleColor }}></animated.div>
      </animated.div>
      {children}
    </animated.button>
  );
};

export default Clickable;
