import React, { useEffect, useState } from "react";
import AppStore from "../App/AppStore";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import Lang from "../Lang/index";
import styles from "./PersonalInformation.module.scss";
import { Form, message, Radio, Space } from "antd";
import HeaderTitle from "../Header/HeaderTitle";
import left_icon from "../../assets/images/arrowmenu.svg";
import { BackButton } from "../../blocks";
import { Button } from "../../UI";
import AlertStore from "../Alert/AlertStore";

const SettingsLanguage = ({ isModal, onClick }) => {
  let history = useNavigate();

  const [value, setValue] = useState(
    localStorage.getItem("appLang") || AppStore.lang
  );

  const onChange = (e) => {
    //console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  useEffect(() => {
    localStorage.setItem("lang", value);
  }, [value]);

  const onFinish = (e) => {
    AppStore.setLang(value);
    AlertStore.success(Lang({ str: "saved", section: "menu" }));

    //setIsModalOpen(false);
    // window.location.reload();
    // history("/");
    // window.location.reload();
    setTimeout(() => {
      window.location.href = '/';
    }, 500)
  };

  return (
    <div className={styles.personal_information_wrp}>
      {!isModal && <BackButton />}

      <div className={styles.personal_language}>
        <p className={styles.titile}>
          {Lang({ str: "language", section: "menu" })}
        </p>
        <Form onFinish={onFinish}>
          <Form.Item>
            <Radio.Group onChange={onChange} value={value}>
              <Space direction="vertical">
                <Radio
                  className={styles.radioo_act}
                  checked={value === "ru"}
                  value="ru"
                >
                  <p>Русский</p>
                </Radio>
                <Radio value="en" checked={value === "en"}>
                  <p>English</p>
                </Radio>
                <Radio value="kz" checked={value === "kz"}>
                  <p>Қазақ</p>
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item className={styles.buttonBlockFixed}>
            <Button onClick={onClick}>
              {Lang({ str: "save", section: "default" })}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default observer(SettingsLanguage);
