import React from 'react';
import { IconButton } from '../../UI';
import { $class } from '../../Utils';
import styles from './style.module.scss';

const FilterBtn = ({ onClick, active, className, size = 'sm' }) => {
  return (
    <div className={$class(styles.filterBtn, className, 'orders-filter-btn')}>
      <IconButton
        variant={active && 'contained'}
        onClick={onClick}
        icon="filter"
        size={size}
      />
    </div>
  );
};

export default FilterBtn;
