import {configure, makeAutoObservable} from "mobx";
import {apiRequest} from "../../Utils";

configure({enforceActions: "never"})

class TickerStore {

  tickerSymbol = {};
  tickerData = {};
  tickerSignal = {};
  tickerRows = []; // список символов

  constructor() {
    makeAutoObservable(this)
  }

  setTicker(tickerId, data){
    this.tickerData[tickerId] = data;
    this.tickerData[tickerId].loadingTime = new Date().getTime();
    console.log(this.tickerData[tickerId])
  }

  setTickerSignal(tickerId, data){
    this.tickerSignal[tickerId] = data;
  }

  getTicker(tickerId, cache = true) {
    return new Promise((resolve) => {
      if (!tickerId) return resolve(null);

      if (!this.tickerData[tickerId]) this.tickerData[tickerId] = {load: true};
      if (this.tickerData[tickerId].loadingTime && cache){
        const t = new Date().getTime()-120000;
        if (this.tickerData[tickerId].loadingTime > t ) return resolve(this.tickerData[tickerId]);
      }

      apiRequest({
        url: "/private/v1/ticker/"+tickerId,
        success: (data) => {
          if (!data) return this.tickerData[tickerId] = null;
          this.tickerData[tickerId] = data;
          this.tickerSymbol[data.symbol] = tickerId;
          this.tickerData[tickerId].loadingTime = new Date().getTime();
          resolve(this.tickerData[tickerId]);
        }
      }).catch(() => {
        resolve(this.tickerData[tickerId]);
      })
    })
  }

  getTickerBySymbol(symbol, cache = true) {
    return new Promise(async (resolve) => {
      if (!symbol) return resolve(null);
      if (this.tickerSymbol[symbol]) return resolve(await this.getTicker(this.tickerSymbol[symbol], cache));
      if (this.tickerSymbol[symbol] === null) return null;

      apiRequest({
        url: `/private/v1/ticker_by_symbol/${symbol}`,
        success: (data) => {
          if (!data) {
            resolve(null)
            this.tickerSymbol[symbol] = null;
            return;
          }
          this.tickerData[data._id] = data;
          this.tickerSymbol[data.symbol] = data._id;
          this.tickerData[data._id].loadingTime = new Date().getTime();
          resolve(data)
        }
      }).catch()
    })
  }
}

export default new TickerStore()