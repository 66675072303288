import AppStore from '../App/AppStore';
const translation = {
  default: {
    back: { ru: 'Назад', en: 'Back', kz: 'Артқа' },
    save: { ru: 'Сохранить', en: 'Save', kz: 'Сақтау' },
    confirm: { ru: 'Подтвердить', en: 'Confirm', kz: 'Растау' },
    close: { ru: 'закрыть', en: 'close', kz: 'жабу' },
    market: { ru: 'Рынок', en: 'Market', kz: 'Нарық' },
    favorites: { ru: 'Избранное', en: 'Watchlist', kz: 'Таңдаулылар' },
    portfolio: { ru: 'Портфель', en: 'Wallet', kz: 'Портфель' },
    all: { ru: 'Все', en: 'All', kz: 'Барлығы' },
    ok: { ru: 'Ок', en: 'Okay', kz: 'Ок' },
    search: { ru: 'Поиск', en: 'Search', kz: 'Іздеу' },
    yes: { ru: 'Да', en: 'Yes', kz: 'Иә' },
    no: { ru: 'Нет', en: 'No', kz: 'Жоқ' },
    error: { ru: 'Ошибка', en: 'Error', kz: 'Қате' },
    send: { ru: 'Отправить', en: 'Send', kz: 'Жіберу' },
    download_pdf: { ru: 'Поделиться', en: 'Share', kz: 'Бөлісу' },
    pieces: { ru: 'шт.', en: 'pcs.', kz: 'нәрсе' },
    clear: { ru: 'очистить', en: 'clear', kz: 'тазалау' },
    open: { ru: 'открыть', en: 'open', kz: 'ашу' },
    source: { ru: 'Источник', en: 'Source', kz: 'Ақпарат көзі' },
    apply: { ru: 'Применить', en: 'Apply', kz: 'Қолдану' },
    exit: { ru: 'Выйти из аккаунта', en: 'Log out', kz: 'Шығу' },
    copied: { ru: 'Скопировано', en: 'Copied', kz: 'Көшірілді' },
    start_verification: {
      ru: 'Начать верификацию',
      en: 'Start verification',
      kz: 'Верификацияны бастаңыз',
    },
    start_verification_2: {
      ru: 'Продолжить верификацию',
      en: 'Continue verification',
      kz: 'Верификацияны жалғастырыңыз',
    },
    end_verification: {
      ru: 'Заявка на верификацию личности отправлена',
      en: 'Application for identity verification sent',
      kz: 'Жеке басын растауға өтініш жіберілді',
    },
    end_verification_2: {
      ru: 'Пожалуйста, ожидайте результат рассмотрения',
      en: 'Please wait for the review result',
      kz: 'Тексеру нәтижесін күтіңіз',
    },
    continue_verification: {
      ru: (<>Вы не закончили процесс<br/>верификации личности</>),
      en: (<>You have not completed<br/>the identity verification process</>),
      kz: (<>Сіз жеке куәлікті<br/>растау процесін аяқтамадыңыз</>),
    },
    continue_verification_2: {
      ru: 'Пожалуйста, вернитесь и продолжите',
      en: 'Please come back and continue',
      kz: 'Қайта оралыңыз және жалғастырыңыз',
    },
    block_verification: {
      ru: 'Заявка на верификацию личности отклонена',
      en: 'Identity Verification Request Rejected',
      kz: 'Жеке басты растау сұрауы қабылданбады',
    },
    block_verification_2: {
      ru: 'В ближайшее время с вами свяжется наш менеджер',
      en: 'Our manager will contact you shortly',
      kz: 'Жақын уақытта сізбен біздің менеджер байланысады',
    },
    verification_msg: {
      ru: 'Пройдите верификацию',
      en: 'Finish verification',
      kz: 'Верификацины өтіңіз',
    },
    verification_msg_2: {
      ru: 'и откройте брокерский счет',
      en: 'and open a brokerage account',
      kz: 'және брокерлік шот ашыңыз',
    },
    verification_msg_3: {
      ru: 'и начните инвестировать в свои мечты!',
      en: 'and start investing in your dreams!',
      kz: 'және арманыңызға инвестиция сала бастаңыз!',
    },
  },
  home: {
    //Главная страница
    popular: { ru: 'Популярное', en: 'Popular', kz: 'Танымал' },
    news: { ru: 'Новости', en: 'News', kz: 'Жаңалықтар' },
    brokerage_acc: { ru: 'Брокерский счет', en: 'Brokerage account', kz: 'Брокерлік шоты' },
    trading_acc: { ru: 'Торговый счет', en: 'Trading account', kz: 'Сауда шоты' },
    investments_overview: { ru: 'Обзор инвестиций', en: 'Your Portfolio', kz: 'Инвестицияларға шолу' },
    replenishment: { ru: 'Пополнение', en: 'Replenishment', kz: 'Толықтыру' },
    withdrawal: { ru: 'Вывод', en: 'Withdrawal', kz: 'Қорытынды' },
    transfer: { ru: 'Перевод', en: 'Transfer', kz: 'Аударма' },
    growth: { ru: 'Растут', en: 'Top Gainers', kz: 'Өсіп жатыр' },
    decline: { ru: 'Падают', en: 'Top Losers', kz: 'Құлап жатыр' },
    all_shares: { ru: 'Все акции', en: 'View All', kz: 'Барлық акциялар' },
    no_results: {
      ru: 'По вашему запросу ничего не найдено',
      en: 'No results found for your request',
      kz: 'Сұрауыңыз бойынша нәтижелер табылмады',
    },
    show_more: { ru: 'Показать еще', en: 'Show more', kz: 'Көбірек көрсету' },
    recent_searches: { ru: 'Недавние запросы', en: 'Recent Searches', kz: 'Соңғы сұраулар' },
    news_disclaimer: {
      ru: (
        <p>
          © {new Date().getFullYear()}. Benzinga. Все права защищены.
          <br />
          Полнота или точность рыночных цен, данных и прочей информации (включая источники третьих
          лиц), доступные через Geeko Invest, не гарантируется, а также они могут меняться без
          предупреждения. Информация актуальна на указанную дату и время, и Geeko Invest не обязана
          ее обновлять.
        </p>
      ),
      en: (
        <p>
          © {new Date().getFullYear()}. Benzinga. All rights reserved.
          <br />
          Market prices, data, and other information (including third-party sources) available
          through Geeko Invest are not warranted as to completeness or accuracy and are subject to
          change without notice. Information is current as of the referenced date and time, and
          Geeko Invest is not obligated to update it.
        </p>
      ),
      kz: (
        <p>
          © {new Date().getFullYear()}. Benzinga. Барлық құқықтар қорғалған.
          <br />
          Нарықтық бағалардың, деректердің және өзге де ақпараттың толықтығы немесе дәлдігі (үшінші
          Geeko Invest арқылы қол жетімді адамдарға кепілдік берілмейді, сондай-ақ олар
          ескерту. Ақпарат көрсетілген күн мен уақытқа өзекті және Geeko Invest
          оны жаңарту.
        </p>
      ),
    },
    one_signal_msg: {
      ru: 'Мы хотели бы показывать вам уведомления о последних новостях и обновлениях.',
      en: 'We would like to show you notifications about the latest news and updates.',
      kz: 'Сізге соңғы жаңалықтар мен жаңартулар туралы хабарландыруларды көрсеткіміз келеді.',
    },
    subscribe: { ru: 'Подписаться', en: 'Subscribe', kz: 'Қол қою' },
    later: { ru: 'Позже', en: 'Later', kz: 'Сосын' },
    acc_blocked: {
      ru: (<>Ваш аккаунт заблокирован.<br/>Просим незамедлительно связаться</>),
      en: (<>Your account is blocked.<br/>Please make sure to contact</>),
      kz: (<>Тіркелгіңіз құрсауланған.<br/>Дереу хабарласуыңызды сұраймыз</>),
    },
    with_support: { //продолжение предыдущей строки
      ru: 'со службой поддержки',
      en: 'with support team',
      kz: 'қолдау қызметімен',
    },
    why_blocked: {
      ru: 'Почему это могло произойти?',
      en: 'Why might this happen?',
      kz: 'Бұл неге болуы мүмкін?',
    },
  },
  menu: {
    //Боковое меню
    dear_user: { ru: 'Уважаемый пользователь', en: 'Dear user', kz: 'Құрметті пайдаланушы' },
    notifications: { ru: 'Уведомления', en: 'Notifications', kz: 'Хабарландырулар' },
    safety: { ru: 'Безопасность', en: 'Security', kz: 'Қауіпсіздік' },
    verification: { ru: 'Верификация', en: 'Your Account', kz: 'Верификациялау' },
    bank_accounts: { ru: 'Мои банковские счета', en: 'My Bank Accounts', kz: 'Банктік шоттар' },
    reports: { ru: 'Отчеты', en: 'Statements', kz: 'Есеп беру' },
    contracts: { ru: 'Договоры', en: 'Agreements', kz: 'Келісім-шарттар' },
    lang_change: { ru: 'Смена языка', en: 'Language', kz: 'Тілді ауыстыру' },
    verified: { ru: 'Верифицирован', en: 'Verified', kz: 'Верификацияланған' },
    unverified: { ru: 'Не верифицирован', en: 'Not verified', kz: 'Верификацияланғанбан' },
    user_agreement: { ru: 'Пользовательское соглашение', en: 'User agreement', kz: 'Қолдану ережелері' },
    privacy_policy: { ru: 'Политика конфиденциальности', en: 'Privacy policy', kz: 'Құпиялылық саясат' },
    unread: { ru: 'Непрочитанные', en: 'Unread', kz: 'Оқылмаған' },
    mark_all_read: { ru: 'Прочитать все', en: 'Mark all as read', kz: 'Барлығын оқу' },
    settings: { ru: 'Настройки', en: 'Settings', kz: 'Параметрлер' },
    notifications_off: {
      ru: 'Уведомления отключены',
      en: 'Notifications disabled',
      kz: 'Хабарландырулар өшірілген',
    },
    notifications_allow: {
      ru: 'Разрешить уведомления',
      en: 'Allow Notifications',
      kz: 'Ескертулерге рұқсат ету',
    },
    notifications_on: {
      ru: 'Уведомления включены',
      en: 'Notifications enabled',
      kz: 'Хабарландырулар қосылған',
    },
    all_notifications: {
      ru: 'Все уведомления',
      en: 'All notifications',
      kz: 'Барлық ескертулер',
    },
    notification: { ru: 'Уведомление', en: 'Notification', kz: 'Хабарлама' },
    pass_changed: {
      ru: 'Пароль изменен',
      en: 'Password changed',
      kz: 'Құпия сөз өзгертілген',
    },
    saved: { ru: 'Сохранено', en: 'Saved', kz: 'Сақталды' },
    password: { ru: 'Пароль', en: 'Password', kz: 'Құпия сөз' },
    code_word_change: {
      ru: 'Смена кодового слова',
      en: 'Code Word',
      kz: 'Код сөзін өзгерту',
    },
    trusted_person: { ru: 'Доверенное лицо', en: 'Trusted Contact', kz: 'Сенімді тұлға' },
    enter_code: { ru: 'Введите код', en: 'Enter code', kz: 'Кодты енгізіңіз' },
    pass_change: {
      ru: 'Смена пароля',
      en: 'Password change',
      kz: 'Құпия сөзді өзгерту',
    },
    sessions_terminated: {
      ru: 'Все сеансы, кроме текущего, будут завершены.',
      en: 'All sessions except the current one will be terminated.',
      kz: 'Ағымдағыдан басқа барлық сеанстар тоқтатылады',
    },
    current_pass: {
      ru: 'Введите текущий пароль',
      en: 'Enter current password',
      kz: 'Ағымдағы құпия сөзді енгізіңіз',
    },
    new_pass: {
      ru: 'Введите новый пароль',
      en: 'Enter new password',
      kz: 'Жаңа құпия сөзді енгізіңз ',
    },
    new_pass_2: {
      ru: 'Повторите новый пароль',
      en: 'Verify new password',
      kz: 'Жаңа құпия сөзді қайталаңыз',
    },
    pass_must_contain: {
      ru: 'Пароль должен содержать не менее 6 символов.',
      en: 'The password must contain at least 6 characters.',
      kz: 'Құпия сөз кемінде 6 таңбадан тұруы керек.',
    },
    pass_must_contain_2: {
      ru: 'Пароль должен содержать буквы, цифры и специальные символы',
      en: 'The password must contain letters, numbers and special characters',
      kz: 'Құпия сөзде әріптер, сандар және арнайы таңбалар болуы керек',
    },
    passwords_dont_match: {
      ru: 'Два введенных вами пароля не совпадают!',
      en: 'The two passwords you entered do not match!',
      kz: 'Сіз енгізген екі құпия сөз сәйкес келмейді!',
    },
    pass_must_contain_3: {
      ru: 'Пароль должен содержать не менее 8 символов, включая цифры, буквы и специальные символы (!”№%).',
      en: 'The password must contain at least 8 characters, including numbers, letters and special characters (!”№%).',
      kz: 'Құпия сөз сандарды, әріптерді және арнайы таңбаларды қоса алғанда кемінде 8 таңбадан тұруы керек (!”№%)',
    },
    change_pass: {
      ru: 'Сменить пароль',
      en: 'Password change',
      kz: 'Құпия сөзді өзгерту',
    },
    code_word_changed: {
      ru: 'Кодовое слово успешно изменено',
      en: 'Pass Word successfully changed',
      kz: 'Код сөзі сәтті өзгертілді',
    },
    enter_code_word: {
      ru: 'Придумайте кодовое слово',
      en: 'Enter Passcode',
      kz: 'Код сөзін ойлап табыңыз',
    },
    required_verify: {
      ru: 'Требуется для идентификации вашей личности',
      en: 'Required to verify your identity',
      kz: 'Сізді анықтау үшін қажет',
    },
    change_code: { ru: 'Сменить код', en: 'Change Passcode', kz: 'Кодты өзгерту' },
    data_saved: {
      ru: 'Данные сохранены',
      en: 'Data saved',
      kz: 'Деректер сақталды',
    },
    trusted_person_contact: {
      ru: 'Контакт доверенного лица',
      en: 'Contact Information for the Trusted Contact',
      kz: 'Сенімгермен байланыс',
    },
    fields_required: {
      ru: 'Все поля являются обязательными',
      en: 'All fields are required',
      kz: 'Барлық өрістер қажет',
    },
    first_name: { ru: 'Имя', en: 'First Name', kz: 'Аты' },
    last_name: { ru: 'Фамилия', en: 'Last Name', kz: 'Тегі' },
    email: { ru: 'Электронный адрес', en: 'Email', kz: 'Электрондық пошта' },
    phone: { ru: 'Номер телефона', en: 'Phone Number', kz: 'Телефон нөмірі' },
    enter_first_name: {
      ru: 'Введите имя!',
      en: 'Enter First Name!',
      kz: 'Атыңызды енгізіңіз!',
    },
    enter_last_name: {
      ru: 'Введите фамилию!',
      en: 'Enter Last Name!',
      kz: 'Тегіңізді енгізіңіз',
    },
    enter_email: {
      ru: 'Введите электронный адрес!',
      en: 'Enter email address!',
      kz: 'Электрондық пошта мекенжайын енгізіңіз',
    },
    enter_phone: {
      ru: 'Введите номер телефона!',
      en: 'Enter phone number!',
      kz: 'Телефон нөміріңізді енгізіңіз!',
    },
    trusted_person_is: {
      ru: 'Доверенное лицо - это человек, с которым вы уполномочиваете брокера контактировать в ограниченных обстоятельствах, например, если есть опасения по активности на вашем счете, и брокер не смог связаться с вами. Доверенное лицо может быть членом семьи, адвокатом, бухгалтером или другим третьим лицом, которому вы доверяете и который, по вашему мнению, уважает вашу конфиденциальность и знает, как справиться с ответственностью. Доверенному лицу должно быть больше 18 лет.',
      en: 'A Trusted Contact is an individual whom you empower the broker to contact under specific circumstances, such as concerns about account activity, when the broker is unable to reach you. The Trusted Contact can be a family member, attorney, accountant, or another third party you trust, and whom you believe will respect your confidentiality and handle responsibilities responsibly. The Trusted Contact must be over 18 years old.',
      kz: 'Прокси - брокерге шектеулі жағдайларда байланысуға рұқсат берген адам, мысалы, тіркелгіңіздегі әрекетке қатысты алаңдаушылық болса және брокер сізбен байланыса алмаса. Сенімді адам отбасы мүшесі, заңгер, есепші немесе сіз сенетін және сіздің жеке өміріңізді құрметтейтін және жауапкершілікті қалай шешу керектігін білетін басқа үшінші тарап болуы мүмкін. Өкілетті тұлға 18 жастан асқан болуы керек.',
    },
    full_name: { ru: 'Полное имя', en: 'Full Name', kz: 'Толық аты' },
    birth: { ru: 'Дата рождения', en: 'Date of Birth', kz: 'Туған күні' },
    change: { ru: 'Изменить', en: 'Change', kz: 'Өзгерту' },
    identification_docs: {
      ru: 'Документы, удостоверяющие личность',
      en: 'Identification documents',
      kz: 'Жеке басын куәландыратын құжаттар',
    },
    id_card: { ru: 'Удостоверение личности', en: 'ID card', kz: 'Жеке куәлік' },
    passport: { ru: 'Паспорт', en: 'Passport', kz: 'Паспорт' },
    address_doc: {
      ru: 'Документ подтверждающий адрес проживания',
      en: 'Address Proof',
      kz: 'Тұрғылықты мекен жайын растайтын құжат',
    },
    email_sent: {
      ru: 'Письмо отправлено',
      en: 'Email sent',
      kz: 'Хат жіберілген',
    },
    resend_in: {
      ru: 'Отправить повторно через',
      en: 'Resend in',
      kz: 'Кейін қайта жіберу',
    },
    seconds: { ru: 'секунд', en: 'seconds', kz: 'секунд' }, //продолжение предыдущей строки
    resend: { ru: 'Отправить повторно', en: 'Resend', kz: 'Қайта жіберу' },
    email_confirmed: {
      ru: 'Почта успешно подтверждена',
      en: 'Email successfully verified',
      kz: 'Электрондық пошта сәтті расталды',
    },
    enter_conf_code: {
      ru: 'Введите код подтверждения',
      en: 'Enter the confirmation code',
      kz: 'Растау кодын енгізіңіз',
    },
    enter_code_email: {
      ru: 'Введите 6-значный код, который мы отправили на электронный адрес',
      en: 'Enter the 6-digit code we sent to your email address',
      kz: 'Электрондық пошта мекенжайыңызға жіберілген 6 таңбалы кодты енгізіңіз',
    },
    email_change: {
      ru: 'Смена электронного адреса',
      en: 'Email Address change',
      kz: 'Электрондық пошта мекенжайын өзгерту',
    },
    sessions_terminated_2: {
      ru: 'Все сеансы, кроме текущего, будут завершены. Это позволит предотвратить неавторизованный доступ к вашему аккаунту.',
      en: "You'll be logged out of all sessions except this one to protect your account if anyone is trying to gain access.",
      kz: 'Ағымдағыдан басқа барлық сеанстар тоқтатылады. Бұл сіздің есептік жазбаңызға рұқсатсыз кіруді болдырмайды.',
    },
    sessions_terminated_22: {
      ru: 'Все сеансы, кроме текущего, будут завершены. ',
      en: 'All sessions except the current one will be terminated.',
      kz: 'Ағымдағыдан басқа барлық сеанстар тоқтатылады. ',
    },
    sessions_terminated_3: {
      ru: 'Вы действительно хотете сменить электронный адрес:',
      en: 'Do you really want to change your email address:',
      kz: 'Сіз шынымен электрондық пошта мекенжайын өзгерткіңіз келеді:',
    },
    enter_new_email: {
      ru: 'Введите новый электронный адрес',
      en: 'Enter new email',
      kz: 'Жаңа электрондық поштаны енгізіңіз',
    },
    verify_new_email: {
      ru: 'Повторите новый электронный адрес',
      en: 'Retype new email',
      kz: 'Жаңа электрондық поштаны қайталаңыз',
    },
    emails_dont_match: {
      ru: 'Два введенных вами электронный адрес не совпадают!',
      en: 'The entered emails do not match',
      kz: 'Сіз енгізген екі электрондық пошта сәйкес келмейді!',
    },
    change_email: {
      ru: 'Изменить электронный адрес',
      en: 'Change email address',
      kz: 'Электрондық поштаны өзгерту ',
    },
    phone_updated: {
      ru: 'Номер обновлен',
      en: 'Phone number updated',
      kz: 'Нөмір жаңартылды',
    },
    update_phone: {
      ru: 'Обновите свой номер телефона',
      en: 'Update your Phone Number',
      kz: 'Телефон нөміріңізді жаңартыңыз',
    },
    current_phone: {
      ru: 'Текущий номер вашего мобильного телефона',
      en: 'Your current Phone Number',
      kz: 'Сіздің ағымдағы ұялы телефон нөміріңіз',
    },
    correct_contact_info: {
      ru: 'Удостоверьтесь, что ваша контактная информация указана верно, чтобы у вас была возможность сбрасывать пароль, получать уведомления.',
      en: 'Ensure that your contact information is correctly entered to enable password reset and receive notifications',
      kz: 'Құпия сөзді қалпына келтіру және хабарландырулар алу үшін байланыс ақпаратыңыздың дұрыс екеніне көз жеткізіңіз',
    },
    confirm_phone: {
      ru: 'Подтвердить номер телефона',
      en: 'Confirm phone number',
      kz: 'Телефон нөмірін растаңыз',
    },
    data_updated: { ru: 'Данные обновлены', en: 'Information updated', kz: 'Деректер жаңартылды' },
    address_conf: {
      ru: 'Подтверждение адреса',
      en: 'Address Confirmation',
      kz: 'Мекенжайды растау',
    },
    msg_sent_1414: {
      ru: 'На ваш номер телефона отправлено сообщение от номера 1414. В ответ на сообщение отправьте цифру - 511 означает ДА; 512 - означает НЕТ. После того как вы отправите код необходимо нажать на кнопку “Обновить” в приложении Geeko Invest',
      en: "An SMS has been sent to your phone from the number 1414. In response, please send the number: 511 for YES, or 512 for NO. After sending the code, press the 'Refresh' button in the Geeko Invest app",
      kz: 'Сіздің телефон нөміріңізге 1414 нөмірінен хабарлама жіберілді. Хабарламаға жауап ретінде нөмірді жіберіңіз - 511 ИӘ дегенді білдіреді; 512 ЖОҚ дегенді білдіреді. Кодты жібергеннен кейін Geeko Invest қосымшасындағы «Жаңарту» түймесін басу керек',
    },
    consent_requested: {
      ru: 'Запрошено согласие на получение данных',
      en: 'Requested permission to retrieve data',
      kz: 'Деректерді алуға келісім сұралды',
    },
    update_status: {
      ru: 'Обновить статус',
      en: 'Update status',
      kz: 'Күйді жаңарту',
    },
    reg_address: {
      ru: 'Адрес регистрации',
      en: 'Registration Address',
      kz: 'Тіркеу мекенжайы',
    },
    address_question: {
      ru: 'Ваш адрес проживания совпадает с адресом прописки?',
      en: 'Does your residential address match your registered address?',
      kz: 'Сіздің тұрғылықты мекенжайыңыз тіркелген мекенжайыңызға сәйкес келе ме?',
    },
    if_answer_no: {
      ru: 'Если ваш ответ НЕТ, потребуется дополнительная проверка и загрузка документов, подтверждающих адрес проживания',
      en: 'If your answer is NO, additional verification will be required, including the upload of documents confirming your residential address',
      kz: 'Жауабыңыз ЖОҚ болса, сіздің тұрғылықты мекенжайыңызды растайтын құжаттарды қосымша тексеру және жүктеп салу қажет болады',
    },
    statements: { ru: 'Выписки', en: 'Statements', kz: 'Сығындылар' },
    open_pdf: { ru: 'Открыть PDF', en: 'Open PDF', kz: 'PDF файлын ашу' },
    confirmations: {
      ru: 'Подтверждения',
      en: 'Confirmations',
      kz: 'Растаулар',
    },
    alpaca_customer_agreement: {
      ru: 'Клиентское соглашение Alpaca',
      en: 'Alpaca Client Agreement',
      kz: 'Alpaca тұтынушы келісімі',
    },
    brokerage_service_agreement: {
      ru: 'Договор брокерского обслуживания',
      en: 'Brokerage Services Agreement',
      kz: 'Брокерлік қызмет көрсету шарты',
    },
    annex_1: {
      ru: 'Приложение 1 к Договору',
      en: 'Application for Accession',
      kz: 'Келісімге 1-қосымша',
    },
    language: { ru: 'Язык', en: 'Language', kz: 'Тіл' },
    add_new_acc: {
      ru: 'Добавить новый счет',
      en: 'Add a new account',
      kz: 'Жаңа есеп қосу',
    },
    add_new_acc_1: {
      ru: 'Добавить новый счет для вывода',
      en: 'Add a new account for withdrawal',
      kz: 'Шығару үшін жаңа есеп қосу',
    },
    add_new_acc_2: {
      ru: 'Добавить новый банковский счет',
      en: 'Add a new bank account',
      kz: 'Жаңа банк шотын қосу',
    },
    iban: { ru: 'IBAN/Номер счёта', en: 'IBAN/Account number', kz: 'IBAN/Шот нөмірі' },
    enter_iban: { ru: 'Введите IBAN', en: 'Enter IBAN', kz: 'IBAN енгізіңіз' },
    enter_iban1: {
      ru: 'Введите IBAN/Номер счета',
      en: 'Enter IBAN/Account number',
      kz: 'IBAN/Шот нөмірін енгізіңіз',
    },
    bank_recipient: { ru: 'Банк получатель', en: 'Bank of recipient', kz: 'Банк получатель' },
    select_bank_recipient: {
      ru: 'Выберите банк получателя',
      en: 'Select bank of recipient',
      kz: 'Алушының банкін таңдаңыз',
    },
    bic: {
      ru: 'БИК банка бенефициара',
      en: "BIC of beneficiary's bank",
      kz: 'Бенефициар банкінің БСК',
    },
    enter_bic: {
      ru: 'Введите БИК банка бенефициара',
      en: "Enter BIC of beneficiary's bank",
      kz: 'Бенефициар банктің БСК енгізіңіз',
    },
    correspondent_bank: {
      ru: 'Банк-корреспондент',
      en: 'Correspondent bank',
      kz: 'Корреспондент банк',
    },
    enter_correspondent_bank: {
      ru: 'Введите Банк-корреспондент',
      en: 'Enter correspondent bank',
      kz: 'Банк-Корреспондент банкті енгізіңіз',
    },
    main: { ru: 'основной', en: 'main', kz: 'негізгі' },
    acc: { ru: 'Счет', en: 'Account', kz: 'Шот' },
    acc_edited: {
      ru: 'Счет отредактирован',
      en: 'Account has been edited',
      kz: 'Есеп өңделді',
    },
    acc_deleted: {
      ru: 'Счет удален',
      en: 'Account has been deleted',
      kz: 'Шот жойылды',
    },
    edit_acc: {
      ru: 'Редактировать банковский счет',
      en: 'Edit bank account',
      kz: 'Банктік шотты өңдеу',
    },
    delete_acc: {
      ru: 'Удалить счет',
      en: 'Delete account',
      kz: 'Шотты өшіру',
    },
    thanks_for_trading: {
      ru: 'Спасибо что торгуете вместе с Geeko Invest. По всем вопросам и предложениям вы всегда можете написать нам на почту',
      en: 'Thank you for trading with Geeko Invest. For all questions and suggestions, you can always write to us at',
      kz: 'Geeko Invest-пен бірге сауда жасағаныңызға рахмет. Барлық сұрақтар мен ұсыныстар бойынша сіз әрқашан бізге пошта арқылы хабарласа аласыз',
    },
    no_statements: {
      ru: 'На данный момент у нас нет активных отчетов.',
      en: 'We currently have no active statements.',
      kz: 'Қазір бізде белсенді есеп жоқ.',
    },
    doc_change_warning: {
      ru: (<>Вы уверены, что вы хотите<br/>изменить документ?<br/>Обработка вашего поручения на<br/>изменение данных может занять до<br/>3-х рабочих дней</>),
      en: (<>Are you sure you want to<br/>change the document?<br/>Processing of your request to<br/>change data may take up to<br/>3 business days</>),
      kz: (<>Қалағаныңызға сенімдісіз<br/>құжатты өзгерту керек пе?<br/>Тапсырмаңызды өңдеу<br/>деректерді өзгерту<br/>3 жұмыс күні</>),
    },
  },
  portfolio: {
    //Страница с коллекциями
    collections: { ru: 'Коллекции', en: 'Watchlist', kz: 'Жинақтар' },
    favorite: { ru: 'Избранное', en: 'Favorites', kz: 'Таңдаулылар' },
    my_collections: {
      ru: 'Мои коллекции',
      en: 'My Watchlist',
      kz: 'Менің жинақтарым',
    },
    geeko_collections: {
      ru: 'Коллекции Geeko Invest',
      en: 'Geeko Invest Watchlist',
      kz: 'Geeko Invest жинақтары',
    },
    stocks: { ru: 'Акций', en: 'Symbols', kz: 'Акциялар' }, //склонение намеренно
    use_search: {
      ru: 'Используйте поиск, чтобы добавить новые акции',
      en: 'Add new symbol by using the search tool',
      kz: 'Жаңа жарнамаларды қосу үшін іздеуді пайдаланыңыз',
    },
    enter_name_1: {
      ru: 'Укажите имя коллекции',
      en: 'Enter Watchlist name',
      kz: 'Жинақ атауын көрсетіңіз',
    },
    new_collection: {
      ru: 'Новая коллекция',
      en: 'New Watchlist',
      kz: 'Жаңа жинақ',
    },
    no_select: {
      ru: 'Вы еще не выбрали ни одного актива',
      en: 'Add at least one symbol to create a watchlist',
      kz: 'Сіз әлі ешбір активтерді таңдамадыңыз',
    },
    create: { ru: 'Создать', en: 'Create', kz: 'Жасау' },
    delete: { ru: 'Удалить', en: 'Delete', kz: 'Жою' },
    delete_collection: {
      ru: 'Удалить коллекцию?',
      en: 'Delete Watchlist?',
      kz: 'Жинақты жою керек пе?',
    },
    enter_name_2: {
      ru: 'Введите наименование коллекции',
      en: 'Enter Watchlist Name',
      kz: 'Топтаманың атын енгізіңіз',
    },
    collection_name: {
      ru: 'Название коллекции',
      en: 'Watchlist Name',
      kz: 'Жинақтың атауы',
    },
    no_stocks: {
      ru: 'В этой коллекции нет акций',
      en: 'Add at least one symbol to create a watchlist',
      kz: 'Бұл жинақта акциялар жоқ',
    },
    follow_companies: {
      ru: 'Следите за компаниями, что бы быть в курсе последних новостей, аналитики и изменения цен',
      en: 'Follow companies to stay up to date with the latest news, analytics and price changes',
      kz: 'Соңғы жаңалықтардан, аналитикадан және баға өзгерістерінен хабардар болу үшін компанияларды бақылаңыз',
    },
    add: { ru: 'Добавить', en: 'Add', kz: 'Қосу' },
    no_stocks_2: { ru: 'Нет акций', en: 'No stocks', kz: 'Акциялар жоқ' },
    copy: {
      ru: 'Скопировать коллекцию',
      en: 'Copy collection',
      kz: 'Жинақты көшіру',
    },
    untitled: { ru: 'Без имени', en: 'Untitled', kz: 'Атауы жоқ' },
  },
  auth: {
    //Страницы авторизации/регистрации/восстановления пароля
    log_in: { ru: 'Войти в аккаунт', en: 'Log in', kz: 'Кіру' },
    no_account: {
      ru: 'Нет аккаунта?',
      en: 'No account?',
      kz: 'Аккаунтыңыз жоқ па?',
    },
    create_acc: {
      ru: 'Зарегистрироваться',
      en: 'Sign Up',
      kz: 'Аккаунтты құру',
    },
    email_address: {
      ru: 'Электронный адрес',
      en: 'Email',
      kz: 'Электронды мекен-жай',
    },
    enter_email: {
      ru: 'Введите email',
      en: 'Enter email',
      kz: 'Электрондық поштаңызды енгізіңіз',
    },
    password: { ru: 'Пароль', en: 'Password', kz: 'Құпия сөз' },
    enter_pass: {
      ru: 'Введите пароль',
      en: 'Enter password',
      kz: 'Құпия сөзді еңгізіңіз',
    },
    remember_me: {
      ru: 'Запомнить меня',
      en: 'Remember me',
      kz: 'Мені есте сақтау',
    },
    forgot_pass: {
      ru: 'Забыли пароль?',
      en: 'Forgot Password?',
      kz: 'Құпия сөзіңізді ұмыттыңыз ба?',
    },
    lets_start: {
      ru: 'Давайте начнем!',
      en: "Let's get started!",
      kz: 'Бастайық!',
    },
    already_reg: {
      ru: 'Уже зарегистрирован?',
      en: 'Already registered?',
      kz: 'Тіркелдіңіз бе?',
    },
    agree_terms: {
      ru: 'Я ознакомился(-ась) и принимаю условия',
      en: 'I have read and accept the terms',
      kz: 'Мен шарттар мен шарттарды оқыдым және қабылдаймын',
    },
    user_agreement: {
      //склонение намеренно
      ru: 'Пользовательского соглашения',
      en: 'User agreement',
      kz: 'Қолдану ережелері',
    },
    and: { ru: 'и', en: 'and', kz: 'және' },
    privacy_policy: {
      //склонение намеренно
      ru: 'Политики конфиденциальности',
      en: 'Privacy policy',
      kz: 'Құпиялылық саясат',
    },
    create_account: {
      ru: 'Создать аккаунт',
      en: 'Sign up',
      kz: 'Аккаунтты құру',
    },
    ensure_pass: {
      ru: 'Пожалуйста, убедитесь, что пароль:',
      en: 'Please ensure that your password:',
      kz: 'Құпия сөздің мынаны тексеріңіз:',
    },
    ensure_pass_2: {
      ru: '- Содержит не менее 8 символов',
      en: '- is at least 8 characters long',
      kz: '- Кемінде 8 таңбадан тұрады',
    },
    ensure_pass_3: {
      ru: '- Включает как минимум одну заглавную букву и одну строчную букву',
      en: '- includes at least one uppercase letter and one lowercase letter',
      kz: '- Кем дегенде бір бас әріп пен бір кіші әріпті қамтиды',
    },
    ensure_pass_4: {
      ru: '- Содержит как минимум одну цифру или специальный символ (!, @, #, и т.д.)',
      en: '- contains at least one number or special character (!, @, #, etc.)',
      kz: '- Кемінде бір санды немесе арнайы таңбаны (!, @, #, т.б.) қамтиды',
    },
    pass_8_characters: {
      ru: 'Пароль должен содержать не менее 8 символов',
      en: 'The password must contain at least 8 characters',
      kz: 'Құпия сөз кемінде 8 таңбадан тұруы керек',
    },
    pass_unsafe: {
      ru: 'Пароль не соответствует требованиям безопасности',
      en: 'Password does not meet security requirements',
      kz: 'Құпия сөз қауіпсіздік талаптарына сәйкес келмейді',
    },
    request_err: {
      ru: 'Ошибка запроса. Пожалуйста повторите восстановление пароля заново.',
      en: 'Request error. Please retry the password recovery process.',
      kz: 'Сұрау орындалмады. Құпия сөзіңізді қайта орнатып көріңіз.',
    },
    enter_conf_code: {
      ru: 'Введите код подтверждения',
      en: 'Enter the confirmation code',
      kz: 'Растау кодын енгізіңіз',
    },
    pass_recovery: {
      ru: 'Восстановление пароля',
      en: 'Password Recovery',
      kz: 'Құпия сөзді қалпына келтіру',
    },
    pass_restored: {
      ru: 'Пароль успешно востановлен',
      en: 'Password successfully restored',
      kz: 'Құпия сөз сәтті қалпына келтірілді',
    },
    enter_new_pass: {
      ru: 'Введите новый пароль',
      en: 'Enter a new password',
      kz: 'Жаңа құпия сөзді енгізіңіз',
    },
    reenter_new_pass: {
      ru: 'Введите повторно новый пароль',
      en: 'Retype the new password',
      kz: 'Жаңа құпия сөзді қайта енгізіңіз',
    },
    confirm_pass: {
      ru: 'Пожалуйста, подтвердите свой пароль!',
      en: 'Please confirm your password!',
      kz: 'Құпия сөзіңізді растаңыз!',
    },
    passwords_dont_match: {
      ru: 'Пароли не совпадают',
      en: 'Passwords do not match',
      kz: 'Құпия сөз сәйкес келмеді',
    },
    six_code: { ru: '6-значный код', en: '6-digit code', kz: '6 таңбалы код' },
    enter_six_code: {
      ru: 'Введите 6-значный код, который мы отправили на электронный адрес',
      en: 'Enter the 6-digit code we sent to your email',
      kz: 'Электрондық поштаға жіберілген 6 таңбалы кодты енгізіңіз',
    },
    resend: {
      ru: 'Отправить код повторно',
      en: 'Resend the code',
      kz: 'Кодты қайта жіберу',
    },
    restore_pass: {
      ru: 'Восстановить пароль',
      en: 'Restore Password',
      kz: 'Құпия сөзді қалпына келтіру',
    },
    email_sent: { ru: 'Письмо отправлено', en: 'Email sent', kz: 'Хат жіберілді' },
    resend_in: {
      ru: 'Отправить код повторно через',
      en: 'Resend in',
      kz: 'Хабарлама хаты жіберілді',
    },
    seconds: { ru: 'секунд', en: 'seconds', kz: 'секунд' }, //продолжение предыдущей строки
    email_confirmed: {
      ru: 'Почта успешно подтверждена',
      en: 'Email successfully confirmed',
      kz: 'Электрондық пошта сәтті расталды',
    },
    email_confirmation: {
      ru: 'Подтверждение электронной почты',
      en: 'Email confirmation',
      kz: 'Электрондық поштаны растау',
    },
    code_sent_email: {
      ru: 'Код отправлен на электронный адрес',
      en: 'Code sent to the email address',
      kz: 'Код электрондық поштаға жіберілді',
    },
    enter_captcha: {
      ru: 'Введите капчу',
      en: 'Enter captcha',
      kz: 'Капчаны енгізіңіз',
    },
  },
  trading: {
    //Страница акции
    trading: { ru: 'Трейдинг', en: 'Trading', kz: 'Трейдинг' },
    futures: { ru: 'Фьючерсы', en: 'Futures', kz: 'Фьючерстар' },
    bonds: { ru: 'Облигации', en: 'Bonds', kz: 'Облигациялар' },
    crypt: { ru: 'Крипта', en: 'Crypto', kz: 'Криптавалюталар' },
    trade: { ru: 'Торговать', en: 'Trade', kz: 'Сауда жасау' },
    buy: { ru: 'Купить', en: 'Buy', kz: 'Сатып алу' },
    sell: { ru: 'Продать', en: 'Sell', kz: 'Сату' },
    pre_market: { ru: 'Пре-маркет', en: 'Pre-market', kz: 'Алдын ала нарық' },
    market_open: { ru: 'Рынок открыт', en: 'Market open', kz: 'Нарық ашық' },
    post_market: { ru: 'Пост-маркет', en: 'Post-market', kz: 'Пост-нарық' },
    market_closed: { ru: 'Рынок закрыт', en: 'Market closed', kz: 'Нарық жабық' },
    after_hours: { ru: 'Рынок закрыт', en: 'After hours', kz: 'Нарық жабық' },
    summary: { ru: 'Сводка', en: 'Summary', kz: 'Түйіндеме' },
    analysis: { ru: 'Анализ', en: 'Analysis', kz: 'Талдау' },
    financials: { ru: 'Финансы', en: 'Financials', kz: 'Қаржы' },
    timeswork: {
      ru: 'Время работы биржи',
      en: 'Opening hours of the exchange',
      kz: 'Биржаның жұмыс уақыты',
    },
    timeswork1: {
      ru: 'Основная торговая сессия на Американской фондовой бирже, начинается с 9:30 до 16:00 по Нью-Йоркскому времени, часовой пояс (GMT-4). По Казахстанскому времени, биржа открывается в 19:30 и закрывается в 6:00 по Астане, часовой пояс (GMT/UTC +6). Будьте внимательны при переходе на летнее или зимнее время.',
      en: 'The main trading session on the American Stock Exchange starts from 9:30 to 16:00 (GMT-4). According to Kazakhstan time, the exchange opens at 19:30 and closes at 6:00 (GMT/UTC +6). Be careful when changing to summer or winter time.',
      kz: 'Америка қор биржасындағы негізгі сауда сессиясы Нью-Йорк уақыты бойынша сағат 9: 30-дан 16: 00-ге дейін басталады, уақыт белдеуі (GMT-4). Қазақстан уақыты бойынша биржа Астана қаласы бойынша сағат 6: 00-де (GMT/UTC + 6) ашылады және жабылады. Жазғы немесе қысқы уақытқа өту кезінде мұқият болыңыз.',
    },
    key_stats: {
      ru: 'Ключевая статистика',
      en: 'Key stats',
      kz: 'Негізгі статистика',
    },
    dividends: { ru: 'Дивиденды', en: 'Dividends', kz: 'Дивидендтер' },
    market_cap: { ru: 'Капитализация', en: 'Market cap', kz: 'Капиталдандыру' },
    p_e: {
      ru: 'Коэффициент P/E (TTM)',
      en: 'P/E ratio (TTM)',
      kz: 'P/E коэффициенті (TTM)',
    },
    company_description: {
      ru: 'Описание компании',
      en: 'Company profile',
      kz: 'Компания сипаттамасы',
    },
    indicator: { ru: 'Индикатор', en: 'Indicator', kz: 'Көрсеткіш' },
    income: { ru: 'Доходы', en: 'Income', kz: 'Кіріс' },
    balance_sheet: {
      ru: 'Балансовый отчет',
      en: 'Balance sheet',
      kz: 'Баланс',
    },
    annual: { ru: 'Годовые', en: 'Annual', kz: 'Жылдық' },
    quarterly: { ru: 'Квартальные', en: 'Quarterly', kz: 'Тоқсан' },
    category: { ru: 'Категория', en: 'Category', kz: 'Санат' },
    no_data: {
      ru: 'По данной компании отсутствуют данные за этот период',
      en: 'No data available for this company during this period',
      kz: 'Бұл компания бойынша осы кезеңге қатысты деректер жоқ',
    },
    last_news: { ru: 'Последние новости', en: 'Latest news', kz: 'Соңғы жаңалықтар' },
    show_more: { ru: 'Показать еще', en: 'Show more', kz: 'Тағы көрсету' },
    no_chart: {
      ru: 'Нет данных графика',
      en: 'No chart data available',
      kz: 'Диаграмма деректері жоқ',
    },
    no_news: { ru: 'Новостей нет', en: 'No news', kz: 'Хабар жоқ' },
    at_close: { ru: 'При открытии', en: 'At close', kz: 'Ашу кезінде' },
    open_broker_acc: {
      ru: 'Открыть брокерский счет',
      en: 'Open a brokerage account',
      kz: 'Брокерлік шот ашу',
    },
    acc_blocked: {
      ru: (<>Ваши счета заблокированы.<br/>Просим незамедлительно связаться</>),
      en: (<>Your accounts are blocked.<br />Please make sure to contact</>),
      kz: (<>Шоттарыңыз құрсауланған.<br />Дереу хабарласуыңызды сұраймыз</>),
    },
    with_support: {
      ru: 'со службой поддержки',
      en: 'with support team',
      kz: 'қолдау қызметімен',
    },
    trading_before_open: {
      ru: 'Торговля до открытия рынка (основной торговой сессии)',
      en: 'Trading before the market opens (main trading session)',
      kz: 'Нарық ашылғанға дейінгі сауда (негізгі сауда сессиясы)',
    },
    pre_market_time: {
      ru: 'Начинается с 14:00 до 19:30 (+5 GMT)',
      en: 'Starts from 14:00 to 19:30 (+5 GMT)',
      kz: 'Сағат 14:00-ден 19:30-ға дейін (+5 GMT)',
    },
    only_limit_orders: {
      ru: 'Только Лимитные поручения и срок действия День',
      en: 'Only Limit orders and validity period 1 Day',
      kz: 'Тек Лимиттік тапсырмалар және қолданылу мерзімі Күн',
    },
    main_trading_session: {
      ru: 'Основная торговая сессия на Американском фондовом рынке',
      en: 'Main trading session on the American stock market',
      kz: 'Америка қор нарығындағы негізгі сауда сессиясы',
    },
    market_open_time: {
      ru: 'Начинается с 19:30 до 02:00 (+5 GMT)',
      en: 'Starts from 19:30 to 02:00 (+5 GMT)',
      kz: '19:30 - 02:00 (+ 5 GMT)',
    },
    trading_after_closed: {
      ru: 'Торговля после закрытия рынка (основной торговой сессии)',
      en: 'Trading after the market closes (main trading session)',
      kz: 'Нарық жабылғаннан кейінгі сауда (негізгі сауда сессиясы)',
    },
    post_market_time: {
      ru: 'Начинается с 02:00 до 06:00 (+5 GMT)',
      en: 'Starts from 02:00 to 06:00 (+5 GMT)',
      kz: 'Сағат 02: 00-ден 06: 00-ге дейін (+ 5 GMT)',
    },
    market_closed_warning: {
      ru: 'Поручения выставленные во время закрытого рынка будут исполнены в следующую торговую сессию',
      en: 'Orders placed during the closed market will be executed in the next trading session',
      kz: 'Жабық нарық кезінде қойылған тапсырмалар келесі сауда сессиясында орындалады',
    },
  },
  trading_modal: {
    //Страница покупки/продажи
    order_rejected: {
      ru: 'Поручение отклонено',
      en: 'Order Rejected',
      kz: 'Ордер қабылданбады',
    },
    order_issued: {
      ru: 'Поручение выставлено',
      en: 'Order Created',
      kz: 'Ордер берілді',
    },
    buy: { ru: 'Купить', en: 'Buy', kz: 'Сатып алу' },
    sell: { ru: 'Продать', en: 'Sell', kz: 'Сату' },
    order_type: { ru: 'Тип поручения', en: 'Order type', kz: 'Тапсырыс түрі' },
    market: { ru: 'Рыночное', en: 'Market', kz: 'Нарықтық' },
    limit: { ru: 'Лимитное', en: 'Limit', kz: 'Шектеу' },
    market_price: {
      ru: 'Рыночная цена',
      en: 'Market price',
      kz: 'Нарықтық баға',
    },
    limit_price: {
      ru: 'Цена покупки (USD)',
      en: 'Limit price (USD)',
      kz: 'Сатып алу бағасы (USD)',
    },
    quantity: { ru: 'Количество', en: 'Quantity', kz: 'Саны' },
    transaction_amount: {
      ru: 'Сумма (USD)',
      en: 'Transaction Amount (USD)',
      kz: 'Сомасы (USD)',
    },
    fee: { ru: 'Комиссия', en: 'Fee', kz: 'Комиссия' },
    warning_msg: {
      ru: 'Поручения на покупку и продажу активов будут активны 3 месяца с момента выставления.',
      en: 'Buy and sell orders will remain active for 3 months from the placement date.',
      kz: 'Активтерді сатып алу-сату бойынша өтінімдер берілген күннен бастап 3 ай бойы белсенді болады',
    },
    select_purchase: {
      ru: 'Выберите вариант покупки',
      en: 'Choose how to buy',
      kz: 'Сатып алу түрін таңдаңыз',
    },
    select_sale: {
      ru: 'Выберите вариант продажи',
      en: 'Choose how to sell',
      kz: 'Сату түрін таңдаңыз',
    },
    stocks: { ru: 'Акции', en: 'Shares', kz: 'Науқандар' },
    usd: { ru: 'Доллары', en: 'USD', kz: 'Доллар' },
    select_validity: {
      ru: 'Выберите срок действия',
      en: 'Time in Force',
      kz: 'Жарамдылық мерзімін таңдаңыз',
    },
    day: { ru: 'День', en: 'Day', kz: 'Күн' },
    till_cancel: {
      ru: 'До отмены',
      en: 'Good till cancelled',
      kz: 'Бас тарту алдында',
    },
    day_extended: {
      ru: 'День (Расширенные часы)',
      en: 'Day (extended hours)',
      kz: 'Күн (ұзартылған сағат)',
    },
  },
  onboarding: {
    //Онбординг
    verification: {
      ru: 'Верификация личности',
      en: 'Identity verification',
      kz: 'Жеке басты растау',
    },
    verification_2: {
      ru: 'Для того чтобы открыть брокерский счет, вам нужно будет пройти несколько небольших этапов: ',
      en: 'In order to open a brokerage account, you will need to follow a few small steps:',
      kz: 'Брокерлік шотты ашу үшін сізге бірнеше шағын қадамдардан өту керек:',
    },
    verification_step_1: {
      ru: 'Сфотографировать документ, удостоверяющий личность (паспорт, удостоверение личности)',
      en: 'Take a photo of your identification document (passport, ID card)',
      kz: 'Жеке басты куәландаратын құжатты суретке түсіріңіз (паспорт, жеке куәлік)',
    },
    verification_step_2: {
      ru: 'Пройти биометрический анализ лица',
      en: 'Take biometric facial analysis',
      kz: 'Биометриялық бет таулдауын өту',
    },
    verification_step_3: {
      ru: 'Подтвердить адрес проживания с помощью СМС или загрузить документ подтверждающий адрес (официальный государственный документ, выписка из банка, или другой официальный документ с адресом и Вашим именем)',
      en: 'Confirm your residential address via SMS or upload a document verifying your address (official government document, bank statement, or another official document with your address and name)',
      kz: 'SMS арқылы тұрғылықты мекенжайыңызды растаңыз немесе мекенжайыңызды растайтын құжатты жүктеңіз (ресми мемлекеттік құжат, банк үзіндісі немесе мекенжайыңыз бен атыңыз көрсетілген басқа ресми құжат)',
    },
    begin: { ru: 'Начать', en: 'Start', kz: 'Бастау' },
    continue: { ru: 'Продолжить', en: 'Continue', kz: 'Жалғастыру' },
    address_verification: {
      ru: 'Верификация адреса',
      en: 'Address verification',
      kz: 'Мекенжайды тексеру',
    },
    address_verification_2: {
      ru: 'Ваш адрес проживания совпадает с адресом прописки?',
      en: 'Does your residential address match your registered address?',
      kz: 'Сіздің тұрғылықты мекенжайыңыз тіркелген мекенжайыңызға сәйкес келе ме?',
    },
    address_verification_warning: {
      ru: 'Если ваш ответ НЕТ, потребуется дополнительная проверка и загрузка документов, подтверждающих адрес проживания',
      en: "If your answer is NO, additional verification will be required, and you'll need to upload documents confirming your residential address",
      kz: 'Жауабыңыз ЖОҚ болса, сіздің тұрғылықты мекенжайыңызды растайтын құжаттарды қосымша тексеру және жүктеп салу қажет болады',
    },
    status: { ru: 'Статус', en: 'Status', kz: 'Күй' },
    status_1: {
      ru: 'Запрошено согласие на получение данных',
      en: 'Requested permission to retrieve data',
      kz: 'Деректерді алуға келісім сұралды',
    },
    update_status: {
      ru: 'Не получили СМС?',
      en: "Didn't receive an SMS?",
      kz: 'СМС алмадыңыз ба?',
    },
    replied_to_SMS: {
      ru: 'Ответил на SMS',
      en: 'Replied to SMS',
      kz: 'SMS-ке жауап берді',
    },
    sms: {
      ru: (<>Вам направлено SMS от номера 1414<br/>Отправьте в ответ на сообщение цифру 511<br/>SMS направлено на номер мобильного телефона, который зарегестрирован в eGov</>),
      en: (<>An SMS has been sent to you from number 1414<br/>Reply to a message with the number 511<br/>The SMS is sent to a mobile phone number that is registered in eGov</>),
      kz: (<>Сізге 1414 нөмірінен SMS жіберілді<br/>Хабарға жауап ретінде 511 санын жіберіңіз<br/>SMS eGov тіркелген ұялы телефон нөміріне жіберілді</>),
    },
    address_verified: {
      ru: 'Ваш адрес успешно подтвержден',
      en: 'Your address has been successfully verified',
      kz: 'Мекенжайыңыз сәтті расталды',
    },
    signing_of_documents: {
      ru: 'Подписание документов',
      en: 'Document signing',
      kz: 'Құжаттарға қол қою',
    },
    broker_profile: {
      ru: 'Анкета брокера',
      en: 'Broker application',
      kz: 'Брокер профилі',
    },
    W8BEN_form: {
      ru: 'Форма W-8BEN',
      en: 'W-8BEN form',
      kz: 'W-8BEN нысаны',
    },
    brokerage_service_agreement: {
      ru: 'Договор брокерского обслуживания',
      en: 'Brokerage Services Agreement',
      kz: 'Брокерлік қызмет көрсету шарты',
    },
    alpaca_agreement: {
      ru: 'Клиентское соглашение Alpaca',
      en: 'Alpaca Customer Agreement',
      kz: 'Alpaca тұтынушы келісімі',
    },
    signature_err: {
      ru: 'Поставьте подпись',
      en: 'Sign here',
      kz: 'Мұнда қол қойыңыз',
    },
    make_signature: {
      ru: 'Распишитесь на экране',
      en: 'Sign on the screen',
      kz: 'Экранда қол қойыңыз',
    },
    personal_data: {
      ru: 'Личные данные',
      en: 'Personal Information',
      kz: 'Жеке деректер',
    },
    aml_cft: {
      ru: 'Анкета ПОД/ФТ клиента',
      en: 'AML/CFT Client Form',
      kz: 'АЖ/ТҚҚ клиентінің сауалнамасы',
    },
    financial_profile: {
      ru: 'Финансовый профиль',
      en: 'Financial Information',
      kz: 'Қаржылық профиль',
    },
    risk_appetite: {
      ru: 'Определение риск-аппетита',
      en: 'Risk Appetite Assessment',
      kz: 'Тәуекелге тәбетті анықтау',
    },
    firstname: { ru: 'Имя', en: 'First Name', kz: 'Аты' },
    lastname: { ru: 'Фамилия', en: 'Last Name', kz: 'Тегі' },
    lastname_2: { ru: 'Отчество', en: 'Patronymic', kz: 'Әкесінің аты' },
    birth_date: { ru: 'Дата рождения', en: 'Date of Birth', kz: 'Туған күні' },
    tax_id: {
      ru: 'Индивидуальный идентификационный номер (ИИН)',
      en: 'Tax ID Number',
      kz: 'Жеке сәйкестендіру нөмірі (ЖСН)',
    },
    doc_type: {
      ru: 'Вид документа',
      en: 'Document type',
      kz: 'Құжат түрі',
    },
    validity: {
      ru: 'Срок действия',
      en: 'Expiration Date',
      kz: 'Жарамдылық',
    },
    doc_number: {
      ru: 'Номер документа',
      en: 'Document Number',
      kz: 'Құжат нөмірі',
    },
    issuing_authority: {
      ru: 'Орган выдачи',
      en: 'Issuing Authority',
      kz: 'Берген мекеме',
    },
    citizenship: {
      ru: 'Гражданство',
      en: 'Country of Citizenship',
      kz: 'Азаматтығы',
    },
    country: {
      ru: 'Страна налогового резидентства',
      en: 'Country of Tax Residence',
      kz: 'Салық резиденттік елі',
    },
    taxpayer_number_type: {
      ru: 'Вид номера налогоплательщика',
      en: 'Tax ID Type',
      kz: 'Салық төлеушінің нөмірі түрі',
    },
    region: {
      ru: 'Район/Область',
      en: 'District/Region',
      kz: 'Аудан, облыс',
    },
    city: { ru: 'Город', en: 'City', kz: 'Қала' },
    residence_address: {
      ru: 'Адрес проживания',
      en: 'Residential Address',
      kz: 'Тұрғын үй мекен-жайы',
    },
    house_apartment: {
      ru: 'Дом/Квартира',
      en: 'House/Apartment',
      kz: 'Үй/Пәтер',
    },
    postcode: {
      ru: 'Почтовый индекс',
      en: 'Zip code',
      kz: 'Почталық индекс',
    },
    select_answer: {
      ru: 'Выберите подходящий ответ',
      en: 'Select answer',
      kz: 'Сәйкес жауапты таңдаңыз',
    },
    aml_question_1: {
      ru: 'Каковы цель и характер деловых отношений с Geeko Invest Limited?',
      en: 'What is the purpose and nature of the business relationship with Geeko Invest Limited?',
      kz: 'Geeko Invest Limited компаниясымен іскерлік қарым-қатынастың мақсаты мен сипаты қандай?',
    },
    brokerage_services: {
      ru: 'Брокерское обслуживание на рынке ценных бумаг',
      en: 'Brokerage Services in the Securities Market',
      kz: 'Бағалы қағаздар нарығындағы брокерлік қызмет',
    },
    other: {
      ru: 'Иное (свой ответ)',
      en: 'Other (your answer)',
      kz: 'Басқа (сіздің жауабыңыз)',
    },
    employment: {
      ru: 'Занятость',
      en: 'Employment Status',
      kz: 'Жұмыспен қамту',
    },
    employed: { ru: 'Трудоустроен', en: 'Employed', kz: 'Жұмыспен қамтылған' },
    unemployed: { ru: 'Безработный', en: 'Unemployed', kz: 'Жұмыссыз' },
    pensioner: { ru: 'Пенсионер', en: 'Retired', kz: 'Зейнеткер' },
    student: { ru: 'Студент', en: 'Student', kz: 'Студент' },
    self_employed: {
      ru: 'Самозанятый',
      en: 'Self-employed',
      kz: 'Өзін-өзі жұмыспен қамтушы ',
    },
    employment_err: {
      ru: 'Выберите занятость',
      en: 'Select employment',
      kz: 'Жұмыс таңдау',
    },
    organization_name: {
      ru: 'Название организации',
      en: 'Name of Employer',
      kz: 'Ұйымның атауы',
    },
    organization_address: {
      ru: 'Адрес организации',
      en: 'Employer Address',
      kz: 'Ұйым мекенжайы',
    },
    job: { ru: 'Должность', en: 'Job title/Occupation', kz: 'Қызмет атауы' },
    financing_source: {
      ru: 'Источник финансирования',
      en: 'Account Funding Source',
      kz: 'Қаржыландыру көзі',
    },
    salary: {
      ru: 'Заработная плата и бонусы',
      en: 'Employment Income',
      kz: 'Жалақы және бонустар',
    },
    investments: { ru: 'Инвестиции', en: 'Investments', kz: 'Инвестициялар' },
    inheritance: { ru: 'Наследство', en: 'Inheritance', kz: 'Мұрагерлік' },
    business_income: {
      ru: 'Доход от бизнеса',
      en: 'Business Income',
      kz: 'Кәсіпкерлік табыс ',
    },
    savings: { ru: 'Сбережения', en: 'Savings', kz: 'Сақтау' },
    family: { ru: 'Семья', en: 'Family', kz: 'Жаңұя' },
    financing_source_err: {
      ru: 'Пожалуйста, введите Источник финансирования',
      en: 'Please select Funding Source',
      kz: 'Қаржыландыру көзін енгізіңіз',
    },
    pdl: {
      ru: 'Являетесь ли Вы или члены Вашей семьи ПДЛ?',
      en: 'Are you or an immediate family member currently or formerly a Politically Exposed Person or Public Official?',
      kz: 'Сіз немесе сіздің отбасы мүшелеріңіз ЖЛА болып табыласыз ба?',
    },
    pdl_no: {
      ru: 'НЕТ, ни я, ни мои близкие не являются (являлись) ПДЛ',
      en: 'NO, neither I nor my close relatives are (have been) PEPs (Politically Exposed Persons)',
      kz: 'ЖОҚ, мен де, менің жақындарым да ЖЛА емеспін',
    },
    pdl_yes_1: {
      ru: 'ДА, я являюсь (являлся) ПДЛ',
      en: 'YES, I am (have been) a PEP (Politically Exposed Person)',
      kz: 'ИӘ, мен ЖЛАмін (болдым)',
    },
    pdl_yes_2: {
      ru: 'ДА, члены моей семьи или мои близкие являются (являлись) ПДЛ',
      en: 'YES, my family members or my relatives are (have been) PEPs (Politically Exposed Persons)',
      kz: 'ИӘ, менің отбасы мүшелерім немесе менің жақындарым ЖЛА болды',
    },
    pdl_relationship: {
      ru: 'Степень родства либо статус по отношению к ПДЛ',
      en: 'Relationship or status in relation to the PEPs (Politically Exposed Persons)',
      kz: 'ЖЛА-ға қатысты қарым-қатынас немесе мәртебе дәрежесі',
    },
    pdl_name: {
      ru: 'ФИО ПДЛ',
      en: 'First and last name',
      kz: 'ЖЛАның толық аты-жөні ',
    },
    add_note: {
      ru: 'Добавить запись',
      en: 'Add a note',
      kz: 'Жазба қосу',
    },
    aml_question_2: {
      ru: 'Вы или кто-либо из членов Вашей непосредственной семьи связаны или работают в фондовой бирже, регулирующем органе, фирме-участнице биржи, FINRA или муниципальном брокер-дилере по ценным бумагам, зарегистрированным в США?',
      en: 'Are you or an immediate family member affiliated with or employed by a stock exchange, regulatory body, member firm of an exchange, FINRA or a municipal securities broker-dealer?',
      kz: 'Сіз немесе сіздің жақын туыстарыңыздағы кез келген адам Америка Құрама Штаттарында тіркелген қор биржасы, реттеуші агенттік, мүше-фирма, FINRA немесе муниципалды бағалы қағаздар брокер-дилерімен үлестессіз бе немесе оларда жұмыс істейсіз бе?',
    },
    aml_question_3: {
      ru: 'Вы или кто-либо из членов вашей непосредственной семьи являетесь должностным лицом или владеет 10% или более акций публичной компании, подпадающей под действие Закона о ценных бумагах США 1934 года?',
      en: 'Are you or an immediate family member an officer or 10% or greater shareholder of a publicly traded company, subject to the US Securities Exchange Act 1934?',
      kz: 'Сіз немесе сіздің жақын туыстарыңыздың кез келген адамы 1934 жылғы АҚШ-тың бағалы қағаздар туралы заңына сәйкес ашық компанияның 10% немесе одан да көп акциясына иелік ететін қызметкерсіз бе?',
    },
    pdl_1: {
      ru: 'Публичное должностное лицо (ПДЛ)',
      en: 'Politically exposed person (PEP)',
      kz: 'Жария лауазымды адам (ЖЛА)',
    },
    pdl_is: {
      ru: 'Физическое лицо, которое было наделено значимыми публичными функциями. В частности:\nПДЛ: лица, которым доверены или были доверены значительные публичные функции страны, например, главы государств или правительств, старшие правительственные, судебные или военные сотрудники, старшие руководители государственных корпораций, видные деятели политических партий;\nПДЛ международных организаций: лица, которым доверены или были доверены важные функции международной организацией, относятся к членам старшего руководства, т.е. директорам, заместителям директоров и членам правления или эквивалентным должностям;\nЧлены семьи – это физические лица, которые связаны с ПДЛ напрямую (кровное родство) или браком или схожие (гражданские) формы партнерства;\nБлизкие к ПДЛ лица – это физические лица, которые тесно связаны с ПДЛ социально или профессионально.',
      en: 'An individual who has been vested with significant public functions. In particular:\nPEPs: persons who are or have been entrusted with significant public functions of the country, such as heads of state or government, senior government, judicial or military officials, senior executives of public corporations, prominent political party officials;\nPEPs of international organizations: persons who are or have been entrusted with important functions by an international organization are members of senior management, i.e. directors, deputy directors and members of the board or equivalent positions;\nFamily members are individuals who are related to the PEP directly (by blood) or by marriage or similar (civil) forms of partnership;\nPersons close to a PEP are individuals who are closely associated with a PEP socially or professionally.',
      kz: 'Маңызды мемлекеттік функциялар жүктелген жеке тұлға. Сондай-ақ:\nЖЛА: мемлекет немесе үкімет басшылары, жоғары үкімет, сот немесе әскери лауазымды тұлғалар, мемлекеттік корпорациялардың жоғары лауазымды тұлғалары, саяси партиялардың көрнекті шенеуніктері сияқты елдің маңызды қоғамдық функциялары тапсырылған тұлғалар;\nХалықаралық ұйымдардың ЖЛА: халықаралық ұйымдардың маңызды функциялары тапсырылған тұлғалар, жоғары басшылықтың мүшелері, мысалы, директорлар, директорлардың орынбасарлары және басқарма мүшелері немесе оларға теңестірілген лауазымдар;\nЖанұя мүшелері - бұл ЖЛА-мен тікелей (қан бойынша) немесе неке немесе ұқсас (азаматтық) серіктестік нысандары бойынша туыстық қатынасы бар тұлғалар;\nЖЛА-ға жақын адамдар - бұл ЖЛА-мен әлеуметтік немесе кәсіби түрде тығыз байланысты адамдар.',
    },
    annual_income: {
      ru: 'Годовой доход',
      en: 'Annual income',
      kz: 'Жылдық табысы',
    },
    annual_income_2: {
      ru: 'Годовой доход (в долл. США)',
      en: 'Annual Income (USD)',
      kz: 'Жылдық табыс (АҚШ доллары)',
    },
    annual_income_3: {
      ru: 'Годовой доход включает доход от таких источников, как заработная плата, алименты, социальное обеспечение, инвестиционный доход и т.д.',
      en: 'Annual income includes income from sources such as employment, alimony, social security, investment income, etc.',
      kz: 'Жылдық табысқа жалақы, алимент, әлеуметтік қамсыздандыру, инвестициялық кіріс және т.б. кірістер кіреді.',
    },
    net_assets: {
      ru: 'Чистые активы',
      en: 'Estimated Net Worth',
      kz: 'Таза активтер',
    },
    net_assets_2: {
      ru: 'Чистые активы (в долл. США)',
      en: 'Net Worth (USD)',
      kz: 'Таза активтер (АҚШ долларымен)',
    },
    net_assets_3: {
      ru: 'Чистые активы - это стоимость Ваших активов за вычетом Ваших обязательств.\nАктивы включают акции, облигации, паевые инвестиционные фонды, другие ценные бумаги, банковские счета и другую личную собственность, за исключением вашего основного места жительства.\nВ обязательства входят все непогашенные кредиты, остатки на кредитных картах, налоги и т. д., за исключением ипотечного кредита на Ваше основное место жительства.',
      en: 'Net worth is the value of your assets less your liabilities.\nAssets include stocks, bonds, mutual funds, other securities, bank accounts, and other personal property, excluding your primary residence.\nFor liabilities, include any outstanding loans, credit card balances, taxes, etc., excluding your mortgage on your primary residence.',
      kz: 'Таза активтер - бұл сіздің активтеріңіздің міндеттемелеріңізді шегергендегі құны.\nАктивтерге акциялар, облигациялар, инвестициялық қорлар, басқа бағалы қағаздар, банктік шоттар және негізгі тұрғылықты жеріңізден басқа басқа жеке мүлік кіреді.\nМіндеттемелер үшін негізгі резиденцияңыздағы ипотеканы қоспағанда, барлық өтелмеген несиелерді, несие картасының қалдықтарын, салықтарды және т.б. тізімдеңіз.',
    },
    liquid_assets: {
      ru: 'Ликвидные активы',
      en: 'Liquid Assets',
      kz: 'Өтімді активтер',
    },
    liquid_assets_2: {
      ru: 'Инвестиционные/ликвидные активы',
      en: 'Investment/Liquid assets',
      kz: 'Инвестициялық/өтімді активтер',
    },
    liquid_assets_3: {
      ru: 'Инвестиционные/ликвидные активы - это Ваша общее состояние за вычетом активов, которые невозможно быстро и без штрафов обменять на наличные деньги, например, недвижимость, коммерческий капитал, личное имущество и автомобили, ожидаемое наследство, драгоценные металлы, оборудование и т.д.\nЛиквидные активы включают денежные средства и краткосрочные инвестиции.',
      en: 'Investment/Liquid assets is your net worth minus assets that cannot be converted quickly and easily into cash, such as real estate, business equity, personal property and automobiles, expected inheritances, assets earmarked for other purposes, and investments or accounts subject to substantial penalties if they were sold or if assets were withdrawn from them.',
      kz: 'Инвестициялық/өтімді активтер - бұл жылжымайтын мүлік, бизнес капиталы, жеке мүлік және автомобильдер, күтілетін мұралар, қымбат металдар, жабдықтар және т. Өтімді активтерге ақшалай және қысқа мерзімді инвестициялар жатады.',
    },
    investment_experience: {
      ru: 'Ваш инвестиционный опыт:',
      en: 'Your investment experience:',
      kz: 'Сіздің инвестициялық тәжірибеңіз:',
    },
    stocks_etfs: {
      ru: 'Акции и ETF',
      en: 'Stocks and ETFs',
      kz: 'Акциялар және ETF',
    },
    less_one: { ru: 'Менее 1 года', en: 'Less than 1 year', kz: '1 жылдан аз' },
    one_five: { ru: '1-5 лет', en: '1-5 years', kz: '1-5 жыл' },
    more_five: {
      ru: 'Более 5 лет',
      en: 'More than 5 years',
      kz: '5 жылдан астам',
    },
    digital_assets: {
      ru: 'Цифровые активы',
      en: 'Digital Assets',
      kz: 'Цифрлық активтер',
    },
    mutual_funds: {
      ru: 'Паевые инвестиционные фонды (ПИФы)',
      en: 'Mutual Investment Funds (MIFs)',
      kz: 'Пайлық инвестициялық қорлар (UIFs)',
    },
    products: {
      ru: 'Производные ценные продукты/продукты с кредитным плечом',
      en: 'Derivative Financial Products / Leveraged Products',
      kz: 'Туынды құралдар/Левередждік өнімдер',
    },
    risk_question_1: {
      ru: 'С какими инвестиционными услугами Вы знакомы?',
      en: 'Which investment services are you familiar with?',
      kz: 'Қандай инвестициялық қызметтерді білесіз?',
    },
    brokerage: {
      ru: 'Брокерское обслуживание',
      en: 'Brokerage Services',
      kz: 'Делдалдық қызметтер ',
    },
    deposits: { ru: 'Депозиты', en: 'Deposits', kz: 'Депозиттер' },
    trust_management: {
      ru: 'Доверительное управление',
      en: 'Trust Management',
      kz: 'Сенімді басқару ',
    },
    risk_question_2: {
      ru: 'Количество инвестиционных сделок за последний год:',
      en: 'Number of investment transactions over the past year:',
      kz: 'Өткен жылдағы инвестициялық операциялардың саны ',
    },
    risk_question_3: {
      ru: 'Имеете ли Вы какое-либо образование или квалификацию в области финансовых услуг?',
      en: 'Do you have any education or qualifications in the field of financial services?',
      kz: 'Сіздің қаржылық қызметтерде біліміңіз немесе біліктілігіңіз бар ма?',
    },
    yes_higher_education: {
      ru: 'Да, высшее образование в области финансов или экономики',
      en: 'Yes, higher education in finance or economics',
      kz: 'Иә, қаржы немесе экономика саласында жоғарғы білім',
    },
    yes_prof_exam: {
      ru: 'Да, сдал профессиональный экзамен или прошел профессиональные курсы (экзамен на брокера, профессиональное образование для регистрации инвестиционного брокера, связанного агента и т.д.)',
      en: 'Yes, passed a professional exam or completed professional courses (broker exam, professional education for registration of investment broker, tied agent, etc.',
      kz: 'Иә, кәсіби емтихан тапсырғанмын немесе кәсіби курстарды аяқтағанмын (брокер емтиханы, инвестициялық брокерді тіркеу бойынша кәсіби білім, байланысты агент және т.б.)',
    },
    risk_question_4: {
      ru: 'Имеете ли Вы какой-либо опыт работы в области финансовых услуг?',
      en: 'Do you have any experience in financial services?',
      kz: 'Сіздің қаржылық қызметтерде тәжірибеңіз бар ма?',
    },
    brokerage_company: {
      ru: 'Брокерская компания',
      en: 'Brokerage Company',
      kz: 'Брокерлік компания ',
    },
    banking_sector: {
      ru: 'Банковская сфера',
      en: 'Banking Sector',
      kz: 'Банк секторы ',
    },
    other_company: {
      ru: 'Другая финансовая организация (свой ответ)',
      en: 'Other financial institution (your answer)',
      kz: 'Басқа қаржы институттары (сіздің жауыңыз)',
    },
    risk_question_5: {
      ru: 'Какое утверждение лучше всего описывает Ваши знания об инвестициях?',
      en: 'Which statement best describes your knowledge of investments?',
      kz: 'Қай мәлімдеме сіздің инвестиция туралы біліміңізді жақсы сипаттайды?',
    },
    know_little: {
      ru: 'Я очень мало знаю об инвестициях и финансовых рынках',
      en: 'I know very little about investing and financial markets',
      kz: 'Мен инвестиция және қаржы нарығы туралы өте аз білемін',
    },
    know_average: {
      ru: 'Я обладаю средним уровнем знаний об инвестициях и финансовых рынках',
      en: 'I have an intermediate level of knowledge about investments and financial markets',
      kz: 'Инвестициялар мен қаржы нарықтары туралы білімім орташа',
    },
    know_extensive: {
      ru: 'Я обладаю обширными знаниями в области инвестиций, разбираюсь в различных инвестиционных продуктах и внимательно слежу за финансовыми рынками',
      en: 'I have extensive knowledge in the field of investments, understand various investment products, and closely monitor financial markets.',
      kz: 'Мен инвестиция саласында кең білімім бар, әртүрлі инвестициялық өнімдерді түсінемін және қаржы нарықтарын мұқият қадағалаймын',
    },
    doc_generation: {
      ru: 'Генерация документа. Пожалуйста ждите...',
      en: 'Document generation. Please, wait...',
      kz: 'Құжатты құру. Өтінемін күте тұрыңыз...',
    },
    w8_disclosure: {
      ru: 'W-8 Раскрытие информации',
      en: 'W-8 Information disclosure',
      kz: 'W-8 ашу',
    },
    not_us_resident: {
      ru: 'Я подтверждаю, что я не являюсь гражданином США, иностранцем-резидентом США или другим лицом США для целей налогообложения США, и я подаю соответствующую форму W8 вместе с этой формой, чтобы подтвердить свой иностранный статус и, если применимо, требовать льготы по налоговому соглашению',
      en: 'I certify that I am not a US citizen, US resident alien or other US person for US tax purposes, and I am submitting the applicable Form W8 with this form to certify my foreign status and, if applicable, claim tax treaty benefits.',
      kz: 'Мен АҚШ азаматы, АҚШ-та резидент шетелдік немесе АҚШ-тың салық салу мақсаттары үшін АҚШ-тың басқа тұлғасы емес екенімді растаймын және шетелдік мәртебемді растау және қажет болса, шарттық жеңілдіктерді талап ету үшін осы үлгімен тиісті W8 пішінін толтырып жатырмын',
    },
    tax_form: {
      ru: 'Просим ознакомиться с Вашей налоговой формой США (W-8BEN)',
      en: 'Please review your U.S. tax form (W-8BEN)',
      kz: 'АҚШ салық формасын (W-8BEN)',
    },
    W8BEN_pdf: {
      ru: 'PDF файл (W-8BEN)',
      en: 'PDF file (W-8BEN)',
      kz: 'PDF файлы (W-8BEN)',
    },
    acknowledged: { ru: 'Ознакомлен', en: 'Acknowledged', kz: 'Танысты' },
    us_resident_is: {
      ru: 'Налоговый резидент США\nфизическое лицо, индивидуальный предприниматель и лицо, занимающееся частной практикой, признаются налоговыми резидентами США,\nесли выполняется ли одно из следующих условий: наличие гражданства США, наличие разрешение на постоянное пребывание в США (форма I551 (Green Card)), наличие критерия «долгосрочного пребывания»',
      en: 'US tax resident\nan individual, an individual entrepreneur and a person engaged in private practice are recognized as US tax residents\nif one of the following conditions is met: US citizenship, permanent residence permit in the US (Form I551 (Green Card)), availability of the “long-term” criterion stay',
      kz: 'АҚШ салық резиденті\nжеке тұлға, жеке кәсіпкер және жеке тәжірибемен айналысатын тұлға келесі шарттардың бірі орындалған жағдайда АҚШ салық резиденті болып танылады:\nАҚШ азаматтығы, АҚШ-та тұрақты тұруға рұқсат (I551 нысаны (Жасыл карта)), құжаттың болуы, «ұзақ мерзімді» қалу критерийі',
    },
    brokerage_service_agreement_2: {
      ru: 'Просим ознакомиться с Договором брокерского обслуживания и Заявлением о присоединении',
      en: 'Please read the Brokerage Service Agreement and the Adherence Statement',
      kz: 'Брокерлік қызмет көрсету келісімімен және қосылу туралы өтінішпен танысыңыз',
    },
    application_membership: {
      ru: 'Заявление о присоединении',
      en: 'Adherence Statement',
      kz: 'Қосылу туралы өтініш',
    },
    brokerage_services_sms: {
      ru: 'Введите номер Вашего телефона в поле ниже, мы отправим вам SMS с уникальным кодом подтверждения для подписания Договора брокерского обслуживания',
      en: 'Enter your phone number in the field below, we will send you an SMS with a unique confirmation code to sign the Brokerage Service Agreement',
      kz: 'Төмендегі өріске телефон нөміріңізді енгізіңіз, біз сізге Брокерлік келісімге қол қою үшін бірегей растау коды бар SMS жібереміз.',
    },
    sign: { ru: 'Подписать', en: 'Sign', kz: 'Қол қою' },
    phone_number_err: {
      ru: 'Введите номер телефона',
      en: 'Enter your Phone Number',
      kz: 'Телефон нөміріңізді енгізіңіз',
    },
    brokerage_service_agreement_3: {
      ru: 'Отправляя код, Вы подписываете Договор брокерского обслуживания в электронном виде и подтверждаете, что Ваша электронная подпись будет иметь такую же силу, как подпись, совершенная собственноручно.',
      en: 'By submitting the code, you sign the Brokerage Agreement in electronic form and confirm that your electronic signature will have the same force as a handwritten signature.',
      kz: 'Кодты жіберу арқылы сіз Брокерлік қызмет көрсету шартына электронды түрде қол қоясыз және электрондық қолтаңбаңыз өз қолыңызбен жасалған қолтаңба сияқты күшке ие болатынын растайсыз.',
    },
    enter_code: {
      ru: 'Введите 6-значный код',
      en: 'Enter 6-digit code',
      kz: '6 таңбалы кодты енгізіңіз',
    },
    code_sent: {
      ru: 'Код отправлен на номер',
      en: 'Code sent to number',
      kz: 'Код нөмірге жіберілді',
    },
    resend: {
      ru: 'Отправить повторно через',
      en: 'Resend in',
      kz: 'Арқылы қайта жіберу',
    },
    seconds: {
      //продолжение предыдущей строки
      ru: 'секунд',
      en: 'seconds',
      kz: 'секунд',
    },
    resend_code: {
      ru: 'Отправить код повторно',
      en: 'Resend code',
      kz: 'Кодты қайта жіберу',
    },
    enter_code_2: {
      ru: 'Введите код',
      en: 'Enter code',
      kz: 'Кодты енгізіңіз',
    },
    docs_signed: {
      ru: 'Документы подписаны',
      en: 'The documents are signed',
      kz: 'Құжаттарға қол қойылды',
    },
    alpaca_agreement_2: {
      ru: 'Заявление на учетную запись и Клиентское соглашение Alpaca',
      en: 'Alpaca Account Application and Customer Agreement',
      kz: 'Alpaca тіркелгісінің қолданбасы және клиенттік келісім',
    },
    alpaca_agreement_3: {
      ru: 'Просим ознакомиться с Заявлением на учетную запись и Клиентским соглашением Alpaca',
      en: 'Please read the Alpaca Account Application and Customer Agreement',
      kz: 'Alpaca тіркелгісінің қосымшасын және клиент келісімін оқыңыз',
    },
    alpaca_agreement_4: {
      ru: 'Я ознакомился(-лась), понял(-а) и согласен(-на) с условиями открытия счета у субброкера на международном рынке ценных бумаг Alpaca Securities LLC и Geeko Invest Limited,а также все другие условия, раскрытия информации и отказы от ответственности, применимые ко мне, как указано в',
      en: 'I have read, understood, and agree to be bound by Alpaca Securities LLC and Geeko Invest Limited account terms, and all other terms, disclosures and disclaimers applicable to me, as referenced in the ',
      kz: 'Мен Alpaca Securities LLC және Geeko Invest Limited компанияларында халықаралық бағалы қағаздар суб-брокер шотын ашу шарттары мен шарттарын, сондай-ақ маған қолданылатын барлық басқа шарттарды, шарттарды, ақпаратты ашу және бас тартуды оқыдым, түсіндім және келісемін ',
    },
    alpaca_agreement_5: {
      //продолжение предыдущей строки
      ru: 'Клиентском соглашении Alpaca',
      en: 'Alpaca Customer Agreement',
      kz: 'Alpaca тұтынушы келісімінде',
    },
    alpaca_agreement_6: {
      //продолжение предыдущей строки
      ru: '. Я также признаю, что Клиентское соглашение Alpaca содержит арбитражную оговорку о предварительном споре в разделе 43.',
      en: '. I also acknowledge that the Alpaca Customer Agreement contains a pre-dispute arbitration clause in Section 43.',
      kz: ' көрсетілгендей.  Мен сондай-ақ Alpaca Тұтынушы келісімінде 43-бөлімде дауға дейінгі арбитраждық тармақ бар екенін мойындаймын.',
    },
    signature_confirmation: {
      ru: 'Подтверждение электронной подписи',
      en: 'Electronic signature confirmation',
      kz: 'Электрондық қолтаңбаны растау',
    },
    alpaca_agreement_7: {
      ru: 'Нажимая кнопку «Я согласен(-на)», я понимаю, что подписываю данное соглашение в электронном виде, и что моя электронная подпись будет иметь такую же силу, как физическое подписание и возвращение Заявления на открытие счета.',
      en: 'By clicking the «I agree» button, I understand I am signing this agreement electronically and that my electronic signature is the legal equivalent of my manual signature on this Agreement',
      kz: '«Келісемін» түймесін басу арқылы мен осы келісімге электронды түрде қол қойып жатқанымды және менің электрондық қолтаңбамның шот ашу өтінішіне физикалық қол қою және қайтару сияқты күші болатынын түсінемін',
    },
    agree: {
      ru: 'Я согласен(-на)',
      en: 'I agree',
      kz: 'Мен келісемін',
    },
    congrats_1: {
      ru: 'Спасибо за Ваше время!',
      en: 'Thank you for your time!',
      kz: 'Уақыт бөлгеніңізге рахмет!',
    },
    congrats_2: {
      ru: (<>Вы получите уведомление,<br/>когда Ваш счет будет открыт!</>),
      en: (<>You will receive a notification<br/>once your account is opened!</>),
      kz: (<>Есептік жазбаңыз ашылғанда<br/>сізге хабарлама келеді!</>),
    },
    congrats_3: {
      ru: (<>Открытие счета осуществляется<br/>только в рабочее время</>),
      en: (<>Account opening is carried out<br/>only during business hours</>),
      kz: (<>Шотты ашу<br/>тек жұмыс уақытында</>),
    },
    okay: { ru: 'Хорошо', en: 'Okay', kz: 'Жақсы' },
    slider_1: {
      ru: 'Завершите регистрацию',
      en: 'Complete registration',
      kz: 'Тіркеуді аяқтау',
    },
    slider_2: {
      ru: 'И откройте доступ к инвестиционным инструментам',
      en: 'And get access to investment instruments',
      kz: 'Және инвестициялық құралдарға қолжетімділікті ашыңыз',
    },
    slider_3: {
      ru: 'Пополните брокерский счет',
      en: 'Replenish your brokerage account',
      kz: 'Брокерлік шотты толтырыңыз',
    },
    slider_4: {
      ru: 'Легко и быстро',
      en: 'Quick and easy',
      kz: 'Оңай және тез',
    },
    slider_5: {
      ru: 'Торгуйте и приумножайте',
      en: 'Trade and earn',
      kz: 'Сауда және көбейту',
    },
    slider_6: {
      ru: 'У нас вы найдете более чем 5000 инструментов',
      en: 'Here you will find more than 5000 instruments',
      kz: 'Бізде сіз 5000-нан астам құралдарды таба аласыз',
    },
    request_again_in: {
      ru: 'Запросить статус повторно через',
      en: 'Request status again in',
      kz: 'Мәртебені қайта сұрау',
    },
    true_info_confirmation: {
      ru: 'Настоящим подтверждаю, что, приведенная мной выше информация является полной, достоверной и точной.',
      en: 'I confirm that the information I have provided above is complete, true and accurate.',
      kz: 'Осымен жоғарыда келтірілген ақпараттың толық, дұрыс және дәл болып табылатынын растаймын.',
    },
    risk_question_6: {
      ru: 'Когда вы слышите слово "риск" в контексте ваших финансов, какая первая мысль приходит на ум?',
      en: 'When you hear the word “risk” in the context of your finances, what is the first thought that comes to mind?',
      kz: 'Қаржы тұрғысынан «тәуекел» деген сөзді естігенде, бірінші ойға қандай ой келеді?',
    },
    left_with_nothing: {
      ru: 'Я беспокоюсь, что могу остаться ни с чем',
      en: "I'm worried I might be left with nothing",
      kz: 'Мен ештеңесіз қалуыма алаңдаймын',
    },
    integral_of_investment: {
      ru: 'Я понимаю, что это неотъемлемая часть процесса инвестирования',
      en: 'I understand that this is an integral part of the investment process',
      kz: 'Мен бұл инвестициялау процесінің ажырамас бөлігі екенін түсінемін',
    },
    make_profits: {
      ru: 'Я вижу возможность получить большие доходы',
      en: 'I see an opportunity to make big profits',
      kz: 'Мен үлкен табыс табу мүмкіндігін көріп отырмын',
    },
    exciting_to_invest: {
      ru: 'Я думаю о том, как захватывающе инвестировать',
      en: "I'm thinking about how exciting it is to invest",
      kz: 'Мен инвестициялау туралы ойланамын',
    },
    to_home: {
      ru: 'Вернуться на главную',
      en: 'To main',
      kz: 'Басты бетке оралу',
    },
    double:{
      ru: 'Пользователь с такими данными уже зарегистрирован в другой учетной записи. Если вы не можете восстановить доступ к учетной записи,свяжитесь со службой поддержки.',
      en: 'A user with this data is already registered in another account. If you cannot regain access to your account, please contact support.',
      kz: 'Бұл деректері бар пайдаланушы басқа тіркелгіде бұрыннан тіркелген. Есептік жазбаңызға қайта кіре алмасаңыз, қолдау қызметіне хабарласыңыз.',
    }
  },
  wallet: {
    //Страница кошелька
    my_investments: {
      ru: 'Валовая рыночная стоимость',
      en: 'Gross Position Market Value',
      kz: 'Позицияның жалпы нарықтық құны',
    },
    my_investments_are: {
      ru: 'Валовая рыночная стоимость позиции равна сумме текущих рыночных стоимостей всех открытых позиций.',
      en: 'Gross Position Market Value means an amount equal to the sum of all current market values of all positions.',
      kz: 'Позицияның жалпы нарықтық құны барлық ашық позициялардың ағымдағы нарықтық құнының қосындысына тең.',
    },
    cash: {
      ru: 'Доступные средства',
      en: 'Available Funds',
      kz: 'Қолжетімді қаражат',
    },
    cash_is: {
      ru: 'Доступные средства представляют собой доступные денежные средства на вашем торговом счете, не обремененные конкретными инвестициями.\nЭти средства могут быть использованы для проведения новых сделок, покупки ценных бумаг или проведения других финансовых операций.',
      en: 'Available funds represent the available cash in your portfolio that is not committed to specific investments.\nThese funds can be used to carry out new transactions, purchase securities, or conduct other financial transactions.',
      kz: 'Қолжетімді қаражат сіздің портфолиоңыздағы нақты инвестициямен шектелмеген қолма-қол ақшаны білдіреді.\nБұл қаражатты жаңа операцияларды жүргізуге, бағалы қағаздарды сатып алуға немесе басқа да қаржылық операцияларды жүргізуге пайдалануға болады.',
    },
    close_positions: {
      ru: 'Закрыть все позиции',
      en: 'Close all positions',
      kz: 'Барлық позицияларды жабу',
    },
    close_positions_2: {
      ru: 'Вы действительно хотите закрыть все позиции?',
      en: 'Are you sure you want to close all positions?',
      kz: 'Сіз шынымен барлық позицияларды жапқыңыз келе ме?',
    },
    positions: { ru: 'Позиции', en: 'Positions', kz: 'Позициялар' },
    orders: { ru: 'Поручения', en: 'Orders', kz: 'Тапсырыстар' },
    history: { ru: 'История', en: 'History', kz: 'Тарих' },
    orders_history: {
      ru: 'История поручений',
      en: 'Orders History',
      kz: 'Тапсырмалар тарихы',
    },
    position_closed: {
      ru: 'Позиция успешно закрыта',
      en: 'Order(s) Created',
      kz: 'Позиция сәтті жабылды',
    },
    error_occurred: {
      ru: 'Произошла ошибка',
      en: 'An error occurred',
      kz: 'Қате орын алды',
    },
    no_positions: { ru: 'Позиций нет', en: 'No Positions', kz: 'Позиция жоқ' },
    close_position: {
      ru: 'Закрыть позицию',
      en: 'Close Position',
      kz: 'Позицияны жабу',
    },
    close_position_2: {
      ru: 'Вы уверены что вы хотите закрыть позицию?',
      en: 'Are you sure you want to close this position?',
      kz: 'Позицияңызды жабғыңыз келетініне сенімдісіз бе?',
    },
    order_type: {
      ru: 'Тип поручения:',
      en: 'Order Type:',
      kz: 'Тапсырыс түрі:',
    },
    positions_quantity: {
      ru: 'Количество позиций:',
      en: 'Quantity:',
      kz: 'Лауазымдар саны:',
    },
    pnl_all_time: {
      ru: 'PnL за всё время:',
      en: 'PnL All Time:',
      kz: 'PnL барлық уақытта:',
    },
    market_volume: {
      ru: 'Объем по рынку:',
      en: 'Market Price:',
      kz: 'Нарық көлемі:',
    },
    avg_entry_price: {
      ru: 'Средняя цена входа:',
      en: 'Average Entry Price:',
      kz: 'Орташа кіру бағасы:',
    },
    order_cancelled: {
      ru: 'Поручение отменен',
      en: 'Order canceled',
      kz: 'Ордер жойылды',
    },
    order_cancelled_err: {
      ru: 'Ошибка при отмене поручения',
      en: 'Error when canceling order',
      kz: 'Тапсырысты тоқтату кезіндегі қате',
    },
    no_orders: { ru: 'Поручений нет', en: 'No orders', kz: 'Нұсқаулар жоқ' },
    no_history: { ru: 'Истории нет', en: 'No history', kz: 'Тарих жоқ' },
    sale: { ru: 'Продажа', en: 'Sell', kz: 'Сату' },
    purchase: { ru: 'Покупка', en: 'Buy', kz: 'Сатып алу' },
    market: { ru: 'Рыночное', en: 'Market', kz: 'Нарық' },
    limit: { ru: 'Лимитное', en: 'Limit', kz: 'Шектеулі' },
    cancelled: { ru: 'Отменен', en: 'Canceled', kz: 'Жойылды' },
    filled: { ru: 'Исполнен', en: 'Executed', kz: '' },
    rejected: { ru: 'Отклонен', en: 'Rejected', kz: 'Аяқталды' },
    new: { ru: 'Новый', en: 'New', kz: 'Жаңа' },
    partially_filled: {
      ru: 'Частично исполнен',
      en: 'Partially Executed',
      kz: 'Ішінара орындалды',
    },
    filled_day: {
      ru: 'Исполнен в течении дня',
      en: 'Day Executed',
      kz: 'Бір күн ішінде жасалды',
    },
    expired: { ru: 'Истек', en: 'Expired', kz: 'Жарамдылық мерзімі өткен' },
    replaced: { ru: 'Заменен', en: 'Replaced', kz: 'Ауыстырылды' },
    pending_cancel: {
      ru: 'Ожидает отмены',
      en: 'Pending Cancellation',
      kz: 'Бас тарту күтілуде',
    },
    pending_replace: {
      ru: 'Ожидает замены',
      en: 'Pending Replacement',
      kz: 'Ауыстыруды күтуде',
    },
    accepted: { ru: 'Одобрен', en: 'Accepted', kz: 'Бекітілген' },
    unknown: { ru: 'Незивестно', en: 'Unknown', kz: 'Белгісіз' },
    one_month: { ru: '1 месяц', en: '1 month', kz: '1 ай' },
    three_months: { ru: '3 месяца', en: '3 months', kz: '3 ай' },
    six_months: { ru: '6 месяцев', en: '6 months', kz: '6 ай' },
    period: { ru: 'Период', en: 'Period', kz: 'Кезең' },
    from: { ru: 'от', en: 'from', kz: 'бастап' },
    to: { ru: 'до', en: 'to', kz: 'дейін' },
    action: { ru: 'Действие', en: 'Action', kz: 'Әрекет' },
    amount: { ru: 'Сумма', en: 'Amount', kz: 'Сомасы' },
    reset: { ru: 'Сбросить', en: 'Reset', kz: 'Қалпына келтіру' },
    apply: { ru: 'Применить', en: 'Apply', kz: 'Қолдану' },
    correct_period: {
      ru: 'Укажите правильный период времени',
      en: 'Enter the correct time period',
      kz: 'Дұрыс уақыт аралығын енгізіңіз',
    },
    correct_amount: {
      ru: 'Укажите правильный диапазон суммы',
      en: 'Enter the correct amount range',
      kz: 'Дұрыс сома ауқымын енгізіңіз',
    },
    cancel_order: {
      ru: 'Отменить поручение',
      en: 'Cancel order',
      kz: 'Тапсырыстардан бас тарту',
    },
    cancel_order_2: {
      ru: 'Вы уверены что вы хотите отменить ваше поручение?',
      en: 'Are you sure you want to cancel your order?',
      kz: 'Тапсырысыңыздан шынымен бас тартқыңыз келе ме?',
    },
    assets_qty: {
      ru: 'Количество активов:',
      en: 'Quantity:',
      kz: 'Активтер саны',
    },
    limit_2: { ru: 'лимитная', en: 'Limit', kz: 'шектеу' },
    market_2: { ru: 'рыночная', en: 'Market', kz: 'нарықты' },
    order_status: {
      ru: 'Статус поручения:',
      en: 'Order status:',
      kz: 'Тапсырыс күйі',
    },
    pending: { ru: 'В ожидании', en: 'Pending', kz: 'Күтуде' },
  },
  accounts: {
    //Страница со счетами
    my_accounts: { ru: 'Мои счета', en: 'My accounts', kz: 'Менің шоттарым' },
    broker_acc: {
      ru: 'Брокерский счет',
      en: 'Broker Account',
      kz: 'Брокерлік шот',
    },
    broker_acc2: {
      ru: 'Брокерский счет (демо)',
      en: 'Broker Account (demo)',
      kz: 'Брокерлік шот (демо)',
    },
    trading_acc: {
      ru: 'Торговый счет',
      en: 'Trading account',
      kz: 'Сауда шоты',
    },
    trading_acc2: {
      ru: 'Торговый счет (демо)',
      en: 'Trading account (demo)',
      kz: 'Сауда шоты (демо)',
    },
    open_account: { ru: 'Открыть счет', en: 'Open an account', kz: 'Шот ашу' },
    replenishment: { ru: 'Пополнение', en: 'Replenishment', kz: 'Толықтыру' },
    withdrawal: { ru: 'Вывод', en: 'Withdrawal', kz: 'Қорытынды' },
    transfer: { ru: 'Перевод', en: 'Transfer', kz: 'Аударма' },
    other: { ru: 'Другое', en: 'Other', kz: 'Басқа' },
    accounts: { ru: 'Счета', en: 'Accounts', kz: 'Шоттар' },
    history: { ru: 'История', en: 'History', kz: 'Оқиға' },
    history_tr: {
      ru: 'История транзакций',
      en: 'Transaction history',
      kz: 'Транзакция тарихы',
    },
    period: { ru: 'Период', en: 'Period', kz: 'Кезең' },
    from: { ru: 'от', en: 'from', kz: 'бастап' },
    to: { ru: 'до', en: 'to', kz: 'дейін' },
    action: { ru: 'Действие', en: 'Action', kz: 'Әрекет' },
    amount: { ru: 'Сумма', en: 'Amount', kz: 'Сомасы' },
    apply: { ru: 'Применить', en: 'Apply', kz: 'Қолдану' },
    pending: { ru: 'Ожидает', en: 'Pending', kz: ' Күтуде' },
    cancelled: { ru: 'Отменено', en: 'Canceled', kz: 'Бас тартылды' },
    done: { ru: 'Исполнено', en: 'Done', kz: 'Аяқталды' },
    replenishment_current_acc: {
      ru: 'Пополнение брокерского счета',
      en: 'Replenishment of your current account',
      kz: 'Ағымдағы шотыңызды толтырыңыз',
    },
    replenishment_current_acc2: {
      ru: 'Пополнение брокерского счета (демо)',
      en: 'Replenishment of your current account (demo)',
      kz: 'Ағымдағы шотыңызды толтырыңыз (демо)',
    },
    transfer_from_trading: {
      ru: 'Перевод с торгового счета',
      en: 'Transfer from Trading Account',
      kz: 'Сауда шотынан аудару',
    },
    transfer_from_trading2: {
      ru: 'Перевод с торгового счета (демо)',
      en: 'Transfer from Trading Account (demo)',
      kz: 'Сауда шотынан аудару (демо)',
    },
    transfer_to_trading: {
      ru: 'Перевод на торговый счет',
      en: 'Transfer to Trading Account',
      kz: 'Сауда шотына аудару',
    },
    transfer_to_trading2: {
      ru: 'Перевод на торговый счет (демо)',
      en: 'Transfer to Trading Account (demo)',
      kz: 'Сауда шотына аудару (демо)',
    },
    transfer_from_current: {
      ru: 'Перевод с брокерского счета',
      en: 'Transfer from current account',
      kz: 'Брокерлік шоттан аударым',
    },
    transfer_from_current2: {
      ru: 'Перевод с брокерского счета (демо)',
      en: 'Transfer from current account (demo)',
      kz: 'Брокерлік шоттан аударым (демо)',
    },
    transfer_to_current: {
      ru: 'Перевод на брокерский счет',
      en: 'Transfer to current account',
      kz: 'Брокерлік шотқа аудару',
    },
    transfer_to_current2: {
      ru: 'Перевод на брокерский счет (демо)',
      en: 'Transfer to current account (demo)',
      kz: 'Брокерлік шотқа аудару (демо)',
    },
    sale_securities: {
      //после этой строки идет название бумаги
      ru: 'Продажа бумаг',
      en: 'Selling of',
      kz: 'Қағаздарды сату',
    },
    purchase_securities: {
      //после этой строки идет название бумаги
      ru: 'Покупка бумаг',
      en: 'Buying of',
      kz: 'Қағаздарды сатып алу',
    },
    sale_fee: {
      ru: 'Комиссия за продажу бумаг',
      en: 'Fee',
      kz: 'Қағаздарды сатқаны үшін комиссия',
    },
    purchase_fee: {
      ru: 'Комиссия за покупку бумаг',
      en: 'Fee',
      kz: 'Буманы сатып алу үшін комиссия',
    },
    transfer_from_current_fee: {
      ru: 'Комиссия за перевод с брокерского счета',
      en: 'Fee',
      kz: 'Брокерлік шоттан аударым үшін комиссия',
    },
    transfer_to_current_fee: {
      ru: 'Комиссия за перевод на брокерский счет',
      en: 'Fee',
      kz: 'Брокерлік шотқа аударғаны үшін комиссия',
    },
    withdrawal_funds: {
      ru: 'Снятие с брокерского счета',
      en: 'Withdrawal of funds',
      kz: 'Брокерлік шоттан алу',
    },
    from_2: { ru: 'Откуда', en: 'From', kz: 'Қайдан шыққан' },
    to_2: { ru: 'Куда', en: 'To', kz: 'Қайда' },
    fee: { ru: 'Комиссия', en: 'Fee', kz: 'Комиссия' },
    order_date_time: {
      ru: 'Дата и время поручения',
      en: 'Date and time',
      kz: 'Тапсырманың күні мен уақыты',
    },
    order_status: { ru: 'Статус поручения', en: 'Order status', kz: 'Тапсырманың мәртебесі' },
    cancel_order: { ru: 'Отменить поручение', en: 'Cancel order', kz: 'Тапсырманы болдырмау' },
    transfer_created: { ru: 'Перевод создан', en: 'Transfer created', kz: 'Аударма жасалды' },
    transfer_between: {
      ru: 'Перевод между счетами',
      en: 'Transfer between accounts',
      kz: 'Шоттар арасындағы аударым',
    },
    transfer_between1: {
      ru: 'Вывод с Брокерского счета',
      en: 'Withdrawal from a Brokerage account',
      kz: 'Брокерлік шоттан алу',
    },
    enter_amount: {
      ru: 'Введите количество',
      en: 'Enter amount',
      kz: 'Санды енгізіңіз',
    },
    transfer_amount: {
      ru: 'Сумма (USD)',
      en: 'Amount (USD)',
      kz: 'Сомасы (USD)',
    },
    transfer_2: { ru: 'Перевести', en: 'Transfer', kz: 'Аудару' },
    enter_code: { ru: 'Введите код...', en: 'Enter code...', kz: 'Кодты енгізіңіз...' },
    enter_code_2: {
      ru: 'Введите 6-значный код, который мы отправили на номер',
      en: 'Enter the 6-digit code that we sent to the number',
      kz: 'Нөмірге жіберген 6 таңбалы кодты енгізіңіз',
    },
    enter_code_3: {
      ru: 'Отправка кода может занять до одной минуты.',
      en: 'It may take up to one minute for the code to be sent',
      kz: 'Кодты жіберу бір минутқа дейін созылуы мүмкін.',
    },
    resend_in: {
      ru: 'Отправить повторно через',
      en: 'Resend in',
      kz: 'Қайта жіберу',
    },
    seconds: { ru: 'секунд', en: 'seconds', kz: 'секунд' }, //продолжение предыдущей строки
    resend: { ru: 'Отправить повторно', en: 'Resend', kz: 'Қайта жіберу' },
    replenishment_completed: {
      ru: 'Пополнение произведено',
      en: 'Replenishment completed',
      kz: 'Толтыру жүргізілді',
    },
    replenish_broker: {
      ru: 'Пополнить Брокерский счет',
      en: 'Replenish Broker Account',
      kz: 'Брокерлік шотты толтыру',
    },
    replenish: { ru: 'Пополнить', en: 'Replenish', kz: 'Толықтыру' },
    select_date_range: {
      ru: 'Выберите диапазон дат!',
      en: 'Select a date range!',
      kz: 'Күндер ауқымын таңдаңыз!',
    },
    get_statement: {
      ru: 'Получить выписку',
      en: 'Get a statement',
      kz: 'Үзінді алу',
    },
    in_kzt: { ru: 'В тенге', en: 'In KZT', kz: 'Тенгеде' },
    in_usd: { ru: 'В долларах', en: 'In USD', kz: 'Долларда' },
    demo: { ru: 'Демо', en: 'Demo', kz: 'Демо' },
    replenishment_instructions: {
      ru: 'Инструкция для пополнения',
      en: 'Instructions for replenishment',
      kz: 'Толықтыруға арналған нұсқаулық',
    },
    replenishment_instructions_in: {
      ru: 'Инструкция для пополнения в',
      en: 'Instructions for replenishment in',
      kz: 'Толтыруға арналған нұсқаулық',
    },
    requisites: { ru: 'Реквизиты', en: 'Requisites', kz: 'Деректемелер' },
    iban: { ru: 'IBAN/Номер счёта', en: 'IBAN/Account number', kz: 'IBAN/Шот нөмірі' },
    recipient: { ru: 'Получатель', en: 'Recipient', kz: 'Алушы' },
    bin: { ru: 'БИН', en: 'BIN', kz: 'БИН' },
    beneficiary_bank: { ru: 'Банк бенефициар', en: "Beneficiary's Bank", kz: 'Бенефициар банк' },
    knp: { ru: 'КНП', en: 'PPC', kz: 'КНП' },
    cbe: { ru: 'Кбе', en: 'CBe', kz: 'Кбе' },
    payment_purpose: {
      ru: 'Назначение платежа',
      en: 'Purpose of payment',
      kz: 'Төлемді тағайындау',
    },
    share_requisites: {
      ru: 'Поделиться реквизитами',
      en: 'Share requisites',
      kz: 'Деректемелерді ортақ пайдалану',
    },
    bcc: { ru: 'Банк Центр Кредит', en: 'Bank Center Credit', kz: 'Банк Центр Кредит' },
    other_banks: { ru: 'Другие банки', en: 'Other banks', kz: 'Басқа банктер' },
    other_banks_2: { ru: 'других банках', en: 'other banks', kz: 'басқа банктерде' },
    bic: {
      ru: 'БИК Банка бенефициара (SWIFT)',
      en: "BIC of beneficiary's bank (SWIFT)",
      kz: 'Бенефициар банктің БСК (SWIFT)',
    },
    choose_where: {
      ru: 'Выберите куда',
      en: 'Choose where to',
      kz: 'Қайда таңдаңыз',
    },
    no_results: {
      ru: 'По вашим критериям ничего не найдено',
      en: 'No results matching your criteria',
      kz: 'Сіздің өлшемдеріңіз бойынша ештеңе табылмады',
    },
    bank_acc: { ru: 'Банковский счет', en: 'Bank Account', kz: 'Банк шоты' },
    order_withdrawal: {
      ru: 'Поручение на вывод средств',
      en: 'Order for a withdrawal',
      kz: 'Қаражатты шығаруға тапсырма',
    },
    iin: { ru: 'ИИН', en: 'IIN', kz: 'ЖСН' },
    name: { ru: 'Имя', en: 'Name', kz: 'Аты' },
    address: { ru: 'Адрес', en: 'Address', kz: 'Мекен-жай' },
    fee_warning: {
      ru: 'Комиссия за перевод согласно тарифу банка получателя (Geeko Invest не взимает комиссию за вывод денежных средств с брокерского счета)',
      en: 'Transfer fee according to the tariff of the recipient bank (Geeko Invest does not charge a fee for withdrawing funds from a brokerage account)',
      kz: 'Алушы банкінің тарифіне сәйкес аударым үшін комиссия (Geeko Invest брокерлік шоттан ақша қаражатын шығарғаны үшін комиссия алмайды)',
    },
    open_halyk: {
      ru: 'Откройте приложение Halyk',
      en: 'Open the Halyk app',
      kz: 'Halyk бағдарламасын ашу',
    },
    go_to_transfers: {
      ru: 'Зайдите в раздел «Переводы»',
      en: 'Go to the «Transfers» section',
      kz: 'Аударымдар бөліміне кіріңіз',
    },
    select_all_transfers: {
      ru: 'Выберите «Все переводы»',
      en: 'Select «All Transfers»',
      kz: 'Барлық аударымдарды таңдаңыз',
    },
    in_section_other: {
      ru: 'В разделе «Другие»',
      en: 'In section «Other»',
      kz: '«Басқалар» бөлімінде',
    },
    select_to_acc: {
      ru: 'Выберите «На номер счета»',
      en: 'Select «To an account number»',
      kz: '«Шот нөміріне» таңдаңыз',
    },
    enter_data: {
      ru: 'Введите данные',
      en: 'Enter data',
      kz: 'Деректерді енгізіңіз',
    },
    copy: { ru: 'Скопируйте', en: 'Copy', kz: 'Көшірмелеу' },
    copy_recipient_num: {
      ru: 'Скопируйте номер счета получателя',
      en: "Copy the recipient's account number",
      kz: 'Алушының шот нөмірін көшіріңіз',
    },
    copy_recipient_bin: {
      ru: 'Скопируйте БИН получателя',
      en: "Copy the recipient's BIN",
      kz: 'Алушының БСН-ін көшіріңіз',
    },
    select_card: {
      ru: 'Выберите карту для пополнения брокерского счета',
      en: 'Select a card to replenish your brokerage account',
      kz: 'Брокерлік шотты толтыру үшін картаны таңдаңыз',
    },
    enter_transfer_amount: {
      ru: 'Введите сумму перевода',
      en: 'Enter the transfer amount',
      kz: 'Аударым сомасын енгізіңіз',
    },
    transfer_completed: {
      ru: 'Ваш перевод успешно совершен, ожидайте пополнение вашего брокерского счета в течении 3-х рабочих дней',
      en: 'Your transfer has been successfully completed, expect your brokerage account to be replenished within 3 business days',
      kz: 'Сіздің аударымыңыз сәтті жасалды, 3 жұмыс күні ішінде брокерлік шотыңызды толықтыруды күтіңіз',
    },
    open_bcc: {
      ru: 'Откройте приложение BCC.KZ',
      en: 'Open the BCC.KZ app',
      kz: 'BCC.KZ бағдарламасын ашыңыз',
    },
    select_international_transfers: {
      ru: 'Выберите «Международные переводы»',
      en: 'Select «International transfers»',
      kz: '«Халықаралық аударымдар» таңдаңыз',
    },
    in_international_transfers: {
      ru: 'В разделе Международные переводы',
      en: 'In the «International transfers» section',
      kz: 'Халықаралық аударымдар бөлімінде',
    },
    select_swift: {
      ru: 'Выберите «Swift»',
      en: 'Select «Swift»',
      kz: '«Swift» таңдаңыз',
    },
    in_swift: {
      ru: 'В разделе «Swift»',
      en: 'In the «Swift» section',
      kz: '«Swift» бөлімінде',
    },
    select_recipients_country: {
      ru: 'Выберите страну проживания получателя',
      en: "Select the recipient's country of residence",
      kz: 'Алушының тұратын елін таңдаңыз',
    },
    kazakhstan: { ru: 'Казахстан', en: 'Kazakhstan', kz: 'Қазақстан' },
    swift: { ru: 'SWIFT-код/БИК', en: 'SWIFT-code/BIC', kz: 'SWIFT коды/БСК' },
    select_intermediary_bank: {
      ru: 'Выберите банк-посредник',
      en: 'Select an intermediary bank',
      kz: 'Делдал банкті таңдаңыз',
    },
    without_intermediary_bank: {
      ru: 'Без банка-посредника',
      en: 'Without intermediary bank',
      kz: 'Делдал банкісіз',
    },
    copy_transfer_details: {
      ru: 'Скопируйте детали перевода',
      en: 'Copy transfer details',
      kz: 'Аударма деректерін көшіріңіз',
    },
    transfer_details: {
      ru: 'Детали перевода',
      en: 'Transfer details',
      kz: 'Аударма деректері',
    },
    copy_transfers_beneficiary: {
      ru: 'Скопируйте получателя певевода',
      en: "Copy transfer's beneficiary",
      kz: 'Әнші алушыны көшіріңіз',
    },
    transfers_beneficiary: {
      ru: 'Получатель перевода',
      en: "Transfer's beneficiary",
      kz: 'Аударма алушы',
    },
    copy_recipients_city: {
      ru: 'Скопируйте город проживания получателя',
      en: "Copy recipient's city of residence",
      kz: 'Алушының тұратын қаласын көшіріңіз',
    },
    recipients_city: {
      ru: 'Город проживания получателя',
      en: "Recipient's city of residence",
      kz: 'Алушының тұратын қаласы',
    },
    click_continue: {
      ru: 'Нажмите кнопку «Продолжить»',
      en: 'Click the «Continue» button',
      kz: '«Жалғастыру» түймешігін басыңыз',
    },
    open_bank_app: {
      ru: 'Войдите в мобильное приложение банка',
      en: "Log in to your bank's mobile app",
      kz: 'Банктің мобильді қосымшасына кіру',
    },
    open_bank_app_2: {
      ru: 'Откройте мобильное приложение вашего банка и войдите в свой аккаунт.',
      en: "Open your bank's mobile app and log into your account.",
      kz: 'Банкіңіздің мобильді қосымшасын ашып, тіркелгіңізге кіріңіз.',
    },
    select_transfers_payments: {
      ru: 'Выберите опцию «Переводы» или «Платежи»',
      en: 'Select the option «Transfers» or «Payments»',
      kz: '«Аударымдар» немесе «Төлемдер» параметрін таңдаңыз',
    },
    select_transfers_payments_2: {
      ru: 'Обычно переводы могут находиться в разделе «Переводы», «Платежи» или чего-то подобного. Выберите этот раздел.',
      en: 'Typically, transfers can be found in the «Transfers», «Payments» or somewhere similar. Select this section.',
      kz: 'Әдетте аударымдар «Аударымдар», «Төлемдер» немесе сол сияқты бөлімде болуы мүмкін. Осы бөлімді таңдаңыз.',
    },
    select_international_swift: {
      ru: 'Выберите «Международный перевод» или «SWIFT-перевод»',
      en: 'Select «International transfer» or «SWIFT transfer»',
      kz: '«Халықаралық аударма» немесе «SWIFT-аударма» дегенді таңдаңыз',
    },
    select_international_swift_2: {
      ru: 'Найдите опцию для международных переводов или SWIFT-переводов. Возможно, ее название будет зависеть от банка.',
      en: 'Find the option for international transfers or SWIFT transfers. Perhaps its name will depend on the bank.',
      kz: 'Халықаралық аудармалар немесе SWIFT аудармалар үшін опцияны табыңыз. Бәлкім, оның атауы банкке байланысты болар.',
    },
    select_acc_recipient: {
      ru: 'Выберите счет и получателя',
      en: 'Select account and recipient',
      kz: 'Шот пен алушыны таңдаңыз',
    },
    select_acc_recipient_2: {
      ru: 'Укажите счет, с которого вы хотите осуществить перевод, и выберите получателя. Реквизиты получателя можете скопировать ниже.',
      en: "Specify the account from which you want to transfer and select the recipient. You can copy the recipient's details below.",
      kz: 'Аударым жасағыңыз келетін шотты көрсетіңіз және алушыны таңдаңыз. Алушының деректемелерін төменде көшіре аласыз.',
    },
    enter_transfer_details: {
      ru: 'Введите сведения о переводе',
      en: 'Enter transfer details',
      kz: 'Аударма мәліметтерін енгізіңіз',
    },
    enter_transfer_details_2: {
      ru: 'Введите сумму перевода, валюта - доллары США.',
      en: 'Enter the transfer amount, currency - US dollars.',
      kz: 'Аударым сомасын, валюта - АҚШ долларын енгізіңіз.',
    },
    confirm_transfer: {
      ru: 'Подтвердите перевод',
      en: 'Confirm the transfer',
      kz: 'Аударманы растаңыз',
    },
    confirm_transfer_2: {
      ru: 'Пересмотрите введенные данные и, если все верно, подтвердите перевод. Возможно, вам также потребуется ввести код подтверждения, отправленный вам банком.',
      en: 'Review the entered data and, if everything is correct, confirm the transfer. You may also need to enter the confirmation code sent to you by your bank.',
      kz: 'Енгізілген деректерді қайта қараңыз және егер бәрі дұрыс болса, аударманы растаңыз. Сізге банк жіберген растау кодын енгізу қажет болуы мүмкін.',
    },
    consider_fees: {
      ru: 'Учтите комиссии и курсы обмена',
      en: 'Consider fees and exchange rates',
      kz: 'Комиссиялар мен айырбас бағамдарын ескеріңіз',
    },
    consider_fees_2: {
      ru: 'Обратите внимание на комиссии и курсы обмена валют, применяемые вашим банком. Уточните эти детали перед подтверждением перевода.',
      en: 'Pay attention to the fees and exchange rates applied by your bank. Check these details before confirming the transfer.',
      kz: 'Сіздің банк қолданатын комиссиялар мен валюта айырбастау бағамдарына назар аударыңыз. Аударманы растау алдында осы егжей-тегжейлерді нақтылаңыз.',
    },
    swift_warning: {
      ru: 'Помните, что процесс SWIFT-перевода может отличаться в зависимости от вашего банка, поэтому рекомендуется уточнить в вашем банке. В вашем банке может не быть онлайн SWIFT-перевода',
      en: 'Remember that the SWIFT transfer process may vary depending on your bank, so it is recommended to check with your bank. Your bank may not have an online SWIFT transfer',
      kz: 'SWIFT-аударым процесі сіздің банкіңізге байланысты өзгеше болуы мүмкін екенін есте сақтаңыз, сондықтан сіздің банкіңізде нақтылау ұсынылады. Сіздің банкіңізде онлайн SWIFT аударымы болмауы мүмкін',
    },
    favorable_fees: {
      ru: 'Выгодные комиссии',
      en: 'Favorable fees',
      kz: 'Тиімді комиссиялар',
    },
    open_smartbank: {
      ru: 'Откройте приложение SmartBank',
      en: 'Open the SmartBank app',
      kz: 'SmartBank бағдарламасын ашыңыз',
    },
    full_name: { ru: 'ФИО', en: 'Full name', kz: 'Толық аты' },
    copy_address: {
      ru: 'Скопируйте адрес',
      en: 'Copy address',
      kz: 'Мекенжайды көшіріңіз',
    },
    select_country: {
      ru: 'Выберите страну',
      en: 'Select country',
      kz: 'Елді таңдаңыз',
    },
    select_bank_recipient: {
      ru: 'Выберите банк получателя',
      en: 'Select bank of recipient',
      kz: 'Алушының банкін таңдаңыз',
    },
    select_acc_to_transfer: {
      ru: 'Выберите счет для перевода на брокерский счет',
      en: 'Select an account to transfer to a brokerage account',
      kz: 'Брокерлік шотқа аудару үшін шотты таңдаңыз',
    },
    select: {
      ru: 'Выберите',
      en: 'Select',
      kz: 'Таңдау',
    },
    replenishment_err: {
      ru: 'Пополнение недоступно: Счет не открыт',
      en: 'Replenishment is not available: Account is not open',
      kz: 'Толықтыру қол жетімді емес: Шот ашылмаған',
    },
  },
};

export default ({ str, section }) => {
  if (!section) section = 'default';
  if (!translation[section]) {
    console.error('Нет указаной секции в базе переводов', { str, section });
    return '';
  }
  if (!translation[section][str]) {
    console.error('Нет перевода для указаного ключа', { str, section });
    return '';
  }
  return translation[section][str][AppStore.lang];
};
