import { Form, Input } from "antd";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCodeInput from "react-code-input";
import { useNavigate } from "react-router-dom";
import { Button } from "../../UI";
import { apiQuery, apiQueryError } from "../../Utils";
import left_icon from "../../assets/images/arrowmenu.svg";
import { BackButton } from "../../blocks";
import urlStore from "../../stores/urlStore";
import AlertStore from "../Alert/AlertStore";
import userStore from "../Auth/userStore";
import HeaderTitle from "../Header/HeaderTitle";
import Lang from "../Lang";
import styles from "./PersonalInformation.module.scss";

const propsReactCodeInput = {
  inputStyle: {
    margin: "6px 3% 6px 0",
    width: "40px",
    fontSize: "22px",
    height: 40,
    backgroundColor: "#EAEAEA",
    border: 0,
    borderRadius: "4px",
    textAlign: "center",
    color: "#252525",
  },
};

const Resend = () => {
  const [sec, setSec] = useState(0);
  const [btnVisible, setBtnVisible] = useState(false);

  const onLoad = useCallback((s) => {
    setSec(s);
    apiQuery("/private/v1/email_captcha", {
      method: 'POST',
      body: {
        mode: 'secretWord'
      }
    })
      .then(() => {
        AlertStore.success(Lang({str: "email_sent", section: "menu"}));
      })
      .catch((error) => {
        apiQueryError({error});
      });
  }, [])

  useEffect(() => {
    const time = setInterval(() => {
      setSec((v) => {
        if (v === 0) return 0;
        if (v === 1) {
          setBtnVisible(true);
          return v - 1;
        }
        setBtnVisible(false);
        return v - 1;
      });
    }, 1000);

    return () => {
      clearInterval(time);
    };
  }, []);

  useEffect(() => {
    onLoad(30);
  }, [onLoad])

  return (
    <>
      {!btnVisible && (
        <p style={{color: "#A8A9AD"}}>
          {Lang({ str: "resend_in", section: "menu" })} {sec} {Lang({ str: "seconds", section: "menu" })}
        </p>
      )}
      {btnVisible && (
        <p>
              <span
                style={{color: "#A8A9AD", cursor: 'pointer'}}
                onClick={() => onLoad(30)}
              >
                {Lang({ str: "resend", section: "menu" })}
              </span>
        </p>
      )}
    </>
  );
};

const VerifyEmail = observer(({values}) => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");

  const onFinish = () => {
    if (code.length !== 6) return AlertStore.error(Lang({ str: "enter_code", section: "menu" }));

    values.code = code;

    apiQuery("/private/v1/updateSecretWord", {
      method: "POST",
      body: values,
    })
      .then((data) => {
        if (data?.error) return AlertStore.error(data.error.msg);
        AlertStore.orderSuccess(Lang({ str: "code_word_changed", section: "menu" }));
        userStore.authCheck().catch();
        navigate("/user/security");
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };
  const onChange = (v) => {
    setCode(v);
  };

  return (
    <div>
      <HeaderTitle
        title={Lang({str: "enter_conf_code", section: "menu"})}
        button1={<img src={left_icon} alt={""}/>}
      />
      <div className={styles.security_wrp + " mt-32"}>
        <div className={styles.width_vv}>
          <p className="pb-16">
            {Lang({str: "enter_code_email", section: "menu"})} {userStore.userData?.email}
          </p>
        </div>
      </div>

      <Form name="normal_login" className="login-form" onFinish={onFinish}>
        <div
          style={{
            display: "block",
            margin: "20px auto",
            width: "100%",
            textAlign: "center"
          }}
        >
          <ReactCodeInput
            type="number"
            fields={6}
            inputMode="numeric" 
            name="code"
            value={code}
            onChange={onChange}
            autoComplete={"one-time-code"}
            style={{width: 295}}
            {...propsReactCodeInput}
          />
        </div>

        <Form.Item>
          <Resend/>
        </Form.Item>

        <Form.Item>
          <Button className={styles.button_save} htmlType="submit">{Lang({str: "send", section: "default"})}</Button>
        </Form.Item>
      </Form>
    </div>
  );
});

const ChangeCodeword = ({visible}) => {
  const [values, setValues] = useState({});
  const [stage, setStage] = useState(1);
  const [form] = Form.useForm();
  const initCode = useRef();
  const [buttonActive, setButtonActive] = useState(false);

  const getTraderData = useCallback(() => {
    apiQuery("/private/v1/trader/data")
      .then((data) => {
        form.setFieldValue(
          "secretWord",
          data?.data?.questionnaire?.purpose?.secretWord
        );
        initCode.current = data?.data?.questionnaire?.purpose?.secretWord;
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  }, [form]);

  const handleInputChange = (e) => {
    if (e.target.value.length === 0 || initCode.current === e.target.value) {
      setButtonActive(false);
    } else {
      setButtonActive(true);
    }
  };

  const onFinish = (v) => {
    setValues(v)
    setStage(2)
  }

  useEffect(() => {
    getTraderData();
  }, [getTraderData]);

  useEffect(() => {
    if (!visible) {
      setValues({})
      setStage(1)
    }
    urlStore.setSection("");
    window.scroll(0, 0);
  }, [visible]);

  return (
    <div className={styles.personal_information_wrp}>
      <BackButton />

      {stage === 1 && <>
        <div className={styles.security_wrp}>
          <p className={styles.titile}>{Lang({str: "enter_code_word", section: "menu"})}</p>
          <div className={styles.width_vv}>
            <p className="pb-16">{Lang({str: "required_verify", section: "menu"})}</p>
          </div>
        </div>
        <div className={styles.personal_information}>
          <Form form={form} onFinish={onFinish} className={styles.personal_pas} layout="vertical">
            <Form.Item
              name="secretWord"
              rules={[{required: true, message: `${Lang({str: "enter_code", section: "menu"})}!`}]}
            >
              <Input onChange={handleInputChange} placeholder={Lang({str: "enter_code", section: "menu"})}/>
            </Form.Item>

            <Form.Item
              className={styles.buttonBlockFixed + " " + styles.margin_bottom24}
            >
              <Button htmlType="submit" disabled={!buttonActive}>{Lang({str: "change_code", section: "menu"})}</Button>
              {/*<ModalSMSVerify*/}
              {/*  button={<Button disabled={!buttonActive}>{Lang({str: "change_code", section: "menu"})}</Button>}*/}
              {/*  onChange={(v) => setCode(v)}*/}
              {/*  onClick={onFinish}*/}
              {/*  verify={() => {*/}
              {/*    let result = form.getFieldValue("secretWord");*/}
              {/*    result = result && result.length > 0;*/}
              {/*    if (!result) AlertStore.error(Lang({str: "enter_code", section: "menu"}));*/}
              {/*    return result;*/}
              {/*  }}*/}
              {/*/>*/}
            </Form.Item>
          </Form>
        </div>
      </>}

      {stage === 2 && <VerifyEmail values={values}/>}

    </div>
  );
};

export default ChangeCodeword;
