import { configure, makeAutoObservable } from "mobx";
import { apiQuery, apiQueryError } from "../../Utils";
import PortfolioStore from "./PortfolioStore";

configure({ enforceActions: "never" });

class CollectionModalOpenStore {
  isModalVisible = false;
  id = null;

  constructor() {
    makeAutoObservable(this);
  }

  open(id) {
    this.id = id;
    this.isModalVisible = true;
    PortfolioStore.setCollectionMyEdit(false);
  }

  close() {
    this.id = null;
    this.isModalVisible = false;
  }
}

export default new CollectionModalOpenStore();
