import { Alert } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { Button, IconButton } from '../../UI';
import { $class } from '../../Utils';
import { SearchInput } from '../../blocks';
import websocket from '../../websocket';
import AppStore from '../App/AppStore';
import userStore from '../Auth/userStore';
import DrawerMenuStore from '../DrawerMenu/DrawerMenuStore';
import Lang from '../Lang';
import Logo from '../Logo';
import SearchScreen from '../SearchScreen';
import TradingStore from '../Trading/TradingStore';
import styles from './Header.module.scss';

const HeaderComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  /*  React.useEffect(() => {
    AppStore.setNotificvisible(false);
    if (
      ["/trading", "/wallet", "/", "/accounts", "/favorites"].includes(
        window.location.pathname
      )
    ) {
      AppStore.setNotificvisible(true);
    }
  }, [location]);*/

  const [{ shadow }, springApi] = useSpring(() => ({
    shadow: 0,
  }));

  useEffect(() => {
    console.log('userStore.userData: ', userStore.userData._id);
    if (window.navigator.userAgent === 'GeekoTech') {
      try {
        window.ReactNativeWebView.postMessage(
          'UserID ' + (userStore?.userData?._id ? userStore?.userData?._id : '')
        );
      } catch (e) {
        console.error({ e });
      }
    }
    if (window.navigator.userAgent === 'GeekoIOS') {
      try {
        window.webkit.messageHandlers.headerInfo.postMessage(
          'UserID ' + (userStore?.userData?._id ? userStore?.userData?._id : '')
        );
      } catch (e) {
        console.error({ e });
      }
    }
    let eventId = null;

    // eventId = websocket.subscribe(
    //   { event: "walletBalanceChange", key: userStore.userData._id },
    //   (data) => {
    //     console.log("websocket data: ", data);
    //     getTrading();
    //   }
    // );

    const scrollHandler = (e) => {
      if (e.target.scrollTop < 30) springApi.start({ shadow: 0 });
      else springApi.start({ shadow: 0.06 });
    };

    document.querySelector('.basic-routes')?.addEventListener('scroll', scrollHandler);

    return () => {
      websocket.unSubscribe(eventId);
      document.querySelector('.basic-routes')?.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  // инбоксы
  const getCountNotReadInboxes = useCallback(() => {
    console.log('getCountNotReadInboxes');

    DrawerMenuStore.getCountNotReadInboxes();
  }, []);

  useEffect(() => {
    getCountNotReadInboxes();
  }, [getCountNotReadInboxes]);
  useEffect(() => {
    // console.log("userStore.userData: ", userStore.userData._id);

    if (!userStore.userData._id) return;

    let eventId = websocket.subscribe({ event: 'newInbox', key: userStore.userData._id }, (data) => {
      console.log('websocket data: ', data);
      getCountNotReadInboxes();
    });

    return () => {
      websocket.unSubscribe(eventId);
    };
  }, [userStore.userData._id]);

  // Search
  const [search, setSearch] = useState('');
  const open = TradingStore.isSearchOpen;
  // const { logoWidth, width, btnsWidth } = useSpring({
  //   logoWidth: open ? 0 : 130,
  //   width: open ? "100%" : "70%",
  //   btnsWidth: open ? 60 : 40,
  // });

  return (
    <>
      {userStore.auth && userStore?.userData?.profileData?.documentReset && (
        <Alert
          message="Необходимо обновить документы"
          type="warning"
          style={{ margin: '15px 0', textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            navigate('/sumsub_doc_update');
          }}
        />
      )}
      <animated.div
        className={$class(
          styles.fix_head,
          'header',
          [styles.fix_head_active, open],
          [styles.fix_head_hidden, !AppStore.pagesWithHeader.has(location.pathname)]
        )}
        style={{
          boxShadow: shadow.to((x) => `0px 2px 2px 0px rgba(0, 0, 0, ${x})`),
        }}>
        <div style={{ height: 48, background: open && '#FFFFFF' }} className={styles.header_block}>
          {/* <button className={styles.back_bl} onClick={() => navigate(-1)}>
              {props.button1}
            </button>*/}
          <div className={styles.header_logo} style={{ width: open ? 0 : 130 }}>
            <Logo />
          </div>

          <div className={styles.header_search_wrap}>
            <div
              style={{ width: open ? '100%' : '70%' }}
              className={styles.header_search}
              onClick={() => TradingStore.openSearch()}>
              {/* <input
                  style={{ backgroundPosition: open && !!search && '150%' }}
                  placeholder="Поиск"
                  value={search}
                  onChange={(e) => setSearch(e.target.value.toUpperCase())}
                /> */}
              <SearchInput
                style={{ pointerEvents: !open && 'none' }}
                isAccent={open}
                hideIcon={open && !!search}
                placeholder={Lang({ str: 'search', section: 'default' })}
                value={search}
                onChange={(e) => setSearch(e.target.value.toUpperCase())}
              />
            </div>
            <IconButton
              onClick={() => setSearch('')}
              style={{
                opacity: open && !!search ? 1 : 0,
                pointerEvents: open && !!search ? 'all' : 'none',
              }}
              className={styles.header_search_clear}
              variant="clear"
              icon="circle-cross1"
            />
          </div>
          <div className={styles.header_btns} style={{ width: open ? 60 : 40 }}>
            <Button
              className={styles.header_search_close}
              style={{ opacity: +open, pointerEvents: open ? 'all' : 'none' }}
              variant="text"
              onClick={() => {
                TradingStore.closeSearch();
                setSearch('');
              }}>
              {Lang({ str: 'close', section: 'default' })}
            </Button>

            <div
              className={styles.button_menu}
              style={{ opacity: +!open, pointerEvents: !open ? 'all' : 'none' }}>
              <IconButton
                icon="profile"
                onClick={() => {
                  if (open) return;

                  if (!userStore.auth) {
                    navigate('/login');
                    return;
                  }

                  navigate('/user', { state: location });
                }}
                iconSize={16}
              />
              {DrawerMenuStore.inboxes > 0 ? (
                <b>{DrawerMenuStore.inboxes > 99 ? '99+' : DrawerMenuStore.inboxes}</b>
              ) : (
                ''
              )}
            </div>
            {/* <button
                className={styles.button_menu}
                style={{ opacity: +!open, pointerEvents: !open ? 'all' : 'none' }}
                onClick={() => {
                  if (open) return;
  
                  if (!userStore.auth) {
                    history('/login');
                    return;
                  }
                  DrawerMenuStore.setOpenRight(true);
                }}>
                <img src={authimg} alt={''} />
  
              </button> */}
          </div>
        </div>
        <SearchScreen search={search} topOffset={48} />
      </animated.div>
    </>
  );
};

export default observer(HeaderComponent);
