import { configure, makeAutoObservable } from "mobx";
import { apiRequest } from "../../Utils";
import Lang from "../Lang";
import TickerStore from "../Ticker/TickerStore";

configure({ enforceActions: "never" });

class WalletStore {
  isTrader = false;
  cash = null;
  market_value = null;
  today_percent = null;
  today_dif = "0.00";

  orders = null;
  ordersTotalCount = 0;
  ordersPage = 1;
  moreOrders = true;
  orderFilter = false;

  my_papers = null;
  activities = null;
  activitiesHistory = null;
  activitiesFilterLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  getOrders(
    orderFilterPeriodEnd,
    orderFilterPeriodStart,
    orderFilterStatusCanceled,
    orderFilterStatusComplete,
    orderFilterStatusWait,
    orderFilterSumEnd,
    orderFilterSumStart,
    filterPapers = [],
    page = 1
  ) {
    if (page < 1) page = 1;
    let url = `/private/v1/get-orders/${page}`;
    let query = "";
    if (filterPapers.length > 0) {
      if (query) {
        query += `&`;
      }
      query += `tickers=${filterPapers}`;
    }

    if (orderFilterPeriodStart) {
      if (query) {
        query += `&`;
      }
      query += `periodStart=${orderFilterPeriodStart?._d?.toISOString()}`;
    }
    if (orderFilterPeriodEnd) {
      if (query) {
        query += `&`;
      }
      query += `periodEnd=${orderFilterPeriodEnd?._d?.toISOString()}`;
    }
    if (orderFilterStatusComplete) {
      if (query) {
        query += `&`;
      }
      query += `statusComplete=${orderFilterStatusComplete}`;
    }
    if (orderFilterStatusCanceled) {
      if (query) {
        query += `&`;
      }
      query += `statusCanceled=${orderFilterStatusCanceled}`;
    }
    if (orderFilterStatusWait) {
      if (query) {
        query += `&`;
      }
      query += `statusWait=${orderFilterStatusWait}`;
    }
    if (orderFilterSumStart) {
      if (query) {
        query += `&`;
      }
      query += `sumStart=${orderFilterSumStart}`;
    }
    if (orderFilterSumEnd) {
      if (query) {
        query += `&`;
      }
      query += `sumEnd=${orderFilterSumEnd}`;
    }
    if (query) {
      url += `?${query}`;
      // setOrderFilter(true);
      this.orderFilter = true;
    } else {
      // setOrderFilter(false);
      this.orderFilter = false;
    }
    // console.log({ url });

    apiRequest({
      url,
      method: "GET",
      success: (data) => {
        if (data?.error) return; // message.error(data?.error.msg);
        if (data?.orders === undefined) data.orders = []
        console.log("orders: ", data);
        let ar = [];
        for (const item of data?.orders) {
          const obj = {
            id: item.id,
            obj: item,
          };
          obj.ticker = TickerStore.tickerData[item?.tickerId];
          ar.push(obj);
        }

        // console.log({ ar });
        if (ar.length === 0) {
          // setMoreOrders(false);
          this.moreOrders = false;
          console.log({page, ar});
          if (page === 1) {
            // setOrders(ar);
            this.orders = ar;
            // setOrdersTotalCount(data?.ordersTotal);
            this.ordersTotalCount = data?.ordersTotal;
          }
        } else {
          if (data?.ordersTotal > this.orders?.length + ar.length) {
            // setMoreOrders(true);
            this.moreOrders = true;
          } else {
            // setMoreOrders(false);
            this.moreOrders = false;
          }
          // setOrdersTotalCount(data?.ordersTotal);
          this.ordersTotalCount = data?.ordersTotal;
          if (page === 1) {
            // setOrders(ar);
            this.orders = ar;
          } else {
            // setOrders((prev) => [...prev].concat(ar));
            this.orders = [...this.orders].concat(ar);
          }
        }
      },
    }).catch((error) => {
      console.error({ error });
    });
  }

  getTrading() {
    apiRequest({
      url: "/private/v1/account-trading",
      success: (data) => {
        if (data?.error) return; // message.error(data?.error.msg);
        // console.log({ data });
        if (
          !data ||
          !data.cash ||
          !data.long_market_value ||
          !data.short_market_value
        ) {
          // setIsTrader(false);
          this.isTrader = false;
        } else {
          const cash = data.cash;
          // setCash(parseFloat(cash.toString()).toFixed(2));
          this.cash = parseFloat(cash.toString()).toFixed(2);

          const position_market_value = data?.position_market_value;

          let market_value = Number(position_market_value);
          if (!market_value) market_value = 0;
          // setMarketValue(parseFloat(market_value.toString()).toFixed(2));
          this.market_value = parseFloat(market_value.toString()).toFixed(2);

          const last_long_market_value = data.last_long_market_value
            ? data.last_long_market_value
            : 0;

          const last_short_market_value = data.last_short_market_value
            ? data.last_short_market_value
            : 0;

          const last_market_value =
            Number(last_long_market_value) + Number(last_short_market_value);

          let percent = 0;
          if (last_market_value && last_market_value !== 0) {
            percent =
              ((Number(market_value) - Number(last_market_value)) * 100) /
              Number(last_market_value);
          }

          // setTodayDif(data?.unrealized_pl ? data?.unrealized_pl : 0);
          this.today_dif = data?.unrealized_pl ? data?.unrealized_pl : 0;

          // setTodayPercent(data?.unrealized_pl_percent ? data?.unrealized_pl_percent : 0);
          this.today_percent = data?.unrealized_pl_percent
            ? data?.unrealized_pl_percent
            : 0;

          // setIsTrader(true);
          this.isTrader = true;
        }
      },
    }).catch((error) => {
      console.error({ error });
      // setIsTrader(false);
      this.isTrader = false;
    });
  }

  getActivities(params) {
    const { filter, actionPapers, limit, isHistory } = params || {};
    const { period, sum, action } = filter || {};

    const includeBuy = action === 'buy' || action === 'all' || !action;
    const includeSell = action === 'sell' || action === 'all' || !action;

    let url = `/private/v1/get-alpaca-account-activities?`;
    let query = ``;

    if (actionPapers?.length > 0) {
      query += `&tickers=${actionPapers}`;
    }

    if (limit) {
      query += `&limit=${limit}`
    }

    if (period?.[0]) {
      query += `&periodStart=${period[0]?.$d?.toISOString()}`;
    }

    if (period?.[1]) {
      query += `&periodEnd=${period[1]?.$d?.toISOString()}`;
    }

    if (includeSell) {
      query += `&statusSell=${includeSell}`;
    }

    if (includeBuy) {
      query += `&statusBuy=${includeBuy}`;
    }

    if (sum?.[0]) {
      query += `&sumStart=${sum[0]}`;
    }

    if (sum?.[1]) {
      query += `&sumEnd=${sum[1]}`;
    }

    url += query;

    if (filter) this.activitiesFilterLoading = true;
    apiRequest({
      url,
      method: "GET",
      success: (data) => {
        console.log('activities: ', data)
        if (data?.error) return; // message.error(data?.error.msg);

        if (isHistory) this.activitiesHistory = data;
        else this.activities = data;
      },
    }).catch((error) => {
      console.error({ error });
    }).finally(() => {
      this.activitiesFilterLoading = false;
    });
  }

  getPapers(page = 0) {
    let url = `/private/v1/account-papers/`;

    apiRequest({
      url: url,
      success: (data) => {
        if (data?.error) return; // message.error(data?.error.msg);
        // console.log({ data });

        if (!data || !Array.isArray(data) || data.length === 0) {
          this.my_papers = [];
        } else {
          let ar = [];
          for (const item of data) {
            // console.log({ item });
            const percent =
              ((Number(item.current_price) - Number(item.lastday_price)) *
                100) /
              Number(item.lastday_price);
            const obj = {
              _id: item._id,
              obj: {
                _id: item._id,
                symbol: item.symbol,
                name: item.name,
                cost_basis: item.cost_basis,
                current_price: item.current_price,
                avg_entry_price: item?.avg_entry_price,
                leaves_qty: item?.leaves_qty,
                unrealized_pl: item?.unrealized_pl,
                unrealized_plpc: item?.unrealized_plpc,
                side: item?.side == "long" ? "Long" : "Short",
                name_ticker: { name: item.name, symbol: item.symbol },
                quote: {
                  close: parseFloat(item.current_price.toString()).toFixed(2),
                  changePercent: parseFloat(percent.toString()).toFixed(2),
                },
                // chartMini: item.chartMini,
                // bar: item.bar,
                market_value: parseFloat(item.market_value.toString()).toFixed(
                  2
                ),
                qty: item.qty, //parseFloat(item.qty.toString()).toFixed(2),
                // logo: item.logo,
                tickerData: {
                  tickerId: item._id,
                  type: "collectionMy",
                },
              },
            };
            ar.push(obj);
          }
          this.my_papers = ar;
        }
      },
    }).catch((error) => {
      console.error({ error });
      this.my_papers = [];
    });

    return () => {
      // for (const eventId of events) {
      //   websocket.unSubscribe(eventId);
      // }
      // events = [];
    };
  }

  formatOrderStatus(status) {
    if (status === 'canceled') return Lang({ str: "cancelled", section: "wallet" })
    if (status === 'filled') return Lang({ str: "filled", section: "wallet" })
    if (status === 'reject') return Lang({ str: "rejected", section: "wallet" })
    if (status === 'new') return Lang({ str: "new", section: "wallet" })
    if (status === 'partially_filled') return Lang({ str: "partially_filled", section: "wallet" })
    if (status === 'done_for_day') return Lang({ str: "filled_day", section: "wallet" })
    if (status === 'expired') return Lang({ str: "expired", section: "wallet" })
    if (status === 'replaced') return Lang({ str: "replaced", section: "wallet" })
    if (status === 'pending_cancel') return Lang({ str: "pending_cancel", section: "wallet" })
    if (status === 'pending_replace') return Lang({ str: "pending_replace", section: "wallet" })
    if (status === 'accepted') return Lang({ str: "accepted", section: "wallet" })



    return Lang({ str: "unknown", section: "wallet" })
  }
}

export default new WalletStore();
