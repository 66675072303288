import { configure, makeAutoObservable } from "mobx";
import { apiRequest } from "../../Utils";
import OneSignal from "react-onesignal";

configure({ enforceActions: "never" });

class userStore {
  auth = null; // null - данных еще нет (прилоадер), false|true - не авторизован | авторизован
  userData = {};
  restoreEmail = "";
  restoreCode = "";

  constructor() {
    makeAutoObservable(this);
  }

  setAuth(value) {
    this.auth = value;
  }

  setBlocking(value) {
    this.userData.blocking = value;
  }

  setUserData(value) {
    this.userData = value;
  }

  setRestoreEmail(value) {
    this.restoreEmail = value;
  }

  setRestoreCode(value) {
    this.restoreCode = value;
  }

  langSave() {
    const locale = navigator.language;
    let lang;
    if (locale === "ru" || locale === "ru-RU") {
      lang = "ru";
    } else {
      lang = "en";
    }
    apiRequest({
      url: "/private/v1/langSave",
      method: 'POST',
      body  : {
        lang: localStorage.getItem("appLang") ? localStorage.getItem("appLang") : lang
      },
      success: () => {
        this.authCheck().catch()
      }
    }).catch();
  }

  async authCheck() {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/check",
        success: (userData) => {
          this.userData = userData;
          this.auth = true;
          console.log("UserID " + this?.userData?._id ? this?.userData?._id : '')

          if (window.navigator.userAgent === "GeekoTech") {
            try {
              window.ReactNativeWebView.postMessage("UserID " + (this?.userData?._id ? this?.userData?._id : ''));
            }
            catch (e) {
              console.error({e})
            }
          }
          if (window.navigator.userAgent === "GeekoIOS") {
            try {
              window.webkit.messageHandlers.headerInfo.postMessage("UserID " + (this?.userData?._id ? this?.userData?._id : ''));
            }
            catch (e) {
              console.error({e})
            }
          }

          if (window.OneSignal && window.OneSignal.getUserId) {
            window.OneSignal.getUserId((pushId) => {

              console.log({pushId})

              this.pushSave(pushId);
            });
          }

          resolve(this.userData);
        },
        error: (error) => {
          console.error(error);
          if (error.res?.status === 401) this.auth = false;
          if (window.navigator.userAgent === "GeekoTech") {
            try{
              window.ReactNativeWebView.postMessage("UserID ");
            }
            catch (e) {
              console.error({e})
            }
          }
          if (window.navigator.userAgent === "GeekoIOS") {
            try{
              window.webkit.messageHandlers.headerInfo.postMessage("UserID ");
            }
            catch (e) {
              console.error({e})
            }
          }
          resolve(null);
        },
      }).catch();
    });
  }

  logout(redirect = null) {
    apiRequest({
      url: "/logout",
      success: () => {
        this.auth = false;
        this.userData = {};
        if (redirect) window.location.href = redirect;
      },
      error: (error) => {
        console.error(error);
        this.auth = false;
      },
    }).catch();
  }

  pushSave(pushId, isEnabled = false, msg = '') {
    console.log('pushSave: ', {pushId, isEnabled})
    if (!this.auth || !pushId) return;

    if (isEnabled !== undefined) {
      apiRequest({
        url: "/private/v1/push_save",
        method: 'POST',
        body  : {
          // userId: this?.userData?._id,
          pushId,
          status: isEnabled ? 1 : 0,
          msg,
        },
      }).catch();
      return;
    }

    // if (window.OneSignal) {
    //   OneSignal.isPushNotificationsEnabled().then((isEnabled) => {
    //     apiRequest({
    //       url: "/private/v1/push_save",
    //       method: 'POST',
    //       body  : {
    //         pushId,
    //         status: isEnabled ? 1 : 0,
    //       },
    //     }).catch();
    //   });
    // }

    window.OneSignal.isPushNotificationsEnabled((isEnabled) => {

      apiRequest({
        url: "/private/v1/push_save",
        method: 'POST',
        body  : {
          pushId,
          status: isEnabled ? 1 : 0
        }
      })
          .then((userData) => {
          })
          .catch((error) => {
            console.error(error)
          })

    });
  }

  async checkPush(pushId){
    if (!this.auth || !pushId) {
      return;
    }

    const result = apiRequest({
      url: "/private/v1/check_push/" + pushId,
      method: 'GET',
      success: (data) => {
        console.log('check_push', { data });
        if(data?.status !== 'ok'){
          return false
        }

        if (window.navigator.userAgent === "GeekoTech") {
          if(data?.status == 'ok'){
            window.ReactNativeWebView.postMessage("checkPush " + data?.is_subscribed);
          }
          else{
            window.ReactNativeWebView.postMessage("checkPush error status");
          }
        }

        return data
      },
      error: (e) => {
        console.error({e})
        return false
      },
    }).catch();

    return result;
  }

}

const clsUserStore = new userStore();
export default clsUserStore;
