import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Clickable } from "../../UI";
import { $class } from "../../Utils";
import NumberFormat from "../../components/NumberFormat";
import TickerStore from "../../components/Ticker/TickerStore";
import PriceChanging from "../PriceChanging";
import PriceNumber from "../PriceNumber";
import TickerTitle from "../TickerTitle";
import "./index.scss";
import moment from "moment";
import Lang from "../../components/Lang";
import DollarSign from "../DollarSign";

const TickerOrderCard = ({ tickerId, order, onClick, style }) => {
  const ticker = TickerStore.tickerData[tickerId];

  useEffect(() => {
    TickerStore.getTicker(tickerId);
  }, [tickerId]);

  let qty = 0;
  let fullPrice = 0;
  if (order?.order_type === "market") {
    if (order?.notional) {
      fullPrice = order?.notional
        ? order?.notional
        : (order.current_price * order.qty).toFixed(2);
      if (order?.current_price) {
        let commiss = (order?.notional * order?.commission_bps) / 100 / 100;
        commiss =
          ((order?.notional - commiss) * order?.commission_bps) / 100 / 100;
        qty = order?.notional /*- commiss*/ / order?.current_price;
      }
    } else {
      qty = order?.qty;
      let sum = order.current_price * order.qty;

      fullPrice = sum.toFixed(2);
    }
  } else {
    if (order?.order_type === "limit") {
      qty = order?.qty;
      let sum = order.limit_price * order.qty;

      fullPrice = sum.toFixed(2);
    }
  }

  const removeLastZeros = (value) => {
    value = Number(value);
    return value;
  };

  return (
    <div className={$class("ticker-card", "ticker-order-card")} style={style}>
      <Clickable className="ticker-card-wrap" onClick={onClick}>
        <TickerTitle size={50} ticker={ticker} withSymbol withDesc>
          <div className={$class("ticker-paper-card-bottom")}>
            <b>
              ≈{/*  {NumberFormat({*/}
              {/*  value: parseFloat(qty?.toString()).toFixed(2),*/}
              {/*}) || 0} */}
              {removeLastZeros(parseFloat(qty?.toString()).toFixed(9))}
              {Lang({ str: "pieces", section: "default" })}
            </b>{" "}
            <i>
              (
              <DollarSign
                color={"var(--basic-dark40)"}
                size={7}
                top={2}
                right={1}
              />
              {NumberFormat({ value: fullPrice })})
            </i>
          </div>
        </TickerTitle>
        <div className="ticker-card-price">
          <p>{moment(order?.created_at).format("HH:mm DD.MM.YY")}</p>
          <p>
            {Lang({
              str: order?.side === "buy" ? "purchase" : "sale",
              section: "wallet",
            })}
          </p>
          <p>
            {Lang({
              str: order?.type === "limit" ? "limit" : "market",
              section: "wallet",
            })}
          </p>
        </div>
      </Clickable>
    </div>
  );
};

export default observer(TickerOrderCard);
