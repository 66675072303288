import React, { useEffect, useState } from 'react';
import { Button } from '../../../UI';
import { TickerCard } from '../../../blocks';
import Lang from '../../Lang';
import SearchList from '../../SearchList';
import PortfolioStore from '../PortfolioStore';

const CollectionAddTickerModal = ({ collectionId, closeModal }) => {
  const collectionData = PortfolioStore.collectionData[collectionId];

  const [listSelect, setListSelect] = useState({});
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const tickers = collectionData?.tickers || [];
    setListSelect(tickers.reduce((obj, x) => ({ ...obj, [x]: 1 }), {}));
  }, [collectionData?.tickers]);

  const onFavoriteHandler = (id) => {
    const newSelected = { ...listSelect };
    if (id in listSelect) {
      delete newSelected[id];
    } else {
      newSelected[id] = 1;
    }
    setListSelect(newSelected);
  };

  const onSaveHandler = () => {
    PortfolioStore.collectionTickersSave(collectionId, Object.keys(listSelect)).catch();
    closeModal();
  };

  return (
    <>
      <div className="p-16" style={{ overflowX: 'hidden' }}>
        <SearchList
          search={searchValue}
          onChange={setSearchValue}
          height={window.innerHeight * 0.6}
          itemHeight={72 + 10}
          content={
            <>
              {searchValue.trim() === '' && !Object.keys(listSelect).length && (
                <p style={{ textAlign: 'center', margin: 20 }}>
                  {Lang({ str: 'use_search', section: 'portfolio' })}
                </p>
              )}

              {searchValue.trim() === '' &&
                Object.keys(listSelect).map((_id) => (
                  <TickerCard
                    style={{ margin: '5px 0' }}
                    wrapStyle={{ paddingRight: 20 }}
                    onFavorite={() => onFavoriteHandler(_id)}
                    favorite={listSelect[_id]}
                    onClick={() => null}
                    key={_id}
                    tickerId={_id}
                  />
                ))}
            </>
          }
          withInput>
          {(t, key, style) => (
            <TickerCard
              style={style}
              key={key}
              tickerId={t._id}
              onFavorite={() => onFavoriteHandler(t._id)}
              favorite={listSelect[t._id]}
            />
          )}
        </SearchList>

        <div style={{ marginTop: 16 }}>
          <Button variant="secondary" onClick={onSaveHandler}>{Lang({ str: 'save', section: 'default' })}</Button>
        </div>
      </div>
    </>
  );
};

export default CollectionAddTickerModal;
