import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, IconButton } from '../../UI';
import { apiRequest, shortenNumObj } from '../../Utils';
import PriceChanging from '../../blocks/PriceChanging';
import Icon from '../Icon';
import { fields } from './financial';
import styles from './index.module.scss';
import DollarSign from '../../blocks/DollarSign';
import Lang from "../Lang";

const dict = {
  income_statement: Lang({str: "income", section: "trading"}),
  balance_sheet_statement: Lang({str: "balance_sheet", section: "trading"}),
};

const last = (arr, i = 1) => arr[arr.length - i];

const chartHeight = 20;

const TradingFinancial = () => {
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();

  const [type, setType] = useState('annual');
  let section = params.get('section');
  const symbol = params.get('symbol');

  const [analytic, setAnalytic] = useState(null);

  useEffect(() => {
    if (!symbol) return;
    getAnalytic();
  }, [symbol]);

  const getAnalytic = () => {
    apiRequest({
      url: `/private/v1/get-analytic/${symbol}`,
      method: 'GET',
      success: async (data) => {
        if (data?.error) return; // message.error(data?.error.msg);
        console.log('get-analytic: ', data);
        if (data?.status !== 'ok' || !data?.data) {
          return;
        }
        setAnalytic(data?.data);
      },
    }).catch((error) => {
      console.error({ error });
    });
  };

  const [annual, quarter] = useMemo(() => {
    if (!analytic) return [null, null];
    console.log('an', analytic);
    const annualRes = {};
    const quarterRes = {};

    analytic[`${section}_year`].slice(0, 5).forEach((x) => {
      for (const field in x) {
        if (field in annualRes) annualRes[field].unshift(x[field]);
        else annualRes[field] = [x[field]];
      }
    });

    analytic[`${section}_quarter`].forEach((x) => {
      for (const field in x) {
        if (field in quarterRes) quarterRes[field].unshift(x[field]);
        else quarterRes[field] = [x[field]];
      }
    });

    return [annualRes, quarterRes];
  }, [analytic, section]);

  return (
    <>
      <div className={styles.header}>
        <IconButton variant="clear" icon="arrow-left" onClick={() => navigate(-1)} />
        <div className={styles.title}>{dict[section]}</div>
      </div>
      <div className={styles.wrap}>
        <div className={styles.tabs}>
          <Button onClick={() => setType('annual')} variant={type !== 'annual' && 'secondary'}>
            {Lang({str: "annual", section: "trading"})}
          </Button>
          <Button onClick={() => setType('quarter')} variant={type !== 'quarter' && 'secondary'}>
            {Lang({str: "quarterly", section: "trading"})}
          </Button>
        </div>

        <div
          className={styles.screenWrap}
          style={{ transform: type === 'quarter' ? 'translateX(-100%)' : 'translateX(0%)' }}>
          <div className={styles.screen}>
            <div className={styles.screenHead}>
              {annual?.symbol && <div className={styles.item_label}>{Lang({str: "category", section: "trading"})}</div>}
              <div className={styles.item_chart}>
                {annual?.year?.map((x, i) => (
                  <p>{x}</p>
                ))}
              </div>
              <div className={styles.item_price}></div>
            </div>
            {annual && fields[section].map((x, i) => <FinancialItem data={annual} field={x} />)}
            {!annual?.symbol && (
              <div className={styles.screenEmpty}>
                <Icon slug="empty" />
                <h4>{Lang({str: "no_data", section: "trading"})}</h4>
              </div>
            )}
          </div>
          <div className={styles.screen}>
            <div className={styles.screenHead}>
              <div className={styles.item_label}>{Lang({str: "category", section: "trading"})}</div>
              <div className={styles.item_chart}>
                {quarter?.year?.map((x, i) => (
                  <p>
                    {`${x}`.slice(-2)}'{quarter.period[i].slice(1)}
                  </p>
                ))}
              </div>
              <div className={styles.item_price}></div>
            </div>
            {quarter && fields[section].map((x, i) => <FinancialItem data={quarter} field={x} />)}
          </div>
        </div>
      </div>
    </>
  );
};

const FinancialItem = ({ data, field }) => {
  const [active, setActive] = useState(null);
  const prices = data[field.field];
  if (!prices) return null;

  const { sign, number, symbol } = shortenNumObj(last(prices), 2);
  const change = (last(prices) - last(prices, 2)) / Math.abs(last(prices)) * 100;

  const max = Math.max(...prices.map((x) => Math.abs(x)));

  const isActive = active === field.field;

  return (
    <div
      className={styles.item}
      key={field.field}
      onClick={() => setActive(isActive ? null : field.field)}>
      <div className={styles.item_label} style={{ width: isActive && 0 }}>
        <div>{field.label}</div>
      </div>
      <div className={styles.item_chart} style={{ height: chartHeight }}>
        {prices.map((d, j, arr) => (
          <div
            key={j}
            style={{
              height: (Math.abs(d) / max) * chartHeight + 'px',
              transform: d < 0 && 'translateY(100%)',
              ...(isActive && {
                backgroundColor: arr[j] < arr[j - 1] ? 'var(--misk-error)' : 'var(--misk-success)',
              }),
            }}>
            <p style={{ opacity: +isActive }}>
              {shortenNumObj(d).sign}
              {shortenNumObj(d).number} {shortenNumObj(d).symbol}
            </p>
          </div>
        ))}
      </div>
      <div className={styles.item_price}>
        <div className={styles.item_price_value}>
          {sign}
          {!field?.isRatio && <DollarSign size={8} top={2} right={0} />}
          {number} {symbol}
        </div>
        <PriceChanging change={change} align="end" />
      </div>
    </div>
  );
};

export default TradingFinancial;
