import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { BackButton } from '../../blocks';
import AccountStore from './AccountStore';
import styles from './Accounts.module.scss';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { $class } from '../../Utils';
import { useFilter } from '../Filter/useFilter';
import Lang from '../Lang';
import ItemHistoryBlock from './ItemHistoryBlock';
import { useSearchParams } from 'react-router-dom';

const filterFields = (type) => [
  {
    type: 'period',
    name: 'period',
  },
  {
    type: 'checkbox',
    name: 'types',
    items: [
      {
        label: Lang({ str: 'replenishment', section: 'accounts' }),
        value: 'deposit',
      },
      {
        label: Lang({ str: 'withdrawal', section: 'accounts' }),
        value: 'withdrawal',
      },
      {
        label: Lang({ str: 'transfer', section: 'accounts' }),
        value: 'transfer',
      },
      { label: Lang({ str: 'other', section: 'accounts' }), value: 'other' },
    ],
  },
  {
    type: 'sum',
    name: 'sum',
  },
];

const AccountHistoryPage = ({ params, visible }) => {
  const { typeAccount } = params;
  const [search] = useSearchParams();
  const { filter, FilterBtn, FilterChipsWrap, openFilter } = useFilter({
    filterFields: filterFields(typeAccount),
    onUpdateRows: (filter) =>
      AccountStore.getAccountHistory({ accountId, typeAccount, filter, clear: true, offset: 0 }),
  });

  const accountId = AccountStore?.accounts[typeAccount]?._id;
  const items = AccountStore[typeAccount + 'AccountHistory'];
  const loading = AccountStore.accountHistoryLoading;

  useEffect(() => {
    if (visible && accountId)
      AccountStore.getAccountHistory({ accountId, typeAccount, clear: true, offset: 0 });

    if (visible && accountId === false) AccountStore.getAccounts();

    if (visible && search.get('openFilter')) {
      setTimeout(() => {
        openFilter();
      }, 200);
    }
  }, [visible, accountId]);

  return (
    <>
      <BackButton
        label={Lang({ str: typeAccount + '_acc', section: 'home' })}
        style={{
          position: 'sticky',
          top: 0,
          background: '#f5f6f7',
          zIndex: 10,
        }}
      />

      <div className={styles.history}>
        <div className={styles.history_top}>
          <p className={styles.history_title}>{Lang({ str: 'history_tr', section: 'accounts' })}</p>
          <FilterBtn />
        </div>

        <FilterChipsWrap />

        <div className={$class(styles.history_list, styles.tabs_account)}>
          {(items === null || loading) && visible && (
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
              <Spin
                indicator={
                  <LoadingOutlined style={{ fontSize: 24, color: 'var(--basic-dark)' }} spin />
                }
              />
            </div>
          )}

          {items.length ? (
            <InfiniteScroll
              pageStart={0}
              loadMore={async (page) => {
                console.log('Страница: ' + page);

                if (items === null || loading || !visible) return;
                AccountStore.getAccountHistory({
                  accountId,
                  typeAccount,
                  filter,
                  offset: (items.length / 10) * 10,
                });
              }}
              hasMore={AccountStore.accountHistoryHasMore}
              useWindow={false}>
                {items?.map((el, index) => {
                  return <ItemHistoryBlock key={index} data={el} />;
                })}
            </InfiniteScroll>
          ) : (
            <div className={styles.no_results}>
              <p>{Lang({ str: 'no_results', section: 'accounts' })}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default observer(AccountHistoryPage);
