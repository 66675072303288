import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Clickable } from '../../../UI';
import { PriceChanging, TickerTitle } from '../../../blocks';
import DollarSign from '../../../blocks/DollarSign';
import Lang from '../../Lang';
import NumberFormat from '../../NumberFormat';
import TickerStore from '../../Ticker/TickerStore';
import stylesW from '../Wallet.module.scss';
import ModalConfirmButton from '../../ModalManager/ModalConfirmButton';
import { apiRequest } from '../../../Utils';
import AlertStore from '../../Alert/AlertStore';
import modalStore from "../../ModalManager/modalStore";

const PaperDetailsModal = ({ details, closeModal }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const closePosition = () => {
    const symbol = TickerStore?.tickerData[details?._id]?.symbol;
    console.log("close position: ", symbol);

    apiRequest({
      url: "/private/v1/close_position",
      method: "POST",
      body: {
        symbol,
        percentage: 100, // closeAll
      },
      success: (data) => {
        console.log({ data });
        AlertStore.orderSuccess(Lang({ str: "position_closed", section: "wallet" }));
        closeModal();
      },
      error: () => {
        AlertStore.orderError(Lang({ str: "error_occurred", section: "wallet" }));
      },
    }).catch();
  };

  return (
    <>
      <div className={stylesW.assignment_modal}>
        <Clickable
          className={stylesW.assignment_modal_ticker}
          onClick={() => {
            navigate(`/trading/${details?._id}`, { state: location });
            closeModal();
          }}>
          <TickerTitle ticker={TickerStore.tickerData[details?._id]} withSymbol withDesc />
        </Clickable>
        <div className={stylesW.assignment_modalWrap}>
          <div>
            <p>{Lang({ str: 'order_type', section: 'wallet' })}</p>
            <p>
              {details?.side === 'Long'
                ? Lang({ str: 'purchase', section: 'wallet' })
                : Lang({ str: 'sale', section: 'wallet' })}
            </p>
          </div>
          <div>
            <p>{Lang({ str: 'positions_quantity', section: 'wallet' })}</p>
            {/*<p>{NumberFormat({ value: details?.qty })}</p>*/}
            <p>{details?.qty}</p>
          </div>
          <div>
            <p>{Lang({ str: 'pnl_all_time', section: 'wallet' })}</p>
            <PriceChanging
              delimiter
              pnl={details?.unrealized_pl}
              change={details?.unrealized_plpc}
            />
          </div>
          <div>
            <p>{Lang({ str: 'market_volume', section: 'wallet' })}</p>
            <div>
              <p>≈</p>
              <p>
                <DollarSign size={9} top={2} right={1} />
                {NumberFormat({
                  value: parseFloat(details?.cost_basis?.toString()).toFixed(2),
                  styleWholePart: { fontSize: 16 },
                  styleFractionalPart: { fontSize: 14 },
                })}
              </p>
            </div>
          </div>
          <div>
            <p>{Lang({ str: 'avg_entry_price', section: 'wallet' })}</p>
            <div>
              <p>≈</p>
              <p>
                <DollarSign size={9} top={2} right={1} />
                {NumberFormat({ value: details?.avg_entry_price })}
              </p>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirmButton
        onClick={closePosition}
        confirmTitle={Lang({ str: 'close_position_2', section: 'wallet' })}
        btnLabel={Lang({ str: 'close_position', section: 'wallet' })}
      />
    </>
  );
};

export default PaperDetailsModal;
