import React, { useEffect, useState } from "react";
import styles from "../BrokerAccountNew/BrokerAccountNew.module.scss";
import { Button, Form, Checkbox, message } from "antd";
import { observer } from "mobx-react-lite";
import Utils, { apiQuery, apiQueryError } from "../../Utils";
import BrokerAccountStore from "./BrokerAccountStore";
import Lang from "../Lang";
import AlertStore from "../Alert/AlertStore";
import modalStore from "../ModalManager/modalStore";

const Step8 = ({ setBack }) => {
  const docData = BrokerAccountStore.docData;
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (value) => {
    apiQuery("/private/v1/getDocUrl", {
      method: "POST",
      body: value,
    })
      .then((data) => {
        if (data?.error) return AlertStore.error(data.error.msg);
        console.log(data);
        modalStore.openModal('pdf', { url: `https://${Utils.apiDomain() + data.url}` })
        setButtonDisabled(false);
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  const onFinishForm = () => {
    apiQuery("/private/v1/signAgreement", {
      method: "POST",
      body: { mode: "alpacaCustomerAgreement" },
    })
      .then((data) => {
        if (data.error) {
          AlertStore.error(data.error.msg);
          return;
        }
        BrokerAccountStore.save({
          view: "questionnaire",
          step: 1,
        }).catch();
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  const onChange = (set, e) => {
    set(e.target.checked)
  }

  useEffect(() => {
    const scroll = window.document.getElementsByClassName("basic-routes");
    if (scroll[0]) scroll[0].scrollTo(0, 0);

    setBack({
      view: "questionnaire",
      step: 1,
    });
  }, []);

  useEffect(() => {
    setButtonDisabled(!checkbox1 || !checkbox2)
  }, [setButtonDisabled, checkbox1, checkbox2])

  useEffect(() => {
    if (docData?.alpacaCustomerAgreement?.signDate) {
      setCheckbox1(true)
      setCheckbox2(true)
    }
  }, [docData])

  return (
    <div className={styles.page_fl}>
      <div className={styles.text_bl}>
        <h4>{Lang({ str: "alpaca_agreement_2", section: "onboarding" })}</h4>
        <p className={styles.lit_text + " mb-16"}>
          {Lang({ str: "alpaca_agreement_3", section: "onboarding" })}
        </p>
        <div className={styles.group_link}>
          <span onClick={() => onFinish({ mode: "alpacaCustomerAgreement" })}>
            {Lang({ str: "alpaca_agreement", section: "onboarding" })}
          </span>
        </div>
        <Form className={styles.form} layout="vertical" form={form}>
          <fieldset>
            <Form.Item className={styles.flex_wrp}>
              <Checkbox checked={checkbox1} onChange={(e) => onChange(setCheckbox1, e)} value="Брокерское обслуживание">
                {Lang({ str: "alpaca_agreement_4", section: "onboarding" })}{" "}
                <a
                  // href={
                  //   "https://files.alpaca.markets/disclosures/library/AcctAppMarginAndCustAgmt.pdf"
                  // }
                  // target={"_blank"}
                  // rel="noopener noreferrer"
                  onClick={() => modalStore.openModal('pdf', { url: `https://${Utils.apiDomain()}/rest_api/private/v1/getCrossUrl?url=${encodeURIComponent('http://files.alpaca.markets/disclosures/library/AcctAppMarginAndCustAgmt.pdf')}` })}
                >
                  {Lang({ str: "alpaca_agreement_5", section: "onboarding" })}
                </a>
                {Lang({ str: "alpaca_agreement_6", section: "onboarding" })}
              </Checkbox>
            </Form.Item>
          </fieldset>
          <fieldset>
            <span>{Lang({ str: "signature_confirmation", section: "onboarding" })}</span>
            <Form.Item className={styles.flex_wrp}>
              <Checkbox checked={checkbox2} onChange={(e) => onChange(setCheckbox2, e)} value="Брокерское обслуживание">
                {Lang({ str: "alpaca_agreement_7", section: "onboarding" })}
              </Checkbox>
            </Form.Item>
          </fieldset>
        </Form>
      </div>
      <Form.Item className={styles.buttonBlockFixed + " m-0 b-0"}>
        <Button
          type="primary"
          htmlType="submit"
          className={
            !buttonDisabled ? styles.gr_btn + " " + styles.activ : styles.gr_btn
          }
          disabled={buttonDisabled}
          onClick={onFinishForm}
        >
          {Lang({ str: "agree", section: "onboarding" })}
        </Button>
      </Form.Item>
    </div>
  );
};

export default observer(Step8);
