import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import { events } from "./config";
import { apiDomain } from "../Utils"

class Websocket {
  subscribeEvents = {};

  constructor() {
    if (window.GeekoSocket) return;

    window.GeekoSocket = io("wss://"+apiDomain(), {
      transport: ["websocket"],
      path: "/ws",
    });
    window.GeekoSocket.on("connect", () => {
      // console.log("GeekoSocket connect", this.subscribeEvents);
      Object.keys(this.subscribeEvents).forEach((eventId) => {
        // console.log('Websocket subscribeEvents', this.subscribeEvents[eventId])
        window.GeekoSocket.emit("s", {
          eventId,
          event: this.subscribeEvents[eventId].event,
          key: this.subscribeEvents[eventId].key,
        });
      });
    });
    window.GeekoSocket.on("error", (e) => {
      console.error(e);
    });
  }

  /**
   * Подписка на событие
   * @param event
   * @param key
   * @param cb
   * @returns {string|null}
   */
  subscribe({ event, key }, cb) {
    if (!events.has(event)) {
      console.error("Event в конфигах на клиенте не указан", {
        event,
        key,
        cb,
      });
      return null;
    }

    const eventId = uuidv4();
    this.subscribeEvents[eventId] = { event, key, cb };

    if (key) {
      window.GeekoSocket.on(event + "|" + key, cb);
    } else {
      window.GeekoSocket.on(event, cb);
    }

    window.GeekoSocket.emit("s", { eventId, event, key });

    console.log('Websocket subscribe', {eventId, event, key})
    // console.log('Websocket subscribeEvents', this.subscribeEvents)

    return eventId;
  }

  /**
   * Отписка от события
   * @param eventId
   */
  unSubscribe(eventId) {
    if (!this.subscribeEvents[eventId]) return;
    if (this.subscribeEvents[eventId].key) {
      window.GeekoSocket.off(
        this.subscribeEvents[eventId].event +
          "|" +
          this.subscribeEvents[eventId].key,
        this.subscribeEvents[eventId].cb
      );
    } else {
      window.GeekoSocket.off(
        this.subscribeEvents[eventId].event,
        this.subscribeEvents[eventId].cb
      );
    }
    window.GeekoSocket.emit("us", {
      event: this.subscribeEvents[eventId].event,
      key: this.subscribeEvents[eventId].key,
    });
    // console.log('Websocket unSubscribe', {eventId, event: this.subscribeEvents[eventId]})
    delete this.subscribeEvents[eventId];
  }
}

export default new Websocket();
