import React, { useCallback, useEffect, useState } from "react";
import styles from "../BrokerAccountNew/BrokerAccountNew.module.scss";
import { observer } from "mobx-react-lite";
import icon from "../../assets/images/IconButton.svg";
import {useNavigate} from "react-router-dom";
import Lang from "../Lang";
import {Button} from "../../UI";

const Step9 = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.page_fl}>
      <div className={styles.text_bl}>
        <div className={styles.center + " mt-62"}>
          <h4>{Lang({ str: "congrats_1", section: "onboarding" })}</h4>
          <h4>{Lang({ str: "congrats_2", section: "onboarding" })}</h4>
          <p style={ {fontFamily: 'GolosText-Medium'} }>
              {Lang({ str: "congrats_3", section: "onboarding" })}
          </p>
        </div>
      </div>
      <Button className={styles.start_but} onClick={() => navigate('/')}>{Lang({ str: "okay", section: "onboarding" })}</Button>
    </div>
  );
};

export default observer(Step9);
