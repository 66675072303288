import { observer } from 'mobx-react-lite';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Clickable } from '../../UI';
import { $class, $null, shimmer } from '../../Utils';
import image2 from '../../assets/images/perevod.svg';
import image1 from '../../assets/images/popolneniye.svg';
import image3 from '../../assets/images/vivod.svg';
import DollarSign from '../../blocks/DollarSign';
import AlertStore from '../Alert/AlertStore';
import userStore from '../Auth/userStore';
import Lang from '../Lang';
import modalStore from '../ModalManager/modalStore';
import NumberFormat from '../NumberFormat';
import styles from './Accounts.module.scss';

const AccountItem = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = (to) => {
    if (!userStore.userData.role.trader) {
      modalStore.openModal('openBrokerAccount');
    } else navigate(to, { state: location });
  };

  return (
    <button
      onClick={() => {
        if (props.link) navigate(props.link, { state: location });
      }}
      className={styles.account_wp}>
      <p className={styles.account_wp__title}>{props.title}</p>
      {props.number !== undefined && (
        <p className={$class(styles.account_wp__number, shimmer('text', props.number === null))}>
          № {props.number || '__________________'}
        </p>
      )}
      {/* {props.price === null && (
        <span className={styles.account_wp__price}>
          <span className={'loader'}>0000000</span>
        </span>
      )} */}
      <span className={$class(styles.account_wp__price, shimmer('text', props.price === null))}>
        <DollarSign size={14} top={2} right={1} />
        {NumberFormat({
          value: $null(props.price, '99999.99'),
          styleWholePart: { fontSize: 32 },
          styleFractionalPart: { fontSize: 24, color: '#A8A8A8' },
        })}
      </span>
      {props.links && (
        <div className={styles.account_wp__buttons}>
          {props.link1 && (
            <div>
              <div
                className={styles.account_wp__buttons_link}
                onClick={() => {
                  if (props.link1click) props.link1click();
                }}>
                {props.link1}
              </div>
              <span className={$class(shimmer('text', props.invest1 === null))}>
                <DollarSign size={8} top={2} right={1} />
                {NumberFormat({
                  value: $null(props.invest1, '99999.99'),
                  styleWholePart: { fontSize: 16, color: '#221f1c' },
                  styleFractionalPart: { fontSize: 16, color: '#A8A8A8' },
                })}
              </span>
            </div>
          )}
          {props.link2 && (
            <div>
              <div
                className={styles.account_wp__buttons_link}
                onClick={() => {
                  if (props.link1click2) props.link1click2();
                }}>
                {props.link2}
              </div>
              <span className={$class(shimmer('text', props.invest2 === null))}>
                <DollarSign size={8} top={2} right={1} />
                {NumberFormat({
                  value: $null(props.invest2, '99999.99'),
                  styleWholePart: { fontSize: 16, color: '#221f1c' },
                  styleFractionalPart: { fontSize: 16, color: '#A8A8A8' },
                })}
              </span>
            </div>
          )}
        </div>
      )}

      {props.buttons && (
        <div className={styles.account_wp__buttons}>
          {props.button1 && (
            <Clickable
              onClick={() => {
                if (userStore.userData.statusData?.ais === 'done') {
                  navigate(props.src1, { state: location });
                } else {
                  AlertStore.error(Lang({ str: 'replenishment_err', section: 'accounts' }));
                }
                handleNavigation(props.src1);
              }}
              className={styles.account_wp__buttons_popol + ' ' + styles.link}>
              <img src={image1} alt="Пополнение" />
              <span>{Lang({ str: 'replenishment', section: 'accounts' })}</span>
            </Clickable>
          )}
          {props.button2 && (
            <Clickable
              rippleColor="var(--basic-dark40)"
              className={styles.link}
              onClick={() => handleNavigation(props.src2)}>
              <img src={image3} alt="Вывод" />
              <span>{Lang({ str: 'withdrawal', section: 'accounts' })}</span>
            </Clickable>
          )}
          {props.button3 && (
            <Clickable
              rippleColor="var(--basic-dark40)"
              className={styles.link}
              onClick={() => {
                if (props.clickHandler) props.clickHandler();
                handleNavigation(props.src3);
              }}>
              <img src={image2} alt="Перевод" />
              <span>{Lang({ str: 'transfer', section: 'accounts' })}</span>
            </Clickable>
          )}
        </div>
      )}
    </button>
  );
};

export default observer(AccountItem);
