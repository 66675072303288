import React from "react";
import { $class, shimmer } from "../../Utils";
import Icon from "../../components/Icon";
import NumberFormat from "../../components/NumberFormat";
import "./index.scss";

const changingMap = (v) => {
  v = +v;
  if (v > 0) return "positive";
  if (v < 0) return "negative";
  return "neutral";
};

const typesIcon = {
  positive: "growing",
  negative: "falling",
  neutral: "minus",
};

const PriceChanging = ({
  style,
  className,
  change = 0,
  size = "md",
  align = "start",
  withoutIcon = false,
  pnl,
  delimiter,
}) => {
  if (Math.abs(change) === Infinity) change = 100 * Math.sign(change);
  const type = changingMap(change);

  return (
    <div
      className={$class(
        "price-changing",
        `price-changing--${size}`,
        `price-changing--${type}`,
        className
      )}
      style={{ justifyContent: align, ...style }}
    >
      <div className="price-changing-percent">
        {pnl && (
          <div
            className="price-changing-pnl"
            style={{ marginRight: delimiter && 6 }}
          >
            {type == "negative" && "-"}
            {NumberFormat({ value: Math.abs(pnl) })}
          </div>
        )}
        {delimiter && <div className="price-changing-delimiter">/</div>}
        <div className="price-changing-percent-value">
          {type == "negative" && "-"}
          {NumberFormat({
            value: Math.abs(change) || 0,
          })}
          %
        </div>
        {/* {!withoutIcon && <Icon slug={typesIcon[type]} />} */}
      </div>
    </div>
  );
};

export default PriceChanging;
