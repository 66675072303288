import { Carousel, message } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import arrow_left from '../../assets/images/arrow_left.svg';
import arrow_right from '../../assets/images/arrow_right.svg';
import copyicon from '../../assets/images/copyicon.svg';
import manual6 from '../../assets/images/manual6.svg';
import { BackButton } from '../../blocks';
import styles from '../Accounts/Accounts.module.scss';
import AlertStore from '../Alert/AlertStore';
import Lang from '../Lang/index';
import TitleCategory from '../TitleCategory';
import AppStore from "../App/AppStore";

const ManualHalyk = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [active, setActive] = useState(false);
  const categoryConfig = [
    {
      title: Lang({ str: 'replenishment_instructions_in', section: 'accounts' }) + ' Halyk Bank',
    },
  ];

  function copy(text) {
    // navigator.clipboard.writeText(text);
    AlertStore.copy();
  }
  const onChange = (currentSlide) => {
    console.log(currentSlide);
    currentSlide == '5' && setActive(true);
  };

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.next();
  };
  const previous = () => {
    sliderRef.prev();
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const onCopy = useCallback(() => {
    AlertStore.copy();
  }, []);
  return (
    <>
      <div className={styles.account_page_sl}>
        {contextHolder}
        <BackButton label={Lang({ str: 'back', section: 'default' })} />
        <TitleCategory title={categoryConfig[0].title} />
        <div className={styles.info_bl + ' mt-8'}>
          <Carousel
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...settings}
            afterChange={onChange}
          >
            <div className={styles.info_bl_one}>
              <ul>
                <li>{Lang({ str: 'open_halyk', section: 'accounts' })}</li>
                <li>{Lang({ str: 'go_to_transfers', section: 'accounts' })}</li>
                <li>{Lang({ str: 'select_all_transfers', section: 'accounts' })}</li>
              </ul>
              <img className={styles.center_img} src={`/images/halyk_manual_1_${AppStore.lang}.png`} />
            </div>
            <div className={styles.info_bl_one}>
              <ul>
                <li>{Lang({ str: 'in_section_other', section: 'accounts' })}</li>
                <li>{Lang({ str: 'select_to_acc', section: 'accounts' })}</li>
              </ul>
              <img className={styles.center_img} src={`/images/halyk_manual_2_${AppStore.lang}.png`} />
            </div>
            <div className={styles.info_bl_one}>
              <ul>
                <li>{Lang({ str: 'enter_data', section: 'accounts' })}</li>
                <li>
                  {Lang({ str: 'copy_recipient_num', section: 'accounts' })}:
                  <CopyToClipboard onCopy={onCopy} text="KZ64601A861024970331">
                    <div className={styles.requisites_one}>
                      <div>
                        <span>{Lang({ str: 'iban', section: 'accounts' })}</span>
                        <p>KZ64601A861024970331</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                </li>
                <li>
                  {Lang({ str: 'copy_recipient_bin', section: 'accounts' })}:
                  <CopyToClipboard onCopy={onCopy} text="210840900168">
                    <div className={styles.requisites_one}>
                      <div>
                        <span>{Lang({ str: 'bin', section: 'accounts' })}</span>
                        <p>210840900168</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                </li>
              </ul>
              <img className={styles.center_img} src={`/images/halyk_manual_3_${AppStore.lang}.png`} />
            </div>
            <div className={styles.info_bl_one}>
              <ul>
                <li>{Lang({ str: 'enter_data', section: 'accounts' })}</li>
                <li>
                  {Lang({ str: 'copy', section: 'accounts' })} {Lang({ str: 'cbe', section: 'accounts' })}:
                  <CopyToClipboard onCopy={onCopy} text="17">
                    <div className={styles.requisites_one}>
                      <div>
                        <span>{Lang({ str: 'cbe', section: 'accounts' })}</span>
                        <p>17</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                </li>
                <li>
                  {Lang({ str: 'copy', section: 'accounts' })} {Lang({ str: 'knp', section: 'accounts' })}:
                  <CopyToClipboard onCopy={onCopy} text="361">
                    <div className={styles.requisites_one}>
                      <div>
                        <span>{Lang({ str: 'knp', section: 'accounts' })}</span>
                        <p>361</p>
                      </div>
                      <button>
                        <img src={copyicon} />
                      </button>
                    </div>
                  </CopyToClipboard>
                </li>
              </ul>
              <img className={styles.center_img} src={`/images/halyk_manual_4_${AppStore.lang}.png`} />
            </div>
            <div className={styles.info_bl_one}>
              <ul>
                <li>{Lang({ str: 'select_card', section: 'accounts' })}</li>
                <li>{Lang({ str: 'enter_transfer_amount', section: 'accounts' })}</li>
              </ul>
              <img className={styles.center_img} src={`/images/halyk_manual_5_${AppStore.lang}.png`} />
            </div>
            <div className={styles.info_bl_one}>
              <ul>
                <li>{Lang({ str: 'transfer_completed', section: 'accounts' })}</li>
              </ul>
              <img className={styles.center_img} src={manual6} />
            </div>
          </Carousel>

          <div className={styles.info_buttons}>
            <button className="btn" onClick={previous}>
              <img src={arrow_left} />
            </button>
            <button className="btn" onClick={next}>
              <img src={arrow_right} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(ManualHalyk);
