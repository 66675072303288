import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { $class } from '../../Utils';
import { BackButton, TickerOrderHistoryCard } from '../../blocks';
import { useFilter } from '../Filter/useFilter';
import Lang from '../Lang';
import modalStore from '../ModalManager/modalStore';
import VList from '../VList';
import styles from './Wallet.module.scss';
import WalletStore from './WalletStore';

const filterFields = [
  {
    type: 'period',
    name: 'period',
  },
  {
    type: 'choice',
    name: 'action',
    items: [
      {
        value: 'all',
        label: Lang({ str: 'all', section: 'default' }),
      },
      {
        value: 'buy',
        label: Lang({ str: 'purchase', section: 'wallet' }),
      },
      {
        value: 'sell',
        label: Lang({ str: 'sale', section: 'wallet' }),
      },
    ],
  },
  {
    type: 'sum',
    name: 'sum',
  },
];

const History = ({ visible }) => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { FilterChipsWrap, FilterBtn, onResetHandler, openFilter } = useFilter({
    filterFields,
    onUpdateRows: (filter) => WalletStore.getActivities({ filter, isHistory: true }),
  });

  const items = WalletStore.activitiesHistory;
  const filterLoading = WalletStore.activitiesFilterLoading;

  useEffect(() => {
    if (visible === false) onResetHandler();
    if (visible === true) {
      WalletStore.getActivities({ isHistory: true });
    }

    if (visible && search.get('openFilter')) {
      setTimeout(() => {
        openFilter();
      }, 200);
    }
  }, [visible]);

  return (
    <>
      <BackButton onClick={() => navigate('/wallet?tab=3')} />

      <div className={$class(styles.wallet_wrp, styles.wallet_wrp_history)}>
        <div className={styles.wallet_wrp_history_top}>
          <h4 className={styles.wallet_wrp_history_title}>
            {Lang({ str: 'orders_history', section: 'wallet' })}
          </h4>
          <FilterBtn />
        </div>

        <FilterChipsWrap />

        <div className={$class(styles.list, 'orders-list')}>
          {(items === null || filterLoading) && (
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
              <Spin
                indicator={
                  <LoadingOutlined style={{ fontSize: 24, color: 'var(--basic-dark)' }} spin />
                }
              />
            </div>
          )}

          {!filterLoading && (
            <VList style={{ paddingBottom: 40 }} items={items} rowHeight={100} gap={16}>
              {({ item }) => (
                <TickerOrderHistoryCard
                  onClick={() =>
                    modalStore.openModal('orderDetails', {
                      details: item,
                      isHistory: true,
                    })
                  }
                  ticker={item.ticker}
                  order={item}
                />
              )}
            </VList>
          )}
        </div>
      </div>
    </>
  );
};

export default observer(History);
