import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import iconTrustedPerson from '../../assets/images/dovlic.svg';
import iconEmail from '../../assets/images/email.svg';
import iconPass from '../../assets/images/uil_lock.svg';
import urlStore from '../../stores/urlStore';
import userStore from '../Auth/userStore';
import styles from './PersonalInformation.module.scss';

import { BackButton } from '../../blocks';
import Lang from '../Lang/index';
import modalStore from '../ModalManager/modalStore';

const Security = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    urlStore.setSection('');
    window.scroll(0, 0);
  }, []);

  return (
    <div className={styles.personal_information_wrp}>
      <BackButton />

      <div className={styles.personal_information + ' ' + styles.horizont_in}>
        <p className={styles.titile}>{Lang({ str: 'safety', section: 'menu' })}</p>
        <div className={styles.security_link}>
          <ul>
            <li>
              <Link
                state={location}
                className={styles.security_link_item}
                to="/user/security/change_email">
                <img src={iconEmail} alt={''} />
                <div>
                  <p>{Lang({ str: 'email', section: 'menu' })}</p>
                </div>
              </Link>
            </li>
            <li>
              <Link
                state={location}
                className={styles.security_link_item}
                to="/user/security/change_password">
                <img src={iconPass} alt={''} />
                <div>
                  <p>{Lang({ str: 'password', section: 'menu' })}</p>
                </div>
              </Link>
            </li>
            {/*<li>
              <button
                className={styles.security_link_item}
                onClick={() => {
                  userStore?.userData?.statusData?.alpaca === "active"
                    ? navigate("/user/security/change_codeword", {
                      state: location,
                    })
                    : OpenBrokerAccountStore.open();
                }}
              >
                <img src={iconCodeWord} alt={''}/>
                <div>
                  <p>{Lang({str: "code_word_change", section: "menu"})}</p>
                </div>
              </button>
            </li>*/}
            <li>
              <button
                className={styles.security_link_item}
                onClick={() => {
                  userStore?.userData?.statusData?.alpaca === 'active'
                    ? navigate('/user/security/trusted_person', {
                        state: location,
                      })
                    : modalStore.openModal('openBrokerAccount');
                }}>
                <img src={iconTrustedPerson} alt={''} />
                <div>
                  <p>{Lang({ str: 'trusted_person', section: 'menu' })}</p>
                  {/*<span>Измените свое кодовое слово</span>*/}
                </div>
              </button>
            </li>
            {/* <li>
              <Link className={styles.switch_wrp}>
                <img src={bezop15} />
                <div className={styles.switch_bl}>
                  <div>
                    <p>{Lang({ str: "faceid", section: "menu" })}</p>
                  </div>

                  <Switch defaultChecked />
                </div>
              </Link>
            </li>
            <li>
              <Link className={styles.switch_wrp}>
                <img src={bezop14} />
                <div className={styles.switch_bl}>
                  <div>
                    <p>{Lang({ str: "fingerprint", section: "menu" })}</p>
                  </div>
                  <Switch />
                </div>
              </Link>
            </li>*/}
            {/* <li>
              <Link to="#">
                <img src={bezop13} width={"24px"} />
                <div>
                  <p>Двух-факторная аутентификация</p>
                </div>
              </Link>
            </li>*/}
            {/*<li>
              <Link to={"/user/change_email"}>
                <img src={bezop1} />
                <div>
                  <p>Электронный адрес</p>
       
                </div>
              </Link>
            </li>
            <li>
              <Link to="/user/change_phone">
                <img src={bezop2} />
                <div>
                  <p>Номер мобильного телефона</p>
 
                </div>
              </Link>
            </li>*/}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Security;
