export let events = new Set([
  'test',
  'portfolioMy',          // Уведомление об обновлении данных моих коллекций
  'portfolioGeeko',       // Уведомление об обновлении данных коллекций geeko
  'articleCommentCount',  // Уведомление об обновлении данных комментариев в article
  'updateTrading',        // Обновление цены
  'updateBars',           // Обновление барс
  'serviceMessages',      // автоматически подписывающийся event
  'walletBalanceChange',  //Уведомление об изменении баланса
  'newsComments', // Уведомление о добавлении комментария
  'newInbox', // Уведомление о новом уведомлении
  'trader', // Добавление роли трейдер
  'activesChange', // ордера
  'updateNews', // Получение новых новостей
]);
