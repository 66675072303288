import { observer } from 'mobx-react-lite';
import React from 'react';
import { IconButton } from '../../UI';
import styles from './style.module.scss';

const FilterChip = ({ value, type, items = [], onClick }) => {
  if (type === 'period') {
    value = value.map((x) => x.format('DD.MM.YYYY')).join(' - ');
  }

  if (type === 'sum') {
    value = value
      .map((x) => (x !== null ? `$${new Intl.NumberFormat('en-EN').format(x)}` : ''))
      .join(' - ');
  }

  if (type === 'choice') {
    value = items.find(x => x.value === value)?.label || '';
  }

  return (
    <div className={styles.filterChip}>
      <span>{value}</span>
      <IconButton variant="clear" size="sm" icon="circle-cross" onClick={onClick} />
    </div>
  );
};

export default observer(FilterChip);
