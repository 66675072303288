import { makeAutoObservable } from "mobx";

class urlStore {

  section = '';

  constructor() {
    makeAutoObservable(this)
  }

  setSection(value){
    this.section = value
  }

}

export default new urlStore()