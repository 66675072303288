import React from "react";
import {ResponsiveLine} from '@nivo/line'

const ChartTicker = ({data, changePercent = 0}) => {

  let colors = ['#d8811a'];
  if (changePercent>0) colors = ['rgba(58, 204, 55, 0.8)'];
  if (changePercent<0) colors = ['rgba(255, 23, 23, 0.8)'];

  return (
    <ResponsiveLine
      data={[
        {
          id: 1, data
        }
      ]}
      colors={colors}
      lineWidth={2}
      margin={{ top: 1, right: 1, bottom: 1, left: 1 }}
      xScale={{ type: 'linear' }}
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
      yFormat=" >-.2f"
      curve="monotoneX"
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      enableGridX={false}
      enableGridY={false}
      enablePoints={false}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={0}
      isInteractive={false}
      enableCrosshair={false}
      legends={[]}
    />
  )
}

export default ChartTicker;