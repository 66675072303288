import {observer} from "mobx-react-lite";
import userStore from "../Auth/userStore";
import Lang from "../Lang";
import {useNavigate} from "react-router-dom";
import broker_status_icon from "../../assets/images/broker_status_icon.svg";
import React from "react";
import BrokerAccountStore from "../BrokerAccount/BrokerAccountStore";
import styles from './index.module.scss'
import Button from "../../UI/Button";

const BrokerStatusBlock = () => {
  const traderData = BrokerAccountStore.traderData;
  const navigate = useNavigate()

  if (userStore?.userData?.statusData?.brokerForm === 'completed') return (
    <div className={styles.broker_status}>
      <img src={broker_status_icon} alt=''/>
      <p>{Lang({str: 'end_verification', section: 'default'})}</p>
      <p>{Lang({str: 'end_verification_2', section: 'default'})}</p>
    </div>
  )

  if (userStore?.userData?.statusData?.brokerForm === 'in progress') return (
    <div className={styles.broker_status}>
      <img src={broker_status_icon} alt=''/>
      <p>{Lang({str: 'continue_verification', section: 'default'})}</p>
      <p>{Lang({str: 'continue_verification_2', section: 'default'})}</p>
      <Button
        autoWidth
        style={ {backgroundColor: '#221F1C', padding: '12px 40px', marginTop: '20px'} }
        onClick={() => navigate(traderData?.view === "info" ? "/slider" : "/broker_account")}
      >
        {Lang({ str: 'continue', section: 'onboarding' })}
      </Button>
    </div>
  )

  if (userStore?.userData?.statusData?.brokerForm === 'blocked') return (
    <div className={styles.broker_status}>
      <img src={broker_status_icon} alt=''/>
      <p>{Lang({str: 'block_verification', section: 'default'})}</p>
      <p>{Lang({str: 'block_verification_2', section: 'default'})}</p>
    </div>
  )

  return (
    <div className={styles.broker_status_start}>
      <p>{Lang({ str: 'verification_msg', section: 'default' })}</p>
      <p>{Lang({ str: 'verification_msg_2', section: 'default' })}</p>
      <Button
        variant='primary'
        autoWidth
        style={ {padding: '12px 40px', marginTop: '40px'} }
        onClick={() => navigate(traderData?.view === 'info' ? '/slider' : '/broker_account')}
      >
        {Lang({ str: 'start_verification', section: 'default' })}
      </Button>
    </div>
  )
}

export default observer(BrokerStatusBlock)