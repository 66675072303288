import { configure, makeAutoObservable } from "mobx";

configure({ enforceActions: "never" });

const locale = navigator.language;
let lang2;
if (locale === "ru" || locale === "ru-RU") {
  lang2 = "ru";
} else {
  lang2 = "en";
}

class AppStore {
  width = 0;
  height = 0;
  lang = localStorage.getItem("appLang")
    ? localStorage.getItem("appLang")
    : lang2;

  pagesWithHeader = new Set();
  notification = false;

  constructor() {
    makeAutoObservable(this);
  }

  resize(width, height) {
    this.width = width;
    this.height = height;
  }

  showHeader(location) {
    this.headerVisible = true;
    this.pagesWithHeader.add(location);
  }

  disableScroll() {
    document.body.classList.add("scroll-disable");
  }

  enableScroll() {
    document.body.classList.remove("scroll-disable");
  }

  setLang(value) {
    this.lang = value;
    localStorage.setItem("appLang", value);
  }

  setNotificvisible(v) {
    this.notification = v;
  }
}

const clsAppStore = new AppStore();
export default clsAppStore;
