import { configure, makeAutoObservable } from "mobx";
import {apiRequest} from "../../Utils";
import {message} from "antd";
import AlertStore from "../Alert/AlertStore";

configure({ enforceActions: "never" });

class BrokerAccountStore {

  traderData = null;
  docData = null;
  sign = '';

  countriesOptions = [
    {value: 'KAZ', label: 'Казахстан'}
  ]

  constructor() {
    makeAutoObservable(this);
  }

  setStep(v) {
    if(!this.traderData) return;
    this.traderData.step = v;
  }

  setSign(v){
    this.sign = v;
  }

  get() {
    apiRequest({
      url: "/private/v1/trader/data",
      success: (data) => {
        console.log(data)
        if (data.error) return AlertStore.error(data.error?.msg)
        this.traderData = data.data;
        this.docData = data.docData;
      },
      error: (error) => {
        console.error(error);
        if (error.res?.status === 401) this.auth = false;
      },
    }).catch();
  }

  async save(body) {
    body.ver = 2;
    return await apiRequest({
      url: "/private/v1/trader/data",
      method: 'POST',
      body,
      success: (data) => {
        if (data.error) return AlertStore.error(
          data.error?.msg,
          () => this.save({view: 'questionnaire', step:
            data.error?.msg?.includes('Анкета') ? 2 :
            data.error?.msg?.includes('W-8BEN') ? 9 :
            data.error?.msg?.includes('договор') ? 10 :
            data.error?.msg?.includes('Alpaca') ? 8 : null
          }).catch()
        )
        this.traderData = data.data;
        this.docData = data.docData;
        if (data.status === 1) localStorage.removeItem("savedDrawing");
      },
      error: (error) => {
        console.error(error);
        if (error.res?.status === 401) this.auth = false;
      },
    });
  }

  async trustedPersonSave(body) {
    return await apiRequest({
      url: "/private/v1/trustedPerson",
      method: 'POST',
      body: {trustedPerson: body},
      success: (data) => {
        if (data.error) return AlertStore.error(data.error?.msg)
        this.traderData = data.data;
        this.docData = data.docData;
      },
      error: (error) => {
        console.error(error);
        if (error.res?.status === 401) this.auth = false;
      },
    });
  }

}

const clsBrokerAccountStore = new BrokerAccountStore();
export default clsBrokerAccountStore
