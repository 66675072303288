import React, { useCallback, useEffect, useRef } from 'react';
import { Navigate, Route, Routes, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { animated } from 'react-spring';
import AuthLayout from '../../layouts/AuthLayout';
import CabinetLayout from '../../layouts/CabinetLayout';
import Accounts from '../Accounts';
import AddDemoAccount from '../Accounts/AddDemoAccount';
import BetweenAccount from '../Accounts/BetweenAccount';
import WithdrawalBrokerageAccount from '../Accounts/WithdrawalBrokerageAccount';
// import BrokerageAccount from "../Accounts/BrokerageAccount";
// import BrokerageAccountHistory from "../Accounts/BrokerageAccountHistory";
// import DemoAccount from "../Accounts/DemoAccount";

// import DemoAccountHistory from "../Accounts/DemoAccountHistory";
import { observer } from 'mobx-react-lite';
import AccountHistoryPage from '../Accounts/AccountHistoryPage';
import AddBrokerAccount from '../Accounts/AddBrokerAccount';
import BrokerageAccount from '../Accounts/Brokerage';
import ManualBcc from '../Accounts/ManualBcc';
import ManualHalyk from '../Accounts/ManualHalyk';
import ManualOther from '../Accounts/ManualOther';
import ManualSmart from '../Accounts/ManualSmart';
import TradingAccount from '../Accounts/Trading';
import Create from '../Auth/Create';
import Login from '../Auth/Login';
import Restore from '../Auth/Restore';
import VerifyEmail from '../Auth/VerifyEmail';
import userStore from '../Auth/userStore';
import BrokerAccountNew from '../BrokerAccountNew';
import Slider from '../BrokerAccountNew/Slider';
import HeaderComponent from '../Header/HeaderComponent';
import ModalManager from '../ModalManager';
import News from '../News';
import Notification from '../Notification';
import NotificationPage from '../Notification/Notification';
import Signals from '../Notification/Signals';
import PersonalInformation from '../PersonalInformation';
import AddAccounts from '../PersonalInformation/AddAccounts';
import Agreements from '../PersonalInformation/Agreements';
import ChangeAddress from '../PersonalInformation/ChangeAddress';
import ChangeCodeword from '../PersonalInformation/ChangeCodeword';
import ChangeDocument from '../PersonalInformation/ChangeDocument';
import ChangeEmail from '../PersonalInformation/ChangeEmail';
import ChangePassword from '../PersonalInformation/ChangePassword';
import ChangePhone from '../PersonalInformation/ChangePhone';
import EditAccounts from '../PersonalInformation/EditAccounts';
import MyAccounts from '../PersonalInformation/MyAccounts';
import Reports from '../PersonalInformation/Reports';
import Security from '../PersonalInformation/Security';
import SettingsLanguage from '../PersonalInformation/SettingsLanguage';
import SettingsNotification from '../PersonalInformation/SettingsNotification';
import TrustedPerson from '../PersonalInformation/TrustedPerson';
import Verification from '../PersonalInformation/Verification';
import Collection from '../Portfolio/Collection';
import Main from '../Portfolio/Main';
import Portfolio from '../Portfolio/Portfolio';
import SideBarBottom from '../SideBarBottom';
import TickerTrade from '../TickerTrade';
import Trading from '../Trading/Trading';
import TradingFinancial from '../TradingFinancial';
import User from '../User';
import AddBalance from '../Wallet/AddBalance';
import AddBalanceBank from '../Wallet/AddBalanceBank';
import History from '../Wallet/History';
import Wallet from '../Wallet/Wallet';
import WalletTransfer from '../Wallet/WalletTransfer';
import WithdrawalBalance from '../Wallet/WithdrawalBalance';

const overlayRoutes = [
  {
    path: 'favorites/:collectionId',
    element: Collection,
  },
  {
    path: 'news/:newsId',
    element: News,
  },
  /* WALLET */
  {
    path: 'wallet/history',
    element: History,
  },

  /* TRADING */
  {
    path: 'trading/:tickerId',
    element: Trading,
  },
  {
    path: 'trading/:tickerId/financial',
    element: TradingFinancial,
  },
  {
    path: 'trading/:tickerId/trade',
    element: TickerTrade,
  },
  /* TRADING */

  {
    path: 'news2/:newsId',
    element: News,
  },

  /* ACCOUNTS */

  {
    path: 'accounts/brokerage',
    element: BrokerageAccount,
    params: {
      typeAccount: 'brokerage',
    },
  },
  {
    path: 'accounts/brokerage/history',
    // element: BrokerageAccountHistory,
    element: AccountHistoryPage,
    params: {
      typeAccount: 'brokerage',
    },
  },

  {
    path: 'accounts/trading',
    element: TradingAccount,
    params: {
      typeAccount: 'trading',
    },
  },
  {
    path: 'accounts/trading/history',
    element: AccountHistoryPage,
    params: {
      typeAccount: 'trading',
    },
  },

  {
    path: 'accounts/add_demo',
    element: AddDemoAccount,
  },

  {
    path: 'accounts/between_account',
    element: BetweenAccount,
  },

  {
    path: 'accounts/add_broker_account',
    element: AddBrokerAccount,
  },
  {
    path: 'accounts/output_broker_account',
    element: WithdrawalBrokerageAccount,
  },
  {
    path: 'accounts/manual_halyk',
    element: ManualHalyk,
  },
  {
    path: 'accounts/manual_bcc',
    element: ManualBcc,
  },

  {
    path: 'accounts/manual_smartbank',
    element: ManualSmart,
  },
  {
    path: 'accounts/manual_other',
    element: ManualOther,
  },
  /* ACCOUNTS */
  /* PROFILE MENU */
  {
    path: 'user',
    element: User,
  },
  {
    path: 'user/notification',
    element: Notification,
  },
  {
    path: 'user/notification/:notificationId',
    element: NotificationPage,
  },
  {
    path: 'user/security',
    element: Security,
  },

  {
    path: 'user/security/change_password',
    element: ChangePassword,
  },
  {
    path: 'user/security/change_codeword',
    element: ChangeCodeword,
  },
  {
    path: 'user/security/change_email',
    element: ChangeEmail,
  },
  {
    path: 'user/security/trusted_person',
    element: TrustedPerson,
  },
  {
    path: 'user/verification',
    element: Verification,
  },
  {
    path: 'user/change_phone',
    element: ChangePhone,
  },
  {
    path: 'user/change_address',
    element: ChangeAddress,
  },
  {
    path: 'user/reports',
    element: Reports,
  },
  {
    path: 'user/agreements',
    element: Agreements,
  },
  {
    path: 'user/language',
    element: SettingsLanguage,
  },
  {
    path: 'user/my_accounts',
    element: MyAccounts,
  },
  {
    path: 'user/my_accounts/add_account',
    element: AddAccounts,
  },
  {
    path: 'user/my_accounts/edit_account/:id',
    element: EditAccounts,
  },
  /* PROFILE MENU */
  {
    path: 'slider',
    element: Slider,
  },
];

const getOverlayIndex = (loc) =>
  overlayRoutes.findIndex((route) => matchPath(route.path, loc?.pathname || ''));

const isOverlay = (loc) =>
  overlayRoutes.findIndex((route) => matchPath(route.path, loc?.pathname || '')) !== -1;

const Router = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ref = location.state;

  const activeOverlay = useRef(null);
  const prevLocation = useRef(null);
  const overlayPages = useRef(Array(overlayRoutes.length).fill(null));

  // const [wrapProps, wrapApi] = useSpring(() => ({
  //   interactive: "none",
  //   left: 0,
  //   opacity: 0,
  // }));

  // const [overlayProps, overlayApi] = useSprings(overlayRoutes.length, (i) => ({
  //   x: matchPath(overlayRoutes[i].path, location.pathname) ? "0%" : "100%",
  //   zIndex: 0,
  // }));

  const overlayAnimCheck = useCallback((curr, prev) => {
    const $basic = document.querySelector('.basic-routes');
    const $overlay = document.querySelector('.overlay-routes');
    const $overlayAll = document.querySelectorAll('.overlay-routes-page');
    
    // RESET FOR AUTH
    const $auth = document.querySelector('.auth-routes');
    if ($auth && userStore.auth === false) {
      $auth.style.transform = 'none';
      activeOverlay.current = null;
      return;
    }

    if (!$basic || !$overlay || $overlayAll.length === 0) return;

    // Close all overlays if current is basic route
    if (!isOverlay(curr) && activeOverlay.current) {
      $basic.style.transform = 'none';

      activeOverlay.current = null;
    }

    // Reset overlays
    $basic.querySelector('.basic-routes-overlay').style.opacity = 0;
    $overlay.style.pointerEvents = 'none';
    $overlayAll.forEach((x) => (x.style.transform = 'translateX(100%)'));

    // Keep open or open current overlay
    if (isOverlay(curr)) {
      const index = getOverlayIndex(curr);
      activeOverlay.current = { index, location: curr };

      $overlayAll[index].style.transform = 'none';

      $overlay.style.pointerEvents = 'all';
      if (!document.body.classList.contains('modal--active')) {
        $basic.style.transform = 'translateX(-100px)';
        $basic.querySelector('.basic-routes-overlay').style.opacity = 0.3;
      }
    }

    // Keep open ref overlay
    if (isOverlay(prev)) {
      const index = getOverlayIndex(prev);
      // overlayApi.start((i) => (i === index ? { x: "-20%" } : null));
      $overlayAll[index].style.transform = 'translateX(-20%)';
    }
  }, []);

  useEffect(() => {
    overlayAnimCheck(location, ref);

    prevLocation.current = location;
  }, [location, ref, overlayAnimCheck]);

  const closeOverlayPage = () => {
    navigate(-1);

    // overlayAnimCheck(ref, prevRef.current);

    // setTimeout(() => {
    //   navigate(-1);
    // }, 400);
  };

  if (userStore.auth === false)
    return (
      <div>
        <div className="auth-routes">
          <Routes location={ref || location}>
            <Route path="/" element={<AuthLayout />}>
              <Route path="/" element={<Login />} />
              <Route path="login" element={<Login />} />
              <Route path="create" element={<Create />} />
              <Route path="restore" element={<Restore />} />
              <Route path="verify_email" element={<VerifyEmail />} />
              <Route path='*' element={<Navigate to='/' state={{ authRef: location }} />} />
            </Route>
          </Routes>
        </div>
      </div>
    );

  return (
    <div>
      {/* Basic Page */}
      <div className="basic-routes">
        <div className="basic-routes-overlay"></div>

        <Routes location={ref || location}>
          <Route path="/" element={<CabinetLayout />}>
            <Route path="verify_email" element={<VerifyEmail />} />
            <Route path="/" element={<Main />} />
            <Route path="favorites" element={<Portfolio />} />
            {/* [OVERLAY] <Route path="favorites/:collectionId" element={<Collection />} /> */}

            {/* [DISABLED] <Route path="user" element={<UserCard />} /> */}
            <Route path="user/information" element={<PersonalInformation />} />
            <Route path="user/settings_notification" element={<SettingsNotification />} />
            {/* [OVERLAY] <Route path="user/security" element={<Security />} /> */}
            {/* [OVERLAY] <Route path="user/verification" element={<Verification />} /> */}
            {/* [OVERLAY] <Route path="user/reports" element={<Reports />} /> */}
            {/* [OVERLAY] <Route path="user/change_password" element={<ChangePassword />} /> */}
            {/* [OVERLAY] <Route path="user/change_phone" element={<ChangePhone />} /> */}
            {/* [OVERLAY] <Route path="user/change_codeword" element={<ChangeCodeword />} /> */}
            {/* [OVERLAY] <Route path="user/change_email" element={<ChangeEmail />} /> */}
            <Route path="user/change_document" element={<ChangeDocument />} />
            {/* <Route path="user/change_address" element={<ChangeAddress />} />*/}
            {/* [OVERLAY] <Route path="user/language" element={<SettingsLanguage />} /> */}
            {/* [OVERLAY] <Route path="user/agreements" element={<Agreements />} /> */}

            {/* [OVERLAY] <Route path="notification" element={<Notification />} /> */}
            <Route path="notification/signals" element={<Signals />} />

            <Route path="wallet" element={<Wallet />} />
            <Route path="wallet/transfer" element={<WalletTransfer />} />
            <Route path="wallet/add_balance" element={<AddBalance />} />
            <Route path="wallet/add_balance_bank" element={<AddBalanceBank />} />
            <Route path="wallet/withdrawal_balance" element={<WithdrawalBalance />} />

            <Route path="accounts" element={<Accounts />} />
            {/*<Route*/}
            {/*  path="accounts/brokerage_account"*/}
            {/*  element={<BrokerageAccount />}*/}
            {/*/>*/}
            <Route path="broker_account" element={<BrokerAccountNew />} />

            {/*<Route path="broker_account/formw8" element={<FormW />} />*/}
            {/*<Route path="broker_account/dogovor" element={<Dogovor />} />*/}

            {/* [OVERLAY] <Route
              path="accounts/between_account"
              element={<BetweenAccount />}
            /> */}
            {/* [OVERLAY] <Route path="accounts/demo_account" element={<DemoAccount />} /> */}
            {/* [OVERLAY] <Route path="accounts/add_demo" element={<AddDemoAccount />} /> */}
            {/*<Route path="broker_account" element={<BrokerAccount />} />*/}
          </Route>
        </Routes>
      </div>

      <HeaderComponent />
      {!userStore?.userData?.blocking && <SideBarBottom />}

      {/* Overlay Pages */}
      <animated.div className="overlay-routes" id="overlay-routes">
        {overlayRoutes.map((page, i) => {
          const RouteElement = overlayRoutes[i].element;

          const locI = getOverlayIndex(prevLocation.current);
          const visible = !!matchPath(overlayRoutes[i].path, location.pathname);

          return (
            <div
              style={{
                transform: `translate(${visible ? 'none' : '100%'})`,
                backgroundColor: overlayRoutes[i].color,
              }}
              className="overlay-routes-page"
              key={i}
              ref={(el) => (overlayPages.current[i] = el)}>
              <Routes location={(locI === i ? prevLocation.current : null) || location}>
                <Route
                  path={overlayRoutes[i].path}
                  element={
                    <DismountOverlay visible={visible}>
                      <RouteElement
                        $page={overlayPages.current[i]}
                        visible={visible}
                        closeOverlayPage={closeOverlayPage}
                        params={overlayRoutes[i].params || {}}
                      />
                    </DismountOverlay>
                  }
                />
              </Routes>
            </div>
          );
        })}
      </animated.div>

      <ModalManager />
    </div>
  );
};

const DismountOverlay = ({ visible, children }) => {
  // const [show, setShow] = useState(true);
  // const timeout = useRef(null);

  // useEffect(() => {
  //   if (visible === false) {
  //     clearTimeout(timeout.current);
  //     timeout.current = setTimeout(() => {
  //       setShow(false)
  //     }, 400);
  //   } else {
  //     clearTimeout(timeout.current);
  //     setShow(true);
  //   }
  // }, [visible]);

  // if (show) return children;
  // return null;

  return children;
};

export default observer(Router);
