import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BackButton } from '../../blocks';
import styles from '../../components/Accounts/Accounts.module.scss';
import AlertStore from '../Alert/AlertStore';
import Lang from '../Lang';
import modalStore from '../ModalManager/modalStore';
import BankAccountsStore from './BankAccountsStore';

const { TabPane } = Tabs;
const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#7A24EA' }} spin />;

const EditAccounts = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [saveLoad, setSaveLoad] = useState(false);
  const [getLoad, setGetLoad] = useState(false);
  const [delLoad, setDelLoad] = useState(false);
  const [currency, setCurrency] = useState('KZT');

  const onFinish = (values) => {
    setSaveLoad(true);
    values.id = id;
    values.currency = currency;
    BankAccountsStore.edit(values)
      .then(() => {
        setSaveLoad(false);
        AlertStore.success(Lang({ str: 'acc_edited', section: 'menu' }));
        navigate(-1);
      })
      .catch(() => setSaveLoad(false));
  };

  const deleteAccount = () => {
    setDelLoad(true);
    BankAccountsStore.del(id)
      .then(() => {
        setDelLoad(false);
        AlertStore.success(Lang({ str: 'acc_deleted', section: 'menu' }));
        navigate(-1);
      })
      .catch(() => {
        setDelLoad(false);
      });
  };

  useEffect(() => {
    if (!id) return;
    setGetLoad(true);
    BankAccountsStore.get(id)
      .then((data) => {
        setCurrency(data.currency);
        if (data.currency === 'KZT') form.setFieldsValue(data);
        if (data.currency === 'USD') form2.setFieldsValue(data);
        setGetLoad(false);
      })
      .catch(() => {
        setGetLoad(false);
        navigate(-1);
      });
  }, [id]);

  if (getLoad)
    return (
      <div className="container5">
        <BackButton />
        <div className={styles.personal_information + ' ' + styles.horizont_in}>
          <p className={styles.titile}>{Lang({ str: 'edit_acc', section: 'menu' })}</p>
          <Spin indicator={antIcon} />
        </div>
      </div>
    );

  return (
    <div className="container5">
      <BackButton />

      <div className={styles.personal_information + ' ' + styles.horizont_in}>
        <p className={styles.titile}>{Lang({ str: 'edit_acc', section: 'menu' })}</p>

        <div className={styles.tabs_block + ' mt-8'}>
          <div className={styles.tabs1}>
            <div className={styles.block_valute}>
              <Form
                form={form}
                layout="vertical"
                name="basic"
                requiredMark={false}
                onFinish={onFinish}
                labelCol={{
                  span: 24,
                }}
                autoComplete="off">
                <div className={styles.block_valute1}>
                  <Form.Item
                    label={Lang({ str: 'iban', section: 'menu' })}
                    name="iban"
                    rules={[{ required: true }]}>
                    <Input placeholder={Lang({ str: 'enter_iban1', section: 'menu' })} />
                  </Form.Item>
                  <Form.Item
                    label={Lang({ str: 'bank_recipient', section: 'menu' })}
                    name="bankRecipient"
                    rules={[{ required: true }]}>
                    <Select
                      showSearch
                      placeholder={Lang({ str: 'select_bank_recipient', section: 'menu' })}
                      optionFilterProp="children"
                      options={Object.keys(BankAccountsStore.banks).map((key) => {
                        return {
                          value: key,
                          label: BankAccountsStore.banks[key],
                        };
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    label={Lang({ str: 'bic', section: 'menu' })}
                    name="bankRecipientBik"
                    rules={[{ required: true }]}>
                    <Input placeholder={Lang({ str: 'enter_bic', section: 'menu' })} />
                  </Form.Item>
                </div>
                <Form.Item className={styles.buttonBlockFixed + ' m-0 p-0'}>
                  <Button htmlType="submit" loading={saveLoad}>
                    {Lang({ str: 'save', section: 'default' })}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
        <p style={{ textAlign: 'center' }}>
          <Button
            type={'link'}
            style={{ color: '#FB5E49' }}
            onClick={() =>
              modalStore.openModal('confirm', {
                text: `${Lang({ str: 'delete_acc', section: 'menu' })}?`,
                onYes: deleteAccount,
              })
            }>
            {Lang({ str: 'delete_acc', section: 'menu' })}
          </Button>
        </p>
      </div>
    </div>
  );
};

export default EditAccounts;
