import React, {useEffect, useRef, useState} from "react";
import styles from "./Notification.module.scss";
import {Spin, Tabs} from "antd";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import Lang from "../Lang/index";
import {LoadingOutlined} from "@ant-design/icons";
import NotificationItem from "../Notification/NotificationItem";
import notific from "../../assets/images/52-52.svg";
import ModalItem from "../Notification/ModalItem";
import PushNotificationButton from "../PushNotification/PushNotificationButton";
import {apiQuery, apiQueryError} from "../../Utils";
import DrawerMenuStore from "../DrawerMenu/DrawerMenuStore";
import {BackButton} from "../../blocks";
// import TradingStore from "../Trading/TradingStore";
import InfiniteScroll from "react-infinite-scroller";
import NotificationStore from "./NotificationStore";

const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "var(--basic-dark)" }} spin />
);


const Notification = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const { TabPane } = Tabs;
  const [tab, setTab] = useState("1");

  //обработка переключение таба
  function callback(key) {
    setTab(key);
  }

  // Обработка нажатия "Прочитать все"
  const markAllAsRead = () => {
    apiQuery("/private/v1/inboxMarkAllAsRead", {
      method: "POST",
      body: {},
    })
        .then((data) => {
          console.log(data);
          DrawerMenuStore.getCountNotReadInboxes();
          getInboxes({clear:true});
        })
        .catch((error) => {
          apiQueryError({ error });
        });
  };


  // корректировка списка непрочитанных при открытии непрочитанного
  const updateNotReadInboxes = (id)=>{

    // console.log('updateNotReadInboxes')
    NotificationStore.inboxesNotRead = NotificationStore.inboxesNotRead.filter(el => el._id !== id)

    // getInboxes({})
  }


  // Функция подгрузки инбоксов
  const getInboxes = (params) => {

    const {clear=false} = params


    // console.log("activeTab");
    // console.log(tab);

    // Если передали параметр очистки то разрешаем запросы
    if (clear) {
      NotificationStore.inboxesLoadCheck = true
      NotificationStore.inboxesNotReadLoadCheck = true
    }

    // проверка на уже запущенный процесс подгрузки
    if(NotificationStore.fetchCheck){
      return;
    }

    // отмечаем что подгрузка запущена
    NotificationStore.fetchCheck = true

    let skip
    let filter;

    //Активный таб Все
    if (tab === "1") {

      // проверка на конец списка
      if(!NotificationStore.inboxesLoadCheck){
        NotificationStore.fetchCheck =false
        return
      }

      filter = "all";
      skip = clear ? 0 : NotificationStore.inboxes.length
    }

    //Активный таб Непрочитанные
    if (tab === "2") {
      // проверка на конец списка
      if(!NotificationStore.inboxesNotReadLoadCheck){
        NotificationStore.fetchCheck =false
        return
      }
      filter = "new";
      skip = clear ? 0 : NotificationStore.inboxesNotRead.length

    }

    // Активный таб настройки
    if (tab === "3") {

      NotificationStore.fetchCheck =false
      return;
    }

    // console.log(skip)

    apiQuery("/private/v1/accountInboxes", { method: "GET", body: { filter ,skip} })
        .then((data) => {
          // console.log(data);

          let set
          //Активный таб "Непрочитанные"
          if (tab === "2") {

            NotificationStore.inboxesNotReadLoadCheck = data.length>0
            NotificationStore.fetchCheck =false

            // Если был сигнал об очистке то обновляем список
            if(clear){
              return NotificationStore.inboxesNotRead = data;
            }

            // перестраховка от дубляжей
            set = new Set([...NotificationStore.inboxesNotRead, ...data])
            return NotificationStore.inboxesNotRead = Array.from(set);
          }

          //Активный таб Все


          NotificationStore.inboxesLoadCheck = data.length>0
          NotificationStore.fetchCheck =false

          // Если был сигнал об очистке то обновляем список
          if(clear){

            return NotificationStore.inboxes = data;
          }

          // перестраховка от дубляжей
          set = new Set([...NotificationStore.inboxes, ...data])

          // console.log('Записей на странице: '+set.size)

          NotificationStore.inboxes = Array.from(set);
        })
        .catch((error) => {
          NotificationStore.fetchCheck =false
          apiQueryError({ error });
        });
  };

  useEffect(() => {
    getInboxes({clear:true});
  }, [tab]);


  //
  if(!DrawerMenuStore.inboxes){
    DrawerMenuStore.getCountNotReadInboxes()
  }

  console.log(NotificationStore.inboxes);

  return (
      <div className={styles.notificc}>
        <BackButton/>

        <div className={styles.tabs_block + " mt-8"}>
          <Tabs
              className="scroll"
              defaultActiveKey={tab}
              onChange={callback}
              activeKey={tab}
              centered="true"
          >
            <TabPane tab={Lang({ str: "all", section: "default" })} key="1">
              {/* <div className={styles.all_panel}>
              <p>Всего: {inboxes.length}</p>
            </div>*/}
              <div className={styles.height_notific} >

                <InfiniteScroll
                    pageStart={0}
                    loadMore={(e) => {console.log('Страница: '+e); getInboxes({})}}
                    hasMore={NotificationStore.inboxesLoadCheck}
                    useWindow={false}
                    loader={
                      <div className="loader" key={0}>
                        <Spin indicator={antIcon} />
                      </div>
                    }
                >

                  {NotificationStore.inboxes.map((el) => (
                      <NotificationItem
                          key={el._id}
                          data={el}
                          getInboxes={getInboxes}
                      />
                  ))}

                </InfiniteScroll>
              </div>
            </TabPane>
            <TabPane tab={Lang({ str: "unread", section: "menu" })} key="2">
              <div className={styles.height_notific}>
                {DrawerMenuStore.inboxes > 0 ? (
                    <span
                        className={styles.read_all}
                        onClick={() => {
                          markAllAsRead();
                        }}
                    >
                  {Lang({ str: "mark_all_read", section: "menu" })}
                </span>
                ) : (
                    <></>
                )}

                {NotificationStore.inboxesNotRead.length > 0 ? (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={() => getInboxes({})}
                        hasMore={NotificationStore.inboxesNotReadLoadCheck}
                        useWindow={false}
                    >
                      {NotificationStore.inboxesNotRead.map((el) => (
                          <NotificationItem
                              key={el._id}
                              data={el}
                              getInboxes={()=>updateNotReadInboxes(el._id)}
                          />
                      ))}
                    </InfiniteScroll>
                ) : (
                    <div
                        className={
                          NotificationStore.inboxesNotRead.length > 0
                              ? styles.notific_cont
                              : styles.no_notific
                        }
                    >
                      <img src={notific} style={{ maxWidth: "80%" }} />
                    </div>
                )}

              </div>
            </TabPane>
            <TabPane tab={Lang({ str: "settings", section: "menu" })} key="3">
              <div className={styles.security_link}>
                <div className={styles.switch_bl + " b-0"}>
                  <PushNotificationButton />
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
        <ModalItem />
      </div>
  );
};

export default observer(Notification);
