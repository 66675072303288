import { Form } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { Button } from '../../UI';
import { apiQuery, apiQueryError } from '../../Utils';
import AlertStore from '../Alert/AlertStore';

import stylesAuth from '../Auth/Auth.module.scss';
import Lang from '../Lang';
import styles from './index.module.scss';

const TIME_FOR_RESEND = 60;

const propsReactCodeInput = {
  inputStyle: {
    margin: '6px 3% 6px 0',
    width: '14%',
    fontSize: '22px',
    height: 40,
    backgroundColor: '#EAEAEA!important',
    border: 0,
    borderRadius: '4px',
    textAlign: 'center',
    color: '#252525',
  },
};
const ModalSMSVerify = ({ phone: phone_ = null, onCodeVerified, closeModal }) => {
  const [phone, setPhone] = useState(phone_);
  const [code, setCode] = useState('');
  const [time, setTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const otp = useRef(false)

  const codeInputRef = useRef();

  const onSendCode = () => {
    setLoading(true);

    apiQuery('/private/v1/sms_captcha', {
      method: 'POST',
      body: { phone, hostname: window.location.hostname },
    })
      .then((data) => {
        if (data.error) {
          AlertStore.error(data.error.msg);
          return closeModal();
        }

        setTime(TIME_FOR_RESEND);
        if (phone === null) setPhone(data.phone);
      })
      .catch((error) => {
        apiQueryError({ error });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinish = () => {
    if (code.length !== 6)
      return AlertStore.error(Lang({ str: 'enter_code', section: 'accounts' }));

    setLoading(true);
    if (onCodeVerified) onCodeVerified(code);
  };

  const handleVerification = async () => {
    if ("OTPCredential" in window) {
      console.error('OTP запуск');
      try {
        const ac = new AbortController();
        const credential = await navigator.credentials.get({
          otp: { transport: ['sms'] },
          signal: ac.signal,
        });

        if (credential) {
          setCode(credential.code);
          console.log('OTP получен успешно!', credential);
        } else {
          console.error('OTP не получен');
        }
      } catch (error) {
        console.error('Ошибка:', error);
      }
    }
  };

  useEffect(() => {
    handleVerification().catch(console.error)
    onSendCode();

    setTimeout(() => {
      try {
        codeInputRef.current.textInput[0].focus();
      } catch (e) {}
    }, 300);

    const timer = setInterval(() => {
      setTime((x) => {
        if (x <= 0) return null;
        return x - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div style={{ width: 'auto' }} className={stylesAuth.siteLayout + ' pt-0 pr-16 pl-16 pb-0'}>
      <h2 className={styles.title}>{Lang({ str: 'enter_conf_code', section: 'auth' })}</h2>
      <p>
        {Lang({
          str: 'enter_code_2',
          section: 'accounts'
        })} {`*(***) ***-**-${phone ? phone?.slice(-2) : '**'}`}
      </p>
      <p>{Lang({ str: 'enter_code_3', section: 'accounts' })}</p>

      <Form
        layout="vertical"
        style={{ paddingTop: 16 }}
        className={stylesAuth.loginForm + ' ' + stylesAuth.inputs}>
        <div
          style={{
            display: 'block',
            margin: '0px auto 16px',
            width: '100%',
          }}>
          <ReactCodeInput
            ref={codeInputRef}
            type="number"
            fields={6}
            name="code"
            value={code}
            inputMode="numeric"
            autoFocus={false}
            onChange={setCode}
            autoComplete={'one-time-code'}
            {...propsReactCodeInput}
          />
        </div>

        <Button disabled={code?.length !== 6} loading={loading} onClick={onFinish}>
          {Lang({ str: 'confirm', section: 'default' })}
        </Button>

        <div className={styles.resendBlock}>
          {time !== null && (
            <p style={{ color: '#7c7c7c' }}>
              {Lang({ str: 'resend_in', section: 'accounts' })} {time}{' '}
              {Lang({ str: 'seconds', section: 'accounts' })}
            </p>
          )}
          {(time === null) && !loading && (
            <Button
              variant="text"
              style={{ color: '#7c7c7c', cursor: 'pointer' }}
              onClick={onSendCode}>
              {Lang({ str: 'resend', section: 'accounts' })}
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default ModalSMSVerify;
