import { configure, makeAutoObservable } from 'mobx';

configure({ enforceActions: 'never' });

class NotificationStore {
    activeNotificationData = null;
    inboxes=[]
    inboxesNotRead=[]
    inboxesLoadCheck = true
    inboxesNotReadLoadCheck = true
    fetchCheck = false

    constructor() {
        makeAutoObservable(this);
    }

    open(data) {
        this.activeNotificationData = data;
    }

    close() {
        this.activeNotificationData = null;
    }
}

export default new NotificationStore();
