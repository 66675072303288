import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./PersonalInformation.module.scss";
import { Form, Input } from "antd";
import urlStore from "../../stores/urlStore";
import { BackButton } from "../../blocks";
import MaskedInput from "antd-mask-input";
import { Button } from "../../UI";
import BrokerAccountStore from "../BrokerAccountNew/BrokerAccountStore";
import { observer } from "mobx-react-lite";
import Lang from "../Lang";
import AlertStore from "../Alert/AlertStore";
import Utils from "../../Utils";
import modalStore from "../ModalManager/modalStore";

const TrustedPerson = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [load, setLoad] = useState(false);
  const traderData = BrokerAccountStore.traderData;

  const filesItems = [
    {
      file: "https://files.alpaca.markets/disclosures/library/UseAndRisk.pdf",
      title: "Alpaca Use and Risk Disclosures",
    },
    {
      file: "https://files.alpaca.markets/disclosures/library/TermsAndConditions.pdf",
      title: "Alpaca Terms and Conditions",
    },

    {
      file: "https://files.alpaca.markets/disclosures/library/PrivacyNotice.pdf",
      title:
        "What does alpaca securities llc do with your personal information?",
    },

    {
      file: "https://files.alpaca.markets/disclosures/library/PFOF.pdf",
      title: "Alpaca PFOF Disclosure",
    },

    {
      file: "https://files.alpaca.markets/disclosures/library/MarginDiscStmt.pdf",
      title: "Alpaca Margin Disclosure Statement",
    },

    {
      file: "https://files.alpaca.markets/disclosures/library/ExtHrsRisk.pdf",
      title: "Alpaca Extended Hours Trading Risk Disclosure",
    },
    {
      file: "https://files.alpaca.markets/disclosures/library/BCPSummary.pdf",
      title: "Alpaca Business Continuity Plan Summary",
    },
    {
      file: "https://files.alpaca.markets/disclosures/Form+CRS.pdf",
      title: "Customer Relationship Summary (Form CRS)",
    },
  ];

  const onFinish = (values) => {
    (async () => {
      setLoad(true);
      const [err, d] = await BrokerAccountStore.trustedPersonSave(values);
      if (d && !d?.error) {
        AlertStore.success(Lang({ str: "data_saved", section: "menu" }));
        navigate("/user/security");
      }
      setLoad(false);
    })();
  };

  useEffect(() => {
    if (traderData?.trustedPerson) {
      form.setFieldsValue(traderData.trustedPerson);
    }
  }, [traderData]);

  useEffect(() => {
    urlStore.setSection("");
    window.scroll(0, 0);
    BrokerAccountStore.get();
  }, []);

  return (
    <div className="container3">
      <div
        className={
          styles.personal_information_wrp + " " + styles.trusted_person
        }
      >
        <BackButton />
        <div className={styles.security_wrp}>
          <p className={styles.titile + " mb-8"}>
            {Lang({ str: "trusted_person_contact", section: "menu" })}
          </p>
          <p className={styles.title_lit + " mb-16"}>
            {Lang({ str: "fields_required", section: "menu" })}
          </p>
        </div>
        <div className={styles.personal_information}>
          <Form
            form={form}
            className={styles.personal_pas}
            layout="vertical"
            onFinish={onFinish}
          >
            <div>
              <Form.Item
                style={{ marginBottom: "16px!important" }}
                name="name"
                rules={[
                  {
                    required: true,
                    message: Lang({ str: "enter_first_name", section: "menu" }),
                  },
                ]}
              >
                <Input
                  placeholder={Lang({ str: "first_name", section: "menu" })}
                />
              </Form.Item>
              <Form.Item
                name="surname"
                rules={[
                  {
                    required: true,
                    message: Lang({ str: "enter_last_name", section: "menu" }),
                  },
                ]}
              >
                <Input
                  placeholder={Lang({ str: "last_name", section: "menu" })}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: Lang({ str: "enter_email", section: "menu" }),
                  },
                ]}
              >
                <Input placeholder={Lang({ str: "email", section: "menu" })} />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: Lang({ str: "enter_phone", section: "menu" }),
                  },
                ]}
              >
                <MaskedInput
                  maskOptions={{
                    mask: "+7 (000) 000 00 00",
                    lazy: true,
                  }}
                  placeholder={Lang({ str: "phone", section: "menu" })}
                />
                {/* <Input placeholder={Lang({ str: "phone", section: "menu" })} />*/}
              </Form.Item>
            </div>

            <Form.Item
              className={styles.buttonBlockFixed + " " + styles.margin_bottom24}
            >
              <Button loading={load}>
                {Lang({ str: "save", section: "default" })}
              </Button>
            </Form.Item>

            <div className={styles.link_block}>
              {filesItems.map((file, i) => (
                <p key={i} onClick={() => modalStore.openModal('pdf', { url: `https://${Utils.apiDomain()}/rest_api/private/v1/getCrossUrl?url=` + encodeURIComponent(file.file) })}>
                  {file.title}
                </p>
              ))}
            </div>
            <div className={styles.error_block + ' ' + styles.error_block_trusted}>
              <p>{Lang({ str: "trusted_person_is", section: "menu" })}</p>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default observer(TrustedPerson);
