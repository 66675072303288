import { makeAutoObservable } from 'mobx';

class modalStoreClass {
  activeModal = null;

  _open = false;
  _timeout = null;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Открывает модальное окно.
   * @param {'helper' | 'paperDetails' | 'orderDetails' | 'filter' | 'collectionAdd' | 'collectionAddTicker' | 'accountStatement' | 'itemHistoryDetails' | 'addToCollection' | 'openBrokerAccount' | 'SMSVerify' | 'collectionEdit'} name - Имя модального окна.
   * @param {Object} [data={}] - Дополнительные данные для передачи в модальное окно.
   */
  openModal(name, data = {}) {
    
    this.activeModal = { name, data };
    this._open = true;
    clearTimeout(this._timeout);
  }

  closeModal() {
    this._open = false;

    this._timeout = setTimeout(() => {
      this.activeModal = null;
      this._open = false;
    }, 600);
  }
}

const modalStore = new modalStoreClass();

export default modalStore;
