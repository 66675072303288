import React from 'react';
import './index.scss';

const DollarSign = ({ size = 15, top, right, left, white, color, style }) => {
  return (
    <svg
      className="dollar-sign"
      style={{
        width: size,
        height: 'auto',
        transform: `translateY(${top}px)`,
        marginRight: right,
        marginLeft: left,
        ...style,
      }}
      width="13"
      height="25"
      viewBox="0 0 13 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.976 16.1751C11.976 17.6471 11.592 18.7911 10.824 19.6071C10.072 20.4071 9 20.8871 7.608 21.0471V23.0871H5.616V21.0471C4.608 20.9511 3.712 20.6871 2.928 20.2551C2.144 19.8231 1.472 19.2471 0.912 18.5271L2.544 17.0391C2.912 17.5671 3.376 18.0151 3.936 18.3831C4.496 18.7351 5.112 18.9591 5.784 19.0551V13.4151C4.248 13.0471 3.128 12.5111 2.424 11.8071C1.72 11.1031 1.368 10.1351 1.368 8.90309C1.368 7.57509 1.736 6.53509 2.472 5.78309C3.224 5.03109 4.272 4.58309 5.616 4.43909V2.39909H7.608V4.43909C8.536 4.53509 9.344 4.78309 10.032 5.18309C10.72 5.56709 11.296 6.09509 11.76 6.76709L10.152 8.11109C9.464 7.15109 8.552 6.59909 7.416 6.45509V11.5911C8.2 11.7831 8.88 12.0151 9.456 12.2871C10.032 12.5431 10.504 12.8551 10.872 13.2231C11.256 13.5751 11.536 13.9991 11.712 14.4951C11.888 14.9751 11.976 15.5351 11.976 16.1751ZM3.72 8.71109C3.72 9.41509 3.888 9.95909 4.224 10.3431C4.576 10.7111 5.096 10.9911 5.784 11.1831V6.45509C4.408 6.64709 3.72 7.39909 3.72 8.71109ZM9.648 16.4391C9.648 15.7351 9.472 15.1831 9.12 14.7831C8.768 14.3671 8.2 14.0471 7.416 13.8231V19.0311C8.136 18.9031 8.688 18.6311 9.072 18.2151C9.456 17.7831 9.648 17.1911 9.648 16.4391Z"
        fill={color ? color : (white ? 'var(--basic-light)' : 'black')}
      />
    </svg>
  );
};

export default DollarSign;
