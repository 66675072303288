import React, { useState } from "react";
import ImagePreview from "../ImagePreview";
import styles from "./CollectionItem.module.scss";
import PortfolioStore from "../Portfolio/PortfolioStore";
import { Row, Col, Spin, /*Popconfirm,*/ Modal } from "antd";
import close from "../../assets/images/baskett.svg";
import close1 from "../../assets/images/close1.svg";
import Lang from "../Lang/index";
import close3 from "../../assets/images/red_close.svg";
import { observer } from "mobx-react-lite";
import { LoadingOutlined } from "@ant-design/icons";
import CollectionModalOpenStore from "../Portfolio/CollectionModalOpenStore";
import ChartTicker from "../ChartTicker/ChartTicker";
import { AutoSizer } from "react-virtualized";
import { barToChart } from "../../Utils";

const antIcon = (
  <LoadingOutlined style={{ fontSize: "14px", color: "#e6f800" }} spin />
);

function CollectionItem({ id, type }) {
  const item = PortfolioStore.collectionData[id];
  const [load, setLoad] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const collectionDelete = async (collectionId) => {
    console.log("collectionDelete");
    setLoad(true);
    await PortfolioStore.collectionDel(collectionId);
    await PortfolioStore.collectionMyLoad();
    setLoad(false);
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  //   document.body.style.position='relative';
  // };

  const handleCancel = () => {
    setIsModalVisible(false);
    document.body.style.position = "relative";
    PortfolioStore.setCollectionMyEdit(false);
  };

  if (!item) return null;
  if (item.load) return <Spin />;

  return (
    <>
      <Row className="mb-16">
        <Col
          className={styles.width_50}
          flex={"auto"}
          onClick={() => CollectionModalOpenStore.open(id)}
        >
          <div
            className={styles.ticker_item}
            onClick={() => CollectionModalOpenStore.open(id)}
          >
            <Col flex={"auto"} className={styles.block_cont}>
              <AutoSizer disableHeight={true}>
                {({ width }) => (
                  <>
                    <div className={styles.ticker_item__date}>
                      <ImagePreview
                        src={item.logo}
                        iconNoPhoto={false}
                        name={item.name}
                        style={{
                          borderRadius: 50,
                          width: 40,
                          height: 40,
                          flex: "0 0 auto",
                        }}
                      />
                      <div
                        className={styles.ticker_item__text}
                        style={{ width: width - 70 }}
                      >
                        <p>{item.name}</p>
                        <span>
                          {Lang({ str: "stocks1", section: "wallet" })}:{" "}
                          {item.tickers.length}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </AutoSizer>
            </Col>
            {item.tickers.length > 0 && (
              <>
                <Col flex={"60px"} className={styles.block_gr}>
                  <div className={styles.ticker_item__gr}>
                    <div
                      style={{
                        height: 20,
                        width: "100%",
                        // background: '#000',
                        //padding: 5,
                        //borderRadius: 5,
                        //border: "1px solid #2C2D34",
                      }}
                    >
                      <ChartTicker
                        data={barToChart(item.chart)}
                        changePercent={item.percent}
                      />
                    </div>
                  </div>
                </Col>
                <Col flex={"60px"} className={styles.block_pr}>
                  <span
                    className={
                      item.percent < 0
                        ? "minus"
                        : item.percent > 0
                        ? "plus"
                        : ""
                    }
                    style={{ fontSize: 16 }}
                  >
                    {item.percent}%
                  </span>
                </Col>
              </>
            )}
          </div>
        </Col>

        {type && PortfolioStore.collectionMyEdit && (
          <Col flex={"50px"} className={styles.ticker_item__delete}>
            {load ? (
              <button>
                <Spin indicator={antIcon} />
              </button>
            ) : (
              /*<Popconfirm
              title="Вы действительно хотите удалить коллекцию?"
              onConfirm={() => { console.log('onConfirm'); collectionDelete(item._id).catch() }}
              okText="Да"
              cancelText="Нет"
            >
              <button>
                <img src={close} alt="delete" />
              </button>
            </Popconfirm>*/
              <button onClick={showModal}>
                <img src={close} alt="delete" />
              </button>
            )}
          </Col>
        )}
      </Row>
      <Modal
        onOk={() => {
          console.log("onConfirm");
          collectionDelete(item._id).catch();
          PortfolioStore.setCollectionMyEdit(false);
          PortfolioStore.setFavoritesEdit(false);
        }}
        title="Удаление"
        className="modal_delete"
        open={isModalVisible}
        onCancel={handleCancel}
        okText="Удалить"
        cancelText="Отмена"
        closeIcon={<img src={close1} alt={""} />}
      >
        <div className="delete_modal_content">
          <img src={close3} alt="" />
          <p>Вы действительно хотите удалить коллекцию?</p>
        </div>
      </Modal>
    </>
  );
}

export default observer(CollectionItem);
