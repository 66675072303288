import React, { useCallback, useEffect, useState } from "react";
import styles from "./Header.module.scss";
import { observer } from "mobx-react-lite";
import { useNavigate, useLocation } from "react-router-dom";
import { apiQuery } from "../../Utils";
import logo from "../../assets/images/logo_w.svg";
import globus from "../../assets/images/globus.svg";
import urlStore from "../../stores/urlStore";
import userStore from "../Auth/userStore";
import left_icon from "../../assets/images/arrowmenu.svg";
import { Button } from "antd";
import Modal from "../Modal/index";
import AppStore from "../App/AppStore";
import { Alert } from "antd";
import SettingsLanguage from "../PersonalInformation/SettingsLanguage";

const HeaderLang = (props) => {
  const [isIcon, setIsIcon] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  let history = useNavigate();

  const modalOpenLang = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div className={styles.fix_head + " header_glob_flex header"}>
        <div className={styles.header_glob}>
          {props.button1 ? (
            <button
              className={styles.back_bl}
              onClick={() =>
                props.src1 ? userStore.logout("/create") : history(-1)
              }
            >
              <img src={left_icon} /> {props.button1}
            </button>
          ) : (
            <a className="logo_auth" href={"/"}>
              <img src={logo} alt="logo" />
            </a>
          )}
          {isIcon && (
            <Button onClick={modalOpenLang} className={styles.globus}>
              <img src={globus} />
            </Button>
          )}
        </div>
      </div>
      <Modal
        visible={modalOpen}
        cancelVisible={false}
        okVisible={false}
        onCancel={() => {
          setModalOpen(false);
        }}
        onOk={() => {
          setModalOpen(false);
        }}
        className2={styles.header_no}
        // className={styles.auto_height2}
        newheight="auto"
        newheightcontent="auto"
      >
        <div className="p-16" style={{ paddingTop: 0 }}>
          <SettingsLanguage isModal onClick={() => setModalOpen(false)} />
        </div>
      </Modal>
    </>
  );
};

export default observer(HeaderLang);
