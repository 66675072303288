import React, { useEffect, useState, useRef } from "react";
import styles from "../BrokerAccountNew/BrokerAccountNew.module.scss";
import { Button, Form, Input, Checkbox, Radio, Space } from "antd";
import { observer } from "mobx-react-lite";
import BrokerAccountStore from "./BrokerAccountStore";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Lang from "../Lang";
import modalStore from "../ModalManager/modalStore";

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

const Step4 = ({ setBack }) => {
  const ref = useRef();
  const onScreen = useOnScreen(ref);
  const traderData = BrokerAccountStore.traderData;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [form] = Form.useForm();
  const [other, setOther] = useState("");
  const [sourceOfFinancingOther, setSourceOfFinancingOther] = useState("");
  const [value, setValue] = useState("");
  const [pdlValue, setPdlValue] = useState("");

  const onFinishForm = (values) => {
    console.log(values);

    const d = {
      view: "questionnaire",
      step: 5,
      mark: "pdo",
      questionnaire: {
        financialProfile: {
          employment: values.employment,
          nameOfTheOrganization: values.nameOfTheOrganization,
          organizationAddress: values.organizationAddress,
          job: values.job,
          sourceOfFinancing: values.sourceOfFinancing,
          sourceOfFinancingOther,
        },
        additionalInformation: {
          relationFinanceUS: values.relationFinanceUS === "yes",
          relation1934US: values.relation1934US === "yes",
        },
        publicOfficial: {
          pdl: values.pdl,
          me: values.me,
          family: values.family,
        },
        purpose: {
          geekoAims: values.geekoAims,
          geekoAimsOther: other,
        },
      },
    };
    console.log(d);
    BrokerAccountStore.save(d).catch();
  };

  const onChangeRadio = (e) => {
    setValue(e.target.value);
    if (["employed", "selfEmployed"].includes(e.target.value)) {
      form.setFieldValue(
        "nameOfTheOrganization",
        traderData?.questionnaire?.financialProfile?.nameOfTheOrganization
      );
      form.setFieldValue(
        "organizationAddress",
        traderData?.questionnaire?.financialProfile?.organizationAddress
      );
      form.setFieldValue(
        "job",
        traderData?.questionnaire?.financialProfile?.job
      );
    }
  };
  const onChangePdl = (e) => {
    setPdlValue(e.target.value);
  };

  useEffect(() => {
    const scroll = window.document.getElementsByClassName("basic-routes");
    if (scroll[0]) scroll[0].scrollTo(0, 0);

    setBack({
      view: "questionnaire",
      step: 2,
    });
  }, []);

  useEffect(() => {
    if (traderData?.questionnaire?.purpose?.geekoAims) {
      form.setFieldValue(
        "geekoAims",
        traderData?.questionnaire?.purpose?.geekoAims
      );
      setOther(traderData?.questionnaire?.purpose?.geekoAimsOther);
    }
    if (traderData?.questionnaire?.financialProfile?.employment) {
      setValue(traderData?.questionnaire?.financialProfile?.employment);
      form.setFieldValue(
        "employment",
        traderData?.questionnaire?.financialProfile?.employment
      );
      form.setFieldValue(
        "nameOfTheOrganization",
        traderData?.questionnaire?.financialProfile?.nameOfTheOrganization
      );
      form.setFieldValue(
        "organizationAddress",
        traderData?.questionnaire?.financialProfile?.organizationAddress
      );
      form.setFieldValue(
        "job",
        traderData?.questionnaire?.financialProfile?.job
      );
    }
    if (traderData?.questionnaire?.financialProfile?.sourceOfFinancing) {
      setSourceOfFinancingOther(
        traderData?.questionnaire?.financialProfile?.sourceOfFinancingOther
      );
      form.setFieldValue(
        "sourceOfFinancing",
        traderData?.questionnaire?.financialProfile?.sourceOfFinancing
      );
    }
    if (traderData?.questionnaire?.additionalInformation) {
      form.setFieldValue(
        "relationFinanceUS",
        traderData?.questionnaire?.additionalInformation?.relationFinanceUS
          ? "yes"
          : "no"
      );
      form.setFieldValue(
        "relation1934US",
        traderData?.questionnaire?.additionalInformation?.relation1934US
          ? "yes"
          : "no"
      );
    }
    if (traderData?.questionnaire?.publicOfficial) {
      setPdlValue(traderData?.questionnaire?.publicOfficial?.pdl);
      form.setFieldValue("pdl", traderData?.questionnaire?.publicOfficial?.pdl);
      form.setFieldValue("me", traderData?.questionnaire?.publicOfficial?.me);
      form.setFieldValue(
        "family",
        traderData?.questionnaire?.publicOfficial?.family
      );
    }
  }, [traderData]);

  return (
    <>
      <div className={styles.page_fl}>
        <div className={styles.text_bl}>
          <h4>
            {Lang({ str: "aml_cft", section: "onboarding" })}
            <span>{Lang({ str: "other", section: "onboarding" })}</span>
          </h4>

          <Form
            className={styles.form}
            layout="vertical"
            form={form}
            onFinish={onFinishForm}
            requiredMark={true}
            scrollToFirstError={true}
          >
            <fieldset>
              <span>
                {Lang({ str: "aml_question_1", section: "onboarding" })}
              </span>
              <Form.Item
                name={"geekoAims"}
                initialValue={["Брокерское обслуживание на рынке ценных бумаг"]}
              >
                <Checkbox.Group>
                  <Checkbox
                    value="Брокерское обслуживание на рынке ценных бумаг"
                    disabled={true}
                  >
                    {Lang({
                      str: "brokerage_services",
                      section: "onboarding",
                    })}
                  </Checkbox>
                  <Checkbox className={styles.input_text} value={"Прочее"}>
                    {Lang({ str: "other", section: "onboarding" })}
                    <Input
                      value={other}
                      onChange={(e) => {
                        setOther(e.target.value);
                      }}
                    />
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </fieldset>

            <fieldset>
              <span>{Lang({ str: "employment", section: "onboarding" })}</span>
              <Form.Item
                name={"employment"}
                rules={[
                  {
                    required: true,
                    message: Lang({
                      str: "employment_err",
                      section: "onboarding",
                    }),
                  },
                ]}
              >
                <Radio.Group onChange={onChangeRadio} value={value}>
                  <Space direction="vertical">
                    <Radio value={"employed"}>
                      {Lang({ str: "employed", section: "onboarding" })}
                    </Radio>
                    <Radio value={"unemployed"}>
                      {Lang({ str: "unemployed", section: "onboarding" })}
                    </Radio>
                    <Radio value={"pensioner"}>
                      {Lang({ str: "pensioner", section: "onboarding" })}
                    </Radio>
                    <Radio value={"student"}>
                      {Lang({ str: "student", section: "onboarding" })}
                    </Radio>
                    <Radio value={"selfEmployed"}>
                      {Lang({ str: "self_employed", section: "onboarding" })}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </fieldset>
            {(value === "employed" || value === "selfEmployed") && (
              <fieldset className={styles.inputs_bl}>
                <Form.Item
                  label={Lang({
                    str: "organization_name",
                    section: "onboarding",
                  })}
                  name={"nameOfTheOrganization"}
                  rules={[{ required: true, whitespace: true, }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={Lang({
                    str: "organization_address",
                    section: "onboarding",
                  })}
                  name={"organizationAddress"}
                  rules={[{ required: true, whitespace: true, }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={Lang({ str: "job", section: "onboarding" })}
                  name={"job"}
                  rules={[{ required: true, whitespace: true, }]}
                >
                  <Input />
                </Form.Item>
              </fieldset>
            )}

            <fieldset>
              <span>
                {Lang({ str: "financing_source", section: "onboarding" })}
              </span>
              <Form.Item
                name={"sourceOfFinancing"}
                rules={[
                  {
                    required: true,
                    message: Lang({
                      str: "financing_source_err",
                      section: "onboarding",
                    }),
                  },
                ]}
              >
                <Checkbox.Group>
                  <Checkbox value="employment_income">
                    {Lang({ str: "salary", section: "onboarding" })}
                  </Checkbox>
                  <Checkbox value="investments">
                    {Lang({ str: "investments", section: "onboarding" })}
                  </Checkbox>
                  <Checkbox value="inheritance">
                    {Lang({ str: "inheritance", section: "onboarding" })}
                  </Checkbox>
                  <Checkbox value="business_income">
                    {Lang({ str: "business_income", section: "onboarding" })}
                  </Checkbox>
                  <Checkbox value="savings">
                    {Lang({ str: "savings", section: "onboarding" })}
                  </Checkbox>
                  <Checkbox value="family">
                    {Lang({ str: "family", section: "onboarding" })}
                  </Checkbox>
                  {/*<Checkbox className={styles.input_text} value={"Прочее"}>*/}
                  {/*  {Lang({ str: "other", section: "onboarding" })}{" "}*/}
                  {/*  <Input*/}
                  {/*    value={sourceOfFinancingOther}*/}
                  {/*    onChange={(e) => {*/}
                  {/*      setSourceOfFinancingOther(e.target.value);*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*</Checkbox>*/}
                </Checkbox.Group>
              </Form.Item>
            </fieldset>

            <fieldset>
              <p className={styles.brock_link} onClick={() => modalStore.openModal('helper', {
                title: Lang({ str: "pdl_1", section: "onboarding" }),
                text: Lang({ str: "pdl_is", section: "onboarding" })
              })}>
                {Lang({ str: "pdl", section: "onboarding" })}
              </p>
              <Form.Item name={"pdl"} rules={[{ required: true }]}>
                <Radio.Group onChange={onChangePdl} value={pdlValue}>
                  <Space direction="vertical">
                    <Radio value={"nobody"}>
                      {Lang({ str: "pdl_no", section: "onboarding" })}
                    </Radio>
                    <Radio value={"me"}>
                      {Lang({ str: "pdl_yes_1", section: "onboarding" })}
                    </Radio>
                    <Radio value={"family"}>
                      {Lang({ str: "pdl_yes_2", section: "onboarding" })}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </fieldset>
            {pdlValue === "family" && (
              <>
                <Form.List
                  name="family"
                  rules={[{ required: true }]}
                  initialValue={[{}]}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <fieldset className={styles.inputs_bl} key={key}>
                          <Form.Item
                            {...restField}
                            label={Lang({
                              str: "pdl_relationship",
                              section: "onboarding",
                            })}
                            name={[name, "relationship"]}
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label={Lang({
                              str: "pdl_name",
                              section: "onboarding",
                            })}
                            name={[name, "fullName"]}
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            label={Lang({
                              str: "organization_name",
                              section: "onboarding",
                            })}
                            name={[name, "nameOfTheOrganization"]}
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label={Lang({
                              str: "organization_address",
                              section: "onboarding",
                            })}
                            name={[name, "organizationAddress"]}
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label={Lang({
                              str: "job",
                              section: "onboarding",
                            })}
                            name={[name, "job"]}
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>
                          {key > 0 && (
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          )}
                        </fieldset>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          {Lang({ str: "add_note", section: "onboarding" })}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </>
            )}
            {pdlValue === "me" && (
              <Form.List
                name="me"
                rules={[{ required: true }]}
                initialValue={[{}]}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <fieldset className={styles.inputs_bl} key={key}>
                        <Form.Item
                          {...restField}
                          label={Lang({
                            str: "organization_name",
                            section: "onboarding",
                          })}
                          name={[name, "nameOfTheOrganization"]}
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={Lang({
                            str: "organization_address",
                            section: "onboarding",
                          })}
                          name={[name, "organizationAddress"]}
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={Lang({ str: "job", section: "onboarding" })}
                          name={[name, "job"]}
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>
                      </fieldset>
                    ))}
                  </>
                )}
              </Form.List>
            )}

            <fieldset>
              <span>
                {Lang({ str: "aml_question_2", section: "onboarding" })}
              </span>
              <Form.Item name={"relationFinanceUS"} initialValue={"no"}>
                <Radio.Group>
                  <Radio value="no">
                    {Lang({ str: "no", section: "default" })}
                  </Radio>
                  <Radio value="yes">
                    {Lang({ str: "yes", section: "default" })}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </fieldset>
            <fieldset ref={ref}>
              <span>
                {Lang({ str: "aml_question_3", section: "onboarding" })}
              </span>
              <Form.Item name={"relation1934US"} initialValue={"no"}>
                <Radio.Group>
                  <Radio value="no">
                    {Lang({ str: "no", section: "default" })}
                  </Radio>
                  <Radio value="yes">
                    {Lang({ str: "yes", section: "default" })}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </fieldset>

            <Form.Item className={styles.buttonBlockFixed + " m-0 b-0"}>
              <Button
                type="primary"
                htmlType="submit"
                className={
                  onScreen ? styles.gr_btn + " " + styles.activ : styles.gr_btn
                }
                disabled={!onScreen && true}
              >
                {Lang({ str: "continue", section: "onboarding" })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default observer(Step4);
