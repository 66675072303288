import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import ImagePreview from '../ImagePreview';
import TickerStore from '../Ticker/TickerStore';

function CollectionTickerLogo({ tickerId }) {
  const item = TickerStore.tickerData[tickerId];

  useEffect(() => {
    TickerStore.getTicker(tickerId);
  }, [tickerId]);

  if (!item) return null;

  return (
    <ImagePreview
      src={item?.logoData ? item.logoData : item.logo}
      iconNoPhoto={false}
      name={item.symbol}
      style={{ borderRadius: 50, border: '1px solid #E4E5E6' }}
      width={30}
      height={30}
    />
  );
}

export default observer(CollectionTickerLogo);
