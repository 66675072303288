import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { Radio } from "antd";
import { InputNumber, message } from "antd";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Clickable, NumberInput, Tabs } from "../../UI";
import { $class, $null, apiQuery, apiQueryError, shimmer } from "../../Utils";
import dollar from "../../assets/images/dollar.svg";
import {
  BackButton,
  InfoBlock,
  PriceChanging,
  PriceNumber,
  TickerTitle,
} from "../../blocks";
import DollarSign from "../../blocks/DollarSign";
import HelperText from "../../blocks/HelperText";
import Icon from "../Icon";
import NumberFormat from "../NumberFormat";
import TickerStore from "../Ticker/TickerStore";
import userStore from "../Auth/userStore";
import Lang from "../Lang";
import AlertStore from "../Alert/AlertStore";
import modalStore from "../ModalManager/modalStore";

const loadPrice = async (tickerId) => {
  return await apiQuery("/private/v1/trading_price/" + tickerId)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};

const loadAccountOrders = async () => {
  return await apiQuery("/private/v1/get-orders/0")
      .then((data) => {

        return data;
        // return parseFloat(data.cash);
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
};

const loadAccountTrading = async () => {
  return await apiQuery("/private/v1/account-trading")
    .then((data) => {
      if (
        !data ||
        !data.cash ||
        !data.last_long_market_value ||
        !data.last_short_market_value
      )
        return;
      return data;
      // return parseFloat(data.cash);
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};

const loadPaperCount = async (symbol) => {
  return await apiQuery("/private/v1/account-papers")
    .then((data) => {
      if (!data || !Array.isArray(data)) return;

      const paper = data.find((el) => el.symbol === symbol);

      if (paper) return paper.qty;
      return 0;
    })
    .catch((error) => {
      console.error(error);
      apiQueryError({ error });
      return false;
    });
};

const TickerTrade = ({ closeOverlayPage, visible }) => {
  let params = useParams();
  const [orderUnit, setOrderUnit] = useState(1);
  const [orderTime, setOrderTime] = useState("day");

  const [cash, setCash] = useState(null);
  const [price, setPrice] = useState(null);
  const [amount, setAmount] = useState(0);
  const [count, setCount] = useState(0);
  const [side, setSide] = useState("buy");
  const [paperCount, setPaperCount] = useState(0);
  const [orderType, setOrderType] = useState("market");
  const [limitPrice, setLimitPrice] = useState(price);
  const [commission, setCommission] = useState(0);
  const [loadingOrder, setLoadingOrder] = useState(false);

  const [unitPaperEnable, setUnitPaperEnable] = useState(true);
  const [unitUSDEnable, setUnitUSDEnable] = useState(true);
  const [timeDayEnable, setTimeDayEnable] = useState(true);
  const [timeGTCEnable, setTimeGTCEnable] = useState(true);
  const [timeExtendedHoursEnable, setTimeExtendedHoursEnable] = useState(true);
  const [fractionEnable, setFractionEnable] = useState(true);

  const [buttonDisable, setButtonDisable] = useState(false);
  const [sum, setSum] = useState(0);
  const [limitPriceChanged, setLimitPriceChanged] = useState(false)

  const [blockedCash, setBlockedCash] = useState(null)
  const [maxOrderAmount, setMaxOrderAmount] = useState(null)

  useEffect(() => {
    if (side === "buy") {
      if (orderType === "market") {
        setUnitUSDEnable(true);
        setUnitPaperEnable(true);
        setTimeExtendedHoursEnable(false);

        if (orderUnit === 1) {
          setTimeGTCEnable(true);
          if (orderTime === "day") {
            setFractionEnable(true);
          }

          if (orderTime === "gtc") {
            setFractionEnable(false);
            setCount((prev) => Math.trunc(prev));
          }

          if (orderTime === "extended_hours") {
            setOrderTime("day");
          }
        }

        if (orderUnit === 2) {
          setTimeGTCEnable(false);

          if (orderTime === "day") {
          }

          if (orderTime === "gtc") {
            setOrderTime("day");
          }

          if (orderTime === "extended_hours") {
            setOrderTime("day");
          }
        }
      }

      if (orderType === "limit") {
        setTimeExtendedHoursEnable(true);
        setFractionEnable(false);
        setCount((prev) => Math.trunc(prev));
        setUnitUSDEnable(false);
        setUnitPaperEnable(true);
        if (orderUnit === 1) {
          if (orderTime === "day") {
          }

          if (orderTime === "gtc") {
          }

          if (orderTime === "extended_hours") {
          }
        }

        if (orderUnit === 2) {
          setOrderUnit(1);
          if (orderTime === "day") {
          }

          if (orderTime === "gtc") {
          }

          if (orderTime === "extended_hours") {
          }
        }
      }
    }

    if (side === "sell") {
      if (orderType === "market") {
        setTimeExtendedHoursEnable(false);
        setUnitUSDEnable(true);
        if (orderUnit === 1) {
          setTimeGTCEnable(true);
          if (orderTime === "day") {
            setFractionEnable(true);
          }

          if (orderTime === "gtc") {
            setFractionEnable(false);
            setCount((prev) => Math.trunc(prev));
          }

          if (orderTime === "extended_hours") {
            setOrderTime("day");
          }
        }

        if (orderUnit === 2) {
          setTimeGTCEnable(false);
          setFractionEnable(true);
          if (orderTime === "day") {
          }

          if (orderTime === "gtc") {
            setOrderTime("day");
          }

          if (orderTime === "extended_hours") {
            setOrderTime("day");
          }
        }
      }

      if (orderType === "limit") {
        setTimeExtendedHoursEnable(true);
        setFractionEnable(false);
        setCount((prev) => Math.trunc(prev));
        setUnitUSDEnable(false);
        if (orderUnit === 1) {
          if (orderTime === "day") {
          }

          if (orderTime === "gtc") {
          }

          if (orderTime === "extended_hours") {
          }
        }

        if (orderUnit === 2) {
          setOrderUnit(1);
          if (orderTime === "day") {
          }

          if (orderTime === "gtc") {
          }

          if (orderTime === "extended_hours") {
          }
        }
      }
    }
  }, [side, orderType, orderUnit, orderTime]);

  useEffect(() => {
    if (orderUnit === 1) {
      if (orderType === "market") {
        setSum(count * price);
      }
      if (orderType === "limit") {
        setSum(count * limitPrice);
      }
    }
    if (orderUnit === 2) {
      if (orderType === "market") {
        setSum(amount);
      }
      if (orderType === "limit") {
        setSum(amount);
      }
    }
  }, [orderType, orderUnit, count, amount, price, limitPrice]);

  useEffect(() => {
    let commission;
    if (orderType === "market") {
      if (orderUnit === 1) {
        commission = getCommission(Number(count) * Number(price));
      }
      if (orderUnit === 2) {
        commission = getCommission(Number(amount));
      }
    }
    if (orderType === "limit") {
      if (orderUnit === 1) {
        commission = getCommission(Number(count) * Number(limitPrice));
      }
      if (orderUnit === 2) {
        commission = getCommission(Number(amount));
      }
    }

    setCommission(commission);
  }, [orderUnit, orderType]);

  useEffect(() => {
    setLimitPriceChanged(false)
  }, [side, orderType])

  const onChangeOrderUnit = (e) => {
    console.log("OrderUnit checked", e.target.value);
    setOrderUnit(e.target.value);
  };

  const onChangeOrderTime = (e) => {
    console.log("OrderTime checked", e.target.value);
    setOrderTime(e.target.value);
  };

  const tickerId = params.tickerId || null;

  const tickerData = TickerStore.tickerData[tickerId];
  const tickerPercent =
    tickerData &&
    ((tickerData.snapshot?.dailyBar?.c - tickerData.snapshot?.prevDailyBar?.c) /
      tickerData.snapshot?.prevDailyBar?.c) *
      100;

  const loading = !(tickerData && cash && price) || loadingOrder;

  // ==============      RESET    ============== //
  useEffect(() => {
    if (visible) return;

    setCash(null);
    setPrice(null);
    setAmount(0);
    setSide("buy");
    setOrderType("market");
    setLimitPrice(null);
    setPaperCount(0);
    setCount(0);
  }, [visible]);

  function precision(n) {
    return (n.toString().split(".")[1] || "").length;
  }

  // ==============    CHANGERS   ============== //
  const onChangeLimit = (v) => {
    // v = String(v).trim().replace('$', '').replaceAll(' ', '').replace(',', '.')
    // if(isNaN(Number(v)) || Number(v)<0){
    //   v = 0
    // }
    // if(v){

    if (v === "") v = 0;
    let value = v?.toString();
    if (value[value.length - 1] > "0" && value[value.length - 1] < "9") {
      value = Number(value).toString();
    }
    if (precision(value) > 2) {
      value = parseFloat(value).toFixed(2);
    }
    console.log({ value });
    setLimitPrice(value);
    if (orderUnit == 1) {
      setCommission(getCommission(Number(v) * Number(count)));
    }
    // }
  };

  const onChangeOrderSide = (v) => {
    setSide(v);
    onChangeLimit(price);
  };

  const onChangeCount = (v) =>
    setCount((x) => {
      let value = v;
      if (!fractionEnable) {
        value = Math.trunc(value);
      }

      if (value[value.length - 1] > "0" && value[value.length - 1] < "9") {
        value = Number(value).toString();
      }
      let prec = precision(value);
      console.log({ prec });
      if (prec > 9) {
        value = parseFloat(value).toFixed(9);
      }
      if (value < 0) value = 0;
      if (side === "sell" && Number(value) >= Number(paperCount)) value = Number(paperCount);

      if (orderUnit === 1) {
        setCommission(getCommission(value * Number(limitPrice)));
      }

      return value;
    });

  const numberFormat2 = (v) => {
    v = String(v).replace(",", ".");
    return String(v.replace(/(\d)(?=(\d{3})+((\.+\d*$)|$))/g, "$1 "));
  };

  const onChangeAmount = (value) => {
    setAmount((x) => {
      // console.log({value})
      if (value < 0) value = 0;

      if (side === "sell" && value >= paperCount * price)
        value = paperCount * price;

      if (orderUnit === 2) {
        setCommission(getCommission(value));
      }
      // if (value < 0) return 0;
      // if (side === "sell" && value >= paperCount * price)
      //   return paperCount * price;
      return value;
    });
  };

  useEffect(() => {
    console.log({ commission });
  }, [commission]);

  useEffect(() => {
    console.log({ amount });
  }, [amount]);

  const onChangeOrderType = (v) => {
    setLimitPriceChanged(false)
    onChangeLimit(price);
    setOrderType(v);
    let commission;
    if (v === "market") {
      commission = getCommission(Number(amount));
    }
    if (v === "limit") {
      commission = getCommission(Number(count) * Number(limitPrice));
    }

    setCommission(commission);
  };

  const onSellPercent = (percent) => {
    if (side === "sell") {
      const cnt = (paperCount * percent) / 100;
      setAmount(Math.trunc(cnt));
    }
  };

  const getCommission = (amount) => {
    // console.log('getCommission')
    try {
      console.log({ amount });
      if (isNaN(amount)) {
        return 0;
      }
      amount = Number(amount);
      if (!amount) {
        return 0;
      }

      let percent;
      if (userStore.userData.role.worker) {
        percent = 10 / amount + 0.05;
      } else {
        percent = 50 / amount + 0.1;
      }

      return Number(((amount * percent) / 100).toFixed(2));
    } catch (e) {
      return 0;
    }
  };

  // ==============   LOAD DATA   ============== //
  useEffect(() => {
    if (!tickerId || !visible || !tickerData) return;

    // Load data
    (async () => {
      const price_ = await loadPrice(tickerId);
      setPrice(price_);
      if(!limitPriceChanged){
        onChangeLimit(price_);
      }
    })();

    (async () => {
      const data = await loadAccountTrading(tickerId)
      console.log({data})
      const cash_ = data?.cash//await loadCash(tickerId);
      setCash(cash_);
      console.log({cash_})
      const maxOrderAmount_ = data?.max_order_amount
      setMaxOrderAmount(maxOrderAmount_)
    })();

    (async () => {
      const paperCount_ = await loadPaperCount(tickerData?.symbol);
      setPaperCount(paperCount_);
    })();

    (async () => {
      const data = await loadAccountOrders()
      console.log({data})
      let blocked_cash = 0
      if(Array.isArray(data?.orders)){
        for(let  i = 0; i < data?.orders?.length; i++){
          const item = data?.orders[i]
          if(item?.side == 'buy'){
            blocked_cash += Number(item?.amount)
          }
        }
      }
      setBlockedCash(blocked_cash)
      console.log({blocked_cash})
    })();
  }, [tickerId, tickerData, visible]);

  // ============== CREATE ORDERS ============== //
  const createOrder = () => {
    let time_in_force = "day";
    let extended_hours = false;
    if (orderTime == "gtc") {
      time_in_force = "gtc";
    }
    if (orderTime == "extended_hours") {
      extended_hours = true;
    }

    const body = {
      symbol: tickerData.symbol,
      side: side,
      type: orderType,
      price: price,
      time_in_force,
      extended_hours,
    };

    // if (orderType == "market") {
    if (orderUnit == 1) {
      body.qty = Number(count);
    } else {
      body.notional = Number(amount);
    }
    // }
    if (orderType === "limit") {
      body.limit_price = Number(limitPrice);
      // body.qty = Number(count);
    }
    // if (orderType === 'stop') {
    //   data.stop_price = stopPrice;
    // }
    // if (orderType === 'stop_limit') {
    //   data.limit_price = limitPrice;
    //   data.stop_price = stopPrice;
    // }

    console.log({ body });
    setLoadingOrder(true);
    apiQuery("/private/v1/create-order", {
      method: "POST",
      body,
    })
      .then((data) => {
        setLoadingOrder(false);
        // setAmount(0)
        //   setCount(0)
        console.log({ data });
        if (data?.error) {
          if (data?.error?.msg) {
            AlertStore.error(data?.error?.msg);
          } else {
            // message.error(
            //   Lang({ str: "order_rejected", section: "trading_modal" })
            // );
            AlertStore.orderError(data.symbol, '/wallet?tab=2')
          }
          // message.error(data?.error?.msg)
          // return
        } else {
          // message.success(
          //   Lang({ str: "order_issued", section: "trading_modal" })
          // );
          AlertStore.orderSuccess(data.symbol, '/wallet?tab=2')
          // if (limitPrice === null) {
          //   setLimitPrice(price);
          // }
          setLimitPrice(price);
          setCount(0);
          setAmount(0);
        }
        setLoadingOrder(false);
        // setBuyCount(0);
        // setLimitBuyPrice(0);
        // setStopBuyPrice(0);
        // TradingTickerModalStore.modalTradingClose();
        // setVisibleStep(6)
      })
      .catch((error) => {
        // setAmount(0)
        // setCount(0)
        setLoadingOrder(false);
        apiQueryError({ error });
      });
  };

  useEffect(() => {
    if (tickerId && !tickerData) TickerStore.getTicker(tickerId);
  }, [tickerId, tickerData]);

  return (
    <div className="container4">
      <div className={styles.header}>
        <BackButton />
      </div>

      <div className={styles.ticker}>
        <TickerTitle ticker={tickerData} withDesc withSymbol />
        <div className={shimmer('text', tickerData?.snapshot?.dailyBar?.c === undefined)}>
          <PriceNumber
            align="right"
            price={tickerData?.snapshot?.dailyBar?.c ?? 9999}
          />
          <PriceChanging align="end" change={tickerPercent} />
        </div>
      </div>

      <div className={styles.tickerTypes}>
        <Clickable
          onClick={() => onChangeOrderSide("buy")}
          className={$class(styles.tickerTypesItem, [
            styles.tickerTypesItemActive,
            side === "buy",
          ])}
        >
          <Icon slug="growing" />
          <span>{Lang({ str: "buy", section: "trading_modal" })}</span>
        </Clickable>
        <Clickable
          onClick={() => onChangeOrderSide("sell")}
          className={$class(styles.tickerTypesItem, [
            styles.tickerTypesItemActive,
            side === "sell",
          ])}
        >
          <Icon slug="falling" />
          <span>{Lang({ str: "sell", section: "trading_modal" })}</span>
        </Clickable>
      </div>

      <div className={styles.freeFunds}>
        <p onClick={() => modalStore.openModal('helper', {
          title: Lang({ str: "cash", section: "wallet" }),
          text: Lang({ str: "cash_is", section: "wallet" })
        })}>
          {Lang({ str: "cash", section: "wallet" })}
        </p>
        <div style={{ marginTop: 5 }} className={shimmer('text', cash === null)}>
          <DollarSign size={9} top={2} right={0} />
          {NumberFormat({
            value: $null(cash, '9999.99'),
            style: { fontSize: 16 },
            styleWholePart: { color: "var(--basic-dark)" },
          })}
        </div>
      </div>

      <div className={styles.orderType}>
        <h3>{Lang({ str: "order_type", section: "trading_modal" })}</h3>
        <Tabs
          isTabsFlex
          value={orderType}
          onChange={onChangeOrderType}
          items={[
            {
              value: "market",
              label: Lang({ str: "market", section: "trading_modal" }),
            },
            {
              value: "limit",
              label: Lang({ str: "limit", section: "trading_modal" }),
            },
          ]}
        >
          {/* {orderType === "market" && (
            <div className={styles.orderTypeWrap}>
              <label>
                {Lang({ str: "market_price", section: "trading_modal" })}
              </label>
              <PriceNumber
                styles={{ fontSize: 20 }}
                align="center"
                price={price}
              />
            </div>
          )} */}

          <div className={styles.orderTypeWrap + " " + styles.dollarpref + (orderType === "limit" ? ' ' + styles.orderTypeWrapActive : ' ')}>
            <label htmlFor="limit-price">
              {Lang({ str: "limit_price", section: "trading_modal" })}
            </label>

            <NumberInput
              id="limit-price"
              value={limitPrice}
              prefix={""}
              onChange={onChangeLimit}
              // onChange={(e)=> {setLimitPrice(e)}}
              onKeyPress={() => setLimitPriceChanged(true)}
            />
          </div>

        </Tabs>
      </div>

      <div className={styles.block_choice}>
        <p>
          {side === "buy"
            ? Lang({ str: "select_purchase", section: "trading_modal" })
            : Lang({ str: "select_sale", section: "trading_modal" })}
        </p>
        <Radio.Group onChange={onChangeOrderUnit} value={orderUnit}>
          {unitPaperEnable && (
            <Radio value={1}>
              {Lang({ str: "stocks", section: "trading_modal" })}
            </Radio>
          )}
          {unitUSDEnable && (
            <Radio value={2}>
              {Lang({ str: "usd", section: "trading_modal" })}
            </Radio>
          )}
        </Radio.Group>
        <div className={styles.amount}>
          {orderUnit == 1 ? (
            <>
              <h2>
                {Lang({ str: "quantity", section: "trading_modal" })} (
                {Lang({ str: "pieces", section: "default" })})
              </h2>
              <div className={styles.amountWrap}>
                <NumberInput
                  id="amount"
                  value={count}
                  onChange={onChangeCount}
                  onKeyPress={(e) => {
                    console.log({ e });
                    if (!fractionEnable) {
                      let char = e?.key;
                      if (char < "0" || char > "9") {
                        e.preventDefault();
                      }
                    }
                  }}
                />

                {/*<InputNumber*/}
                {/*  inputmode="numeric"*/}
                {/*  defaultValue={0}*/}
                {/*  min={0}*/}
                {/*  formatter={(value) => `${value}`}*/}
                {/*  value={count}*/}
                {/*  onChange={onChangeCount}*/}
                {/*  onKeyPress={(e)=> {*/}
                {/*    console.log({e})*/}
                {/*    if(!fractionEnable){*/}
                {/*      let char = e?.key*/}
                {/*      if(char < '0' || char > '9'){*/}
                {/*        e.preventDefault()*/}
                {/*      }*/}
                {/*    }*/}
                {/*  }}*/}
                {/*/>*/}
              </div>
            </>
          ) : (
            <>
              <h2>
                {Lang({ str: "transaction_amount", section: "trading_modal" })}
              </h2>

              <div className={styles.amountWrap}>
                <NumberInput
                  id="amount"
                  value={amount}
                  prefix={""}
                  onChange={onChangeAmount}
                />
                {/*<InputNumber*/}
                {/*  inputmode="numeric"*/}
                {/*  defaultValue={0}*/}
                {/*  min={0}*/}
                {/*  formatter={(value) => `${numberFormat2(value)}`}*/}
                {/*  value={amount}*/}
                {/*  onChange={onChangeAmount}*/}
                {/*  precision={2}*/}
                {/*/>*/}
              </div>
            </>
          )}
          <p className={styles.komiss_bl}>
            {Lang({ str: "fee", section: "trading_modal" })}{" "}
            <DollarSign size={8} top={2} right={1} color={"#d5b610"} />
            {commission}
          </p>
          <p>{Lang({ str: "select_validity", section: "trading_modal" })}</p>
          <Radio.Group value={orderTime} onChange={onChangeOrderTime}>
            {timeDayEnable && (
              <Radio value={"day"}>
                {Lang({ str: "day", section: "trading_modal" })}
              </Radio>
            )}
            {timeGTCEnable && (
              <Radio value={"gtc"}>
                {Lang({ str: "till_cancel", section: "trading_modal" })}
              </Radio>
            )}
            {timeExtendedHoursEnable && (
              <Radio value={"extended_hours"}>
                {Lang({ str: "day_extended", section: "trading_modal" })}
              </Radio>
            )}
          </Radio.Group>
        </div>
      </div>

      {/*<InfoBlock style={{ marginTop: 30, marginBottom: 32 }}>*/}
      {/*  {Lang({ str: "warning_msg", section: "traidingmodal" })}*/}
      {/*</InfoBlock>*/}
      <div className={styles.buttonBlockFixed}>
        <Button
          loading={loading}
          disabled={(!amount && !count)
          || (sum < 15 && side == 'buy')
          || (amount > cash - blockedCash)
          || (amount > maxOrderAmount)
          || (orderType == 'market' && count * price > cash - blockedCash)
          || (orderType == 'limit'  && count * limitPrice > cash - blockedCash)
          || (orderType == 'market' && count * price > maxOrderAmount)
          || (orderType == 'limit'  && count * limitPrice > maxOrderAmount)
          }
          onClick={createOrder}
        >
          {Lang({
            str: side === "buy" ? "buy" : "sell",
            section: "trading_modal",
          })}
        </Button>
      </div>
    </div>
  );
};

export default observer(TickerTrade);
