import React from 'react';
import { $class } from '../../Utils';
import Icon from '../../components/Icon';
import Clickable from '../Clickable';
import './index.scss';

const IconButton = ({
  onClick,
  icon,
  variant,
  style,
  id,
  iconSize,
  size = 'md',
  loading,
  disabled,
  className,
  children,
}) => {
  return (
    <Clickable
      id={id}
      onClick={onClick}
      style={style}
      disabled={disabled}
      className={$class(
        'ui-icon-button',

        `ui-icon-button--size_${size}`,
        ['ui-icon-button--clear', variant === 'clear'],
        ['ui-icon-button--contained', variant === 'contained'],
        ['ui-icon-button--primary', variant === 'primary'],
        className
      )}>
      <Icon slug={icon} size={iconSize} />
      {children}
    </Clickable>
  );
};

export default IconButton;
