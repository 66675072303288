import { message } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copyicon from '../../assets/images/copyicon.svg';
import { BackButton } from '../../blocks';
import AccountStore from '../Accounts/AccountStore';
import styles from '../Accounts/Accounts.module.scss';
import AlertStore from '../Alert/AlertStore';
import userStore from '../Auth/userStore';
import Lang from '../Lang/index';
import TitleCategory from '../TitleCategory';

const ManualOther = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [active, setActive] = useState(false);
  const categoryConfig = [
    {
      title: Lang({ str: 'replenishment_instructions_in', section: 'accounts' }) + ' ' + Lang({ str: 'other_banks_2', section: 'accounts' }),
    },
  ];

  const onChange = (currentSlide) => {
    console.log(currentSlide);
    currentSlide == '5' && setActive(true);
  };

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.next();
  };
  const previous = () => {
    sliderRef.prev();
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const onCopy = useCallback(() => {
    AlertStore.copy();
  }, []);
  return (
    <>
      <div className={styles.account_page_sl}>
        {contextHolder}
          <BackButton label={Lang({ str: 'back', section: 'default' })} sticky />  {/*зеленые с капсулами*/}

        <TitleCategory title={categoryConfig[0].title} />
        <div className={styles.info_bl + ' mt-8'}>
          <div className={styles.info_bl_one + ' ' + styles.info_bl_other}>
            <ul>
              <li>
                {Lang({ str: 'open_bank_app', section: 'accounts' })}:
                <span>{Lang({ str: 'open_bank_app_2', section: 'accounts' })}</span>
              </li>
              <li>
                {Lang({ str: 'select_transfers_payments', section: 'accounts' })}:
                <span>{Lang({ str: 'select_transfers_payments_2', section: 'accounts' })}</span>
              </li>
              <li>
                {Lang({ str: 'select_international_swift', section: 'accounts' })}:
                <span>{Lang({ str: 'select_international_swift_2', section: 'accounts' })}</span>
              </li>
              <li>
                {Lang({ str: 'select_acc_recipient', section: 'accounts' })}:
                <span>{Lang({ str: 'select_acc_recipient_2', section: 'accounts' })} </span>
              </li>
              <li>
                {Lang({ str: 'enter_transfer_details', section: 'accounts' })}:
                <span>{Lang({ str: 'enter_transfer_details_2', section: 'accounts' })}</span>
              </li>
              <li>
                {Lang({ str: 'confirm_transfer', section: 'accounts' })}:
                <span>{Lang({ str: 'confirm_transfer_2', section: 'accounts' })}</span>
              </li>
              <li>
                {Lang({ str: 'consider_fees', section: 'accounts' })}:
                <span>{Lang({ str: 'consider_fees_2', section: 'accounts' })}</span>
              </li>
            </ul>
          </div>

          <div className={styles.error_block + ' mt-32'}>
            <p>{Lang({ str: 'swift_warning', section: 'accounts' })}</p>
          </div>
          <div>
            <p className={styles.titl_lit}>{Lang({ str: 'requisites', section: 'accounts' })}</p>
            <div className={styles.requisites}>
              <CopyToClipboard onCopy={onCopy} text="KZ64601A861024970331">
                <div>
                  <div>
                    <span>{Lang({ str: 'iban', section: 'accounts' })}</span>
                    <p>KZ64601A861024970331</p>
                  </div>
                  <button>
                    <img src={copyicon} />
                  </button>
                </div>
              </CopyToClipboard>
              <CopyToClipboard onCopy={onCopy} text="ЧК Geeko Invest Limited">
                <div>
                  <div>
                    <span>{Lang({ str: 'recipient', section: 'accounts' })}</span>
                    <p>ЧК Geeko Invest Limited</p>
                  </div>
                  <button>
                    <img src={copyicon} />
                  </button>
                </div>
              </CopyToClipboard>
              <CopyToClipboard onCopy={onCopy} text="210840900168">
                <div>
                  <div>
                    <span>{Lang({ str: 'bin', section: 'accounts' })}</span>
                    <p>210840900168</p>
                  </div>
                  <button>
                    <img src={copyicon} />
                  </button>
                </div>
              </CopyToClipboard>
              <CopyToClipboard onCopy={onCopy} text="АО «Народный Банк Казахстана»">
                <div>
                  <div>
                    <span>{Lang({ str: 'beneficiary_bank', section: 'accounts' })}</span>
                    <p>АО «Народный Банк Казахстана»</p>
                  </div>
                  <button>
                    <img src={copyicon} />
                  </button>
                </div>
              </CopyToClipboard>
              <CopyToClipboard onCopy={onCopy} text="HSBKKZKX">
                <div>
                  <div>
                    <span>{Lang({ str: 'bic', section: 'accounts' })}</span>
                    <p>HSBKKZKX</p>
                  </div>
                  <button>
                    <img src={copyicon} />
                  </button>
                </div>
              </CopyToClipboard>
              <CopyToClipboard onCopy={onCopy} text="361">
                <div>
                  <div>
                    <span>{Lang({ str: 'knp', section: 'accounts' })}</span>
                    <p>361</p>
                  </div>
                  <button>
                    <img src={copyicon} />
                  </button>
                </div>
              </CopyToClipboard>
              <CopyToClipboard onCopy={onCopy} text="17">
                <div>
                  <div>
                    <span>{Lang({ str: 'cbe', section: 'accounts' })}</span>
                    <p>17</p>
                  </div>
                  <button>
                    <img src={copyicon} />
                  </button>
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                onCopy={onCopy}
                text="Brokerage account replenishment Abdykalykova Zarina IIN 950119400010 Account
                      1234567890 according to the application of contract 11235678987654321 dated
                      12.01.2024">
                <div>
                  <div>
                    <span>{Lang({ str: 'payment_purpose', section: 'accounts' })}</span>
                    <p>
                      Brokerage account replenishment {userStore.userData?.fio} IIN {''}
                      {userStore.userData?.iin}. Account №{' '}
                      {AccountStore.accounts.brokerage.number} according to the application of
                      contract номер договора dated дата договора
                    </p>
                  </div>
                  <button>
                    <img src={copyicon} />
                  </button>
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(ManualOther);
