import { LoadingOutlined } from '@ant-design/icons';
import SumsubWebSdk from '@sumsub/websdk-react';
import { Button, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { apiQueryError, apiRequest } from '../../../Utils';
import AppStore from '../../App/AppStore';
import Lang from '../../Lang';
import styles from '../BrokerAccountNew.module.scss';
import BrokerAccountStore from '../BrokerAccountStore';
import './index.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#7A24EA' }} spin />;

const BrokerSumSubSection = ({ levelName = 'geekoinvest', setBack }) => {
  const [updateBtn, setUpdateBtn] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [continueBtn, setContinueBtn] = useState(false);
  const [sumSubData, setSumSubData] = useState({});

  const errorHandler = (e) => {
    console.log(e);
    apiRequest({
      url: '/private/v1/SumSubError',
      method: 'POST',
      body: e,
    }).catch();
  };
  const updateAccessToken = useCallback(() => {
    apiRequest({
      url: '/private/v1/trader/SumSubUpdateAccessToken',
      method: 'POST',
      body: { levelName },
      success: (data) => {
        setAccessToken(data.accessToken);
      },
      error: (error) => {
        apiQueryError({ error });
      },
    }).catch();
  }, [levelName]);

  const handler = (e) => {
    console.log(e);
    setUpdateBtn(true);
  };
  const messageHandler = (type, payload) => {
    console.log({ type, payload });
    let data = {};
    if (type === 'idCheck.applicantStatus' || type === 'idCheck.onApplicantLoaded') {
      data[type] = payload;
    }
    console.log({ type });

    if (type === 'idCheck.applicantStatus') {
      if (payload.reviewStatus === 'completed' && payload?.reviewResult?.reviewAnswer === 'GREEN') {
        setContinueBtn(true);
      }
      // if (payload.reviewStatus === 'pending' ) {
      //   setContinueBtn(true)
      // }
    }
    setSumSubData((val) => {
      for (let key of Object.keys(data)) val[key] = data[key];
      console.log(val);
      return val;
    });
  };

  const onComplete = () => {
    let body = {
      view: levelName === 'geekoinvest' ? 'address' : 'questionnaire',
      step: 1,
    };
    body.sumSub = sumSubData;

    BrokerAccountStore.save(body);
  };

  useEffect(() => {
    if (levelName === 'geekoinvest') {
      setBack({
        view: 'info',
        step: 1,
      });
    } else {
      setBack({
        view: 'address',
        step: 1,
      });
    }
  }, []);

  useEffect(() => {
    updateAccessToken();
  }, [updateAccessToken]);

  if (!accessToken)
    return (
      <div
        style={{
          textAlign: 'center',
          padding: 20,
        }}>
        <Spin indicator={antIcon} />
      </div>
    );

  return (
    <div className="bacc-section bacc-section-sumsub">
      {updateBtn && (
        <Button
          onClick={() => {
            setUpdateBtn(false);
            updateAccessToken();
          }}>
          Обновить
        </Button>
      )}

      <SumsubWebSdk
        accessToken={accessToken}
        expirationHandler={handler}
        config={{
          lang: AppStore.lang === 'en' ? 'en' : 'ru',
        }}
        options={{}}
        onMessage={messageHandler}
        onError={errorHandler}
        updateAccessToken={updateAccessToken}
      />
      {continueBtn && (
        <div className={styles.buttonBlockFixed}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={onComplete}
            className={styles.gr_btn_more}>
            {Lang({ str: 'continue', section: 'onboarding' })}
          </Button>
        </div>
      )}
    </div>
  );
};

export default observer(BrokerSumSubSection);
