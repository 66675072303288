import { Input } from 'antd';
import React from 'react';
import { $class } from '../../Utils';
import './index.scss';

import Icon from '../../components/Icon';

const SearchInput = ({ style, isAccent, value, hideIcon, onChange, placeholder, name, inputProps = {} }) => {
  return (
    <div className={$class('search-input', ['search-input--accent', isAccent])} style={style}>
      <Icon className={$class('search-input-icon', ['hide', hideIcon])} slug="search" />
      <Input
        value={value?.toLocaleUpperCase()}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        {...inputProps}
      />
    </div>
  );
};

export default SearchInput;
