import React, { useEffect, useState, useRef } from "react";
import styles from "../BrokerAccountNew/BrokerAccountNew.module.scss";
import { Button, Row, Col, Form } from "antd";
import { observer } from "mobx-react-lite";
// import CanvasDraw from "react-canvas-draw";
import CanvasDraw from "@win11react/react-canvas-draw";
import clear_ic from "../../assets/images/clear_ic.svg";
import BrokerAccountStore from "./BrokerAccountStore";
import AppStore from "../App/AppStore";
import Lang from "../Lang";

const InputCanvasDraw = observer(({ onChange }) => {
  const canvasDrawRef = useRef(null);

  let width = AppStore.width > 500 ? 500 : AppStore.width - 32;
  if (width < 200) width = 200

  useEffect(() => {
    console.log('CanvasDraw открыт')

    if (canvasDrawRef.current && localStorage.getItem("savedDrawing"))
      canvasDrawRef.current?.loadSaveData(localStorage.getItem("savedDrawing"));

    return () => {
      console.log('CanvasDraw закрыт')
      canvasDrawRef.current?.clear();
      setTimeout(() => {
        canvasDrawRef.current = null;
      }, 500)
    }
  }, []);

  return (
    <>
      <Row>
        <Col flex={"auto"}>
          <p className={styles.text_500}>
            {Lang({ str: "make_signature", section: "onboarding" })}
          </p>
        </Col>
        <Col flex={"20px"}>
          <div style={{ textAlign: "right" }}>
            <Button
              className={styles.clear_ic}
              type="dashed"
              onClick={() => {
                canvasDrawRef.current?.clear();
                onChange("");
                localStorage.removeItem("savedDrawing");
              }}
            >
              <img src={clear_ic} alt={''}/>
              {Lang({ str: "clear", section: "default" })}
            </Button>
          </div>
        </Col>
      </Row>

      <div style={{minHeight: 200, height: width, minWidth: 200, width: width}}>
        <CanvasDraw
          ref={canvasDrawRef}
          className={['mt-24', styles.podpis].join(' ')}
          canvasWidth={width}
          canvasHeight={width}
          brushColor="#177ddc"
          brushRadius={3}
          lazyRadius={0}
          gridColor="#dddddd"
          catenaryColor="#171717"
          onChange={(arg) => {
            onChange(arg.getDataURL());
            localStorage.setItem("savedDrawing", arg.getSaveData());
          }}
        />
      </div>
    </>
  );
});

const Step1 = ({ setBack }) => {
  const traderData = BrokerAccountStore.traderData;
  const docData = BrokerAccountStore.docData;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [form] = Form.useForm();

  const setStep = (step) => {
    let body = {
      view: "questionnaire",
      step,
    };
    BrokerAccountStore.save(body);
  };

  const onFinishForm = (values) => {
    let body = {
      view: "sign",
      step: 1,
      validateFullItems: true
    };
    BrokerAccountStore.save(body);
  };

  useEffect(() => {
    const scroll = window.document.getElementsByClassName("basic-routes");
    if (scroll[0]) scroll[0].scrollTo(0, 0);

    setBack({
      view: "address",
      step: 1,
    });
  }, []);

  return (
    <div className={styles.page_fl}>
      <div className={styles.text_bl}>
        <h4>
          {Lang({ str: "signing_of_documents", section: "onboarding" })}
        </h4>
        <div className={styles.group_link}>
          <span className={(!traderData?.mark?.personalData || !traderData?.mark?.pdo ||
          !traderData?.mark?.financialProfile || !traderData?.mark?.riskAppetite) && styles.markCheck}
                onClick={() => setStep(2)}>
            {Lang({ str: "broker_profile", section: "onboarding" })}
          </span>
          <span className={!docData?.w8ben?.signDate && styles.markCheck} onClick={() => setStep(9)}>
            {Lang({ str: "W8BEN_form", section: "onboarding" })}
          </span>
          <span className={!docData?.brokerageServiceAgreement?.signDate && styles.markCheck} onClick={() => setStep(10)}>
            {Lang({
              str: "brokerage_service_agreement",
              section: "onboarding",
            })}
          </span>
          <span className={!docData?.alpacaCustomerAgreement?.signDate && styles.markCheck} onClick={() => setStep(8)}>
            {Lang({ str: "alpaca_agreement", section: "onboarding" })}
          </span>
        </div>

        <div className="mt-24">
          <Form
            className={styles.form}
            form={form}
            onFinish={onFinishForm}
            // onValuesChange={(changedValues, allValues) => {
            //   console.log(allValues);
            //   if (
            //     form.getFieldsError().some((field) => field.errors.length > 0)
            //   ) {
            //     setButtonDisabled(true);
            //     return;
            //   }
            //   if (allValues.sign) {
            //     setButtonDisabled(false);
            //   } else {
            //     setButtonDisabled(true);
            //   }
            // }}
          >
            {/*<Form.Item*/}
            {/*  name="sign"*/}
            {/*  rules={[*/}
            {/*    {*/}
            {/*      required: true,*/}
            {/*      message: Lang({*/}
            {/*        str: "signature_err",*/}
            {/*        section: "onboarding",*/}
            {/*      }),*/}
            {/*    },*/}
            {/*  ]}*/}
            {/*>*/}
            {/*  <InputCanvasDraw />*/}
            {/*</Form.Item>*/}

            <Form.Item className={styles.buttonBlockFixed + " m-0 b-0"}>
              <Button
                type="primary"
                htmlType="submit"
                className={
                  !buttonDisabled
                    ? styles.gr_btn + " " + styles.activ
                    : styles.gr_btn
                }
                disabled={buttonDisabled}
              >
                {Lang({ str: "continue", section: "onboarding" })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default observer(Step1);
