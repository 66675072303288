const NumberFormat = ({
  value,
  className = '',
  style = {},
  classWholePart = '',
  classFractionalPart = '',
  styleWholePart = {},
  styleFractionalPart = {},
}) => {
  if (value === undefined || value === null) return null;
  else value = Math.abs(+value) < 0.01 ? (+value).toFixed(3) : (+value).toFixed(2);

  value = new Intl.NumberFormat('en-EN').format(value);
  if (value % 1 === 0) return Number.parseFloat(value).toFixed(2);

  if (value == 0) value = '0.00';

  const mass = value.split('.');

  return (
    <span className={className} style={style}>
      <span className={classWholePart} style={styleWholePart}>
        {mass[0]}
      </span>
      {mass[1] && (
        <span className={classFractionalPart} style={styleFractionalPart}>
          .{mass[1]}
        </span>
      )}
    </span>
  );
};

export default NumberFormat;
