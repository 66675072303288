import React, { useEffect, useState } from "react";
import styles from "../BrokerAccountNew/BrokerAccountNew.module.scss";
import { Button, Form, Checkbox } from "antd";
import { observer } from "mobx-react-lite";
import BrokerAccountStore from "./BrokerAccountStore";
import Lang from "../Lang";
import ben8Pdf from "../../assets/8ben.pdf";
import modalStore from "../ModalManager/modalStore";

function urlOpen(url) {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.click();
}

const FormW = ({ setBack = () => {} }) => {
  // const navigate = useNavigate();
  const docData = BrokerAccountStore.docData;
  const [checkOpen, setCheckOpen] = useState(localStorage.getItem("formaW8"));
  const [buttonDisabled, setButtonDisabled] = useState(true);
  // const [form] = Form.useForm();
  // const [url, setUrl] = useState(null);
  // const height = AppStore.height;

  // const generateGetDoc = useCallback(() => {
  //   apiQuery("/private/v1/pdf8benGenerate")
  //     .then((data) => {
  //       if (data?.error) return message.error(data.error.msg);
  //       setUrl(data.url);
  //       if (data.signDate) setButtonDisabled(false)
  //     })
  //     .catch((error) => {
  //       apiQueryError({error});
  //     });
  // }, []);

  const onFinishForm = async () => {
    let body = {
      view: "questionnaire",
      step: 1,
      confirm8ben: true,
    };
    await BrokerAccountStore.save(body);
  };

  // useEffect(() => {
  //   generateGetDoc()
  // }, [generateGetDoc])

  useEffect(() => {
    setButtonDisabled(!localStorage.getItem("formaW8"));
  }, [docData]);

  useEffect(() => {
    const scroll = window.document.getElementsByClassName("basic-routes");
    if (scroll[0]) scroll[0].scrollTo(0, 0);

    setBack({
      view: "questionnaire",
      step: 1,
    });
  }, []);

  // if (url === null) return <div className={styles.page_fl}>
  //   <div className={styles.text_bl}>
  //     <h4>{Lang({ str: "W8BEN_form", section: "brockeraccNew" })}</h4>
  //     <Spin tip={Lang({ str: "doc_generation", section: "brockeraccNew" })}
  //           style={{color: 'rgba(37, 37, 37, 0.6)'}}
  //           indicator={
  //             <LoadingOutlined style={{fontSize: 20, color: 'var(--basic-dark)'}} spin/>
  //           }
  //     >
  //       <div style={{marginTop: 40}}/>
  //     </Spin>
  //   </div>
  // </div>

  return (
    <div className={styles.page_fl}>
      <div className={styles.text_bl}>
        <h4>{Lang({ str: "W8BEN_form", section: "onboarding" })}</h4>

        <Form className={styles.form} layout="vertical">
          <fieldset>
            <p
              className={styles.brock_link}
              onClick={() => modalStore.openModal('helper', {
                  title: Lang({ str: "w8_disclosure", section: "onboarding" }),
                  text: Lang({ str: "us_resident_is", section: "onboarding" })
              })}
            >
              {Lang({ str: "w8_disclosure", section: "onboarding" })}
            </p>
            <Form.Item className={styles.flex_wrp}>
              <Checkbox checked={true} value="Брокерское обслуживание">
                {Lang({ str: "not_us_resident", section: "onboarding" })}
              </Checkbox>
            </Form.Item>
          </fieldset>
          <p className={styles.lit_text + " mb-16"}>
            {Lang({ str: "tax_form", section: "onboarding" })}
          </p>
          <div className={styles.group_link}>
            <span
              onClick={() => {
                modalStore.openModal('pdf', { url: ben8Pdf })
                setButtonDisabled(false);
                setCheckOpen(true);
                localStorage.setItem("formaW8", "open");
              }}
            >
              {Lang({ str: "W8BEN_pdf", section: "onboarding" })}
              {!checkOpen && <b/>}
            </span>
          </div>
        </Form>
      </div>
      <Form.Item className={styles.buttonBlockFixed + " m-0 b-0"}>
        <Button
          type="primary"
          htmlType="submit"
          className={
            !buttonDisabled ? styles.gr_btn + " " + styles.activ : styles.gr_btn
          }
          disabled={buttonDisabled}
          onClick={onFinishForm}
        >
          {Lang({ str: "acknowledged", section: "onboarding" })}
        </Button>
      </Form.Item>
    </div>
  );
};

export default observer(FormW);
